import React, {useState} from 'react';
import {Backdrop, Box, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Slide, Typography} from "@mui/material";
import Button from '../../Shared/button';
import CloseIcon from '@mui/icons-material/Close';
import WalletUnSelect from '../../../images/wallet-unselect.png';
import WalletSelect from '../../../images/wallet-select.png';
import InputField from '../../Shared/inputField';
import helpers from "../../../adapters/helpers";
import {setConfirmDialog} from "../../Shared/ConfirmDialog";
import {ProvidersList} from "../../Shared/connectWallet";
import DialogSuccess from "../../../images/dialog-success.png";

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DefaultWalletScreen = ({walletAddType, setWalletAddType, setWalletScreen, snackBarMessage}) => {

    const nextStep = () => {
        if(!walletAddType) {
            snackBarMessage({message: "Please select the method to add the wallet"});
            return false;
        }
        setWalletScreen(walletAddType);
    };

    return (
        <>
            <Typography variant="body1"><strong>You can Connect or Link an existing wallet to Everest:</strong></Typography>
            <Typography variant="body1" mt={2}><strong>Connect Wallet</strong> - web3 wallet sign in flow</Typography>
            <Typography variant="body1" mt={2}><strong>Link Wallet</strong> - backup the wallet and enable automations and deeper services</Typography>

            <Box className="accordion-list-item cursor-pointer" mt={2} onClick={() => setWalletAddType("connect")}>
                <Grid container>
                    <Grid item={true} xs={10}>
                        <Typography variant="body1"><strong>Connect Web3 Wallet</strong></Typography>
                    </Grid>
                    <Grid item={true} xs={2} className="text-right">
                        <img src={walletAddType === "connect" ? WalletSelect : WalletUnSelect} width={20} alt="radio" />
                    </Grid>
                </Grid>
            </Box>

            <Box className="accordion-list-item cursor-pointer" mt={2} onClick={() => setWalletAddType("link")}>
                <Grid container>
                    <Grid item={true} xs={10}>
                        <Typography variant="body1"><strong>Link Web3 Wallet</strong></Typography>
                    </Grid>
                    <Grid item={true} xs={2} className="text-right">
                        <img src={walletAddType === "link" ? WalletSelect : WalletUnSelect} width={20} alt="radio" />
                    </Grid>
                </Grid>
            </Box>

            <Button onClick={nextStep} sx={{mt: 2}} fullWidth>
                <strong>Continue</strong>
            </Button>
        </>
    )
};

const LinkWalletScreen = ({close, getUserWallets, snackBarMessage}) => {

    const [walletName, setWalletName] = useState('');
    const [privateKey, setPrivateKey] = useState('');
    const [addBtnLoading, setAddBtnLoading] = useState(false);

    const addWallet = () => {
        if(!walletName) {
            snackBarMessage({message: "Please provide the wallet name"});
            return false;
        }

        if(!privateKey) {
            snackBarMessage({message: "Please provide the wallet private key"});
            return false;
        }

        if(!addBtnLoading) {
            setAddBtnLoading(true);
            helpers.linkWallet({name: walletName, privatekey: Buffer.from(privateKey).toString('base64')})
                .then((resp) => {
                    setAddBtnLoading(false);
                    if (resp.data.success) {
                        setConfirmDialog({
                            icon: DialogSuccess,
                            title: "Success",
                            message: "Your wallet has been successfully added to our system.",
                            confirmButtonText: "Continue",
                            onConfirm: () => {getUserWallets(); close();},
                            cancelButtonShow: false
                        });
                    } else {
                        snackBarMessage({message: 'Something went wrong while importing wallet.'});
                    }
                })
                .catch((error) => {
                    setAddBtnLoading(false);
                    const err = JSON.parse(error.request.response);
                    snackBarMessage({message: 'Error: '+err.error});
                });
        }
    };
    return (
        <>
            <Typography className="text-center buy-modal-text-color" variant="h5"><strong>Add Linked Wallet</strong></Typography>
            <InputField label={"Name"} value={walletName} onChangeHandler={(val) => setWalletName(val)} />
            <InputField label={"Private Key"} value={privateKey} onChangeHandler={(val) => setPrivateKey(val)} />
            <Button
                sx={{mt: 4}}
                onClick={addWallet}
                variant="contained"
                fullWidth
            >
                <strong>{addBtnLoading ? <><CircularProgress size={15} color="inherit" /> Processing...</> : "Import Wallet"}</strong>
            </Button>
        </>
    )
};

const ConnectWalletScreen = ({close, setLoader, getUserWallets, snackBarMessage}) => {

    const associateWallet = (walletAddress) => {
        setLoader(true);
        helpers.associateWallet(walletAddress)
            .then(() => {
                setLoader(false);
                setConfirmDialog({
                    icon: DialogSuccess,
                    title: "Success",
                    message: "Your wallet has been successfully associated to our system.",
                    confirmButtonText: "Continue",
                    onConfirm: () => {getUserWallets(); close();},
                    height: '400px',
                    cancelButtonShow: false
                });
            })
            .catch((error) => {
                setLoader(false);
                const err = JSON.parse(error.request.response);
                const errMsg = err.code.message;
                let dispMsg = 'Error: '+err.code.message;
                if(errMsg === "ENTRY_ALREADY_EXISTS") {
                    dispMsg = "This wallet is already connected to our system.";
                }
                snackBarMessage({message: dispMsg});
            });
    };

    return (
        <ProvidersList handleWalletAddress={associateWallet} snackBarMessage={snackBarMessage} />
    )
};

const AddWallet = ({openWalletModal, setOpenWalletModal, getUserWallets, snackBarMessage}) => {
    const [walletScreen, setWalletScreen] = useState('default');
    const [walletAddType, setWalletAddType] = useState('');
    const [loader, setLoader] = useState(false);

    const close = () => {
        setOpenWalletModal(false);
        setTimeout(() => {
            setWalletScreen('default');
            setWalletAddType('');
        }, 500);
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={loader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog fullWidth={true} open={openWalletModal} TransitionComponent={Transition}>
                <DialogTitle className="buy-modal-text-color">
                    <Typography variant="subtitle1" component="p">
                        <strong>Wallets</strong>
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={close}
                        className="menu-buttons"
                    >
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>
                <DialogContent dividers>
                    {
                        {
                            'default': <DefaultWalletScreen snackBarMessage={snackBarMessage} walletAddType={walletAddType} setWalletAddType={setWalletAddType} setWalletScreen={setWalletScreen} />,
                            'link': <LinkWalletScreen snackBarMessage={snackBarMessage} close={close} getUserWallets={getUserWallets} />,
                            'connect': <ConnectWalletScreen setLoader={setLoader} snackBarMessage={snackBarMessage} close={close} getUserWallets={getUserWallets} />
                        }[walletScreen]
                    }
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AddWallet;