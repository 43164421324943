import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import InCode from '../Shared/InCode';
import UserInfo from './userInfo';
import PersonalDetails from './personalDetails';
import PoliticallyExposed from './politicallyExposed';
import countries from "../../json/countries";
import helpers from "../../adapters/helpers";
import {setConfirmDialog} from "../Shared/ConfirmDialog";
import DialogSuccess from "../../images/dialog-success.png";
import {useDispatch, useSelector} from "react-redux";
import {selectBuyData} from "../Buy/reduxSlice";
import {selectAppData, setGetProfileData} from "../../store/reduxSlice";
import InputField from "../Shared/inputField";
import {selectInCodeData} from "../Shared/InCode/reduxSlice";
import {INCODE_KYC_FLOW_ID} from "../../js/variables";
import {setRegion} from "../../store/reduxUserSlice";
import {COUNTRY_ISO3_US, REGION_EU, REGION_US} from "../../js/constants";

const Kyc = ({snackBarMessage}) => {

    const buyData = useSelector(selectBuyData);
    const appData = useSelector(selectAppData);
    const inCodeSessionData = useSelector(selectInCodeData).sessionData;
    const service = appData.sdkConfiguration.service;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showScreen, setShowScreen] = useState('selectDocument');
    const [processKycLoader, setProcessKycLoader] = useState(false);

    const userInfoInitial = {gender: '', email: '', address: '', city: '', state: '', zipCode: '', placeOfBirth: '', country: '', nationality: '', occupation: '', employer: '', isPoliticalExpose: '', politicalExpose: '', origin: '', frequency: '', reason: ''};
    const [userInfo, setUserInfo] = useState(userInfoInitial);
    const setInitialState = () => {
        setShowScreen('selectDocument');
        setUserInfo(userInfoInitial);
    };

    const handleSuc = () => {
        let region = REGION_EU;
        if(userInfo.country.alpha3Code === COUNTRY_ISO3_US) {
            region = REGION_US;
        }
        dispatch(setRegion(region));
        dispatch(setGetProfileData(!appData.getProfileData));
        if(service === "identity") {
            navigate('/dashboard');
        } else if(buyData.address) {
            navigate('/confirm/transaction');
        } else {
            navigate('/');
        }
    };

    const processKyc = (isPoliticalExpose, politicalExpose, frequency, reason, source, originFunds) => {
        if(!processKycLoader) {
            setProcessKycLoader(true);
            const residentialAddress = {street: userInfo.address, city: userInfo.city, postcode: userInfo.zipCode, state: userInfo.state, country: userInfo.country.alpha3Code};
            const politicalExposure = {
                isExposed: isPoliticalExpose,
                exposed: politicalExpose,
                frequency,
                reason,
                source,
                originFunds
            };
            const customerDueDiligence = {
                frequency,
                reason,
                source,
                originFunds
            };
            const reqData = {email: userInfo.email, gender: userInfo.gender, birthPlace: userInfo.placeOfBirth.alpha3Code, nationality: userInfo.nationality.alpha3Code, occupation: userInfo.occupation, employer: userInfo.employer, residentialAddress, politicalExposure, customerDueDiligence, workflow: {token: inCodeSessionData.token}};
            helpers.uploadKycData(reqData)
                .then((response) => {
                    setTimeout(() => {
                        setProcessKycLoader(false);
                        if (response.data.success) {
                            let msg = "It will take a few minutes for your account to be approved.";
                            let buttonText = "Let’s get started";
                            let title = "KYC Successful";
                            if(service === "identity") {
                                msg = "KYC Submission Complete. You may now view your Identity Wallet with your KYC status.";
                                buttonText = "Open Identity Wallet";
                            }
                            if(response.data.data.status === "NEED_REVIEW") {
                                title = "In compliance review";
                                msg = "Your KYC review is in process. Please be patient, this review can take a little while, we will contact you via email when complete."
                                buttonText = "Open Identity Wallet";
                            }
                            setConfirmDialog({
                                icon: DialogSuccess,
                                title: title,
                                message: msg,
                                cancelButtonShow: false,
                                confirmButtonText: buttonText,
                                height: '450px',
                                onConfirm: () => handleSuc()

                            });
                        } else {
                            window.alert(
                                'Something went wrong while uploading documents. Please try again'
                            );
                        }
                    }, 10000);
                })
                .catch((error) => {
                    setProcessKycLoader(false);
                    setConfirmDialog({
                        title: "Can not serve you at this time",
                        message: "Unfortunately we are not able to provide service to you at this time. We appreciate your interest in the Everest platform  and our service offerings and hope to be able to serve you in the future.",
                        confirmButtonText: "Try again",
                        onConfirm: () => {setInitialState();},
                        cancelButtonText: "Contact Everest",
                        onCancel: () => {navigate('/')},
                    });
                    const myObject = JSON.parse(error.request.response);
                    if (myObject?.code?.message) {
                        if (myObject?.code?.message === 'VALIDATION_FAILED') {
                            snackBarMessage({
                                message: 'Please provide all the values in the form.',
                                type: 'error'
                            });
                        } else {
                            window.alert(myObject.code.message);
                        }
                    } else {
                        window.alert(error);
                    }
                });
        }
    };

    const inCodeSuccess = () => {
        setShowScreen('userInfo')
    };

    if(showScreen === 'selectDocument') {
        return <InCode
            autoStart={true}
            successHandler={inCodeSuccess}
            configurationId={INCODE_KYC_FLOW_ID}
        />
    } else if(showScreen === 'userInfo') {
        return <UserInfo
            snackBarMessage={snackBarMessage}
            setShowScreen={setShowScreen}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            InputField={InputField}
            countries={countries}
        />
    } else if(showScreen === 'personalDetails') {
        return <PersonalDetails
            snackBarMessage={snackBarMessage}
            setShowScreen={setShowScreen}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            InputField={InputField}
            countries={countries}
        />
    } else if(showScreen === 'politicallyExposed') {
        return <PoliticallyExposed
            snackBarMessage={snackBarMessage}
            setShowScreen={setShowScreen}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            countries={countries}
            processKycLoader={processKycLoader}
            processKyc={processKyc}
        />
    }
};

export default Kyc;