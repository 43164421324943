import InfoIcon from "../../images/info.png";
import { setConfirmDialog } from "./ConfirmDialog";

export const handleOrderError = ({error, confirmFunc, quoteConfirmFunc, minErrorFunc, maxErrorFunc, snackBarMessage, navigate}) => {
    if (error.response) {
        const errorObject = JSON.parse(error.request.response);
        switch (errorObject.code.code) {
            case 1043: //MINIMAL_KYC_REQUIRED
                navigate('/kyc-pass');
                break;
            case 1044: //FULL_KYC_REQUIRED
                setConfirmDialog({
                    title: "KYC Required",
                    message: "Your transaction amount meets the requirements for a KYC check.",
                    confirmButtonText: "Start KYC",
                    onConfirm: () => {navigate('/kyc');},
                    cancelButtonText: "Cancel",
                });
                break;
            case 1049: //QUOTE_EXPIRED
                setConfirmDialog({
                    title: "Quote Expired.",
                    message: "Estimation data has been expired. Please click continue to get the new estimation data to proceed with the order.",
                    onConfirm: quoteConfirmFunc,
                    confirmButtonText: "Continue",
                    cancelButtonShow: false,
                    height: '490px',
                });
                break;
            default:
                const respData = orderErrorMessages(error);
                if(respData.minError && minErrorFunc) {
                    minErrorFunc();
                } else if(respData.maxError && maxErrorFunc) {
                    maxErrorFunc();
                }
                if(respData.type === "popup") {
                    const height = respData.height ? respData.height : "auto";
                    setConfirmDialog({
                        icon: respData.icon,
                        title: respData.title,
                        message: respData.message,
                        confirmButtonText: "Ok",
                        onConfirm: confirmFunc,
                        cancelButtonShow: false,
                        height
                    });
                } else {
                    snackBarMessage({message: respData.message});
                }
        }
    }
};

const orderErrorMessages = (error) => {
    const errorObject = JSON.parse(error.request.response);
    switch (errorObject.code.code) {
        case 1051: //TRANSACTION_NEEDS_APPROVAL
            return { icon: InfoIcon, title: "Your transaction is under review, please be patient.", message: "You are attempting a transaction which requires compliance review. This can take up to an hour depending on volume of requests.", height: "470px", type: "popup" };
        case 1052: //TRANSACTION_CANT_BE_PROCESSED
            return { message: "This transaction cannot be processed", type: "snackbar" };
        case 1053: //TRANSACTION_HAS_BEEN_REJECTED
            return { message: "This transaction has been rejected", type: "snackbar" };
        case 1054: //AMOUNT_HIGHER_MAXIMUM
            return { message: "This transaction amount is above our maximum, please try a lower amount.", type: "snackbar", maxError: true };
        case 1055: //AMOUNT_BELOW_MINIMUM
            return { message: "This transaction amount is below our minimum, please try a higher amount.", type: "snackbar", minError: true };
        case 1056: //TOTAL_FEE_IS_MORE_THAN_SOURCE_AMOUNT
            return { message: "The fees for this transaction are more than the input amount, pease try a higher amount.", type: "snackbar" };
        case 1057: //UNSUPPORTED_TRANSACTION_TYPE
            return { message: "This transaction can not be completed at this time.", type: "snackbar" };
        case 1058: //KYC_IN_PROGRESS
            return { message: "Your account is in KYC review.", type: "snackbar" };
        case 1059: //BAD_KYC_STATUS
            return { message: "Your account is in KYC review.", type: "snackbar" };
        case 1060: //ONLY_EVER_WALLET_ALLOWED
            return { message: "This transaction can only be completed with an EverWallet, please select your EverWallet.", type: "snackbar" };
        case 1061: //BANK_ACCOUNT_NOT_FOUND
            return { message: "There is no Bank Account on record, please add a bank account. ", type: "snackbar" };
        case 1062: //BANK_ACCOUNT_NOT_DEFINED
            return { message: "There is no Bank Account on record, please add a bank account. ", type: "snackbar" };
        case 1063: //REGION_NOT_SUPPORTED
            return { message: "We are sorry that service is not available in your region at this time.", type: "snackbar" };
        case 1064: //UNSUPPORTED_PAYMENT_TYPE
            return { message: "Unsupported payment type, please try another payment method.", type: "snackbar" };
        case 1065: //FIAT_ASSET_NOT_FOUND
            return { message: "Please reconfigure and retry your transaction.", type: "snackbar" };
        case 1066: //CRYPTO_ASSET_NOT_FOUND
            return { message: "Please reconfigure and retry your transaction.", type: "snackbar" };
        case 1067: //MEMBER_NOT_FOUND
            return { message: "User account not found, please try again later.", type: "snackbar" };
        case 1068: //ORDER_NOT_FOUND
            return { message: "Transaction not found, please try again later.", type: "snackbar" };
        case 1069: //ORDER_ALREADY_IN_PROGRESS
            return { message: "Transaction is already submitted, please wait a few minutes for an updated status.", type: "snackbar" };
        case 1070: //CANT_OBTAIN_ORDER_REF_ID
            return { message: "Transaction not found, please try again later.", type: "snackbar" };
        case 1071: //UNABLE_TO_CONFIRM_DEPOSIT
            return { message: "Transaction deposit is still in process, please try again later.", type: "snackbar" };
        case 1072: //UNABLE_TO_ADD_API_EXCHANGE
            return { message: "The system is currently down, please try again later.", type: "snackbar" };
        case 1073: //UNSUPPORTED_CCY_TO_EXCHANGE
            return { message: "Unsupported currency, please try another currency.", type: "snackbar" };
        case 1074: //PROVIDER_NOT_SUPPORTED
            return { message: "Please reset and try your transaction again.", type: "snackbar" };
        case 1075: //MISSING_PARTNER_APP_KEY
            return { message: "App key is missing, please click on your partner link again.", type: "snackbar" };
        case 1045: //KYC_UNDER_REVIEW
            return { message: "Your account is in KYC review.", type: "snackbar" };
        case 1095: //KYC_DOC_EXPIRED
            return { message: "Your KYC documentation has expired, please upload a replacement to \"Documents\"", type: "snackbar" };
        case 1094: //EXCHANGE_NOT_SUPPORTED
            return { message: "Please reset and try your transaction again.", type: "snackbar" };
        case 1121: //USD_NOT_SUPPORTED
            return { message: "I'm sorry, this service is not supported in your region.", type: "snackbar" };
        case 1076: //NOT_ENOUGH_GAS_FEE
            return { message: "You do not have sufficient funds to make this transfer, please choose a lower amount.", type: "snackbar" };
        case 1077: //NOT_ENOUGH_TOKENS_TO_TRANSFER
            return { message: "You do not have sufficient funds to make this transfer, please choose a lower amount.", type: "snackbar" };
        case 2000: //INTERNAL_ERROR
            return { message: "Unable to calculate conversion at this time.  Please try again later.", type: "snackbar" };
        default:
            return { message: errorObject.code.message, type: "snackbar" };
    }
};