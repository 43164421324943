import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    quoteId: '',
    currency: '',
    amount: '',
    paymentMethod: '',
    totalFee: 0,
    networkFee: 0,
    everestFee: 0,
    estimatedAmount: '',
    bank: {},
};

export const WithdrawSlice = createSlice({
    name: 'withdrawData',
    initialState,
    reducers: {
        setAmount: (state, action) => {
            state.amount = action.payload;
        },
        setBank: (state, action) => {
            state.bank = action.payload;
        },
        setData: (state, action) => {
            return {...state, ...action.payload};
        },
        reset: () => {
            return initialState;
        }
    },
});

export const { setAmount, setBank, setData, reset } = WithdrawSlice.actions;

export const selectWithdrawData = (state) => state.withdrawData;

export default WithdrawSlice.reducer;