import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import helpers from '../../adapters/helpers';
import {Box, Typography, Button, Grid, CircularProgress} from '@mui/material';
import {reset, selectWithdrawData} from './reduxSlice';
import Sepa from '../../images/sepa.png';
import Visa from '../../images/visa.png';
import {PAYMENT_BANK} from "../../js/constants";
import {setConfirmDialog} from "../Shared/ConfirmDialog";
import {selectAppData} from "../../store/reduxSlice";
import DialogSuccess from "../../images/dialog-success.png";
import BackBtn from '../Shared/backBtn';
import { handleOrderError } from '../Shared/errorHandler';


const ConfirmTransaction = ({snackBarMessage}) => {
    const withdrawData = useSelector(selectWithdrawData);
    const bankData = withdrawData.bank;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const appData = useSelector(selectAppData);
    const appKey = appData.sdkConfiguration.appKey;
    const [btnLoading, setBtnLoading] = useState(false);

    const totalAmount = (withdrawData.amount.replaceAll(",", ".") * 1);

    const processTransaction = () => {
        if(!btnLoading) {
            setBtnLoading(true);
            helpers.createOrder({quote_id: withdrawData.quoteId, bank_id: withdrawData.bank.id}, appKey)
                .then(resp => {
                    if(resp.data.success) {
                        const respData = resp.data.data;
                        const orderId = respData.order_id;
                        setConfirmDialog({
                            title: "Withdraw Success",
                            message: "Your transaction was successful for more details on the transaction please track your order.",
                            cancelButtonText: "Withdraw again",
                            onCancel: () => {dispatch(reset()); navigate('/withdraw');},
                            confirmButtonText: "Track Order",
                            onConfirm: () => {dispatch(reset()); navigate('/order/'+orderId);},
                            height: '490px',
                            icon: DialogSuccess
                        });
                    } else {
                        snackBarMessage({message: "Something went wrong while creating order."});
                    }
                }).catch(error => {
                setBtnLoading(false);
                handleOrderError({error, snackBarMessage, navigate, confirmFunc: () => {dispatch(reset()); navigate('/withdraw')}});
            });
        }
    };

    return (
        <>
            <BackBtn onClickHandler={() => navigate('/account/select')} backBtnText={"Confirm Your Order"} />
            <Typography mt={4} variant="body2"><b>Funds received at</b></Typography>
            {/* <Typography  variant="caption"><b>Funds received at</b></Typography> */}
            <Box mt={2} className="confirm-payment">
                <img src={withdrawData.paymentMethod === PAYMENT_BANK ? Sepa : Visa} width={100} alt="payment" />
                <Typography variant="caption" component="p"><strong>{withdrawData.paymentMethod === PAYMENT_BANK ? "Bank Account" : "Debit Card"}</strong></Typography>
            </Box>
            {withdrawData.paymentMethod === PAYMENT_BANK &&
                <>
                    <Typography mt={3} variant="caption" component="p"><b>Bank Details</b></Typography>
                    <Box sx={{color: '#A6A6A6'}}>
                        <Grid container>
                            <Grid className='text-left' item={true} xs={6}>
                                <Typography variant="caption">Account Name</Typography>
                            </Grid>
                            <Grid className='text-right' item={true} xs={6}>
                                <Typography variant="caption">{bankData.accountName}</Typography>
                            </Grid>
                            
                            <Grid className='text-left' item={true} xs={6}>
                                <Typography variant="caption">Bank Name</Typography>
                            </Grid>
                            <Grid className='text-right' item={true} xs={6}>
                                <Typography variant="caption">{bankData.bankName}</Typography>
                            </Grid>
                            
                            <Grid className='text-left' item={true} xs={6}>
                                <Typography variant="caption">IBAN</Typography>
                            </Grid>
                            <Grid className='text-right' item={true} xs={6}>
                                <Typography variant="caption">{bankData.accountNumber}</Typography>
                            </Grid>
                            
                            <Grid className='text-left' item={true} xs={6}>
                                <Typography variant="caption">BIC</Typography>
                            </Grid>
                            <Grid className='text-right' item={true} xs={6}>
                                <Typography variant="caption">{bankData.bicCode}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            }
            <Typography mt={3} variant="caption" component="p"><b>Order Details</b></Typography>
            <Box sx={{color: '#A6A6A6'}}>
                <Grid container>
                    <Grid className='text-left' item={true} xs={6}>
                        <Typography variant="caption">Withdraw Amount</Typography>
                    </Grid>
                    <Grid className='text-right' item={true} xs={6}>
                        <Typography variant="caption">{(totalAmount * 1).toFixed(2)} {withdrawData.currency}</Typography>
                    </Grid>

                    <Grid className='text-left' item={true} xs={6}>
                        <Typography variant="caption">Total Amount received</Typography>
                    </Grid>
                    <Grid className='text-right' item={true} xs={6}>
                        <Typography variant="caption">{(withdrawData.estimatedTokenAmount * 1).toFixed(2)} {withdrawData.currency}</Typography>
                    </Grid>

                    <Grid className='text-left' item={true} xs={6}>
                        <Typography variant="caption">Total Fees</Typography>
                    </Grid>
                    <Grid className='text-right' item={true} xs={6}>
                        <Typography variant="caption">{(withdrawData.totalFee).toFixed(2)} {withdrawData.currency}</Typography>
                    </Grid>
                </Grid>
            </Box>
            
            <Button
                className="sdk-btn2 mt64"
                variant="contained"
                onClick={processTransaction}
                fullWidth
            >
                <strong>{btnLoading ? <><CircularProgress size={15} color="inherit" /> Processing...</> : "Withdraw"}</strong>
            </Button>
        </>
    );
};

export default ConfirmTransaction;
