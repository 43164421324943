import React, {useEffect} from "react";
import {Box, Typography} from "@mui/material";
import Button from "../Shared/button";
import { REWARD_STEP_1 } from "../../js/images";

const Step1 = ({setStep, dispatch, setBackFunc, navigate}) => {

    useEffect(() => {
        dispatch(setBackFunc(() => navigate('/dashboard')));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <>
            <img src={REWARD_STEP_1} alt="referralReward" width={350} />
            <Typography variant="body1" mt={6}>
                <strong>Sign up with a selfie and get your QR code</strong>
            </Typography>
            <Button onClick={() => setStep(2)} className="sdk-theme-btn mt32 normal-text gift-btn-width" variant="contained">
                <strong>Next</strong>
            </Button>
        </>
    );
};

export default Step1;