import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {useDispatch} from "react-redux";
import {Autocomplete, Box, Button, CircularProgress, TextField, Typography} from "@mui/material";
import {setBackFunc} from "../../store/reduxSlice";
import Step from "../Shared/step";
import countries from "../../json/countries";
import helpers from "../../adapters/helpers";
import {removeExtraSpace} from "../../util/generalUtil";
import {setRegion} from "../../store/reduxUserSlice";
import {COUNTRY_ISO3_US, REGION_EU, REGION_US} from "../../js/constants";

const AddressDetails = ({snackBarMessage, userInfo, setUserInfo, setShowScreen, InputField, handleChange}) => {

    // const navigate = useNavigate();
    const [address1, setAddress1] = useState(userInfo.address1 || '');
    const [address2, setAddress2] = useState(userInfo.address2 || '');
    const [city, setCity] = useState(userInfo.city || '');
    const [state, setState] = useState(userInfo.state || '');
    const [zipCode, setZipCode] = useState(userInfo.zipCode || '');
    const [country, setCountry] = useState(userInfo.country || null);
    const [loader, setLoader] = useState(false);
    const [address1Err, setAddress1Err] = useState(false);
    const [cityErr, setCityErr] = useState(false);
    const [stateErr, setStateErr] = useState(false);
    const [zipCodeErr, setZipCodeErr] = useState(false);
    const [countryErr, setCountryErr] = useState(false);


    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(setBackFunc(() => setShowScreen('personalDetails')));
    }, [setShowScreen, dispatch]);

    const submitData = () => {
        setAddress1Err(false);
        setCityErr(false);
        setStateErr(false);
        setZipCodeErr(false);
        setCountryErr(false);
        if(!address1) {
            setAddress1Err(true);
        }
        if(!city) {
            setCityErr(true);
        }
        if(!state) {
            setStateErr(true);
        }
        if(!zipCode) {
            setZipCodeErr(true);
        }
        if(!country) {
            setCountryErr(true);
        }

        if(!address1 || !city || !state || !zipCode || !country) {
            return false;
        }

        setUserInfo({...userInfo, address1, address2, city, state, zipCode, country});
        if(!loader) {
            setLoader(true);
            const data = {
                personal_information: {
                    first_name: removeExtraSpace(userInfo.firstName),
                    last_name: removeExtraSpace(userInfo.lastName),
                    date_of_birth: userInfo.dob
                },
                residential_address: {
                    street: removeExtraSpace(address1),
                    secondary_street: removeExtraSpace(address2),
                    city: removeExtraSpace(city),
                    state: removeExtraSpace(state),
                    postcode: removeExtraSpace(zipCode),
                    country: country.alpha3Code
                }
            };
            helpers.passThroughKyc(data)
                .then(resp => {
                    setLoader(false);
                    if(resp.data.success) {
                        let region = REGION_EU;
                        if(country.alpha3Code === COUNTRY_ISO3_US) {
                            region = REGION_US;
                        }
                        dispatch(setRegion(region));
                        navigate('/confirm/transaction');
                    } else {
                        snackBarMessage({message: 'Something went wrong while updating Pass through KYC'});
                    }
                }).catch(error => {
                setLoader(false);
                if (error.response) {
                    const errorObject = JSON.parse(error.request.response);
                    snackBarMessage({message: errorObject.code.message});
                }
            });
        }
    };

    return (
        <>
            <Step totalSteps={2} currentStep={2} />
            <Box pl={2} mt={4}>
                <Typography variant="h5" sx={{fontWeight: 'bold'}}>Your Residential Address?</Typography>
                <InputField label={"Address 1"} value={address1} onChangeHandler={val => handleChange(val, setAddress1, setAddress1Err)} error={address1Err} />
                <InputField label={"Address 2 (optional)"} value={address2} onChangeHandler={(val) => setAddress2(val)} required={false} />
                <InputField label={"City"} value={city} onChangeHandler={val => handleChange(val, setCity, setCityErr)} error={cityErr} />
                <InputField label={"State / Province"} value={state} onChangeHandler={val => handleChange(val, setState, setStateErr)} error={stateErr} />
                <InputField label={"Postal Code"} value={zipCode} onChangeHandler={val => handleChange(val, setZipCode, setZipCodeErr)} error={zipCodeErr} />
                <Autocomplete
                    sx={{mt: 2}}
                    value={country}
                    isOptionEqualToValue={(option, value) => option.alpha3Code === value.alpha3Code}
                    onChange={(e, v) => handleChange(v, setCountry, setCountryErr)}
                    options={countries}
                    autoHighlight={true}
                    openOnFocus={true}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.alpha2Code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.alpha2Code.toLowerCase()}.png 2x`}
                                alt=""
                            />
                            {option.name} ({option.alpha3Code})
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            FormHelperTextProps={{sx: {fontWeight: "bold", textAlign: "right"}}}
                            error={countryErr}
                            helperText={countryErr && "*Mandatory field"}
                            {...params}
                            label="Country"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                            }}
                        />
                    )}
                />
            </Box>
            <Button
                className="login-buttons navigation-login-background normal-text mt32"
                variant="contained"
                onClick={submitData}
                disabled={!removeExtraSpace(address1) || !removeExtraSpace(city) || !removeExtraSpace(state) || !removeExtraSpace(zipCode) || !country}
                fullWidth
            >
                <strong>{loader ? <><CircularProgress size={15} color="inherit"/> Processing...</> : "Submit"}</strong>
            </Button>
        </>
    );
};

export default AddressDetails;