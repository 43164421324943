import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {Box, Slide, CircularProgress, Backdrop, Typography} from "@mui/material";
import Keyboard from '../Shared/keyboard';
import helpers from '../../adapters/helpers';
import PinEmpty from '../../images/pin-empty.png';
import PinFill from '../../images/pin-fill.png';
import PinError from '../../images/pin-error.png';
import {setConfirmDialog} from "../Shared/ConfirmDialog";
import UserSign from "../../images/user-signin.png";
import {useDispatch, useSelector} from "react-redux";
import {selectAppData, setPartialRegister} from "../../store/reduxSlice";
import {selectBuyData} from "../Buy/reduxSlice";
import {setUserID} from "../../store/reduxUserSlice";
import {getOcrData, launchInCode} from "../Shared/InCode";
import InCode from "../Shared/InCode";
import { isLoggedIn, setSession } from '../../util/userFunctions';

/* eslint-disable */ //TODO: Fix useEffect Dependencies
let smsUUID = '', inCodeToken = '', inCodeSessionData = {};
const SignIn = ({snackBarMessage}) => {

    const {state} = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const appData = useSelector(selectAppData);
    const buyData = useSelector(selectBuyData);
    const service = appData.sdkConfiguration.service;
    const [showScreen, setShowScreen] = useState('default');
    const [twoFaCode, setTwoFaCode] = useState('');
    const [twoFaCodeErr, setTwoFaCodeErr] = useState(false);
    const [pin, setPin] = useState('');
    const [pinErr, setPinErr] = useState(false);
    const [loader, setLoader] = useState(false); //full page loader
    const [startInCode, setStartInCode] = useState(false);

    useEffect(() => {
        if(isLoggedIn()) {
            navigateUser();
        } else {
            launchInCode();
        }
    }, []);

    useEffect(() => {
        if(pin.length === 4) {
            startLogin();
        }
    }, [pin]);

    useEffect(() => {
        if(twoFaCode.length === 7) {
            setShowScreen("pin");
        }
    }, [twoFaCode]);

    const navigateUser = () => {
        const from = state?.from;
        if(from === "swap") {
            navigate('/swap')
        } else if(from === "sell") {
            navigate('/sell')
        } else if(service === "identity") {
            navigate('/dashboard')
        } else if(buyData.address) {
            navigate('/confirm/transaction')
        } else {
            navigate('/');
        }
    };

    const startLogin = () => {
        if(localStorage.getItem('everest2FaCode') && !pin) {
            setShowScreen("pin");
        } else {
            setLoader(true);
            let twoFaCodeSkip = localStorage.getItem('everest2FaCode');
            helpers.login({ session_id: inCodeToken, orgName: 'INF' }, pin, twoFaCode, twoFaCodeSkip)
                .then(async (response) => {
                    setLoader(false);
                    if (response.data.success) {
                        inCodeToken = '';
                        const resp = response.data.data;
                        const token = resp.token;
                        const tokenArr = token.split('.');
                        let uuid;
                        if (tokenArr.length > 2) {
                            const tokenJson = atob(tokenArr[1]);
                            uuid = JSON.parse(tokenJson).uuid;
                        }
                        if (resp.two_fa_code) {
                            localStorage.setItem('everest2FaCode', resp.two_fa_code);
                        }
                        setSession(uuid, token);
                        dispatch(setUserID(uuid));
                        navigateUser();
                    } else {
                        snackBarMessage({message: response.data.message});
                    }
                })
                .catch((error) => {
                    setLoader(false);
                    if (error.response) {
                        const errorObject = JSON.parse(error.request.response);
                        if(errorObject.code.message === '2FA_NEEDED' || errorObject.code.message === '2FA_COOLDOWN') {
                            setPin('');
                            if (errorObject?.data?.sms_id) {
                                smsUUID = errorObject.data.sms_id;
                            }
                            setShowScreen("twoFa");
                        } else if(errorObject.code.message === '2FA_INVALID_CODE') {
                            setTwoFaCode('');
                            setPin('');
                            setTwoFaCodeErr(true);
                            setShowScreen("twoFa");
                            snackBarMessage({message: errorObject.code.message});
                        } else if(errorObject.code.message === 'FAILED_TO_VERIFY_PIN_IS_YOUR_PIN_CORRECT') {
                            setPin('');
                            setPinErr(true);
                            snackBarMessage({message: errorObject.code.message});
                        } else if(errorObject.code.message === 'MEMBER_NOT_FOUND') {
                            setConfirmDialog({
                                icon: UserSign,
                                title: 'Partial Registration',
                                message: 'It seems you are partially enrolled. Please complete your registration to continue login.',
                                confirmButtonText: 'Complete Registration',
                                onConfirm: () => {startRegister();},
                                cancelButtonShow: false,
                                height: '425px',
                                width: '500px',
                            });
                        } else {
                            setPin('');
                            setTwoFaCode('');
                            setShowScreen('default');
                            snackBarMessage({message: errorObject.code.message});
                            launchInCode();
                        }
                    } else if (error.request) {
                        snackBarMessage({message: error.request});
                    } else {
                        snackBarMessage({message: error.message});
                    }
                });
        }
    };

    const startRegister = async () => {
        const ocrData = await getOcrData(inCodeSessionData.token);
        dispatch(setPartialRegister(true));
        navigate('/signup', {state: {uuid: ocrData.externalId}});
    };

    const resend2FaCode = async () => {
        if (!smsUUID) {
            snackBarMessage({message: 'Please wait few minutes. Refresh the page and try again'});
            return false;
        }
        setLoader(true);
        helpers.reSendCode(smsUUID)
            .then((response) => {
                setLoader(false);
                if (response.data.success) {
                    snackBarMessage({message: 'Authentication Code has been resent. Note: your message may take up to a minute to arrive.', type: 'success'});
                }
            })
            .catch((error) => {
                setLoader(false);
                if (error.response) {
                    if (error.response.status === 425) {
                        snackBarMessage({message: `Too many requests, please wait 20 seconds before trying again.`});
                    } else {
                        const myObject = JSON.parse(error.request.response);
                        snackBarMessage({message: myObject.errorMessage});
                    }
                } else if (error.request) {
                    snackBarMessage({message: error.request});
                } else {
                    snackBarMessage({message: error.message});
                }
            });
    };

    const manageTwoFa = (val) => {
        setTwoFaCodeErr(false);
        if(val === "backSpace") {
            setTwoFaCode(twoFaCode.substring(0, twoFaCode.length-1));
            return;
        }
        if(twoFaCode.length <= 6) {
            const code = twoFaCode+val;
            setTwoFaCode(code);
        }
    };

    const managePin= (val) => {
        setPinErr(false);
        if(val === "backSpace") {
            setPin(pin.substring(0, pin.length-1));
            return;
        }
        if(pin.length <= 3) {
            const newPin = pin+val;
            setPin(newPin);
        }
    };

    const getPinCodeImg = (from, place, isError) => {
        if(isError) {
            return PinError;
        }
        if(from === "2fa") {
            if(twoFaCode.length >= place)
                return PinFill;
            else
                return PinEmpty;
        }
        if(from === "pin") {
            if(pin.length >= place)
                return PinFill;
            else
                return PinEmpty;
        }
    };

    const successLogin = (data) => {
        inCodeSessionData = data;
        setStartInCode(false);
        inCodeToken = data.interviewToken;
        startLogin();
    };

    const errorLogin = () => {
        setStartInCode(false);
        setConfirmDialog({
            title: "Login Failed!",
            message: "It seems you don't have an account on the Everest Platform. Please click on sign up to proceed further.",
            confirmButtonText: "Sign Up",
            cancelButtonText: "Cancel",
            onConfirm: () => {navigate('/signup');},
            height: '500px',
            width: '500px',
        });
    };

    const Auth2FaScreen = React.forwardRef((props, ref) => {
        return (
            <Box ref={ref} {...props} className="text-center">
                <Typography variant="h4">Enter your confirmation code</Typography>
                <Typography variant="caption" sx={{color: '#16359D', textDecoration: 'underline', cursor: 'pointer'}} onClick={resend2FaCode}>Resend confirmation code</Typography>
                <Box mt={8}>
                    <img src={getPinCodeImg("2fa", 1, twoFaCodeErr)} alt={"pin"} />
                    <img className="pin-img" src={getPinCodeImg("2fa", 2, twoFaCodeErr)} alt={"pin"} />
                    <img className="pin-img" src={getPinCodeImg("2fa", 3, twoFaCodeErr)} alt={"pin"} />
                    <img className="pin-img" src={getPinCodeImg("2fa", 4, twoFaCodeErr)} alt={"pin"} />
                    <img className="pin-img" src={getPinCodeImg("2fa", 5, twoFaCodeErr)} alt={"pin"} />
                    <img className="pin-img" src={getPinCodeImg("2fa", 6, twoFaCodeErr)} alt={"pin"} />
                    <img className="pin-img" src={getPinCodeImg("2fa", 7, twoFaCodeErr)} alt={"pin"} />
                </Box>
                {twoFaCodeErr && <Typography mt={4} variant="caption" sx={{color: 'red'}}>Incorrect code, please try again</Typography>}
                <Box mt={8}>
                    <Keyboard onClickHandler={(val) => manageTwoFa(val)} />
                </Box>
            </Box>
        );
    });

    const PinScreen = React.forwardRef((props, ref) => {
        return (
            <Box ref={ref} {...props} className="text-center">
                <Typography variant="h4">Please enter your pin</Typography>
                <Typography className="display-none" variant="caption" sx={{color: '#16359D', textDecoration: 'underline'}}>Forgot Pin?</Typography>
                <Box mt={8}>
                    <img src={getPinCodeImg("pin", 1, pinErr)} alt={"pin"} />
                    <img className="pin-img" src={getPinCodeImg("pin", 2, pinErr)} alt={"pin"} />
                    <img className="pin-img" src={getPinCodeImg("pin", 3, pinErr)} alt={"pin"} />
                    <img className="pin-img" src={getPinCodeImg("pin", 4, pinErr)} alt={"pin"} />
                </Box>
                {pinErr && <Typography mt={4} variant="caption" sx={{color: 'red'}}>Incorrect pin, please try again</Typography>}
                <Box mt={8}>
                    <Keyboard onClickHandler={(val) => managePin(val)} removeTypeEffect={true} />
                </Box>
            </Box>
        );
    });

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Slide direction="left" in={showScreen === "twoFa"} mountOnEnter unmountOnExit>
                <Auth2FaScreen />
            </Slide>
            <Slide direction="left" in={showScreen === "pin"} mountOnEnter unmountOnExit>
                <PinScreen />
            </Slide>
            {showScreen === "default" &&
            <>
                <Box className="video-box" sx={{height: "350px", opacity: "0.5"}} />
                <InCode
                    successHandler={successLogin}
                    errorHandler={errorLogin}
                    autoStart={false}
                    flow={'login'}
                />
            </>
            }
        </>
    );
};

export default SignIn;