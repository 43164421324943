import React, {useEffect} from "react";
import {Typography} from "@mui/material";
import Button from "../Shared/button";
import { REWARD_STEP_3 } from "../../js/images";

const Step3 = ({setStep, dispatch, setBackFunc}) => {

    useEffect(() => {
        dispatch(setBackFunc(() => setStep(2)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <>
            <img src={REWARD_STEP_3} alt="referralReward" width={"100%"} />
            <Typography variant="body1" mt={30}>
                <strong>Your friends scan your code and sign up</strong>
            </Typography>
            <Button onClick={() => setStep(4)} className="sdk-theme-btn mt32 normal-text gift-btn-width" variant="contained">
                <strong>Next</strong>
            </Button>
        </>
    );
};

export default Step3;