import React, {useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import QRCode from "react-qr-code";
import {Box, Button, CircularProgress, Typography, Grid, IconButton, Divider, FormControl, FilledInput, useTheme} from '@mui/material';
import {setConfirmDialog} from "./ConfirmDialog";
import helpers from "../../adapters/helpers";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {selectTradeData} from "../Trade/reduxSlice";
import Timer from "./timer";
import {reset} from "../Trade/reduxSlice";
import DialogSuccess from "../../images/dialog-success.png";
import GenericTokenImage from "../../images/generic-token.png";
import { displayWalletAddress } from '../../util/generalUtil';
import { selectSellData } from '../Sell/reduxSlice';
import { isLoggedIn } from '../../util/userFunctions';
import { validateTxHash } from '../../util/web3';
import { selectAppData } from '../../store/reduxSlice';
import { VIAARPE_APP_KEY } from '../../js/constants';

const Send = ({snackBarMessage, from}) => {
    const theme = useTheme();
    const {state} = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const sellReduxData = useSelector(selectSellData);
    const tradeReduxData = useSelector(selectTradeData);
    const appData = useSelector(selectAppData);
    const appKey = appData.sdkConfiguration.appKey;
    let tradeData = tradeReduxData, navigateTo = "/swap", label = "Swap";
    if(from === "sell") {
        tradeData = sellReduxData;
        navigateTo = "/sell";
        label = "Sell";
    }

    const sendAddress = state?.address;
    const orderId = state?.orderId;

    const [btnLoading, setBtnLoading] = useState(false);
    const [clearTimer, setClearTimer] = useState(false);
    const [txHash, setTxHash] = useState("");

    const confirmSell = () => {
        if(!txHash) {
            snackBarMessage({message: "Please provide transaction hash of the send that you perform."});
            return false;
        }

        if(!validateTxHash(txHash)) {
            snackBarMessage({message: "Please provide a valid transaction hash."});
            return false;
        }

        if(!btnLoading) {
            setBtnLoading(true);
            const data = {
                order_id: orderId,
                tx_hash: txHash

            };
            helpers.confirmDeposit(data)
                .then(resp => {
                    setBtnLoading(false);
                    setClearTimer(true);
                    let title = label+" Success";
                    let message = "Your transaction was successful.";
                    if(appKey === VIAARPE_APP_KEY) {
                        title = "Transaction Hash Submitted";
                        message = "If the transaction hash is valid, we will transfer your tokens. For swap confirmation, please check your wallet address at <a target='_blank' href='https://etherscan.io/'>https://etherscan.io/</a>"
                    }
                    if(isLoggedIn()) {
                        setConfirmDialog({
                            title: title,
                            message: message+" For more details on the transaction please track your order.",
                            cancelButtonText: "Swap again",
                            onCancel: () => {dispatch(reset()); navigate(navigateTo);},
                            confirmButtonText: "Track Order",
                            onConfirm: () => {dispatch(reset()); navigate('/order/'+orderId);},
                            height: '490px',
                            icon: DialogSuccess
                        });
                    } else {
                        setConfirmDialog({
                            title: title,
                            message: message,
                            confirmButtonText: "Swap again",
                            onConfirm: () => {dispatch(reset()); navigate(navigateTo);},
                            cancelButtonShow: false,
                            height: '490px',
                            icon: DialogSuccess
                        });
                    }
                })
                .catch((error) => {
                    setBtnLoading(false);
                    snackBarMessage({message: error.toString()});
                    setConfirmDialog({
                        title: label+" Error",
                        message: "There has been an error with processing your transaction.",
                        confirmButtonShow: false,
                        cancelButtonText: "Return to "+label+" Main Screen",
                        onCancel: () => {navigate(navigateTo)},
                        height: '490px'
                    });
                });
        }
    };

    const cancelTransaction = () => {
        setConfirmDialog({
            title: "Timer Error",
            message: "We did not receive your token before the timer expired. Please return to "+label+" and try again.",
            confirmButtonShow: false,
            cancelButtonText: label+" to Try Again",
            onCancel: () => {navigate(navigateTo)},
            height: '490px'
        });
    };

    const copyWalletAddress = () => {
        navigator.clipboard.writeText(sendAddress);
        snackBarMessage({
            message: 'Wallet Address has been copied successfully.',
            type: 'success',
        });
    };

    const copyAmount = () => {
        navigator.clipboard.writeText(tradeData?.amount);
        snackBarMessage({
            message: 'Amount has been copied successfully.',
            type: 'success',
        });
    };

    return (
        <Box mt={12}>
            <Grid rowSpacing={2} container>
                <Grid item={true} xs={8}>
                    <Typography className="text-left" color="secondary" variant="h5"><strong>Send Your Tokens</strong></Typography>
                </Grid>
                <Grid className="flex-right" item={true} xs={4}>
                    <Timer preText={"in "} clearTimer={clearTimer} onFinish={cancelTransaction} time={1200} />
                </Grid>
            </Grid>
            <Grid mt={4} sx={{color: theme.customGray.color}} rowSpacing={2} container>
                <Grid item={true} xs={6}>
                    <Typography className="text-left" variant="body1">From Wallet</Typography>
                </Grid>
                <Grid item={true} xs={6}>
                    <Typography className="text-right" variant="body1">{displayWalletAddress(tradeData?.address)}</Typography>
                </Grid>
                <Divider sx={{width: "100%", mt: 1}} />

                <Grid item={true} xs={6}>
                    <Typography className="text-left" variant="body1">Token</Typography>
                </Grid>
                <Grid className="flex-right" item={true} xs={6}>
                    <img className="sell-send-icon" src={tradeData?.token.image} onError={({ currentTarget }) => {currentTarget.onerror = null;currentTarget.src = GenericTokenImage;}} alt="token" width={40} />
                    <Typography className="text-right" variant="body1">{tradeData?.token.symbol}</Typography>
                </Grid>
                <Divider sx={{width: "100%", mt: 1}} />
                
                <Grid item={true} xs={6}>
                    <Typography className="text-left" variant="body1">Network</Typography>
                </Grid>
                <Grid className='flex-right' item={true} xs={6}>
                    <span className="confirm-transaction-network" style={{backgroundColor: tradeData?.token.network.toLowerCase() === "ethereum" ? '#A1A9DC' : '#5D8CCC'}}>{tradeData?.token.network}</span>
                </Grid>
                <Divider sx={{width: "100%", mt: 1}} />
                
                <Grid className='d-flex items-center' item={true} xs={5}>
                    <Typography className="text-left" variant="body1">Transfer Amount</Typography>
                </Grid>
                <Grid className='flex-right items-center' item={true} xs={7}>
                    <Typography className="text-right" variant="caption">
                        <strong>{tradeData?.amount} {tradeData?.token.symbol}</strong>
                        <IconButton onClick={copyAmount} sx={{color: 'black'}}>
                            <ContentCopyIcon sx={{color: "#73D9B9", fontSize: '20px'}} />
                        </IconButton>
                    </Typography>
                </Grid>
            </Grid>

            <Typography mt={4} variant="body2" color="primary"><b>To Wallet Address</b></Typography>
            <Box mt={1.5} className="deposit-wallet-address">
                <Typography variant="caption" component="p"><strong>{sendAddress}</strong></Typography>
                <IconButton onClick={copyWalletAddress} sx={{color: 'black'}}>
                    <ContentCopyIcon sx={{color: "#73D9B9", fontSize: '30px'}} />
                </IconButton>
            </Box>

            <Box className="login-container" mt={2}>
                <QRCode value={sendAddress} />
            </Box>

            <Typography mt={4} variant="body2" color="primary"><b>Enter Transaction Hash</b></Typography>
            <FormControl variant="filled" className='pay-amount-field' sx={{background: "white"}} fullWidth>
                <FilledInput
                    inputProps={{sx: {padding: "15px 12px"}}}
                    value={txHash}
                    onChange={(e) => setTxHash(e.target.value)}
                    type="text"
                    required
                    fullWidth
                />
            </FormControl>

            <Button className="mt32" variant="themeContained" onClick={confirmSell} fullWidth>
                <strong>{btnLoading ? <><CircularProgress size={15} color="inherit" /> Processing...</> : "Continue"}</strong>
            </Button>
        </Box>
    );
};

export default Send;
