import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {withSnackBar} from '../../util/snackbar';

export default withSnackBar(function DefaultLayout({children, snackBarMessage}) {
    return (
        <>
            <CssBaseline />
            {React.cloneElement(children, { snackBarMessage: snackBarMessage })}
        </>
    );
})
