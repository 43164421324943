/* eslint-disable */

function rtrim(string, charToRemove) {
    while (string.charAt(string.length - 1) === charToRemove) {
        string = string.substring(0, string.length - 1);
    }
    return string;
}

function ltrim(string, charToRemove) {
    while (string.charAt(0) === charToRemove) {
        string = string.substring(1);
    }
    return string;
}

function pempty(item) {
    if (typeof item === 'undefined') return true;
    if (item === 0) return true;
    if (item === '0') return true;
    if (item === '') return true;
    if (item === false) return true;
    return typeof item === 'object' && item.length === 0;
}

export const toHuman = (value, decimals, minVisibleDeciminal = 2) => {
    let ret;
    if (decimals < 1) return value;
    if (value.length > decimals) {
        ret =
            value.substr(0, value.length - decimals) +
            '.' +
            value.substr(0 - decimals);
    } else {
        //return "0," . str_repeat("0", $decimals - strlen($value)) . $value;
        ret = '0.' + '0'.repeat(decimals - value.length) + value;
    }
    ret = rtrim(ret, '0');
    let currSufxLen = ret.split('.', 2)[1];
    if (currSufxLen < minVisibleDeciminal) {
        ret += '0'.repeat(minVisibleDeciminal - currSufxLen);
    }
    return ret;
};

export const fromHuman = (value, decimals) => {
    if (decimals < 1) throw 'not supported';
    value = value.replaceAll(',', '.');
    // validate if strpos() and indexOf provide exacly same output
    let pos = value.indexOf('.');
    let ret;
    // no "." - no decimal part
    if (pos === -1) {
        ret = ltrim(value + '0'.repeat(decimals), '0');
        if (pempty(ret)) ret = '0';
        return ret;
    }
    let valArr = value.split('.', 2);
    // if decimal part is longer or equal to decimal part
    if (valArr[1].length >= decimals) {
        // logger -> warn about invalid input, auto-corrected here?
        ret = ltrim(
            valArr[0] + valArr[1].substring(0, decimals), // cut off too big decimal part
            '0'
        );
        if (pempty(ret)) ret = '0';
        return ret;
    }
    // decimal part exist, and it's within boundaries
    ret = ltrim(
        value.replaceAll('.', '') + '0'.repeat(decimals - valArr[1].length),
        '0'
    );
    if (pempty(ret)) ret = '0';
    return ret;
};
