import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    quoteId: '',
    currency: '',
    provider: '',
    exchange: 'bitstamp',
    network: 'Ethereum',
    networkIcon: '',
    amount: '',
    token: '',
    tokenIcon: '',
    paymentMethod: '',
    totalFee: 0,
    networkFee: 0,
    everestFee: 0,
    estimatedAmount: '',
    conversionRate: '',
    address: '',
    addressFrom: '',
    manualSend: true,
};

export const SellSlice = createSlice({
    name: 'sellData',
    initialState,
    reducers: {
        setNetwork: (state, action) => {
            state.network = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setAmount: (state, action) => {
            state.amount = action.payload;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
        },
        setData: (state, action) => {
            return {...state, ...action.payload};
        },
        reset: () => {
            return initialState;
        }
    },
});

export const { setNetwork, setToken, setAmount, setAddress, setBank, setData, reset } = SellSlice.actions;

export const selectSellData = (state) => state.sellData;

export default SellSlice.reducer;