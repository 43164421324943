import React, { useState, useEffect, useCallback } from "react";
import {usePlaidLink} from "react-plaid-link";
import {Box, Button, CircularProgress} from "@mui/material";
import helpers from "../../adapters/helpers";
import {getUID} from '../../util/userFunctions';

const ConnectPlaidBank = ({ snackBarMessage, successHandler }) => {

    const [loading, setLoading] = useState(true);
    const [linkToken, setLinkToken] = useState("");

    // @TODO: We need to move side effects into sagas
    const onSuccess = useCallback((public_token, metadata) => {
        const data = {
            user_id: getUID(),
            institution_id: metadata.institution.institution_id,
            institution: metadata.institution.name,
            account: metadata.account.name,
            account_id: metadata.account_id,
            accounts: metadata.accounts,
            link_session_id: metadata.link_session_id,
            public_token: public_token,
        };
        setLoading(true);
        helpers.createPlaidItem(data)
            .then((response) => {
                setLoading(false);
                if (response.data.success && response.data.data) {
                    if (response.data.data) {
                        setLoading(false);
                        onCreateItem(response.data.data, metadata.account_id, metadata.accounts);
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                snackBarMessage({message: 'Failed to get banks detail: '+ error});
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // @TODO: We need to move side effects into sagas
    const onCreateItem = useCallback((access_token, account_id, accounts) => {
        const data = {
            access_token: access_token.AccessToken,
            account_id,
            options: accounts
        };
        setLoading(true);
        helpers.getAuthPlaid(data)
            .then((response) => {
                setLoading(false);
                if (response.data.success && response.data.data) {
                    if (response.data.data) {
                        setLoading(false);
                        successHandler();
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                snackBarMessage({message: 'Failed to get banks detail: '+ error});
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // The usePlaidLink hook manages Plaid Link creation
    // It does not return a destroy function;
    // instead, on unmount it automatically destroys the Link instance
    const config = {
        onSuccess: onSuccess, // @TODO: We need to move side effects into sagas
        // onExit: (err, metadata) => {},
        // onEvent: (eventName, metadata) => {},
        token: linkToken,
        //required for OAuth; if not using OAuth, set to null or omit:
        //receivedRedirectUri: window.location.href,
    };

    const { open, ready } = usePlaidLink(config); //, error <== variabe remove as we are not using

    // @TODO: We need to move side effects into sagas
    useEffect(() => {
        helpers.createPlaidLinkToken()
            .then((response) => {
                setLoading(false);
                if (response.data.success && response.data.data) {
                    if (response.data.data) {
                        setLinkToken(response.data.data);
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                snackBarMessage({message: 'Failed to get banks detail: '+ error});
            });
        return () => {
            setLinkToken('');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {loading ? (
                <Box className="text-center" mt={8}>
                    <CircularProgress />
                </Box>
            ) : (
                <Button
                    onClick={() => open()}
                    className="sdk-btn-connect-wallet"
                    variant="contained"
                    fullWidth
                    disabled={!ready}
                >
                    <strong>Connect bank account</strong>
                </Button>
            )}
        </>
    );
};

export default ConnectPlaidBank;
