import React, {useState} from "react";
import {Box, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useDispatch} from 'react-redux'
import {setBackFunc} from "../../store/reduxSlice";
import Step from "../Shared/step";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";

const ReferralRewards = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const totalSteps = 5;
    const [step, setStep] = useState(1);
    
    return (
        <Box className="login-container" mt={8}>
            <Box mt={4} className="full-width">
                <Typography variant="h6" mb={2}>
                    <strong><span className="ref-reward">Earn Referral Rewards</span> <span className="ref-reward-step">{step}/{totalSteps}</span></strong>
                </Typography>
                <Step totalSteps={totalSteps} currentStep={step} showText={false} stepBlue={true} />
                {
                {
                    1: <Step1 setStep={setStep} dispatch={dispatch} setBackFunc={setBackFunc} navigate={navigate} />,
                    2: <Step2 setStep={setStep} dispatch={dispatch} setBackFunc={setBackFunc} />,
                    3: <Step3 setStep={setStep} dispatch={dispatch} setBackFunc={setBackFunc} />,
                    4: <Step4 setStep={setStep} dispatch={dispatch} setBackFunc={setBackFunc} />,
                    5: <Step5 setStep={setStep} dispatch={dispatch} setBackFunc={setBackFunc} navigate={navigate} />
                }[step]
            }
            </Box>
        </Box>
    );
};

export default ReferralRewards;