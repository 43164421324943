import _fetch from './swagger';

const api = {
    get: (url, data, options) => _fetch(url, 'GET', data, options),
    post: (url, data, options) => _fetch(url, 'POST', data, options),
    put: (url, data, options) => _fetch(url, 'PUT', data, options),
    delete: (url, data, options) => _fetch(url, 'DELETE', data, options)
};

export default api;
