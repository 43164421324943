import React, {useState, useEffect} from "react";
import {IconButton, Box, Button, CircularProgress, Grid, Typography} from "@mui/material";
import helpers from "../../adapters/helpers";
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import Logo from "../../logo.svg";
import Delete from "../../images/delete.png";
import {setConfirmDialog} from "../Shared/ConfirmDialog";
import ConnectPlaidBank from "../Shared/connectPlaidBank";
import AddBankBtn from "../Shared/addBankBtn";

const Banks = ({snackBarMessage}) => {
    const [banks, setBanks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isDelete, setIsDelete] = useState(false);

    useEffect(() => {
        getBanks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBanks = () => {
        helpers.getBanks()
            .then((response) => {
                setLoading(false);
                if (response.data.success && response.data.data) {
                    if (response.data.data) {
                        setBanks(response.data.data);
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                snackBarMessage({message: 'Failed to get banks detail: '+ error});
            });
    };

    const deleteConfirm = (id) => {
        setConfirmDialog({
            icon: Delete,
            title: "Are you sure you want to delete?",
            message: "This action cannot be undone.",
            onConfirm: () => {deleteAccount(id)}
        });
    };

    const deleteAccount = (id) => {
        setLoading(true);
        helpers.deleteBank(id)
            .then((response) => {
                if (response.data.success) {
                    const newBanks = banks.filter(object => {
                        return object.id !== id;
                    });
                    setBanks(newBanks);
                    setLoading(false);
                    setIsDelete(false);
                    snackBarMessage({message: 'Bank account has been deleted.', type : 'success'});
                } else {
                    snackBarMessage({message: 'Something went wrong.'});
                }
            })
            .catch((error) => {
                setLoading(false);
                snackBarMessage({message: 'Something went wrong.'+error});
            });
    };

    return (
        <>
            <Typography variant="h5" mb={4}><strong>Accounts</strong></Typography>
            {loading ? <Box className="text-center" mt={8}><CircularProgress/></Box> :
                <>
                    {banks.length > 0 ?
                        <>
                            <Button className={`${isDelete ? "confirm-buy-delete-disable" : "confirm-buy-edit"} normal-text`} variant="contained"
                                    onClick={() => setIsDelete(prev => !prev)}><DeleteIcon/>Delete</Button>
                            <Typography variant="h6" mt={4}><strong>My accounts</strong></Typography>
                            {banks.map(bank =>
                                <Box className="bank-account" mt={2} key={bank.id}>
                                    {isDelete &&
                                        <IconButton className="bank-remove-icon" onClick={() => deleteConfirm(bank.id)}>
                                            <CancelIcon/>
                                        </IconButton>
                                    }
                                    <Grid container>
                                        <Grid item={true} xs={2}>
                                            <img src={Logo} alt="Menu Icon" height={40} width={40}/>
                                        </Grid>
                                        <Grid item={true} xs={10}>
                                            <p className='buy-token-list-name'><strong>{bank.bankName}</strong></p>
                                            <p className='buy-token-list-symbol buy-token-list-symbol-color'>{bank.accountName}</p>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </> :
                        <Typography className="text-center" variant="h5" mt={4}><strong>No accounts</strong></Typography>
                    }
                </>
            }

            <Box mt={4}>
                <ConnectPlaidBank snackBarMessage={snackBarMessage} successHandler={getBanks} />
                <Typography className="text-center" variant="h6" mt={2} mb={2}><strong>OR</strong></Typography>
                <AddBankBtn snackBarMessage={snackBarMessage} successHandler={getBanks} />
            </Box>
        </>
    );
};

export default Banks;