import React, {useEffect, useState} from 'react';
import {Autocomplete, Box, TextField, Typography} from "@mui/material";
import Button from '../Shared/button';
import Step from "../Shared/step";
import {useDispatch} from "react-redux";
import {setBackFunc} from "../../store/reduxSlice";

const PersonalDetails = ({snackBarMessage, userInfo, setUserInfo, setShowScreen, InputField, countries}) => {

    const [placeOfBirth, setPlaceOfBirth] = useState(userInfo.placeOfBirth || null);
    const [residency, setResidency] = useState(userInfo.country || null);
    const [citizen, setCitizen] = useState(userInfo.nationality || null);
    const [occupation, setOccupation] = useState(userInfo.occupation || '');
    const [employer, setEmployer] = useState(userInfo.employer || '');

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setBackFunc(() => setShowScreen('userInfo')));
    }, [setShowScreen, dispatch]);

    const nextStep = () => {
        if(!placeOfBirth) {
            snackBarMessage({message: 'Please select place of birth'});
            return false;
        }
        if(!residency) {
            snackBarMessage({message: 'Please select country of residency'});
            return false;
        }
        if(!citizen) {
            snackBarMessage({message: 'Please provide address'});
            return false;
        }
        if(!occupation) {
            snackBarMessage({message: 'Please provide city'});
            return false;
        }
        setUserInfo({...userInfo, placeOfBirth: placeOfBirth, country: residency, nationality: citizen, occupation, employer});
        setShowScreen("politicallyExposed");
    };

    return (
        <>
            <Step totalSteps={4} currentStep={3} />
            <Box pl={2} mt={4}>
                <Typography variant="h5" sx={{fontWeight: 'bold'}}>More Personal details</Typography>
                <Typography variant="caption">Good news! Some of the information  will be populated from scanning your document earlier.</Typography>

                <Autocomplete
                    sx={{mt: 2}}
                    value={placeOfBirth}
                    isOptionEqualToValue={(option, value) => option.alpha3Code === value.alpha3Code}
                    onChange={(e, v) => setPlaceOfBirth(v)}
                    options={countries}
                    autoHighlight={true}
                    openOnFocus={true}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.alpha2Code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.alpha2Code.toLowerCase()}.png 2x`}
                                alt=""
                            />
                            {option.name} ({option.alpha3Code})
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Place Of Birth"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />

                <Autocomplete
                    sx={{mt: 2}}
                    value={residency}
                    isOptionEqualToValue={(option, value) => option.alpha3Code === value.alpha3Code}
                    onChange={(e, v) => setResidency(v)}
                    options={countries}
                    autoHighlight={true}
                    openOnFocus={true}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.alpha2Code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.alpha2Code.toLowerCase()}.png 2x`}
                                alt=""
                            />
                            {option.name} ({option.alpha3Code})
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Country of residence"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />

                <Autocomplete
                    sx={{mt: 2}}
                    value={citizen}
                    isOptionEqualToValue={(option, value) => option.alpha3Code === value.alpha3Code}
                    onChange={(e, v) => setCitizen(v)}
                    options={countries}
                    autoHighlight={true}
                    openOnFocus={true}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.alpha2Code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.alpha2Code.toLowerCase()}.png 2x`}
                                alt=""
                            />
                            {option.name} ({option.alpha3Code})
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Country of citizenship"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />

                <InputField label={"Occupation"} value={occupation} onChangeHandler={(val) => setOccupation(val)} />
                <InputField label={"Employer"} value={employer} onChangeHandler={(val) => setEmployer(val)} />
            </Box>
            <Button
                className="sdk-theme-btn normal-text "
                variant="contained"
                onClick={nextStep}
                disabled={!placeOfBirth || !residency || !citizen || !occupation || !employer}
                fullWidth
            >
                <strong>Next</strong>
            </Button>
        </>
    );
};

export default PersonalDetails;