import { WIDGET_URL } from "./constants";

/* Shared Components */
export const STEP_BLUE_ICON = WIDGET_URL+'/step-active-blue.png';
export const BACK_BTN_ICON = WIDGET_URL+'/back-btn.png';
export const POWERED_BY_ICON = WIDGET_URL+'/powered-by-everest.png';

/* Navigation */
export const REWARD_REFERRAL = WIDGET_URL+'/referral-rewards.svg';
export const MY_LINK_ICON = WIDGET_URL+'/my-link-icon.svg';
export const CONTACT_ICON = WIDGET_URL+'/contact.svg';

/* Exchange Campaign */
export const REWARD_STEP_1 = WIDGET_URL+'/ref-reward-step1.png';
export const REWARD_STEP_2 = WIDGET_URL+'/ref-reward-step2.png';
export const REWARD_STEP_3 = WIDGET_URL+'/ref-reward-step3.png';
export const REWARD_STEP_4 = WIDGET_URL+'/ref-reward-step4.png';
export const REWARD_STEP_5 = WIDGET_URL+'/ref-reward-step5.png';
export const GIFT_ICON = WIDGET_URL+'/exchange-gift-icon.svg';
export const GIFT_BLUE_ICON = WIDGET_URL+'/gift_blue.png';
export const GIFT_POPUP = WIDGET_URL+'/gift_popup.png';

/* Contact */
export const TWITTER = WIDGET_URL+'/twitter.png';
export const INSTAGRAM = WIDGET_URL+'/instagram.png';

/* Buy */
export const SEPA_PAYMENT = WIDGET_URL+'/sepa-payment.png';
export const SEPA_PAYMENT_ACTIVE = WIDGET_URL+'/sepa-payment-selected.png';
export const VISA_PAYMENT = WIDGET_URL+'/visa-payment.png';
export const VISA_PAYMENT_ACTIVE = WIDGET_URL+'/visa-payment-selected.png';

/* Sell */
export const SELL_WITHDRAW_ICON = WIDGET_URL+'/sell-withdraw.png';

/* Trade */
export const ARROW_ICON = WIDGET_URL+'/icon-arrow.png';

/* Menu icons */
export const HEADER_LOGO = WIDGET_URL+'/header-logo.png';
export const ACCOUNTS_MENU_ICON = WIDGET_URL+'/accounts.png';
export const ACCOUNTS_MENU_HOVER_ICON = WIDGET_URL+'/accounts-w.png';
export const CONTACT_MENU_ICON = WIDGET_URL+'/contact.png';
export const CONTACT_MENU_HOVER_ICON = WIDGET_URL+'/contact-w.png';
export const HISTORY_MENU_ICON = WIDGET_URL+'/history.png';
export const HISTORY_MENU_HOVER_ICON = WIDGET_URL+'/history-w.png';
export const HOME_MENU_ICON = WIDGET_URL+'/home.png';
export const HOME_MENU_HOVER_ICON = WIDGET_URL+'/home-w.png';
export const MY_LINK_MENU_ICON = WIDGET_URL+'/my-link.png';
export const MY_LINK_MENU_HOVER_ICON = WIDGET_URL+'/my-link-w.png';
export const PROFILE_MENU_ICON = WIDGET_URL+'/profile.png';
export const PROFILE_MENU_HOVER_ICON = WIDGET_URL+'/profile-w.png';
export const REF_REWARDS_MENU_ICON = WIDGET_URL+'/referral-rewards.png';
export const REF_REWARDS_MENU_HOVER_ICON = WIDGET_URL+'/referral-rewards-w.png';
export const TERMS_MENU_ICON = WIDGET_URL+'/terms.png';
export const TERMS_MENU_HOVER_ICON = WIDGET_URL+'/terms-w.png';
export const SIGN_OUT = WIDGET_URL+'/sign-out.png';