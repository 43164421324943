import React from 'react';
import {Button} from "@mui/material";
import {useSelector} from "react-redux";
import {selectAppData} from "../../store/reduxSlice";

const ThemeButton = ({children, highlightButton = false, strictClass = false, className = "sdk-theme-btn", variant = "contained", sx = {}, ...props}) => {
    const appData = useSelector(selectAppData);
    const buttonColor = appData.sdkConfiguration.buttonColor;
    const buttonTextColor = appData.sdkConfiguration.buttonTextColor;

    if(highlightButton) {
        const isValidButtonColor = /^#[0-9A-F]{6}$/i.test(buttonColor);
        return (
            <Button className={className} variant={variant} sx={{mt: 4, background: 'transparent !important', color: `${buttonColor && isValidButtonColor ? buttonColor : '#16359D'}`, border: `3px solid ${buttonColor && isValidButtonColor ? buttonColor : '#16359D'}`, ...sx}} {...props}>
                {children}
            </Button>
        );
    } else if(strictClass) {
        return (
            <Button className={className} variant={variant} sx={{mt: 4, ...sx}} {...props}>
                {children}
            </Button>
        );
    } else {
        return (
            <Button className={className} variant={variant} sx={{mt: 4, background: buttonColor, color: buttonTextColor, ":hover": {filter: 'brightness(85%)', bgcolor: buttonColor, color: buttonTextColor}, ...sx}} {...props}>
                {children}
            </Button>
        );
    }

    // return (
    //     <Button className={className} variant={variant} sx={{mt: 4, ...sx, background: (theme) => theme.palette.primary.mainGradient}} {...props}>
    //         {children}
    //     </Button>
    // );
};

export default ThemeButton;