import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { create } from '@incodetech/welcome';
import {Backdrop, CircularProgress} from "@mui/material";
import Button from "../button";
import {getUID, isLoggedIn} from "../../../util/userFunctions";
import {setSession, setDocumentsData} from "./reduxSlice";
import {INCODE_API_KEY, INCODE_API_URL} from "../../../js/variables";
import { v4 as uuidv4 } from 'uuid';
import {selectAppData} from "../../../store/reduxSlice";

export const launchInCode = () => {
    const inCodeBtn = document.getElementById('launchInCode');
    inCodeBtn.click();
};

export const getOcrData = async (token) => {
    try {
        incode = await startInCode();
        return await incode.ocrData({ token });
    } catch (e) {
        console.dir(e);
        throw e;
    }
};

const startInCode = async () => {
    const config = {
        apiURL: INCODE_API_URL,
        apiKey: INCODE_API_KEY,
    };
    const inCodeObj = create(config);
    await inCodeObj.warmup();
    return inCodeObj;
};

let incode;
let session;
let container;
const InCode = ({autoStart = false, showBtn = false, flow = 'kyc', buttonText = 'KYC', configurationId, successHandler, errorHandler}) => {
    let uuid = uuidv4();
    if (isLoggedIn()) {
        uuid = getUID();
    }
    const appData = useSelector(selectAppData);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [mobileFlow, setMobileFlow] = useState(false);
    useEffect(() => {
        if (autoStart) {
            initInCode();
        }
    }, []);

    const createInCodeSession = async () => {
        let userCustomerFields = {};
        let sessionUuid = null;
        if(flow === "register") {
            sessionUuid = uuid;
            const organization = appData.partnerData.organization;
            let defaultOrgs = ["INF"];
            if(organization) {
                defaultOrgs.push(organization);
            }
            defaultOrgs = JSON.stringify(defaultOrgs);
            userCustomerFields.orgs = defaultOrgs;
        }
        return incode.createSession('ALL', sessionUuid, {
            configurationId: configurationId,
            customFields: userCustomerFields,
        });
    };

    const showError = (err) => {
        console.log(err);
        if(errorHandler) {
            errorHandler();
        }
    };

    const redirectToMobile = () => {
        setMobileFlow(true);
        incode.renderRedirectToMobile(container, {
            onSuccess: showSuccess,
            onError: showError,
            flowId: configurationId,
            externalId: uuid,
            session,
        });
    };

    const showSuccess = async () => {
        if (flow === 'kyc') {
            const kycData = await incode.ocrData({ token: session.token });
            dispatch(setDocumentsData(kycData));
            successHandler();
        } else {
            setLoading(true);
            const face = await incode.processFace({ token: session.token });
            if (face.existingUser) {
                errorHandler();
            } else {
                successHandler({ token: session.token, uuid });
            }
            setLoading(false);
        }
    };

    const initInCode = () => {
        if (flow === 'login') {
            initLogin();
        } else {
            initInCodeFlow();
        }
    };

    const initInCodeFlow = async () => {
        setLoading(true);
        container = document.getElementById('incode-container');
        try {
            incode = await startInCode();
            session = await createInCodeSession();
            if (flow === 'register') {
                session.uuid = uuid;
            }
            dispatch(setSession(session));
            redirectToMobile();
            setLoading(false);
        } catch (e) {
            console.dir(e);
            throw e;
        }
    };

    const initLogin = async () => {
        setLoading(true);
        container = document.getElementById('incode-container');
        try {
            incode = await startInCode();
            incode.renderLogin(container, {
                onSuccess: async (session) => {
                    dispatch(setSession(session));
                    successHandler(session);
                },
                onError: errorHandler,
            });
            setLoading(false);
        } catch (e) {
            console.dir(e);
            throw e;
        }
    };

    return (
        <>
            {/*{loading && <Box mt={15} sx={{color: '#00B171', textAlign: 'center'}}><CircularProgress size={100} color={"inherit"} /></Box>}*/}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={mobileFlow ? "incode-mobile-view" : ""} id="incode-container" />
            <Button
                id="launchInCode"
                className="btn text-white custom-button highlight mx-auto"
                onClick={initInCode}
                sx={{ display: showBtn ? 'block' : 'none' }}
            >
                {buttonText}
            </Button>
        </>
    );
};

export default InCode;
