import React, {useState} from 'react';
import {useSelector} from "react-redux";
import helpers from "../../adapters/helpers";
import {Grid, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import InputField from './inputField';
import { PAYMENT_PROVIDER_OPENPAYD } from '../../js/constants';
import {selectUserData} from "../../store/reduxUserSlice";

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddBankBtn = ({snackBarMessage, successHandler}) => {
    
    const userData = useSelector(selectUserData);

    const [openModal, setOpenModal] = useState(false);
    const [addBtnLoading, setAddBtnLoading] = useState(false);
    const [accountName, setAccountName] = useState('');
    const [bankName, setBankName] = useState('');
    const [iban, setIban] = useState('');
    const [confirmIban, setConfirmIban] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [confirmRoutingNumber, setConfirmRoutingNumber] = useState('');

    const addBank = () => {
        if(!accountName.trim()) {
            snackBarMessage({message: 'Please provide account name'});
            return false;
        }

        if(!bankName.trim()) {
            snackBarMessage({message: 'Please provide bank name'});
            return false;
        }

        if(!iban.trim()) {
            snackBarMessage({message: 'Please provide IBAN'});
            return false;
        }

        if(iban !== confirmIban) {
            snackBarMessage({message: 'IBAN and Confirm IBAN should be same'});
            return false;
        }

        if(!routingNumber.trim()) {
            snackBarMessage({message: 'Please provide routing number'});
            return false;
        }

        if(routingNumber !== confirmRoutingNumber) {
            snackBarMessage({message: 'Routing Number and Confirm Routing Number should be same'});
            return false;
        }

        if(!addBtnLoading) {
            setAddBtnLoading(true);
            const data = {
                accountName,
                accountNumber: iban,
                bankName,
                bicCode: routingNumber,
                ownerFirstName: userData.firstName,
                ownerLastName: userData.lastName,
                provider: PAYMENT_PROVIDER_OPENPAYD
            };
            helpers.addBank(data)
                .then((response) => {
                    setAddBtnLoading(false);
                    if (response.data.success) {
                        setAccountName('');
                        setBankName('');
                        setIban('');
                        setConfirmIban('');
                        setRoutingNumber('');
                        setConfirmRoutingNumber('');
                        setOpenModal(false);
                        successHandler();
                    } else {
                        snackBarMessage({message: 'Failed to get banks detail'});
                    }
                })
                .catch((error) => {
                    setAddBtnLoading(false);
                    snackBarMessage({message: 'Failed to get banks detail: '+ error});
                });
        }
    };
    
    return (
        <>
            <Button
                onClick={() => setOpenModal(true)}
                className="sdk-btn2"
                variant="contained"
                fullWidth
            >
                <strong>Add Bank</strong>
            </Button>

            <Dialog open={openModal} fullWidth={true} onClose={() => setOpenModal(false)} TransitionComponent={Transition}>
                <DialogTitle className="buy-modal-text-color">
                    <Typography variant="subtitle1" component="p">
                        <strong>Banks</strong>
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenModal(false)}
                        className="menu-buttons"
                    >
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>
                <DialogContent className="bank-modal-content" dividers>
                    <Typography className="text-center buy-modal-text-color" variant="h5"><strong>Add Bank</strong></Typography>
                    <Typography className="text-center" variant="caption" component="p"><strong>Only Euro denominated SEPA accounts are currently supported.</strong></Typography>
                    <InputField label={"Account Name"} value={accountName} onChangeHandler={(val) => setAccountName(val)} />
                    <InputField label={"Bank Name"} value={bankName} onChangeHandler={(val) => setBankName(val)} />
                    <Grid container spacing={1}>
                        <Grid item={true} xs={6}>
                            <InputField label={"Account Number or IBAN"} value={iban} onChangeHandler={(val) => setIban(val)} />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <InputField label={"Confirm Account Number or IBAN"} value={confirmIban} onChangeHandler={(val) => setConfirmIban(val)} />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <InputField label={"Routing/BIC Number"} value={routingNumber} onChangeHandler={(val) => setRoutingNumber(val)} />
                        </Grid>
                        <Grid item={true} xs={6}>
                            <InputField label={"Confirm Routing/BIC Number"} value={confirmRoutingNumber} onChangeHandler={(val) => setConfirmRoutingNumber(val)} />
                        </Grid>
                    </Grid>
                    <Button
                        sx={{mt: 4}}
                        onClick={addBank}
                        className="sdk-btn2"
                        variant="contained"
                        fullWidth
                    >
                        <strong>{addBtnLoading ? <><CircularProgress size={15} color="inherit" /> Processing...</> : "Add Bank"}</strong>
                    </Button>

                </DialogContent>
            </Dialog>
        </>
    );
};

export default AddBankBtn;