import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {Box, Dialog, DialogContent, Grid, Typography} from "@mui/material";
import Button from "../Shared/button";
import { GIFT_BLUE_ICON, GIFT_ICON, GIFT_POPUP } from "../../js/images";

const Reward = () => {

    const navigate = useNavigate();

    const [modal, setModal] = useState(false);

    return (
        <>
            <Box onClick={() => setModal(true)} className="reward-footer cursor-pointer">
                <Typography sx={{display: "flex", alignItems: "center"}} variant="body1">
                    <img className="exch-gift-icon" src={GIFT_ICON} alt="gift" width={25} />
                    <strong>Claim your <span className="exch-gift-clr">$25</span> Welcome Bonus</strong>
                </Typography>
            </Box>
            <Dialog PaperProps={{className: "confirm-dialog", sx: {height: "770px"}}} onClose={() => setModal(false)} open={modal} fullWidth={true}>
                <DialogContent sx={{p: 0, height: '600px'}}>
                    <Box mt={4} className="login-container" sx={{padding: '0px 40px'}}>
                        <img src={GIFT_POPUP} alt={"gift"} />
                        <Typography className="confirm-dialog-title">Claim your $25 Welcome Gift</Typography>
                        <Typography mt={1} variant="body1">Complete these steps to claim your rewards in ID tokens...</Typography>
                        <Grid mt={4} container spacing={2}>
                            <Grid item={true} xs={5}>
                                <img className="exch-gift-icon" src={GIFT_BLUE_ICON} alt="gift" width={25} />
                                <Typography className="gift-popup-reward" component="span" variant="body1">$15</Typography>
                            </Grid>
                            <Grid className="balance-token-cell" item={true} xs={7}>
                                <Typography className="gift-popup-text" variant="body1"><b>Sign up + buy crypto</b></Typography>
                            </Grid>
                            <Grid className="gift-cell" item={true} xs={5}>
                            </Grid>
                            <Grid className="balance-token-cell gift-cell" item={true} xs={7}>
                                <Typography className="gift-text-desc" variant="body1">Purchase $50 worth of crytpo</Typography>
                            </Grid>
                        </Grid>
                        <Grid mt={1} container spacing={2}>
                            <Grid item={true} xs={5}>
                                <img className="exch-gift-icon" src={GIFT_BLUE_ICON} alt="gift" width={25} />
                                <Typography className="gift-popup-reward" component="span" variant="body1">$10</Typography>
                            </Grid>
                            <Grid className="balance-token-cell" item={true} xs={7}>
                                <Typography className="gift-popup-text" variant="body1"><b>Make a trade</b></Typography>
                            </Grid>
                            <Grid className="gift-cell" item={true} xs={5}>
                            </Grid>
                            <Grid className="balance-token-cell gift-cell" item={true} xs={7}>
                                <Typography className="gift-text-desc" variant="body1">Trade $50 worth of crytpo</Typography>
                            </Grid>
                        </Grid>

                        <Typography className="text-left" mt={4} variant="body1">To earn even more rewards, be sure to share your referral code with your friends – when they spend, you earn a portion of their fees for a year!</Typography>
                        <Button onClick={() => setModal(false)} className="sdk-theme-btn mt32 normal-text gift-btn-width" variant="contained">
                            <strong>Ok</strong>
                        </Button>
                        <Button onClick={() => navigate('/referral-rewards')} highlightButton={true} className="login-buttons normal-text gift-btn-width" variant="contained">
                            <strong className="sign-up-label navigation-login-buttons">Learn More</strong>
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Reward;