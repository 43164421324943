import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Dialog, DialogContent, Grid, Typography} from "@mui/material";
import Button from '../../Shared/button';
import helpers from "../../../adapters/helpers";
import AddBtn from "../../../images/add-btn.png";
import TwitterUpdateIcon from "../../../images/twitter-update-icon.svg";
import {WALLET_URL} from "../../../js/variables";
import {mobileCheck} from '../../../util/generalUtil';
import { WIDGET_URL } from '../../../js/constants';

const TwitterTile = ({referralCode, isNonMlmCampaign, twitterHandler, setLoader, snackBarMessage}) => {

    const navigate = useNavigate();
    const [retweet, setRetweet] = useState(false);
    const [tweetText, setTweetText] = useState('');
    const [twitterSignIn, setTwitterSignIn] = useState(false);

    const confirmTwitterPost = () => {
        setRetweet(true);
    };

    const postToTwitter = () => {
        setLoader(true);
        helpers.twitterUpdate({share_opt: true, tweet_text: tweetText})
            .then((resp) => {
                setLoader(false);
                if (resp.data.success) {
                    snackBarMessage({
                        message: 'Twitter updates has been done successfully.',
                        type: 'success'
                    });
                } else {
                    snackBarMessage({ message: 'Something went wrong while updating data' });
                }
            })
            .catch((err) => {
                setLoader(false);
                console.log(err);
                snackBarMessage({ message: 'Something went wrong while updating data' });
            });
    };

    const initTwitterSignIn = () => {
        const newTab = window.open();
        setTimeout(() => {
            newTab.location.href = "https://twitter.com/";
            setTwitterSignIn(true);
        }, 1000);
    };

    return (
        <>
            {twitterHandler ?
                <Box className="accordion-list-item">
                    <div>
                        <Typography variant="caption">Twitter Account</Typography>
                        <p title={twitterHandler} style={{width: "150px"}} className='dashboard-email-text'>{"@"+twitterHandler}</p>
                    </div>
                    <div className="twitter-btn-div">
                        <button className="post-twitter-btn" onClick={(e) => {confirmTwitterPost(); e.preventDefault();}}>Post to X.com</button>
                    </div>
                </Box>
                :
                <>
                    {mobileCheck() ?
                        <Box className="accordion-list-item">
                            <div>
                                <Typography variant="caption">Twitter Account</Typography>
                                <p className='dashboard-email-text'>Add Twitter Account</p>
                                <Typography variant="caption">First sign in to Twitter, then return and Connect your account to your EverWallet</Typography>
                            </div>
                            <div className="twitter-btn-div" style={{justifyContent: "center"}}>
                                <button className="post-twitter-btn" onClick={initTwitterSignIn}><b>+Sign In to Twitter</b></button>
                                <button className="post-twitter-btn" onClick={() => navigate('/twitter')} style={{marginLeft: "20px"}} disabled={!twitterSignIn}><b>+Connect Twitter</b></button>
                            </div>
                        </Box>
                    :
                        <Box className="accordion-list-item cursor-pointer" onClick={() => navigate('/twitter')}>
                            <Typography variant="caption">Twitter Account</Typography>
                            <Grid container spacing={0}>
                                <Grid item={true} xs={10} className="content-center">
                                    <p className='dashboard-email-text'>Add Twitter Account Now</p>
                                </Grid>
                                <Grid item={true} xs={2} className="text-right">
                                    <img src={AddBtn} width={20} alt="Add"/>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                </>
            }
            <Dialog PaperProps={{className: "confirm-dialog", sx: {height: "900px", width: "100%"}}} open={retweet} fullWidth={true}>
                <DialogContent sx={{p: 0, height: '600px'}}>
                    <Box mt={4} className="login-container" sx={{padding: '0px 40px'}}>
                        <img src={TwitterUpdateIcon} alt={"icon"} />
                        <Typography className="confirm-dialog-title">Post to X.com</Typography>
                        <Typography className="confirm-dialog-text">You are about to update your X.com profile with the Everest mountain and verified wallet link in your bio, and post a verified wallet announcement (post text below in grey box).</Typography>
                        <div className="tweet-text-parent">    
                            <div className="tweet-disable-text">
                                I have a #VerifiedEverWallet with @EverestDotOrg! 🗻<br /> This proves that I’m a real human, not a bot or deep fake. I can control what info is disclosed to whom and either remain anonymous or verify my real identity. Verify me: &lt;your link&gt;
                            </div>
                            <textarea value={tweetText} maxLength={120} onChange={(e) => setTweetText(e.target.value)} className='tweet-text-area' placeholder="Personal message to include in tweet (optional)" autoFocus={true} />
                        </div>
                        <img height='275' src={WIDGET_URL+'/twitter-update.png'} />
                        <Button onClick={() => {postToTwitter(); setRetweet(false); setTweetText('');}} className="sdk-theme-btn mt8 normal-text" variant="contained" fullWidth>
                            <strong>Post to X.com</strong>
                        </Button>
                        <Button onClick={() => {setRetweet(false);}} highlightButton={true} className="login-buttons mt16 normal-text" sx={{marginBottom: "16px"}} variant="contained" fullWidth>
                            <strong className="sign-up-label navigation-login-buttons">Cancel</strong>
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default TwitterTile;