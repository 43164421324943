import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {useDispatch} from "react-redux";
import {Box} from "@mui/material";
import {setConfirmDialog} from "../Shared/ConfirmDialog";
import {launchInCode} from "../Shared/InCode";
import InCode from "../Shared/InCode";
import {INCODE_REGISTER_FLOW_ID} from "../../js/variables";

/* eslint-disable */
const Biometric = ({snackBarMessage, from, setUuid, setPhone, setCountry, service, setShowSignUpScreen}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        launchInCode();
    }, []);

    const successLogin = (data) => {
        setUuid(data.uuid);
        if(service === "identity") {
            setShowSignUpScreen("pin");
        } else {
            setShowSignUpScreen("userInfo");
        }
    };

    const errorLogin = () => {
        setConfirmDialog({
            title: "Registration Failed!",
            message: "It seems you are already enrolled. Please use login button to sign in",
            confirmButtonText: "Sign In",
            cancelButtonText: "Cancel",
            onConfirm: () => {navigate('/signin');},
            height: '500px',
            width: '500px',
        });
    };

    return (
        <>
            <Box className="video-box" sx={{height: "350px", opacity: "0.5"}} />
            <InCode
                successHandler={successLogin}
                errorHandler={errorLogin}
                autoStart={false}
                flow={'register'}
                buttonText={'InCode Register'}
                configurationId={INCODE_REGISTER_FLOW_ID}
            />
        </>
    );
};

export default Biometric;