import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import moment from "moment";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {useDispatch} from 'react-redux'
import helpers from '../../adapters/helpers';
import {Box, Divider, Typography, Button, Grid, CircularProgress, IconButton} from '@mui/material';
import {setBackFunc} from "../../store/reduxSlice";
import {getCurrencySymbol, getTransactionStatusLabel} from "../../util/generalUtil";
import { FIAT_WITHDRAW, TOKEN_TO_FIAT, TOKEN_TO_TOKEN } from '../../js/constants';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const OrderDetail = ({snackBarMessage}) => {
    const { orderId } = useParams();
    const {state} = useLocation();
    const from = state?.from;

    const queryParams = new URLSearchParams(window.location.search);
    const paymentStatus = queryParams.get("status");

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(from !== "history");
    const [refreshLoader, setRefreshLoader] = useState(false);
    const [orderData, setOrderData] = useState(state?.orderData || {});

    let currencySymbol = getCurrencySymbol(orderData.currency_code_in);
    if(orderData.transaction_type === TOKEN_TO_FIAT) {
        currencySymbol = getCurrencySymbol(orderData.currency_code_out);
    }

    useEffect(() => {
        getOrderData();
        if(orderData.uuid) {
            dispatch(setBackFunc(() => navigate('/history')));
        } else {
            dispatch(setBackFunc(() => navigate('/')));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getOrderData = () => {
        setRefreshLoader(true);
        helpers.getOrder(orderId)
            .then(resp => {
                setLoader(false);
                setRefreshLoader(false);
                const respData = resp.data;
                if(respData.success && respData.data.results.length > 0) {
                    setOrderData(respData.data.results[0]);
                }
            })
            .catch((error) => {
                setLoader(false);
                setRefreshLoader(false);
                snackBarMessage({message: error.toString()});
            })
    };

    let transactionStatus = orderData.transaction_status;
    let processingTime = '5-40 minutes';
    let amountPaid = `${currencySymbol}${(orderData.amount * 1).toFixed(2)}`
    let expectedToken = `${(orderData.token_out_amount * 1).toFixed(5)} ${orderData.token_out}`
    let totalFees = `${currencySymbol}${(orderData.total_fee * 1).toFixed(2)}`
    let amountLabel = "Amount Paid";
    let expectedLabel = "Expected token";
    if(orderData.transaction_type === TOKEN_TO_TOKEN) {
        amountPaid = `${(orderData.amount * 1).toFixed(5) * 1} ${orderData.token_in}`;
        totalFees = `$${(orderData.total_fee * 1).toFixed(2)}`;
    } else if(orderData.transaction_type === TOKEN_TO_FIAT) {
        amountLabel = "Amount Send";
        expectedLabel = "Expected fiat";
        amountPaid = (orderData.token_in_amount * 1).toFixed(5) * 1 + " " + orderData.token_in;
        expectedToken = (orderData.currency_out_amount * 1).toFixed(2) + " " +orderData.currency_code_out+".c";
    } else if(orderData.transaction_type === FIAT_WITHDRAW) {
        processingTime = '2-3 days';
        currencySymbol = getCurrencySymbol(orderData.currency_code_out);
        amountLabel = "Withdraw";
        expectedLabel = "Expected Fiat";
        amountPaid = (orderData.amount * 1).toFixed(5) * 1 + " " + orderData.currency_code_out+".c";
        expectedToken = currencySymbol+(orderData.currency_out_amount * 1).toFixed(2);
        totalFees = `${currencySymbol}${(orderData.total_fee * 1).toFixed(2)}`
    }
    if (paymentStatus && paymentStatus === "failed") {
        transactionStatus = 'Failed';
        processingTime = 'N/A';
        amountPaid = `${currencySymbol}${(0 * 1).toFixed(2)}`
        expectedToken = `${(0 * 1).toFixed(5)} ${orderData.token_out}` // zero out
        totalFees = `${currencySymbol}${(0 * 1).toFixed(2)}`
    }

    return (
        <Box className="order-detail-wrapper">
            {loader ?
                <Box className="text-center" mt={8}><CircularProgress size={100}/></Box> :
                <>
                    <Typography variant="body1" sx={{color: '#16359D'}}><b>Your Order Details</b></Typography>
                    <Box mt={6} className="order-detail-info" sx={{color: '#A6A6A6'}}>
                        <>
                            <Grid className="order-detail-grid" container>
                                <Grid className="text-left" item={true} xs={6}>
                                    <Typography variant="body1"><strong>Status</strong></Typography>
                                </Grid>
                                <Grid className="flex-right" item={true} xs={6}>
                                    <IconButton sx={{color: "#16359D", margin: "-15px 0px"}} onClick={getOrderData}>
                                        <AutorenewIcon className={refreshLoader ? "rotateIcon" : ""} />
                                    </IconButton>
                                    <Typography variant="body1"><strong>{getTransactionStatusLabel(transactionStatus)}</strong></Typography>
                                </Grid>
                            </Grid>
                            <Divider />

                            <Grid className="order-detail-grid" container>
                                <Grid className="text-left" item={true} xs={6}>
                                    <Typography variant="body1">Processing time</Typography>
                                </Grid>
                                <Grid className="text-right" item={true} xs={6}>
                                    <Typography variant="body1">{processingTime}</Typography>
                                </Grid>
                            </Grid>
                            <Divider />

                            <Grid className="order-detail-grid" container>
                                <Grid className="text-left" item={true} xs={3}>
                                    <Typography variant="body1">Order ID</Typography>
                                </Grid>
                                <Grid className="text-right" item={true} xs={9}>
                                    <Typography sx={{fontSize: "11px"}} variant="body1">{orderData.trx_uuid}</Typography>
                                </Grid>
                            </Grid>
                            <Divider />

                            <Grid className="order-detail-grid" container>
                                <Grid className="text-left" item={true} xs={5}>
                                    <Typography variant="body1">Order Date</Typography>
                                </Grid>
                                <Grid className="text-right" item={true} xs={7}>
                                    <Typography variant="body1">{moment(orderData.created_at).format("DD/MM/yyyy h:mm a")}</Typography>
                                </Grid>
                            </Grid>
                            <Divider />

                            <Grid className="order-detail-grid" container>
                                <Grid className="text-left" item={true} xs={4}>
                                    <Typography variant="body1">{amountLabel}</Typography>
                                </Grid>
                                <Grid className="text-right" item={true} xs={8}>
                                    <Typography variant="body1">{amountPaid}</Typography>
                                </Grid>
                            </Grid>
                            <Divider />

                            <Grid className="order-detail-grid" container>
                                <Grid className="text-left" item={true} xs={5}>
                                    <Typography variant="body1">{expectedLabel}</Typography>
                                </Grid>
                                <Grid className="text-right" item={true} xs={7}>
                                    <Typography variant="body1">{expectedToken}</Typography>
                                </Grid>
                            </Grid>
                            <Divider />

                            <Grid className="order-detail-grid" container>
                                <Grid className="text-left" item={true} xs={4}>
                                    <Typography variant="body1">Total fees</Typography>
                                </Grid>
                                <Grid className="text-right" item={true} xs={8}>
                                    <Typography variant="body1">{totalFees}</Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            {orderData?.network?.name &&
                                <>
                                    <Grid className="order-detail-grid" container>
                                        <Grid className="text-left" item={true} xs={4}>
                                            <Typography variant="body1">Network</Typography>
                                        </Grid>
                                        <Grid className='flex-right' item={true} xs={8}>
                                            <span className="confirm-transaction-network" style={{backgroundColor: orderData?.network?.name === "Ethereum" ? '#A1A9DC' : '#5D8CCC'}}>{orderData?.network?.name}</span>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </>
                            }
                            {orderData.wallet_address &&
                                <>
                                    <Grid className="order-detail-grid" container>
                                        <Grid className="text-left" item={true} xs={2}>
                                            <Typography variant="body1">Wallet</Typography>
                                        </Grid>
                                        <Grid className="text-right" item={true} xs={10}>
                                            <Typography sx={{wordWrap: 'break-word'}} variant="body1">{orderData.wallet_address}</Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </>
                    </Box>
                    {from !== "history" &&
                        <Button
                            className="mt64"
                            variant="themeContained"
                            onClick={() => navigate('/')}
                            fullWidth
                        >
                            <strong>Close</strong>
                        </Button>
                    }
                </>
            }
        </Box>
    );
};

export default OrderDetail;
