import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

const Timer = ({ onFinish, preText, clearTimer, time = 30 }) => {
    const [timer, setTimer] = useState(null);
    const [timerSec, setTimerSec] = useState(time);

    const displayTime = () => {
        const minutes = parseInt(timerSec / 60);
        const seconds = parseInt(timerSec % 60);
        return (minutes+':'+(seconds < 10 ? '0'+seconds : seconds));
    };

    const execTimer = () => {
        const timerFunc = setInterval(() => {
            setTimerSec((seconds) => {
                const time = seconds - 1;
                if (time === 0) {
                    clearInterval(timerFunc);
                    onFinish();
                }

                return time;
            });
        }, 1000);

        setTimer(timerFunc);
    };

    useEffect(() => {
        if (clearTimer) {
            clearInterval(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearTimer]);

    useEffect(() => {
        execTimer();
        return () => {
            clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box className="timer">
            <strong>{preText}{displayTime()}</strong>
        </Box>
    );
};

export default Timer;
