import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {Box, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Slide, TextField, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Button from '../Shared/button';
import SendInfo from "./sendInfo";
import Keyboard from "../Shared/keyboard";
import { selectWalletData} from "./reduxSlice";
import { fromHuman } from "../../util/conversions";
import helpers from "../../adapters/helpers";
import PinEmpty from '../../images/pin-empty.png';
import PinFill from '../../images/pin-fill.png';
import PinError from '../../images/pin-error.png';
import { getGweiFromWei, getUnFormattedValueFromWei } from "../../util/web3";
import { setConfirmDialog } from "../Shared/ConfirmDialog";
import DialogSuccess from "../../images/dialog-success.png";
import { getNetworkScanBaseUrl } from "../../util/generalUtil";
import { setBackFunc } from "../../store/reduxSlice";

const TransitionModal = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Send = ({snackBarMessage}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const walletData = useSelector(selectWalletData);
    const token = walletData.token;
    const network = walletData.network;
    const walletAddress = walletData.wallet.address;

    const [showScreen, setShowScreen] = useState('default');
    const [amount, setAmount] = useState('');
    const [toAddress, setToAddress] = useState('');
    const [loading, setLoading] = useState(false);
    const [pin, setPin] = useState('');
    const [pinErr, setPinErr] = useState(false);
    const [modal, setModal] = useState(false);
    const [gasLimit, setGasLimit] = useState(false);
    const [gasPrice, setGasPrice] = useState(false);

    useEffect(() => {
        dispatch(setBackFunc(() => navigate('/token/info')));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAmountChange = (event) => {
        const val = event.target.value;
        if (val) {
            const amountRegex = /^([0-9.,]+)$/;
            if (amountRegex.test(val)) {
                setAmount(val);
            }
        } else {
            setAmount('');
        }
    };

    const showPinScreen = () => {
        if (!network) {
            snackBarMessage({message: `Please select the network`});
            return false;
        }
        if (!token) {
            snackBarMessage({message: `Please select the token`});
            return false;
        }
        if (amount * 1 <= 0) {
            snackBarMessage({message: `Please provide amount greater than 0`});
            return false;
        }
        if (amount * 1 > token.fmt_balance) {
            snackBarMessage({message: `Insufficient balance to send ${token.symbol}`});
            return false;
        }
        if (!toAddress) {
            snackBarMessage({message: `Please provide address of the recipient`});
            return false;
        }

        setShowScreen('pin')
    };

    const suggestGas = () => {
        setLoading(true);
        const amountToSend = fromHuman(amount, token.decimals);
        const data = {
            fromAddress: walletAddress,
            toAddress: toAddress,
            amount: amountToSend,
            chainid: network.chain_id,
        };
        helpers
            .suggestGasByNetwork(data)
            .then((response) => {
                setLoading(false);
                const respData = response.data;
                if (respData.success) {
                    setModal(true);
                    setGasLimit(getGweiFromWei(respData.data.gasLimit.toString()));
                    setGasPrice(getUnFormattedValueFromWei(respData.data.gasPrice));
                    // gasPopUp(respData.data, pin);
                }
            })
            .catch((error) => {
                setLoading(false);
                const errorObject = JSON.parse(error.request.response);
                if(errorObject.code.message === "PROVIDER_CANT_EXCHANGE_RATE") {
                    snackBarMessage({message: "Currently we can't provide the exchange rate of this token"});
                } else if(errorObject.error) {
                    snackBarMessage({message: errorObject.error});
                } else {
                    snackBarMessage({message: error.toString()});
                }
            });
    };

    const makeSendTransaction = () => {
        if (token.symbol === network.symbol) {
            sendNativeToken();
        } else {
            sendToken();
        }
    };

    const sendNativeToken = () => {
        const amountToSend = fromHuman(amount, token.decimals);
        const data = {
            fromAddress: walletAddress,
            toAddress: toAddress,
            amount: amountToSend,
            chainid: network.chain_id,
        };
        setLoading(true);
        helpers
            .transferNativeCurrency(data, pin)
            .then((response) => {
                setLoading(false);
                if (response.data.success) {
                    sendSuccess(response.data.data);
                } else {
                    snackBarMessage({message: "Something went wrong while sending tokens"});
                }
            })
            .catch((error) => {
                setLoading(false);
                setModal(false);
                const errorObject = JSON.parse(error.request.response);
                if (errorObject.errorMessage === 'invalid PIN') {
                    setPinErr(true);
                    setPin('');
                }
                if (errorObject.errorMessage) {
                    snackBarMessage({message: errorObject.errorMessage});
                } else if (errorObject.error) {
                    snackBarMessage({message: errorObject.error});
                } else {
                    snackBarMessage({message: error.toString()});
                }
            });
    };

    const sendToken = () => {
        const amountToSend = fromHuman(amount, token.decimals);
        const data = {
            fromAddress: walletAddress,
            toAddress: toAddress,
            amount: amountToSend,
            tokenAddress: token.address,
            chainid: network.chain_id,
        };
        setLoading(true);
        helpers
            .sendTokenByNetwork(data, pin)
            .then((response) => {
                setLoading(false);
                if (response.data.success) {
                    sendSuccess(response.data.data);
                } else {
                    snackBarMessage({message: "Something went wrong while sending tokens"});
                }
            })
            .catch((error) => {
                setLoading(false);
                setModal(false);
                const errorObject = JSON.parse(error.request.response);
                if (errorObject.errorMessage === 'invalid PIN') {
                    setPinErr(true);
                    setPin('');
                }
                if (errorObject.errorMessage) {
                    snackBarMessage({message: errorObject.errorMessage});
                } else if (errorObject.error) {
                    snackBarMessage({message: errorObject.error});
                } else {
                    snackBarMessage({message: error.toString()});
                }
            });
    };

    const sendSuccess = (txId) => {
        const baseUrl = getNetworkScanBaseUrl(network.name);
        setConfirmDialog({
            title: "Send Success",
            message: `Your transaction is under way! Track its status here.<br /><a href="${baseUrl}tx/${txId}" target="_blank">
                ${baseUrl}tx/${txId}
            </a>`,
            cancelButtonShow: false,
            confirmButtonText: "Go Back",
            onConfirm: () => {navigate('/balance/');},
            height: '490px',
            icon: DialogSuccess
        });
    };

    const managePin = (val) => {
        setPinErr(false);
        if(val === "backSpace") {
            setPin(pin.substring(0, pin.length-1));
            return;
        }
        if(pin.length <= 3) {
            const newPin = pin+val;
            setPin(newPin);
        }
    };

    const getPinCodeImg = (place, isError) => {
        if(isError) {
            return PinError;
        }
        if(pin.length >= place)
            return PinFill;
        else
            return PinEmpty;
    };

    const cancePin = () => {
        setPin('');
        setPinErr(false);
        setShowScreen('default');
    };

    const PinScreen = React.forwardRef((props, ref) => {
        return (
            <Box ref={ref} {...props} className="text-center">
                <Typography variant="h4">Please enter your pin</Typography>
                <Typography className="display-none" variant="caption" sx={{color: '#16359D', textDecoration: 'underline'}}>Forgot Pin?</Typography>
                <Box mt={8}>
                    <img src={getPinCodeImg(1, pinErr)} alt={"pin"} />
                    <img className="pin-img" src={getPinCodeImg(2, pinErr)} alt={"pin"} />
                    <img className="pin-img" src={getPinCodeImg(3, pinErr)} alt={"pin"} />
                    <img className="pin-img" src={getPinCodeImg(4, pinErr)} alt={"pin"} />
                </Box>
                {pinErr && <Typography mt={4} variant="caption" sx={{color: 'red'}}>Incorrect pin, please try again</Typography>}
                <Box mt={8}>
                    <Keyboard onClickHandler={(val) => managePin(val)} removeTypeEffect={true} />
                </Box>
                <Button onClick={suggestGas} className="sdk-btn normal-text" variant="contained" disabled={pin.length !== 4 || loading} fullWidth>
                    <strong>{loading ? <><CircularProgress size={15} color="inherit" /> Processing...</> : "Continue"}</strong>
                </Button>
                <Button onClick={cancePin} className="login-buttons navigation-login-border normal-text" variant="contained" fullWidth>
                    <strong className="sign-up-label navigation-login-buttons">Cancel</strong>
                </Button>
            </Box>
        );
    });
    
    return (
        <>
            <Slide direction="left" in={showScreen === "pin"} mountOnEnter unmountOnExit>
                <PinScreen />
            </Slide>
            {showScreen === "default" &&
                <Box className="login-container" mt={8}>
                    <Box mt={4} className="full-width">
                        <SendInfo />

                        <TextField
                            className="pay-amount-field"
                            variant="outlined"
                            value={amount}
                            onChange={handleAmountChange}
                            type="text"
                            margin="normal"
                            label={"Amount"}
                            required
                            fullWidth
                        />
                        
                        <TextField
                            className="pay-amount-field"
                            variant="outlined"
                            value={toAddress}
                            onChange={(e) => setToAddress(e.target.value)}
                            type="text"
                            margin="normal"
                            label={"Address"}
                            placeholder="Enter address"
                            required
                            fullWidth
                        />

                        <Button
                            className="sdk-btn"
                            fullWidth
                            variant="contained"
                            onClick={showPinScreen}
                        >
                            <strong>Continue</strong>
                        </Button>
                    </Box>
                </Box>
            }
            <Dialog open={modal} fullWidth={true} onClose={() => setModal(false)} TransitionComponent={TransitionModal}>
                <DialogTitle className="buy-modal-text-color">
                    <Typography variant="h5" component="p">
                        <strong>Transaction Summary</strong>
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => setModal(false)}
                        className="menu-buttons"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="bank-modal-content" dividers>
                    <Grid className="send-summary-grid" container>
                        <Grid item={true} xs={6}>
                            <Typography className="text-center" variant="body2"><strong>Token Name</strong></Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Typography className="text-center" variant="body2"><strong>{token.name}</strong></Typography>
                        </Grid>
                    </Grid>
                    <Divider className="send-summary-divider" />
                    <Grid className="send-summary-grid" container>
                        <Grid item={true} xs={6}>
                            <Typography className="text-center" variant="body2"><strong>Amount</strong></Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Typography className="text-center" variant="body2"><strong>{amount} {token.symbol}</strong></Typography>
                        </Grid>
                    </Grid>
                    <Divider className="send-summary-divider" />
                    <Grid className="send-summary-grid" container>
                        <Grid item={true} xs={6}>
                            <Typography className="text-center" variant="body2"><strong>Wallet Address</strong></Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Typography className="text-center send-wallet-address" variant="body2"><strong>{walletAddress}</strong></Typography>
                        </Grid>
                    </Grid>
                    <Divider className="send-summary-divider" />
                    <Grid className="send-summary-grid" container>
                        <Grid item={true} xs={6}>
                            <Typography className="text-center" variant="body2"><strong>Receiver's Wallet Address</strong></Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Typography className="text-center send-wallet-address" variant="body2"><strong>{toAddress}</strong></Typography>
                        </Grid>
                    </Grid>
                    <Divider className="send-summary-divider" />
                    <Grid className="send-summary-grid" container>
                        <Grid item={true} xs={6}>
                            <Typography className="text-center" variant="body2"><strong>Gas Limit</strong></Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Typography className="text-center send-wallet-address" variant="body2"><strong>{gasLimit} Gwei</strong></Typography>
                        </Grid>
                    </Grid>
                    <Divider className="send-summary-divider" />
                    <Grid className="send-summary-grid" container>
                        <Grid item={true} xs={6}>
                            <Typography className="text-center" variant="body2"><strong>Gas Price</strong></Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Typography className="text-center send-wallet-address" variant="body2"><strong>{gasPrice} {network.symbol}</strong></Typography>
                        </Grid>
                    </Grid>
                    <Button
                        className="sdk-btn"
                        fullWidth
                        variant="contained"
                        onClick={makeSendTransaction}
                        disabled={loading}
                    >
                        <strong>{loading ? <><CircularProgress size={15} color="inherit" /> Processing...</> : "Send"}</strong>
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Send;