import React, { useEffect, useState } from "react";
import {Box, CircularProgress, Dialog, DialogTitle, DialogContent, Divider, IconButton, Slide, Typography, TextField, InputAdornment} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import TokenGrid from "./tokenGrid";

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TokenModal = ({open, setModal, loader, tokenList, tokenSelect}) => {

    const [tokenFilteredList, setTokenFilteredList] = useState([]);

    useEffect(() => {
        setTokenFilteredList(tokenList);
    }, [tokenList]);

    const filterTokens = (event) => {
        const filteredArr = [];
        const val = event.target.value.toLowerCase();
        tokenList.forEach(token =>{
            if(token.name.toLowerCase().indexOf(val) > -1 || token.symbol.toLowerCase().indexOf(val) > -1){
                filteredArr.push(token);
            }
        });
        setTokenFilteredList(filteredArr);
    };

    const closeModal = () => {
        setModal(false);
        setTokenFilteredList(tokenList);
    };

    return (
        <Dialog open={open} fullWidth={true} onClose={closeModal} TransitionComponent={Transition}>
            <Box className="asset-header">
                <Typography className="buy-modal-text-header" variant="subtitle1" component="p">
                    <strong>Select Asset</strong>
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    className="asset-close-btn"
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogTitle>
                <TextField
                    className='inputRounded'
                    placeholder='Search'
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position={'start'}>
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                    onChange={filterTokens}
                    autoFocus
                    fullWidth
                />
            </DialogTitle>
            <DialogContent className="buy-modal-content" dividers>
                {
                    loader ? <Box className="text-center buy-modal-text-color"><CircularProgress size={50} color={"inherit"} /></Box> :
                        tokenFilteredList.length > 0 ?
                            tokenFilteredList.map(token =>
                                <Box key={token.id}>
                                    <Box className="buy-modal-item" onClick={() => {tokenSelect(token); closeModal();}}>
                                        <TokenGrid token={token} from={'list'} showBalance={!!token.fmt_balance} />
                                    </Box>
                                    <Divider />
                                </Box>
                            ) :
                            <Box mt={10} className="login-container buy-modal-text-color font30">No Tokens</Box>
                }
            </DialogContent>
        </Dialog>
    );
};

export default TokenModal;