import { configureStore } from '@reduxjs/toolkit';
import appReducer from './reduxSlice';
import userReducer from './reduxUserSlice';
import walletReducer from '../components/Wallet/reduxSlice';
import buyReducer from '../components/Buy/reduxSlice';
import sellReducer from '../components/Sell/reduxSlice';
import confirmDialogReducer from '../components/Shared/ConfirmDialog/reduxSlice';
import inCodeReducer from '../components/Shared/InCode/reduxSlice';
import tradeReducer from '../components/Trade/reduxSlice';
import withdrawReducer from '../components/Withdraw/reduxSlice';

export default configureStore({
    reducer: {
        appData: appReducer,
        walletData: walletReducer,
        userData: userReducer,
        buyData: buyReducer,
        sellData: sellReducer,
        tradeData: tradeReducer,
        withdrawData: withdrawReducer,
        confirmDialogData: confirmDialogReducer,
        inCodeData: inCodeReducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['confirmDialogData/setData', 'appData/setBackFunc'],
                ignoredPaths: ['confirmDialogData.onConfirm', 'confirmDialogData.onCancel', 'appData.backFunc'],
            },
        })
    }
})