import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import {useSelector} from "react-redux";
import {Box, InputAdornment, IconButton, TextField, Typography} from "@mui/material";
import Button from "../Shared/button";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import helpers from "../../adapters/helpers";
import { GEMS_URL, WALLET_URL } from "../../js/variables";
import { selectAppData } from "../../store/reduxSlice";
import { CAMPAIGN_TWITTER } from "../../js/constants";

const MyLink = ({snackBarMessage}) => {

    const navigate = useNavigate();
    
    const appData = useSelector(selectAppData);
    const isTwitterCampaign = appData.sdkConfiguration.campaign === CAMPAIGN_TWITTER;
    const [referralCode, setReferralCode] = useState("");
    const refUrl = WALLET_URL+(isTwitterCampaign ? "/r/" : "/r1/")+referralCode
    const gemUrl = GEMS_URL+'?referer=' + referralCode

    useEffect(() => {
        helpers.getReferralCode()
            .then((response) => {
                if (response.data.success) {
                    const respData = response.data.data;
                    setReferralCode(respData.code);
                } else {
                    snackBarMessage({message: 'Something went wrong.'});
                }
            })
            .catch((error) => {
                snackBarMessage({message: 'Something went wrong.'+error});
            });
    }, []);

    const copyBtn = () => {
        navigator.clipboard.writeText(refUrl);
        snackBarMessage({
            message: 'Referral Code URL has been copied successfully.',
            type: 'success'
        });
    };

    const copyGemsBtn = () => {
        navigator.clipboard.writeText(gemUrl);
        snackBarMessage({
            message: 'GEMS Referral Code URL has been copied successfully.',
            type: 'success'
        });
    };
    
    return (
        <Box className="login-container" mt={8}>
            <Box mt={4} className="full-width">
                <Typography variant="h4" mb={2}>
                    <strong>My Link</strong>
                </Typography>
                <Typography variant="body1" mb={2}>
                    <strong>Share this URL/code to earn Referral Rewards</strong>
                </Typography>
                <TextField
                    className="pay-amount-field"
                    variant="outlined"
                    value={gemUrl}
                    type="text"
                    margin="normal"
                    label={"GEMS Referral Code URL"}
                    disabled={true}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><IconButton onClick={copyGemsBtn}><ContentCopyIcon /></IconButton></InputAdornment>,
                    }}
                    fullWidth
                />
                <TextField
                    className="pay-amount-field"
                    variant="outlined"
                    value={refUrl}
                    type="text"
                    margin="normal"
                    label={"Referral Code URL"}
                    disabled={true}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><IconButton onClick={copyBtn}><ContentCopyIcon /></IconButton></InputAdornment>,
                    }}
                    fullWidth
                />
                <Box mt={4}>
                    <QRCode value={refUrl} />
                </Box>
                <Button onClick={() => navigate('/referral-rewards')} highlightButton={true} className="login-buttons normal-text gift-btn-width" variant="contained">
                    <strong className="sign-up-label navigation-login-buttons">Learn More</strong>
                </Button>
            </Box>
        </Box>
    );
};

export default MyLink;