import axios from 'axios';
import api from './api';
import { getToken, getUID } from '../util/userFunctions';
import store from "../store";
import {setSession} from "../store/reduxUserSlice";

export const cancelToken = axios.CancelToken;

axios.interceptors.response.use((response) => {
        return response;
    },
    (error) => {
        if (error.request) {
            const myObject = JSON.parse(error.request.response);
            if (error.response.status === 401 && myObject.message === 'invalid or expired jwt') {
                store.dispatch(setSession("destroy"));
                return new Promise(() => {});
            }
        }

        return Promise.reject(error);
    }
);

const helpers =  {
    async getBuyTokenList(networkName, region) {
        const headers = { token: getToken() };
        return await api.get(`/v3/mobile/everid/crypto-assets/markets?network=${networkName}&region=${region}`, null, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async getUserWallets() {
        const headers = { token: getToken() };
        return await api.get(`/v3/mobile/everid/${getUID()}/wallets`, null, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async getUserTokens(walletAddress) {
        const headers = { token: getToken() };
        return await api.get(`/v3/mobile/everid/crypto-assets/wallet/${walletAddress}`, null, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async getAssociatedWallets() {
        const headers = { token: getToken() };
        return await api.get(
            `/v3/mobile/everid/${getUID()}/wallets/associated`, null, {
                cancelToken: cancelToken.source().token,
                headers,
            }
        );
    },

    async getProfile() {
        const headers = { token: getToken() };
        return await api.get(`/v3/mobile/everid/${getUID()}/getProfile`, null, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async updateDefaultCurrency(data) {
        let headers = { token: getToken() };
        return await api.put(`/v3/mobile/everid/update/currency-crdt`, data, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async uploadLoginVideo(formData) {
        let headers = {
            'Content-Type': `multipart/form-data`,
        };
        return await api.post('/v3/mobile/auth/liveness/video', formData, {
            headers,
            json: false,
        });
    },

    async identifyFaceVideo(token, data, pin = '', twoFaCode = '', twoFaSkip = '') {
        let headers = { token: token };
        if (pin !== '') {
            headers['X-Credential-Pin'] = pin;
        }
        if (twoFaCode) {
            headers['X-Credential-TwoFACode'] = twoFaCode;
        } else if (twoFaSkip) {
            headers['X-Credential-TwoFASkip'] = twoFaSkip;
        }
        return await api.post('/v3/mobile/identifyFaceVideo', data, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async reSendCode(uuid) {
        return await api.post(`/v3/mobile/sms/${uuid}/re-send`, null, {
            cancelToken: cancelToken.source().token,
        });
    },

    async getKYCSupportedDocuments(country) {
        const headers = { token: getToken() };
        return await api.get(
            `/v3/mobile/everid/kyc/${country}/documents`,
            null,
            { cancelToken: cancelToken.source().token, headers }
        );
    },

    async getKYCSupportedCountries() {
        const headers = { token: getToken() };
        return await api.get(
            `/v3/mobile/everid/kyc/supported-countries`,
            null,
            { cancelToken: cancelToken.source().token, headers }
        );
    },

    async uploadKycDocs(data) {
        const headers = { token: getToken() };
        return await api.post(
            `/v3/mobile/everid/${getUID()}/kyc/upgrade/document/upload/trigger`,
            data,
            { cancelToken: cancelToken.source().token, headers }
        );
    },

    async getKycDocumentsData() {
        const headers = { token: getToken() };
        return await api.get(
            `/v3/mobile/everid/${getUID()}/kyc/upgrade/document/data`,
            null,
            { cancelToken: cancelToken.source().token, headers }
        );
    },

    async uploadKycData(data) {
        const headers = { token: getToken() };
        return await api.post(
            `/v3/mobile/everid/${getUID()}/kyc/upgrade/document/verify`,
            data,
            { cancelToken: cancelToken.source().token, headers }
        );
    },

    async getIdentityDocImage(docId) {
        const headers = { token: getToken() };
        return await api.get(
            `/v3/mobile/everid/${getUID()}/kyc/document/${docId}`,
            null,
            { cancelToken: cancelToken.source().token, headers }
        );
    },

    async sendCode(data) {
        return await api.post('/v1/mobile/sendCode', data, {
            cancelToken: cancelToken.source().token,
        });
    },

    async verifyPhone(data) {
        return await api.post('/v1/mobile/verifyPhone', data, {
            cancelToken: cancelToken.source().token,
        });
    },

    async createAccount(data) {
        return await api.post('/v3/mobile/enrollUser', data, {
            cancelToken: cancelToken.source().token,
        });
    },

    async getUserBanks() {
        const headers = { token: getToken() };
        return await api.get(
            `/v3/mobile/everid/${getUID()}/bankAccounts`,
            null,
            {
                cancelToken: cancelToken.source().token,
                headers,
            }
        );
    },

    async getKycStatus() {
        const headers = { token: getToken() };
        return await api.get(`/v3/mobile/everid/${getUID()}/kyc/status`, null, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async getKycStatusByAddress(walletAddress) {
        const headers = { token: getToken() };
        return await api.get(`/v3/internal/wallet/${walletAddress}/kyc/status`, null, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async getConversionRate(from, to) {
        const headers = { token: getToken() };
        return await api.get(`/v3/integrations/exchangeRate/${from}/${to}`, null, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async getBanks() {
        const headers = { token: getToken() };
        return await api.get(`/v3/mobile/everid/${getUID()}/bankAccounts`, null, {
                cancelToken: cancelToken.source().token,
                headers,
            }
        );
    },

    async addBank(data) {
        let headers = { token: getToken() };
        return await api.post(
            `/v3/mobile/everid/${getUID()}/bankAccounts/add`,
            data,
            {
                cancelToken: cancelToken.source().token,
                headers,
            }
        );
    },

    async deleteBank(id) {
        const headers = { token: getToken() };
        return await api.delete(
            `/v3/mobile/everid/${getUID()}/bankAccounts/${id}/remove`, null, {
                cancelToken: cancelToken.source().token,
                headers,
            }
        );
    },

    async createPlaidPayment(data) {
        const headers = { token: getToken() };
        return await api.post(
            `/v3/mobile/everid/${getUID()}/bankAccounts/plaid/create_payment`,
            data,
            {
                cancelToken: cancelToken.source().token,
                headers,
            }
        );
    },

    async createPlaidLinkToken(data) {
        const headers = { token: getToken() };
        return await api.post(`/v3/mobile/everid/${getUID()}/bankAccounts/plaid/create_link_token`, data, {
                cancelToken: cancelToken.source().token,
                headers,
            }
        );
    },

    async createPlaidItem(data) {
        const headers = { token: getToken() };
        return await api.post(`/v3/mobile/everid/${getUID()}/bankAccounts/plaid/create_item`, data, {
                cancelToken: cancelToken.source().token,
                headers,
            }
        );
    },

    async getAuthPlaid(data) {
        const headers = { token: getToken() };
        return await api.post(`/v3/mobile/everid/${getUID()}/bankAccounts/plaid/get_auth`, data, {
                cancelToken: cancelToken.source().token,
                headers,
            }
        );
    },

    async getUserDocuments() {
        const headers = { token: getToken() };
        return await api.get(`/v1/mobile/everid/${getUID()}/listDocument`, null, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async deleteUserDocument(filename) {
        const headers = { token: getToken() };
        return await api.delete(`/v1/mobile/everid/${getUID()}/deleteDocument/${filename}`, null, {
            cancelToken: cancelToken.source().token,
                headers,
            }
        );
    },

    async getDocumentPreview(filename) {
        const headers = { token: getToken() };
        return await api.get(`/v1/mobile/everid/${getUID()}/getDocument/${filename}`, null, {
            cancelToken: cancelToken.source().token,
                headers,
            }
        );
    },

    async createBuyTransaction(data) {
        const headers = { token: getToken() };
        return await api.post(`/v3/cash/fiat-to-token/create`, data, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async approveBuyTransaction(data, pin) {
        const headers = { token: getToken(), 'X-Credential-Pin': pin };
        return await api.put(`/v3/cash/fiat-to-token/approve`, data, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async buyCRDT(data, pin) {
        const headers = { token: getToken(), 'X-Credential-Pin': pin };
        return await api.post(`/v3/integrations/buyCRDT`, data, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async getCrdtPayment(id) {
        const headers = { token: getToken() };
        return await api.get(`/v3/integrations/getCRDTPayment/${id}`, null, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async createUsTransaction(data, pin) {
        const headers = { token: getToken(), 'X-Credential-Pin': pin };
        return await api.post(`/v3/integrations/exchangeFiat`, data, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async ftxFee(data) {
        const headers = { token: getToken() };
        return await api.get(`/v3/integrations/fiatToToken/estimate?coin=${data.coin}&amount=${data.amount}&method=${data.method}&address=${data.address}`, null, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async getTrustPaymentToken(data) {
        const headers = { token: getToken() };
        return await api.post(`/v3/mobile/everid/trust-payment/token`, data, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async verifyPin(pin) {
        const headers = { token: getToken() };
        return await api.post(`/v1/mobile/everid/${getUID()}/verifyPin`, {pin}, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async getNetworks() {
        return await api.get(`/v3/mobile/everid/networks`, null, {
            cancelToken: cancelToken.source().token,
        });
    },

    async partnerInfo(key) {
        return await api.post(`/v3/mobile/everid/partner/info`, {app_key: key}, {
            cancelToken: cancelToken.source().token,
        });
    },

    async associateWallet(walletAddress) {
        const headers = { token: getToken() };
        return await api.post(
            `/v3/mobile/everid/${getUID()}/wallets/associated/${walletAddress}`, null, {
                cancelToken: cancelToken.source().token,
                headers,
            }
        );
    },

    async cryptoAssets() {
        return await api.get(`/v3/payment/assets/crypto`, null, {
            cancelToken: cancelToken.source().token,
        });
    },

    async cryptoAssetsPairs(params) {
        const baseUrl = '/v3/payment/assets/crypto/pairs';
        const queryParams = new URLSearchParams({
            partner_key: params.appKey,
        });
        return await api.get(`${baseUrl}?${queryParams.toString()}`, null, {
            cancelToken: cancelToken.source().token,
        });
    },

    async fiatAssets() {
        return await api.get(`/v3/payment/assets/fiat`, null, {
            cancelToken: cancelToken.source().token,
        });
    },

    async feeEstimator(data) {
        return await api.get(`/v3/payment/orders/calc?payment_type=${data.paymentMethod}&transaction_type=${data.type}&fiat_ccy=${data.currency}&coin_amount=${data.amount}&crypto_ccy=${data.token}&network=${data.network}&exchange=${data.exchange}&payment_provider=${data.paymentProvider}`, null, {
            cancelToken: cancelToken.source().token,
        });
    },

    async feeSellEstimator(data) {
        return await api.get(`/v3/payment/orders/calc?transaction_type=${data.type}&fiat_ccy=${data.currency}&coin_amount=${data.amount}&crypto_ccy=${data.token}&network=${data.network}&exchange=${data.exchange}`, null, {
            cancelToken: cancelToken.source().token,
        });
    }, 

    async feeWithdrawEstimator(data) {
        return await api.get(`/v3/payment/orders/calc?payment_type=${data.paymentMethod}&transaction_type=${data.type}&fiat_ccy=${data.currency}&coin_amount=${data.amount}&payment_provider=${data.paymentProvider}`, null, {
            cancelToken: cancelToken.source().token,
        });
    },    

    async feeTradeEstimator(data, source) {
        const baseUrl = '/v3/payment/orders/calc';
        const params = new URLSearchParams({
            transaction_type: data.type,
            coin_amount: data.amount,
            exchange: data.exchange,
            input_token: data.tokenIn,
            input_token_network: data.networkIn,
            output_token: data.tokenOut,
            output_token_network: data.networkOut,
            output_exchange: data.exchangeOut,
        });

        return await api.get(`${baseUrl}?${params.toString()}`, null, {
            cancelToken: source.token,
        });
    },

    async transactionTypes(type) {
        return await api.get(`/v3/payment/transactions/type?name=${type}`, null, {
            cancelToken: cancelToken.source().token,
        });
    },

    async createOrder(data, appKey) {
        const headers = { "X-Api-Key": appKey };
        const userToken = getToken();
        if(userToken) {
            headers.token = userToken;
        }
        return await api.post(`/v3/payment/orders`, data, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async createCheckoutPayment(data) {
        const headers = { token: getToken() };
        return await api.post(`/v3/payment/checkout`, data, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async passThroughKyc(data) {
        const headers = { token: getToken() };
        return await api.post(`/v3/mobile/everid/kyc/upgrade/minimal`, data, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async getOrderHistory(pageNumber) {
        const headers = { token: getToken() };
        return await api.get(`/v3/payment/orders?page=${pageNumber}`, null, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async getOrder(orderId) {
        const headers = { token: getToken() };
        return await api.get(`/v3/payment/orders?trx_id=${orderId}`, null, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async getDepositAddress(orderId) {
        return await api.get(`/v3/payment/orders/deposit-address?order_id=${orderId}`, null, {
            cancelToken: cancelToken.source().token
        });
    },

    async confirmDeposit(data) {
        const headers = { token: getToken() };
        return await api.post(`/v3/payment/orders/confirm-deposit`, data, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async linkWallet(data) {
        const headers = { token: getToken() };
        return await api.post(`/v3/mobile/everid/${getUID()}/wallets/add`, data, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async uploadDocument(data) {
        const headers = { token: getToken() };
        return await api.post(`/v1/mobile/everid/${getUID()}/uploadDocument`, data, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async twitterRequestToken() {
        const headers = { token: getToken() };
        return await api.post(`/v3/social/Twitter/tokens`, null, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async twitterInfo() {
        const headers = { token: getToken() };
        return await api.get(`/v3/social/Twitter/users/${getUID()}`, null, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async twitterUpdate(data) {
        const headers = { token: getToken() };
        return await api.put(`/v3/social/Twitter/users/${getUID()}`, data, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async isValidReferralCode(refCode) {
        const headers = { token: getToken() };
        return await api.get(`/v3/mobile/referralCode/${refCode}/isValid`, null, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async getTotalNumberOfReferrals() {
        let headers = { token: getToken() };
        return await api.get(`/v3/referral/refcount`, null, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async getReferralCode() {
        const headers = { token: getToken() };
        return await api.post(`/v3/referral`, null, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async login(data, pin = '', TwoFACode = '', TwoFASkip = '') {
        let headers = {};
        if (pin !== '') {
            headers['X-Credential-Pin'] = pin;
        }
        if (TwoFACode) {
            headers['X-Credential-TwoFACode'] = TwoFACode;
        } else if (TwoFASkip) {
            headers['X-Credential-TwoFASkip'] = TwoFASkip;
        }
        return await api.post('/v3/auth/login', data, {
            cancelToken: cancelToken.source().token,
            headers,
        });
    },

    async register(data) {
        return await api.post('/v3/auth/signup', data, {
            cancelToken: cancelToken.source().token,
        });
    },

    async transferNativeCurrency(data, pin) {
        const headers = { token: getToken(), 'X-Credential-Pin': pin };
        return await api.post(
            `/v3/mobile/everid/${getUID()}/transferNativeCurrency`,
            data,
            {
                cancelToken: cancelToken.source().token,
                headers,
            }
        );
    },

    async sendTokenByNetwork(data, pin) {
        const headers = { token: getToken(), 'X-Credential-Pin': pin };
        return await api.post(
            `/v3/mobile/everid/${getUID()}/sendERC20/sendAllChainToken`,
            data,
            {
                cancelToken: cancelToken.source().token,
                headers,
            }
        );
    },

    async suggestGasByNetwork(data) {
        const headers = { token: getToken() };
        return await api.post(
            `/v3/mobile/everid/sendERC20/suggestAllChainGas`,
            data,
            {
                cancelToken: cancelToken.source().token,
                headers,
            }
        );
    },

};

export default helpers;
