//Env variables..
export const APP_ENV = process.env.REACT_APP_ENV;
export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
export const API_URL = process.env.REACT_APP_API_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const WALLET_URL = process.env.REACT_APP_WALLET_URL;
export const GEMS_URL = process.env.REACT_APP_GEMS_URL;
export const ORGNAME = process.env.REACT_APP_ORGNAME;
export const SECRET = process.env.REACT_APP_SECRET;
export const NETWORK = process.env.REACT_APP_NETWORK;
export const TP_LIVE_STATUS = process.env.REACT_APP_TRUST_PAYMENT_LIVE_STATUS;
export const INCODE_API_URL = process.env.REACT_APP_INCODE_API_URL;
export const INCODE_API_KEY = process.env.REACT_APP_INCODE_API_KEY;
export const CHECKOUT_SDK_KEY = process.env.REACT_APP_CHECKOUT_SDK_KEY;
export const INCODE_KYC_FLOW_ID = process.env.REACT_APP_INCODE_KYC_FLOW_ID;
export const INCODE_REGISTER_FLOW_ID = process.env.REACT_APP_INCODE_REGISTER_FLOW_ID;
export const DEBIT_CARD_PROVIDER = process.env.REACT_APP_DEBIT_CARD_PROVIDER;
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
export const FIREBASE_CONFIG = process.env.REACT_APP_FIREBASE_CONFIG;
export const BLOCK_EXPLORER_EVERCHAIN_URL = process.env.REACT_APP_BLOCK_EXPLORER_EVERCHAIN_URL;
export const WALLET_CONNECT_PROJECT_ID = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;
export const PARTNER_VIAARPE_APP_KEY = process.env.REACT_APP_PARTNER_VIAARPE_APP_KEY;
