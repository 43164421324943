import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from "react-redux";
import {Box, Grid, TextField, Typography} from "@mui/material";
import {selectUserData} from "../../store/reduxUserSlice";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Document from "../../images/document.png";

const PersonalDetails = () => {

    const navigate = useNavigate();
    const userData = useSelector(selectUserData);
    const address = userData.residentialAddress;

    const InputField = (props) => {
        return <TextField
            sx={{mt: 3}}
            inputProps={{style: {WebkitTextFillColor: "#000000"}}}
            variant="outlined"
            label={props.label}
            value={props.value}
            fullWidth
            disabled
        />
    };

    return (
        <Box>
            <Typography variant="h5" mt={8}>
                <strong>Personal Details</strong>
            </Typography>

            <InputField label={"Full Name"} value={userData.firstName+' '+userData.lastName} />
            {userData.gender && <InputField label={"Gender"} value={userData.gender === "M" ? "Male" : "Female"} />}
            {userData.email && <InputField label={"Email"} value={userData.email} />}
            <InputField label={"Phone"} value={"+"+userData.countryCode+' '+userData.phoneNumber} />
            {userData.birthday && <InputField label={"Birthday"} value={userData.birthDate} />}
            {address.street &&
            <>
                <InputField label={"Address Line 1"} value={address.street} />
                <InputField label={"Address Line 2"} value={""} />
                <InputField label={"City"} value={address.city} />
                <InputField label={"State"} value={address.state} />
                <InputField label={"Post Code"} value={address.postcode} />
                <InputField label={"Country"} value={address.country} />
            </>
            }

            <Box mt={5} mb={5} className="document-item" onClick={() => navigate('/documents', {state: {identityDocs: userData.identityDocuments}})}>
                <Grid container>
                    <Grid item={true} xs={2}>
                        <img src={Document} alt="Menu Icon" height={40} width={40} />
                    </Grid>
                    <Grid item={true} xs={8} className="content-center">
                        <Box sx={{textAlign: 'left'}}>
                            <p className='buy-token-list-name'>Documents</p>
                        </Box>
                    </Grid>
                    <Grid item={true} xs={2}>
                        <Typography sx={{textAlign: 'right'}}>
                            <ArrowRightIcon sx={{fontSize: '30px', color: '#195BB6'}} />
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default PersonalDetails;