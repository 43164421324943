import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import {Box, Button, TextField, Typography, CircularProgress} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InputField from '../Shared/inputField';
import { Frames, CardFrame, CardNumber, ExpiryDate, Cvv} from 'frames-react';
import helpers from '../../adapters/helpers';
import {reset} from './reduxSlice';
import ConfirmDialog, {setConfirmDialog} from "../Shared/ConfirmDialog";
import DialogSuccess from "../../images/dialog-success.png";
import {BASE_URL, TP_LIVE_STATUS, CHECKOUT_SDK_KEY} from "../../js/variables";
import { PAYMENT_PROVIDER_CHECKOUT, PAYMENT_PROVIDER_TRUST_PAYMENTS } from '../../js/constants';
import {removeExtraSpace} from "../../util/generalUtil";
import Visa from '../../images/visa.png';
import {selectAppData, selectProfileData} from "../../store/reduxSlice";
import {selectBuyData} from "./reduxSlice";
import BackBtn from '../Shared/backBtn';

const CardPayment = ({snackBarMessage}) => {
    const {state} = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const appData = useSelector(selectAppData);
    const profileData = useSelector(selectProfileData);
    const buyData = useSelector(selectBuyData);
    const appKey = appData.sdkConfiguration.appKey;

    // console.log('profileData: ', profileData);

    const [trustPayLoading, setTrustPayLoading] = useState(false);
    const [checkoutLoading, setCheckoutLoading] = useState(true);
    const [nameOnCard, setNameOnCard] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [stateProvince, setStateProvince] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [country, setCountry] = useState(null);
    const [loader, setLoader] = useState(false);
    const [nameOnCardErr, setNameOnCardErr] = useState(false);
    // const [cardNumberErr, setCardNumberErr] = useState('');
    // const [cardExpireErr, setCardExpireErr] = useState('');
    // const [cardCVVErr, setCardCVVErr] = useState('');
    // const [address1Err, setAddress1Err] = useState(false);
    // const [cityErr, setCityErr] = useState(false);
    // const [stateProvinceErr, setStateProvinceErr] = useState(false);
    // const [zipCodeErr, setZipCodeErr] = useState(false);
    // const [countryErr, setCountryErr] = useState(false);
    const [cardholder, setCardholder] = useState({
        name: '',
        billingAddress: {
            addressLine1: '',
        },
     });
     const [btnLoading, setBtnLoading] = useState(false);
     const [disableButtonState, setDisableButtonState] = useState(true)
     const [cardNumberInvalid, setCardNumberInvalid] = useState(true)
     const [cardExpirationInvalid, setCardExpirationInvalid] = useState(true)
     const [cardCVVInvalid, setCardCVVInvalid] = useState(true)

    useEffect(() => {
        console.log(state)
        if (buyData.provider === PAYMENT_PROVIDER_TRUST_PAYMENTS) {
            if(!state?.jwtToken) {
                navigate('/');
            }
            const scriptTag = document.createElement('script');
            scriptTag.setAttribute('src', 'https://cdn.eu.trustpayments.com/js/latest/st.js');
            scriptTag.addEventListener('load', () => initTrustPayment());
            document.head.appendChild(scriptTag);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }, []);

    useEffect(() => {
        setCardholder({
            name: nameOnCard,
            billingAddress: {
                addressLine1: address1,

            },
         });
    }, [nameOnCard, address1, address2, city, stateProvince, zipCode]);

    useEffect(() => {
        if (!cardNumberInvalid && !cardExpirationInvalid && !cardCVVInvalid && nameOnCard != "") {
            if (disableButtonState) {
                setDisableButtonState(false);
            }
        } else {
            if (!disableButtonState) {
                setDisableButtonState(true);
            }
        }
    }, [nameOnCard, cardNumberInvalid, cardExpirationInvalid, cardCVVInvalid]);

    const handleChange = (val, setFunc, setErrFunc, regexCheck = '') => {
        if(regexCheck !== "" && val !== "" && !regexCheck.test(val)) {
            return false;
        }
        setFunc(val);
        const valCheck = typeof val === "string" ? removeExtraSpace(val) : val;
        if(!valCheck) {
            setErrFunc(true);
        } else {
            setErrFunc(false);
        }
    };

    const handleValidation = (e) => {
        let checkoutField
        if (e && e.element) {
            switch (e.element) {
                case 'card-number':
                    checkoutField = "Card Number";
                    if (!e.isEmpty && e.isValid && cardNumberInvalid) {
                            setCardNumberInvalid(false);
                    } else {
                        if (!cardNumberInvalid) {
                            setCardNumberInvalid(true);
                        }
                    }
                    break;
                case 'expiry-date':
                    checkoutField = "Card Expiration";
                    if (!e.isEmpty && e.isValid && cardExpirationInvalid) {
                        setCardExpirationInvalid(false);
                    } else {
                        if (!cardExpirationInvalid) {
                            setCardExpirationInvalid(true);
                        }
                    }
                    break;
                case 'cvv':
                    checkoutField = "CVV";
                    if (!e.isEmpty && e.isValid && cardCVVInvalid) {
                        setCardCVVInvalid(false);
                    } else {
                        if (!cardCVVInvalid) {
                            setCardCVVInvalid(true);
                        }
                    }
                    break;
                default:
                    console.log('NOOP')
                    break;
            }
        }
    }

    const createPayment = (token) => {
        if (token === "") {
            snackBarMessage({message: "Error processing card"});
            setBtnLoading(false);
            navigate(-1);
        }
        const paymentData = {
            token: token, 
            amount: state?.amount, 
            currency: state?.currency, 
            reference: state?.orderId,
            success_url: `${BASE_URL}/order/${state?.orderId}/?appKey=${appKey}`,
            fail_url: `${BASE_URL}/order/${state?.orderId}/?appKey=${appKey}&status=failed`,
        }
        helpers.createCheckoutPayment(paymentData)
            .then(resp => {
                setBtnLoading(false);
                if(resp.data.success) {
                    console.log('response: ', resp.data, ', redirect to: ', resp.data.data._links.redirect.href);
                    window.location.replace(resp.data.data._links.redirect.href);
                    // setConfirmDialog({
                    //     title: "Order Created",
                    //     message: "An order has been created and will complete after your payment is processed",
                    //     cancelButtonText: "New Order",
                    //     onCancel: () => {dispatch(reset()); navigate('/');},
                    //     confirmButtonText: "View Order Details",
                    //     onConfirm: () => {dispatch(reset()); navigate('/order/'+state?.orderId);},
                    //     height: '490px',
                    //     icon: DialogSuccess
                    // });
                } else {
                    snackBarMessage({message: "Something went wrong while trying to charge your card."});
                }
            }).catch(error => {
                console.log('error: ', error)
                setBtnLoading(false);
                // let eMessage = "Something went wrong while trying to charge your card.";
                // if (error.response.error) {
                //     console.log('error: ', error.response.error)
                //     eMessage = error.response.error;
                // }
                // snackBarMessage({message: eMessage});
                // navigate(-1);
            });
    }

    // For Trust Payments payment form
    const initTrustPayment = () => {
        const config = {
            jwt: state?.jwtToken,
            translations: {'Pay': 'Pay Securely'},
            submitOnSuccess: false,
            animatedCard: true
        };
        if(TP_LIVE_STATUS === "1") {
            config.livestatus = TP_LIVE_STATUS;
        }
        let st = window.SecureTrading(config);
        st.Components();
        setTrustPayLoading(false);
        st.submitCallback = data => {
            const errorCode = data.errorcode * 1;
            if(errorCode > 0) {
                snackBarMessage({message: data.errormessage});
                navigate(-1);
            } else if(data.settlestatus === "3") {
                snackBarMessage({message: data.errormessage});
                navigate(-1);
            } else {
                setConfirmDialog({
                    title: "Buy Success",
                    message: "Your transaction was successful. It can take a few minutes to receive your token.",
                    cancelButtonText: "Buy again",
                    onCancel: () => {dispatch(reset()); navigate('/');},
                    confirmButtonText: "Track Order",
                    onConfirm: () => {dispatch(reset()); navigate('/order/'+state?.orderId);},
                    height: '490px',
                    icon: DialogSuccess
                });
            }
        };
    };

    return (
        <>
            <Box className={`text-center`}>
                <BackBtn onClickHandler={() => navigate('/confirm/transaction')} backBtnText={"Payment Information"} />
                <Typography variant="subtitle1" mt={2}>
                <img src={Visa} width={140} alt={`Debit Card`} />
                    <br />
                    <b>Debit Card</b>
                </Typography>
                <Typography className='text-center d-flex' variant="caption" mt={2}>
                    <ErrorOutlineIcon /> <b>Fiat purchasing is not available for users with Chase and Citi bank accounts</b>
                </Typography>
                {((buyData.provider === PAYMENT_PROVIDER_CHECKOUT && checkoutLoading) || 
                  (buyData.provider === PAYMENT_PROVIDER_TRUST_PAYMENTS && trustPayLoading)) 
                        && <Box className="text-center" mt={8}><CircularProgress/></Box>}
                <Box mt={2}>
                    {buyData.provider === PAYMENT_PROVIDER_TRUST_PAYMENTS &&
                    <div id="st-notification-frame"/> && 
                    <form id="st-form">
                        <div id="st-card-number" className="st-card-number"/>
                        <div id="st-expiration-date" className="st-expiration-date"/>
                        <div id="st-security-code" className="st-security-code"/>
                        {!trustPayLoading && <button className="st-button-submit" type="submit">Pay securely</button>}
                    </form>
                    }

                    {buyData.provider === PAYMENT_PROVIDER_CHECKOUT && 
                    <Frames
                        config={{
                            debug: false,
                            publicKey: CHECKOUT_SDK_KEY,
                            acceptedPaymentMethods: ['Visa', 'Mastercard'],
                            cardholder: cardholder,
                            localization: {
                                cardNumberPlaceholder: 'Card number',
                                expiryMonthPlaceholder: 'MM',
                                expiryYearPlaceholder: 'YY',
                                cvvPlaceholder: 'CVV',
                            },
                            style: {
                                base: {
                                  color: 'black',
                                  fontSize: '18px',
                                },
                                autofill: {
                                  backgroundColor: 'yellow',
                                },
                                hover: {
                                  color: 'blue',
                                },
                                focus: {
                                  color: 'blue',
                                },
                                valid: {
                                  color: 'green',
                                },
                                invalid: {
                                  color: 'red',
                                },
                                placeholder: {
                                  base: {
                                    color: 'gray',
                                    marginLeft: '20px'
                                  },
                                },
                              },
                        }}
                        ready={() => {setCheckoutLoading(false)}}
                        frameActivated={(e) => {}}
                        frameFocus={(e) => {}}
                        frameBlur={(e) => {}}
                        frameValidationChanged={(e) => {handleValidation(e)}}
                        paymentMethodChanged={(e) => {}}
                        cardValidationChanged={(e) => {}}
                        cardSubmitted={(e) => {setBtnLoading(true)}}
                        cardTokenized={(e) => {createPayment(e.token)}}
                        cardTokenizationFailed={(e) => {alert('card tokenization failed'); console.log(e)}}
                        cardBinChanged={(e) => {}}
                    >
                        <InputField label={"Name on Card"} value={nameOnCard} onChangeHandler={val => handleChange(val, setNameOnCard, setNameOnCardErr)} error={nameOnCardErr} />
                        <CardNumber style={{height:  '4em', margin: '20px 0', padding: '20px', border: '1px solid #999'}} />
                        <ExpiryDate style={{height:  '4em', margin: '20px 0', padding: '20px', border: '1px solid #999'}}/>
                        <Cvv style={{height:  '4em', margin: '20px 0', padding: '20px', border: '1px solid #999'}} />
                        {/* <Typography variant="h6" mt={2}>
                            <strong>Billing Address</strong>
                        </Typography>
                        <InputField label={"Address 1"} value={address1} onChangeHandler={val => handleChange(val, setAddress1, setAddress1Err)} error={address1Err} />
                        <InputField label={"Address 2 (optional)"} value={address2} onChangeHandler={(val) => setAddress2(val)} required={false} />
                        <InputField label={"City"} value={city} onChangeHandler={val => handleChange(val, setCity, setCityErr)} error={cityErr} />
                        <InputField label={"State / Province"} value={stateProvince} onChangeHandler={val => handleChange(val, setStateProvince, setStateProvinceErr)} error={stateProvinceErr} />
                        <InputField label={"Postal Code"} value={zipCode} onChangeHandler={val => handleChange(val, setZipCode, setZipCodeErr)} error={zipCodeErr} /> */}
                        <Button
                            className="sdk-btn2 mt64"
                            variant="contained"
                            onClick={() => {
                                Frames.submitCard();
                            }}
                            fullWidth
                            disabled={disableButtonState}
                        ><strong>{btnLoading ? <><CircularProgress size={15} color="inherit" /> Processing...</> : "Pay"}</strong>
                        </Button>
                    </Frames>
                    }
                </Box>
                <Box mt={8}>
                    <div id="st-animated-card" />
                </Box>
            </Box>
            <ConfirmDialog />
        </>
    );
};

export default CardPayment;
