import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import web3 from "web3";
import {
    Box,
    Typography,
    Button,
    InputLabel,
    Grid,
    IconButton,
    CircularProgress,
    Select,
    MenuItem,
    ListItemIcon,
    FormControl,
    FormHelperText,
    FilledInput
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import InfoIcon from '@mui/icons-material/Info';
import helpers from '../../adapters/helpers';
import {selectSellData, setData} from './reduxSlice';
import {selectAppData, selectPartnerData, setAppCurrency} from "../../store/reduxSlice";
import {ESTIMATE_TOOLTIP_TEXT, TOKEN_TO_FIAT, REGION_US, REGION_EU, DEFAULT_CRYPT_TOKEN} from "../../js/constants";
import {getCentralLedgetNetwork, getCurrencySymbol, getNetworkBackGround} from "../../util/generalUtil";
import GenericTokenImage from "../../images/generic-token.png";
import BootstrapTooltip from "../Shared/bootstrapToolTip";
import BuySellLink from "../Shared/buySellLink";
import { selectUserData } from '../../store/reduxUserSlice';
import { isLoggedIn } from '../../util/userFunctions';
import ConnectWallet from '../Shared/connectWallet';
import { getEthBalance, getTokenBalance } from '../../util/web3';
import { setConfirmDialog } from '../Shared/ConfirmDialog';
import { SELL_WITHDRAW_ICON } from '../../js/images';
import TokenModal from '../Shared/TokenModal';
import { handleOrderError } from '../Shared/errorHandler';

const Sell = ({snackBarMessage}) => {

    const isSignIn = isLoggedIn();
    const didMount = useRef(false);
    const tokenMount = useRef(false);
    const sellData = useSelector(selectSellData);
    const appData = useSelector(selectAppData);
    const appKey = appData.sdkConfiguration.appKey;
    const enableAuth = appData.partnerData.enable_auth;
    const isSignAndAuth = isSignIn && enableAuth;
    const isWithdrawEnable = appData.features.withdraw;
    const userRegion = useSelector(selectUserData).region;
    const [currency, setCurrency] = useState(sellData.currency || 'EUR');
    const [estimateTokenValue, setEstimateTokenValue] = useState('');
    const [conversionRate, setConversionRate] = useState('');
    const [inputToken, setInputToken] = useState(sellData.token || {id: ""});
    const [amount, setAmount] = useState(sellData.amount || appData.partnerData.sell_initial_amount);
    const [amountErr, setAmountErr] = useState(false);
    const [tokenList, setTokenList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [tokenLoader, setTokenLoader] = useState(false);
    const [estimateLoader, setEstimateLoader] = useState(false);
    const [fee, setFee] = useState(0);
    const [everestFee, setEverestFee] = useState(0);
    const [networkFee, setNetworkFee] = useState(0);
    const [exchangeFee, setExchangeFee] = useState(0);
    const [quoteId, setQuoteId] = useState('');
    const [showFeeCalculation, setShowFeeCalculation] = useState(false);
    
    const [allTokens, setAllTokens] = useState([]);
    const [availableTokens, setAvailableTokens] = useState([]);
    const [manualSend, setManualSend] = useState(false);
    const [connectWallet, setConnectWallet] = useState('');
    const [selectWallet, setSelectWallet] = useState('');
    const [walletAddress, setWalletAddress] = useState(sellData.address || '');
    const [loader, setLoader] = useState(true);
    const [walletList, setWalletList] = useState([]);
    const [associateWalletList, setAssociateWalletList] = useState([]);
    const [linkedWalletList, setLinkedWalletList] = useState([]);
    const [tokenSymbols, setTokenSymbols] = useState([]);
    const [minimumAmount, setMinimumAmount] = useState(50);
    const [maximumAmount, setMaximumAmount] = useState(10000);
    const [errorText, setErrorText] = useState("");
    const [inputFiatAmount, setInputFiatAmount] = useState(0);

    const [tokenDefault, setTokenDefault] = useState({});


    const amountCal = amount.replaceAll(",", ".") * 1;
    let exchangeRate = "0.00";
    if(amount && conversionRate) {
        exchangeRate = conversionRate;
    }
    const regexAmountCheck = /^([0-9]*(\,|\.)[0-9]+|[0-9]+)$/; //eslint-disable-line
    const currencySymbol = getCurrencySymbol(currency);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if(isSignAndAuth && connectWallet && associateWalletList.length > 0) {
            const delay = setTimeout(() => {
                associateWallet();
            }, 1000);

            return () => clearTimeout(delay)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectWallet, associateWalletList]);

    useEffect(() => {
        if (!tokenMount.current){
            tokenMount.current = true;
            return;
        }

        if(tokenList.length === 0) {
            setInputToken({id: ""});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenList]);

    useEffect(() => {
        if(!walletAddress) {
            return
        }

        if(sellData.addressFrom) {
            if(sellData.addressFrom === 'select') {
                setSelectWallet(walletAddress)
            } else {
                setConnectWallet(walletAddress)
            }
        }
        setInputToken({id: ""});
        resetEstimations();
        getUserTokens();
        let manualSend = true;
        for(let i =0; i < linkedWalletList.length; i++) {
            if(linkedWalletList[i].address.toLowerCase() === walletAddress.toLowerCase()) {
                manualSend = false;
            }
        }
        setManualSend(manualSend);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletAddress]);

    useEffect(() => {
        if(!didMount.current) {
            didMount.current = true;
            return
        }

        const tokenArr = [];
        for (let i = 0; i < availableTokens.length; i++) {
            const currentToken = availableTokens[i];
            if (!currentToken.sellable || (tokenSymbols.length > 0 && tokenSymbols.indexOf(currentToken.symbol) === -1)) {
                continue;
            }
            const balance = currentToken.fmt_balance.replaceAll(',', '');

            const cryptoTokenData = allTokens.find(object => {
                return object.symbol === currentToken.symbol && ((userRegion === REGION_EU && object.regionEu) || (userRegion === REGION_US && object.regionUs));
            });

            const tokenToPush = {
                id: currentToken.id,
                name: currentToken.name,
                symbol: currentToken.symbol,
                address: currentToken.address,
                fmt_balance: balance,
                image: currentToken.icon_uri,
                network: cryptoTokenData?.network ? cryptoTokenData?.network : currentToken.network,
                exchange: cryptoTokenData?.exchange ? cryptoTokenData?.exchange : "bitfinex",
            };
            tokenArr.push(tokenToPush);
            if(sellData.token.id && currentToken.symbol === sellData.token.symbol) {
                setInputToken(tokenToPush);
            } else if(currentToken.symbol === DEFAULT_CRYPT_TOKEN) {
                setInputToken(tokenToPush);
            }
        }
        setTokenList(tokenArr);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableTokens]);

    useEffect(() => {
        const tokenArr = [],
            symbolsArr = [],
            handleTokenPush = (token, symbols, tokens) => {
                symbolsArr.push(token.symbol);
                tokenArr.push(token);

                if(!walletAddress && tokenDefault[token.id]) {
                    setInputToken(token);
                }
            }

        allTokens.forEach((currentToken) => {
            const isRegionUS =
                userRegion === REGION_US && currentToken.regionUs;
            const isRegionEU =
                userRegion === REGION_EU && currentToken.regionEu;

            switch (isSignAndAuth){
                case true:
                    if ((isRegionUS || isRegionEU)) {
                        handleTokenPush(currentToken, symbolsArr, tokenArr);
                    }
                    break;
                default:
                    if (currentToken.regionEu){
                        handleTokenPush(currentToken, symbolsArr, tokenArr);
                    }
                    break
            }
        })
        tokenArr.sort((a, b) => a.symbol.localeCompare(b.symbol));

        setTokenSymbols(symbolsArr);
        if(!walletAddress) {
            setTokenList(tokenArr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRegion, allTokens]);

    useEffect(() => {
        if(amount !== "") {
            if (!regexAmountCheck.test(amount)) {
                setEstimateLoader(false);
                setEstimateTokenValue('');
                setAmountErr(true);
                return false;
            } else {
                setAmountErr(false);
                setErrorText("");
            }
        }
        if(amountCal > 0 && inputToken.id && currency && tokenList.length > 0) {
            setEstimateLoader(true);
            setEstimateTokenValue('');
            resetEstimations();
            const delay = setTimeout(() => {
                getEstimations(inputToken.network, inputToken.exchange);
            }, 1000);

            return () => clearTimeout(delay)
        } else {
            resetEstimations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputToken, amount, currency, tokenList]);

    useEffect(() => {
        setTokenLoader(true);
        helpers
            .transactionTypes(TOKEN_TO_FIAT)
            .then((resp) => {
                if (resp.data.success) {
                    const respData = resp.data.data[0];
                    setMinimumAmount(respData.min_deposit_amount);
                    setMaximumAmount(respData.max_deposit_amount);
                } else {
                    snackBarMessage({message: 'Something went wrong while getting fiat currency data'});
                }
            })
            .catch((error) => {
                snackBarMessage({message: error.toString()});
            });


        Promise.all([
            loadTokenPairs().then((resp) => resp),
            loadCryptoAssets().then((resp) => resp)
        ]).then(([tokenPairs , cryptoAssets]) => {
            setTokenLoader(false);

            const tokenResp = tokenPairs.data;
            const cryptoAssetsResp = cryptoAssets.data;

            if (!tokenResp.success) {
                return;
            }
            if (!cryptoAssetsResp.success) {
                return;
            }
            setTokenDefault(tokenResp.data.default_crypto_to_fiat)

            const whitelistTokens = {};
            for (const [key, pairs] of Object.entries(tokenResp.data.crypto_to_fiat)) {
                whitelistTokens[key] = true;
            }

            const totalTokens = cryptoAssetsResp.data.length;
            const tokenArr = [];
            for (let i = 0; i < totalTokens; i++) {
                const currentToken = cryptoAssetsResp.data[i];

                if(!currentToken.active || !currentToken.sellable) {
                    continue
                }

                if(!whitelistTokens[currentToken.id]) {
                    continue;
                }

                tokenArr.push({
                    id: currentToken.id,
                    name: currentToken.name,
                    symbol: currentToken.symbol,
                    contractAddress: currentToken.address,
                    decimal: currentToken.decimal,
                    token: currentToken.token,
                    crypto: currentToken.crypto,
                    synthetic: currentToken.synthetic,
                    image: currentToken.logo_uri,
                    network: currentToken.network.name,
                    networkImage: currentToken.network.logo_uri,
                    networkSymbol: currentToken.network.symbol,
                    exchange: currentToken.exchange.name,
                    regionUs: currentToken.region_us,
                    regionEu: currentToken.region_eu,
                });
            }
            tokenArr.sort((a, b) => {
                if (a.symbol < b.symbol) return -1;
                if (a.symbol > b.symbol) return 1;
                return 0;
            });

            setAllTokens(tokenArr);
        }).catch((error) => {
            setTokenLoader(false);
            snackBarMessage({message: error.toString()});
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!isSignAndAuth) {
            return
        }
        Promise.all([
            getUserWallets().then((resp) => resp),
            getAssociatedWallets().then((resp) => resp)
        ]).then(([walletResp, associatedWallets]) => {
            if(associatedWallets.data.success && associatedWallets.data.wallets) {
                setAssociateWalletList(associatedWallets.data.wallets);
            }
            setLoader(false);
            if(walletResp.data.wallets) {
                const wallets = walletResp.data.wallets;
                setLinkedWalletList(wallets);
                const walletsArr = [];
                const addressArr = [];
                for(let i =0; i < wallets.length; i++) {
                    const walletObj = {name: wallets[i].name, address: wallets[i].address};
                    walletsArr.push(walletObj);
                    addressArr.push(wallets[i].address);
                }
                if(associatedWallets.data.success && associatedWallets.data.wallets) {
                    const wallets = associatedWallets.data.wallets;
                    for(let i =0; i < wallets.length; i++) {
                        if(addressArr.indexOf(wallets[i].address) < 0) {
                            const walletObj = {name: wallets[i].name, address: wallets[i].address};
                            walletsArr.push(walletObj);
                        }
                    }
                    setWalletList(walletsArr);
                } else {
                    setWalletList(walletsArr);
                }
            } else {
                window.alert(
                    'Something went wrong while getting wallets data.'
                );
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleWalletChange = (e) => {
        const address = e.target.value;
        setSelectWallet(address);
        setWalletAddress(address);
        setConnectWallet('');
    };

    const handleConnectWalletChange = (address) => {
        setConnectWallet(address);
        setWalletAddress(address);
        setSelectWallet('');
    };

    const loadCryptoAssets = () =>{
        return helpers.cryptoAssets()
            .then(response => {
                return response;
            })
            .catch((error) => {
                snackBarMessage({message: error.toString()});
            });
    }

    const loadTokenPairs = () => {
        return helpers
            .cryptoAssetsPairs({appKey: appKey})
            .then((response) => {
                return response;
            })
            .catch(() => {
                setLoader(false);
                snackBarMessage({
                    message:
                        'Unable to load token pairs. Please refresh the page and try again.',
                });
            });
    };

    const getUserTokens = async () => {
        if(isSignAndAuth && walletAddress) {
            getWalletTokens();
        } else if(walletAddress) {
            setTokenLoader(true);
            await getWeb3WalletTokens();
            setTokenLoader(false);
        }
    };

    const getWeb3WalletTokens = async () => {
        const totalTokens = allTokens.length;
        const tokenArr = [];
        let defautToken = "";
        for(let i = 0; i < totalTokens; i++) {
            const currentToken = allTokens[i];
            if(!currentToken.synthetic && currentToken.regionEu) {
                let balance;
                if(currentToken.token) {
                    balance = await getTokenBalance(walletAddress, currentToken);
                } else if(currentToken.crypto) {
                    balance = await getEthBalance(walletAddress);
                }
                if(balance > 0) {
                    currentToken.fmt_balance = balance+"";
                    tokenArr.push(currentToken);
                    if(currentToken.symbol === DEFAULT_CRYPT_TOKEN && (currentToken.fmt_balance * 1) > 0) {
                        defautToken = currentToken;
                    }
                }
            }
            setTokenList(tokenArr);
            if(defautToken) {
                setInputToken(defautToken);
            }
        }
    };

    const getWalletTokens = async () => {
        setTokenLoader(true);
        helpers.getUserTokens(walletAddress)
            .then(resp => {
                setTokenLoader(false);
                if(!resp.data.success) {
                    snackBarMessage({message: "Something went wrong while getting fiat currency data"});
                    return
                }

                const tokenList = resp.data.data;
                tokenList.sort((a, b) => {
                    if (a.symbol < b.symbol) return -1;
                    if (a.symbol > b.symbol) return 1;
                    return 0;
                });
                setAvailableTokens(tokenList);
            })
            .catch((error) => {
                setTokenLoader(false);
                snackBarMessage({message: error.toString()});
            });
    };

    const associateWallet = () => {
        const totalAssociates = associateWalletList.length;
        let found = false;
        for(let i =0; i < totalAssociates; i++) {
            if(associateWalletList[i].address.toLowerCase() === connectWallet.toLowerCase()) {
                found = true;
            }
        }
        if(!found) {
            if(web3.utils.isAddress(connectWallet)) {
                helpers.associateWallet(connectWallet)
            }
        }
    };

    const getUserWallets = async () => {
        return helpers.getUserWallets()
            .then((response) => {
                return response;
            })
            .catch(() => {
                setLoader(false);
                snackBarMessage({message: 'Unable to get Wallets. Please refresh the page and try again.'});
            });
    };

    const getAssociatedWallets = async () => {
        return helpers.getAssociatedWallets()
            .then((response) => {
                return response;
            })
            .catch(() => {
                setLoader(false);
                snackBarMessage({message: 'Unable to get Wallets. Please refresh the page and try again.'});
            });
    };

    const resetEstimations = () => {
        setNetworkFee(0);
        setExchangeFee(0);
        setEverestFee(0);
        setFee(0);
        setInputFiatAmount(0);
        setEstimateTokenValue('');
        setConversionRate('');
    };

    const getEstimations = (network, exchange) => {
        const data = {
            type: TOKEN_TO_FIAT,
            amount: amountCal,
            token: inputToken.symbol,
            currency,
            network: getCentralLedgetNetwork(network),
            exchange: exchange
        };

        helpers.feeSellEstimator(data)
            .then((response) => {
                setEstimateLoader(false);
                if (response.data.success) {
                    const respData = response.data.data;
                    setAmountErr(false);
                    setErrorText("");
                    setQuoteId(respData.quote_id);
                    setEstimateTokenValue(respData.fiat_out_amount);
                    if(minimumAmount * 1 > respData.fiat_out_amount * 1) {
                        setAmountErr(true);
                        setErrorText(`Minimum value of token should be greater than ${currencySymbol+minimumAmount}`);
                    }
            
                    if(maximumAmount * 1 < respData.fiat_out_amount * 1) {
                        setErrorText(`Maximum value of token should be less than ${currencySymbol+maximumAmount}`);
                        setAmountErr(true);
                    }
                    setConversionRate(respData.conversion_price);
                    setInputFiatAmount(amountCal * respData.conversion_price);
                    setFee(respData.total_fee * 1);
                    const fee_breakdown = respData.fee_breakdown;
                    for(let i = 0; i < fee_breakdown.length; i++) {
                        const currentFee = fee_breakdown[i];
                        if(currentFee.name === "ev_fee") {
                            setEverestFee(currentFee.value * 1);
                        } else if(currentFee.name === "network_fee") {
                            setNetworkFee(currentFee.value * 1);
                        } else if(currentFee.name === "exchange_fee") {
                            setExchangeFee(currentFee.value * 1);
                        }
                    }
                }
            })
            .catch((error) => {
                setEstimateLoader(false);
                resetEstimations();
                handleOrderError({
                    error,
                    snackBarMessage,
                    minErrorFunc: () => {setErrorText(`Minimum value of token should be greater than ${currencySymbol+minimumAmount}`); setAmountErr(true);},
                    maxErrorFunc: () => {setErrorText(`Maximum value of token should be less than ${currencySymbol+maximumAmount}`); setAmountErr(true);}
                });
            });
    };

    const handleAmountChange = (event) => {
        const val = event.target.value;
        if (val) {
            const amountRegex = /^([0-9.,]+)$/;
            if (amountRegex.test(val)) {
                setAmount(val);
            }
        } else {
            setAmount('');
        }
    };

    const tokenSelect = (val) => {
        setInputToken(val);
    };

    const handleFiatCurrency = (e) => {
        const val = e.target.value;
        setCurrency(val);
        if(val === "EUR") {
            dispatch(setAppCurrency({code: "EUR", symbol: "€"}));
        } else {
            dispatch(setAppCurrency({code: "USD", symbol: "$"}));
        }
    };

    const sellToken = () => {

        if(!isSignIn && enableAuth) {
            snackBarMessage({message: "Please SignIn/SignUp to proceed further with sell", type: "info"});
            return false;
        }

        if(!inputToken) {
            snackBarMessage({message: "Please select token"});
            return false;
        }

        if(!amount) {
            snackBarMessage({message: "Please enter amount"});
            return false;
        }

        if (!regexAmountCheck.test(amount)) {
            snackBarMessage({message: "Please provide a valid amount"});
            return false;
        }

        if(!estimateTokenValue) {
            snackBarMessage({message: "Please wait for the estimate to be calculated and then press next"});
            return false;
        }

        if (estimateTokenValue * 1 < minimumAmount * 1) {
            snackBarMessage({message: `${minimumAmount + currencySymbol} is the minimum amount to make the sell.`});
            return false;
        }

        if (estimateTokenValue * 1 > maximumAmount * 1) {
            snackBarMessage({message: `${maximumAmount + currencySymbol} is the maximum amount to make the sell.`});
            return false;
        }
        
        let addressFrom = 'connect';
        if(selectWallet) {
            addressFrom = 'select'
        }

        dispatch(setData({amount, currency, token: inputToken, tokenIcon: inputToken.image, network: inputToken.network, networkIcon: inputToken.networkImage, exchange: inputToken.exchange, totalFee: fee, quoteId, networkFee, everestFee, conversionRate, estimatedTokenAmount: estimateTokenValue, address: walletAddress, addressFrom, manualSend}));
        navigate('/sell/confirm/transaction');
    };

    const withdraw = () => {
        if(!isSignAndAuth) {
            snackBarMessage({message: "Please SignIn/SignUp to proceed further with withdraw", type: "info"});
            return false;
        }
        navigate('/withdraw')
    };

    const withdrawInfo = () => {
        const msg = `When you sell your token you receive fiat tokens into your account. You can re-invest that fiat directly into other tokens, or if you would like, you can withdraw those funds to your bank account.<br /><br />
                    <p style="text-align: left; padding-left: 25%">1. Go to the Sell screen<br />
                    2. Select amount and token<br />
                    3. Click Sell to receive fiat token<br />
                    4. Click Withdraw on Sell screen<br />
                    5. Select amount of fiat and bank<br />
                    6. Click Withdraw to receive fiat</p>`;
        
        setConfirmDialog({
            icon: SELL_WITHDRAW_ICON,
            title: "Sell & Withdraw",
            message: msg,
            height: "600px",
            confirmButtonText: "Ok",
            cancelButtonShow: false
        });
    };

    const setMaxAmount = () => {
        setAmount(inputToken.fmt_balance);
    };

    return (
        <>
            <Box className="login-container">
                <BuySellLink sellActive={true} />
                <Box className="full-width">
                    <Grid mt={8} container>
                        <Grid item={true} xs={2}>
                            <Typography className="input-label-feature mt16" variant="caption" component="p">You Sell</Typography>
                        </Grid>
                        <Grid item={true} xs={10}>
                            {walletAddress && inputToken.id && <Typography className="trade-balance-caption" variant="caption" component="p">You Have: {inputToken.fmt_balance} {inputToken.symbol} <span className='max-input-btn' onClick={setMaxAmount}>MAX</span></Typography>}
                        </Grid>
                    </Grid>
                    <Grid mt={0.5} container>
                        <Grid item={true} xs={6}>
                            <FormControl variant="filled" className='pay-amount-field crypto-field'>
                                <FilledInput
                                    value={amount}
                                    onChange={handleAmountChange}
                                    type="text"
                                    placeholder={`${minimumAmount > 0 ? currencySymbol+minimumAmount+" minimum value" : ""}`}
                                    error={amountErr}
                                    required
                                    fullWidth
                                />
                                <FormHelperText className="swap-fiat-values">{inputFiatAmount > 0 ? currencySymbol+(inputFiatAmount * 1).toFixed(2) : <>&nbsp;</>}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={6} className="content-center">
                            {tokenLoader ? <CircularProgress sx={{ml: 1}} size={30} color={"custom"}/> :
                                <FormControl variant="filled" className="text-left crypto-picker" fullWidth>
                                    {inputToken?.network && <InputLabel className={`select-network-label ${getNetworkBackGround(inputToken?.network)}`}>{getCentralLedgetNetwork(inputToken?.network)}</InputLabel>}
                                    <Select
                                        sx={{
                                            fontWeight: "bold",
                                            '.MuiSvgIcon-root ': {
                                                fill: "#16359D !important",
                                            },
                                            padding: inputToken?.id > 0 ? "0px" : "4.95px"
                                        }}
                                        className={"buy-select-dd"}
                                        variant="filled"
                                        value={inputToken.id}
                                        displayEmpty
                                        onChange={(e) => tokenSelect(e.target.value)}
                                        onOpen={(e) => {setOpenModal(true); e.preventDefault();}}
                                        defaultOpen={false}
                                        open={false}
                                        error={amountErr}
                                    >
                                        <MenuItem value="">Please select</MenuItem>
                                        {tokenList.map(token =>
                                            <MenuItem key={token.id} value={token.id}>
                                                <ListItemIcon className={"dropdown-list-item"}>
                                                    <img src={token.image} onError={({ currentTarget }) => {currentTarget.onerror = null;currentTarget.src = GenericTokenImage;}} alt="tokenImg" width="40" height="40" />
                                                </ListItemIcon>
                                                <span>{token.symbol.toUpperCase()}</span>
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            }
                        </Grid>
                        {errorText  && <Typography variant="caption" sx={{color: 'red'}}><strong>{errorText}</strong></Typography>}
                    </Grid>

                    <Grid mt={4} container>
                        <Grid item={true} xs={4}>
                            <Typography className="input-label-feature" variant="caption" component="p">You Receive</Typography>
                        </Grid>
                    </Grid>

                    <Grid mt={0.5} container>
                        <Grid item={true} xs={6}>
                            <FormControl className='pay-amount-field fiat-field'>
                                <FilledInput
                                    value={estimateTokenValue && (estimateTokenValue * 1).toFixed(5)}
                                    type="text"
                                    readyonly={"true"}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={6} className="content-center">
                            <FormControl variant="filled" className="text-left fiat-picker" fullWidth>
                                <Select
                                    sx={{
                                        fontWeight: "bold",
                                        '.MuiSvgIcon-root ': {
                                            fill: "#16359D !important",
                                        },
                                        padding: currency !== "" ? "0px" : "4.95px"
                                    }}
                                    className="buy-select-dd"
                                    value={currency}
                                    variant="filled"
                                    onChange={handleFiatCurrency}
                                >
                                    <MenuItem value={"EUR"}>
                                        <ListItemIcon className={"dropdown-list-item"}>
                                            <img src={"https://cdn.jsdelivr.net/gh/madebybowtie/FlagKit@2.2/Assets/SVG/EU.svg"} alt="EU Flag" width="40" height="40"/>
                                        </ListItemIcon>
                                        <span>EUR</span>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Box mt={2.5} className='fee-area'>
                        <Grid container>
                            <Grid className='text-left' item={true} xs={12}>
                                <IconButton sx={{padding: "0"}} onClick={() => setShowFeeCalculation(prev => !prev)} disableFocusRipple={true} disableRipple={true}><Typography variant = "caption"><strong>Fee Calculation</strong></Typography><PlayArrowIcon sx={{transform: `rotate(${showFeeCalculation ? "90" : "0"}deg)`, fontSize: '15px'}}/></IconButton>
                            </Grid>
                            {showFeeCalculation &&
                                <>
                                    <Grid className="text-left" item={true} xs={6}>
                                        <Typography variant = "caption">Everest Fee</Typography>
                                    </Grid>
                                    <Grid className="text-right" item={true} xs={6}>
                                        <Typography variant = "caption">{currencySymbol}{estimateLoader ? <CircularProgress sx={{ml: 1}} size={15} color={"custom"} /> : everestFee.toFixed(2)}</Typography>
                                    </Grid>

                                    <Grid className="text-left" item={true} xs={6}>
                                        <Typography variant = "caption">Network Fee</Typography>
                                    </Grid>
                                    <Grid className="text-right" item={true} xs={6}>
                                        <Typography variant = "caption">{currencySymbol}{estimateLoader ? <CircularProgress sx={{ml: 1}} size={15} color={"custom"} /> : networkFee.toFixed(2)}</Typography>
                                    </Grid>

                                    <Grid className="text-left" item={true} xs={6}>
                                        <Typography variant = "caption">Exchange Fee</Typography>
                                    </Grid>
                                    <Grid className="text-right" item={true} xs={6}>
                                        <Typography variant = "caption">{currencySymbol}{estimateLoader ? <CircularProgress sx={{ml: 1}} size={15} color={"custom"} /> : exchangeFee.toFixed(2)}</Typography>
                                    </Grid>
                                </>
                            }
                            <Grid className="text-left" item={true} xs={6}>
                                <Typography variant = "caption">Total Fees</Typography>
                            </Grid>
                            <Grid className="text-right" item={true} xs={6}>
                                <Typography variant = "caption">{currencySymbol}{estimateLoader ? <CircularProgress sx={{ml: 1}} size={15} color={"custom"} /> : fee.toFixed(2)}</Typography>
                            </Grid>
                            {inputToken.id &&
                                <>
                                    <Grid className="text-left" item={true} xs={6}>
                                        <Typography variant = "caption">1 {inputToken.symbol} <BootstrapTooltip title={ESTIMATE_TOOLTIP_TEXT} placement="right"><IconButton className="info-fee-icon"><InfoIcon sx={{fontSize: '14px'}} /></IconButton></BootstrapTooltip></Typography>
                                    </Grid>
                                    <Grid className="text-right" item={true} xs={6}>
                                        <Typography variant = "caption">{currencySymbol}{estimateLoader ? <CircularProgress sx={{ml: 1}} size={15} color={"custom"} /> : (exchangeRate * 1).toFixed(2)}</Typography>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Box>

                    <ConnectWallet snackBarMessage={snackBarMessage} walletAddress={connectWallet} from={"sell"} addressHandler={handleConnectWalletChange} />

                    {isSignAndAuth ?
                        <Box sx={{ alignItems: 'left', width: '100%' }}>
                            {loader ? <Box mt={2} sx={{textAlign: 'center'}}><CircularProgress /></Box> :
                                <>
                                    <Typography className='wallet-caption-text' mt={1} mb={1} variant="caption" component="p">Or</Typography>
                                    <FormControl className="wallet-select-form-control" variant="filled" fullWidth>
                                        {!selectWallet && <InputLabel >Select wallet address</InputLabel>}
                                        <Select
                                            className="wallet-select-dd"
                                            value={selectWallet}
                                            onChange={handleWalletChange}
                                        >
                                            {walletList.map(wallet =>
                                                <MenuItem key={wallet.address} value={wallet.address}>{wallet.name+'('+wallet.address+')'}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </>
                            }
                        </Box> :
                        <>
                        {enableAuth &&
                            <>
                                <Button className="sdk-btn2" onClick={() => navigate('/signin', {state: {from: 'sell'}}) } sx={{mt: 2}} variant="contained" fullWidth>
                                    <strong>Sign In</strong>
                                </Button>
                                <Typography className={"connect-wallet-signup"} variant="caption" onClick={() => navigate('/signup', {state: {from: 'sell'}})}><strong>Sign Up With Everest</strong></Typography>
                            </>
                        }
                        </>
                    }

                    <Box mt={4}>
                        {(isSignAndAuth || connectWallet) &&
                            <Button
                                className="sdk-btn2"
                                fullWidth
                                variant="contained"
                                onClick={sellToken}
                                disabled={amountErr || !estimateTokenValue || !walletAddress}
                            >
                                <strong>Next</strong>
                            </Button>
                        }
                        {(isSignAndAuth && isWithdrawEnable) &&
                            <>
                                <Button
                                    sx={{mt: 3}}
                                    className="sdk-btn2"
                                    fullWidth
                                    variant="contained"
                                    onClick={withdraw}
                                >
                                    <strong>Withdraw</strong>
                                </Button>
                                <Typography className={"connect-wallet-signup"} variant="caption" onClick={withdrawInfo}><strong>Sell & Withdraw Info</strong></Typography>
                            </>
                        }
                    </Box>
                </Box>
            </Box>

            <TokenModal
                open={openModal}
                setModal={setOpenModal}
                loader={tokenLoader}
                tokenList={tokenList}
                tokenSelect={tokenSelect}
            />
        </>
    );
};

export default Sell;
