import { BorderColor } from "@mui/icons-material";
import { createTheme } from "@mui/material";

const darkTheme = createTheme({
    typography: {
        fontFamily: [
            "Spartan",
            "sans-serif"
        ].join(",")
    },
    palette: {
        primary: {
            main: "#FFFFFF",
        },
        secondary: {
            main: "#28FFBB",
        },
        custom: {
            light: '#CCCCCC',
            main: '#CCCCCC',
            dark: '#CCCCCC',
        },
        action: {
            disabled: '#000000',
        }
    },
    fee: {
        color: "#FFFFFF"
    },
    customGray: {
        color: "#CCCCCC"
    },
    tradeInput: {
        borderRadius: "5px",
    },
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: "transparent"
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                connectWallet: {
                    backgroundColor: '#28FFBB',
                    color: '#000000',
                    borderRadius: "5px",
                    textTransform: "none",
                    padding: "10px 12px",
                    border: "1px solid transparent",
                    '&:hover': {
                        backgroundColor: '#2F5244',
                        border: "1px solid #26FFBB",
                        color: "#26FFBB",
                    },
                    '&:disabled': {
                        border: "3px solid #A6A6A6",
                        backgroundColor: '#A6A6A6',
                        color: "black",
                    },
                },
                themeContained: {
                    backgroundColor: '#28FFBB',
                    color: '#000000',
                    borderRadius: "5px",
                    textTransform: "none",
                    padding: "10px 12px",
                    border: "1px solid transparent",
                    '&:hover': {
                        backgroundColor: '#2F5244',
                        border: "1px solid #26FFBB",
                        color: "#26FFBB",
                    },
                    '&:disabled': {
                        border: "3px solid #A6A6A6",
                        backgroundColor: '#A6A6A6',
                        color: "black",
                    },
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: "transparent",
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: "#db3131",
                    "&$error": {
                        color: "#db3131",
                    },
                },
            },
        },
    },
});

export default darkTheme;