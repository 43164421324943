import React, {useEffect, useState, useRef} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {Box, FormControlLabel, Checkbox, CircularProgress, Typography, DialogTitle, IconButton, DialogContent, Dialog, Slide} from "@mui/material";
import Button from "../Shared/button";
import Keyboard from "../Shared/keyboard";
import helpers from '../../adapters/helpers';
import PinError from "../../images/pin-error.png";
import PinFill from "../../images/pin-fill.png";
import PinEmpty from "../../images/pin-empty.png";
import {setConfirmDialog} from "../Shared/ConfirmDialog";
import DialogSuccess from "../../images/dialog-success.png";
import NonMlmSignUpSuc from "../../images/r2-signup-suc.svg";
import {useDispatch, useSelector} from "react-redux";
import {setBackFunc} from "../../store/reduxSlice";
import {selectUserData, setUserID} from "../../store/reduxUserSlice";
import CloseIcon from '@mui/icons-material/Close';
import Terms from "./terms";
import Privacy from "./privacy";
import {selectInCodeData} from "../Shared/InCode/reduxSlice";
import {mobileCheck, removeAllStorage}  from "../../util/generalUtil";
import { WALLET_URL } from "../../js/variables";
import { setSession } from "../../util/userFunctions";
import { setWallet } from "../Wallet/reduxSlice";

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Pin = ({snackBarMessage, setShowScreen, setInitialState, profileData, uuid, navigateTo, service, buyData, referralCode, connectWalletAddress, partialRegister, twitterFlow, isNonMlmCampaign, isExchangeCampaign}) => {

    const {state} = useLocation();
    const navigate = useNavigate();
    const inCodeSessionData = useSelector(selectInCodeData).sessionData;
    const userData = useSelector(selectUserData);
    const [pin, setPin] = useState('');
    const [confirmPin, setConfirmPin] = useState('');
    const [pinErr, setPinErr] = useState(false);
    const [from, setFrom] = useState('pin');
    const [loading, setLoading] = useState(false);
    const [terms, setTerms] = useState(false);
    const [privacy, setPrivacy] = useState(false);
    const [termsModal, setTermsModal] = useState(false);
    const [privacyModal, setPrivacyModal] = useState(false);

    const isMobile = mobileCheck();

    const btnRef = useRef(null);

    const dispatch = useDispatch();
    useEffect(() => {
        if(from === "pin") {
            if(partialRegister) {
                dispatch(setBackFunc(() => setShowScreen('userInfo')));
            } else {
                dispatch(setBackFunc(() => setShowScreen('biometric')));
            }
        } else {
            dispatch(setBackFunc(() => setFrom('pin')));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setShowScreen, dispatch, from]);

    const managePin= (val) => {
        setPinErr(false);
        if(from === "confirmPin") {
            if(val === "backSpace") {
                setConfirmPin(confirmPin.substring(0, confirmPin.length-1));
                return;
            }
            if(confirmPin.length <= 3) {
                setConfirmPin(prevVal => prevVal+val);
            }

        } else {
            if(val === "backSpace") {
                setPin(pin.substring(0, pin.length-1));
                return;
            }
            if(pin.length <= 3) {
                setPin(prevVal => prevVal+val);
            }
        }
    };

    const getPinCodeImg = (from, place, isError) => {
        if(isError) {
            return PinError;
        }
        if(from === "confirmPin") {
            if(confirmPin.length >= place)
                return PinFill;
            else
                return PinEmpty;
        }
        if(from === "pin") {
            if(pin.length >= place)
                return PinFill;
            else
                return PinEmpty;
        }
    };

    useEffect(() => {
        if(isExchangeCampaign && userData.walletAddress && !connectWalletAddress) {
            dispatch(setWallet({name: "EverWallet", address: userData.walletAddress}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData.walletAddress]);

    const handleRegSuc = () => {
        if(isNonMlmCampaign) {
            helpers.getReferralCode()
            .then((response) => {
                if (response.data.success) {
                    const respData = response.data.data;
                    window.open(WALLET_URL+"/r1/"+respData.code, "_blank");
                } else {
                    snackBarMessage({message: 'Something went wrong.'});
                }
            })
            .catch((error) => {
                snackBarMessage({message: 'Something went wrong.'+error});
            });
            
        }
        if(service === "identity") {
            if(navigateTo === "kyc") {
                navigate('/kyc');
            } else {
                navigate('/dashboard');
            }
        } else {
            const from = state?.from;
            if(from === "swap") {
                navigate('/swap')
            } else if(from === "sell") {
                navigate('/sell')
            } else if(buyData.address) {
                navigate('/confirm/transaction');
            } else if(isExchangeCampaign) { //moved from top.. This will trigger when user simply signup or connectWallet & signup
                if(connectWalletAddress) {
                    dispatch(setWallet({name: "Connect Wallet 1", address: connectWalletAddress}));
                }
                navigate('/balance');
                return false;
            } else {
                navigate("/");
            }
        }
    };

    const next = () => {
        if(from === "pin") {
            if(!terms) {
                snackBarMessage({message: "Please accept the terms."});
                return false;
            }
            if(!privacy) {
                snackBarMessage({message: "Please accept the privacy & policy."});
                return false;
            }
            if(!pin || pin.length < 4) {
                snackBarMessage({message: "Please provide pin"});
                return false;
            }
            setFrom('confirmPin');
        } else {
            if(pin !== confirmPin) {
                snackBarMessage({message: "Pins don’t match."});
                return false;
            }
            if(!loading) {
                setLoading(true);
                profileData.uuid = uuid;
                const accountData = {
                    session_id: partialRegister ? inCodeSessionData.interviewToken : inCodeSessionData.token,
                    profile: profileData,
                    pin: pin,
                };
                if (referralCode) {
                    accountData.referralCode = referralCode;
                }
                helpers.register(accountData)
                    .then((response) => {
                        setLoading(false);
                        removeAllStorage();
                        const respData = response.data.data;
                        setSession(uuid, respData.token);
                        dispatch(setUserID(uuid));
                        let height = "450px";
                        let icon = DialogSuccess;
                        let title = "Sign up Successful";
                        let msg = "It will take a few minutes for your account to be approved.";
                        if(twitterFlow) {
                            msg = "Your EverWallet is now ready to use.  To connect your Twitter account, click the Plus Sign next to Add Twitter Account Now on the next screen.";
                            if(isMobile) {
                                msg = "Your EverWallet is now ready to use. To connect your Twitter account and share with your followers, please visit <a href='https://wallet.everest.org/r/' target='_blank'>wallet.everest.org/r/</a> from a desktop computer.";
                            }
                        }
                        let btnText = "Let’s get started"
                        if(twitterFlow) {
                            btnText = "Continue";
                            if(isMobile) {
                                btnText = "Close";
                            }
                        }
                        if(isNonMlmCampaign) {
                            height = "570px";
                            icon = NonMlmSignUpSuc;
                            title = "Welcome!";
                            msg = "Let’s look at your link page.<br /><br />This is where you send people to verify your identity claims.<br /><br />It’s designed to provide verification across services.";
                            btnText = "Next";
                        }

                        setConfirmDialog({
                            icon: icon,
                            title: title,
                            message: msg,
                            confirmButtonText: btnText,
                            cancelButtonShow: false,
                            height: height,
                            onConfirm: () => {
                                handleRegSuc();
                            }
                        });
                    })
                    .catch((error) => {
                        setLoading(false);
                        const errorObject = JSON.parse(error.request.response);
                        snackBarMessage({message: errorObject.code.message});
                        setInitialState();
                    });
            }
        }
    };

    return (
        <>
            {from === "pin" &&
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={terms}
                            onChange={() => setTerms(prev => !prev)}
                            inputProps={{ 'aria-label': 'controlled' }} />}
                    label={<>I agree with all <span className="pin-term-text" onClick={() => {setTermsModal(true); setTerms(prev => !prev);}}>Terms of Service</span></>} />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={privacy}
                            onChange={() => setPrivacy(prev => !prev)}
                            inputProps={{ 'aria-label': 'controlled' }} />}
                    label={<>I agree with all <span className="pin-term-text" onClick={() => {setPrivacyModal(true); setPrivacy(prev => !prev);}}>Privacy Policies</span></>} />
            </>
            }
            <Box mt={2} className="text-center">
                <Typography variant="h4">{from === "pin" ? "Choose your pin" : "Re-enter your pin"}</Typography>
                <Box mt={4}>
                    <img src={getPinCodeImg(from, 1, pinErr)} alt={"pin"} />
                    <img className="pin-img" src={getPinCodeImg(from, 2, pinErr)} alt={"pin"} />
                    <img className="pin-img" src={getPinCodeImg(from, 3, pinErr)} alt={"pin"} />
                    <img className="pin-img" src={getPinCodeImg(from, 4, pinErr)} alt={"pin"} />
                </Box>
                {pinErr && <Typography mt={4} variant="caption" sx={{color: 'red'}}>Pins don’t match, try again</Typography>}
                <Box mt={4}>
                    <Keyboard onClickHandler={(val) => managePin(val)} enterBtnRef={btnRef} removeTypeEffect={true} />
                </Box>
            </Box>
            {loading ?
                <Box mt={2} sx={{color: '#16359D', textAlign: 'center'}}>
                    <CircularProgress size={50} color="inherit" />
                    <Typography variant="h5" sx={{fontWeight: 'bold'}}>Processing...<br />We are creating the account, and it takes about 30 seconds</Typography>
                </Box>
            :
                <Button ref={btnRef} onClick={next} className="sdk-theme-btn normal-text" variant="contained" fullWidth>
                    <strong>{from === "pin" ? "Next" : "Submit"}</strong>
                </Button>
            }
            <Dialog open={termsModal} fullWidth={true} onClose={() => setTermsModal(false)} TransitionComponent={Transition}>
                <DialogTitle className="buy-modal-text-color">
                    <Typography variant="h5" component="p">
                        <strong>Terms of Use</strong>
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => setTermsModal(false)}
                        className="menu-buttons"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="buy-modal-content" dividers>
                    <Terms />
                </DialogContent>
            </Dialog>

            <Dialog open={privacyModal} fullWidth={true} onClose={() => setPrivacyModal(false)} TransitionComponent={Transition}>
                <DialogTitle className="buy-modal-text-color">
                    <Typography variant="h5" component="p">
                        <strong>Privacy Policies</strong>
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => setPrivacyModal(false)}
                        className="menu-buttons"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="buy-modal-content" dividers>
                    <Privacy/>
                </DialogContent>
            </Dialog>

        </>
    );
};

export default Pin;