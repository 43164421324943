import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
    Box,
    Typography,
    Button,
    Grid,
    CircularProgress,
    Select,
    MenuItem,
    ListItemIcon,
    FormControl,
    FilledInput,
    FormHelperText,
    IconButton,
    InputAdornment
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import helpers from '../../adapters/helpers';
import {selectWithdrawData, setData} from './reduxSlice';
import {setAppCurrency} from "../../store/reduxSlice";
import {PAYMENT_BANK, USD_SYMBOL, SYNTHETIC_USD, SYNTHETIC_EUR, FIAT_WITHDRAW} from "../../js/constants";
import {getCurrencySymbol} from "../../util/generalUtil";
import { selectUserData } from '../../store/reduxUserSlice';
import BuySellLink from '../Shared/buySellLink';
import { SEPA_PAYMENT, SEPA_PAYMENT_ACTIVE, VISA_PAYMENT, VISA_PAYMENT_ACTIVE } from '../../js/images';
import { handleOrderError } from '../Shared/errorHandler';

const Withdraw = ({snackBarMessage}) => {

    const userData = useSelector(selectUserData);
    const walletAddress = userData.walletAddress;

    const withdrawData = useSelector(selectWithdrawData);
    const [amount, setAmount] = useState(withdrawData.amount || '');
    const [currency, setCurrency] = useState(withdrawData.currency || 'EUR');
    const [estimateTokenValue, setEstimateTokenValue] = useState('');
    const [amountErr, setAmountErr] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(withdrawData.paymentMethod || PAYMENT_BANK);
    const [estimateLoader, setEstimateLoader] = useState(false);
    const [fee, setFee] = useState(0);
    const [everestFee, setEverestFee] = useState(0);
    const [networkFee, setNetworkFee] = useState(0);
    const [quoteId, setQuoteId] = useState('');
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [fiatAssets, setFiatAssets] = useState([]);
    const [fiatLoader, setFiatLoader] = useState(true);
    const [balanceLoader, setBalanceLoader] = useState(true);
    const [showFeeCalculation, setShowFeeCalculation] = useState(false);
    const [usdBalance, setUsdBalance] = useState(0);
    const [eurBalance, setEurBalance] = useState(0);

    const amountCal = amount.replaceAll(",", ".") * 1;
    const regexAmountCheck = /^([0-9]*(\,|\.)[0-9]+|[0-9]+)$/; //eslint-disable-line
    const currencySymbol = getCurrencySymbol(currency);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentPaymentOption = paymentOptions.find(object => {
        return object.type === paymentMethod;
    }) || {};

    const PaymentButton = ({value, label, handleChange}) => {
        let image;
        if(label === "Debit Card") {
            image = VISA_PAYMENT;
            if(paymentMethod === value) {
                image = VISA_PAYMENT_ACTIVE;
            }
        } else {
            image = SEPA_PAYMENT;
            if(paymentMethod === value) {
                image = SEPA_PAYMENT_ACTIVE;
            }
        }
        if(label)
            return (
                <Box mt={0.5} sx={{cursor: "pointer"}} onClick={() => handleChange(value)}>
                    <img src={image} alt="payment" width="90%" />
                </Box>
            );
    };

    useEffect(() => {
        if(currency) {
            for (let i = 0; i < fiatAssets.length; i++) {
                if(fiatAssets[i].symbol === currency) {
                    const paymentOptions = fiatAssets[i].payment_options;
                    setPaymentOptions(paymentOptions);
                    let found = false;
                    for (let j = 0; j < paymentOptions.length; j++) {
                        if(paymentOptions[j].type === paymentMethod) {
                            found = true;
                            break;
                        }
                    }
                    if(!found && paymentOptions.length > 0) {
                        setPaymentMethod(paymentOptions[0].type);
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currency]);

    useEffect(() => {
        if(amount !== "") {
            if (!regexAmountCheck.test(amount)) {
                setEstimateLoader(false);
                setEstimateTokenValue('');
                setAmountErr(true);
                return false;
            } else {
                setAmountErr(false);
            }
        }
        if(amountCal > 0 && currency && currentPaymentOption.provider) {
            setEstimateLoader(true);
            setEstimateTokenValue('');
            resetEstimations();
            const delay = setTimeout(() => {
                getEstimations();
            }, 1000);

            return () => clearTimeout(delay)
        } else {
            resetEstimations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount, currency, currentPaymentOption]);


    useEffect(() => {
        if(walletAddress) {
            helpers.getUserTokens(walletAddress)
            .then(resp => {
                setBalanceLoader(false);
                if(resp.data.success) {
                    const tokenList = resp.data.data;
                    for (let i = 0; i < tokenList.length; i++) {
                        const currentToken = tokenList[i];
                        const balance = ((currentToken.fmt_balance.replaceAll(',', '') * 1) / 100).toFixed(2);
                        if(currentToken.symbol === SYNTHETIC_USD) {
                            setUsdBalance(balance);
                        } else if(currentToken.symbol === SYNTHETIC_EUR) {
                            setEurBalance(balance);
                        }
                    }
                } else {
                    snackBarMessage({message: "Something went wrong while getting fiat currency data"});
                }
            })
            .catch((error) => {
                setBalanceLoader(false);
                snackBarMessage({message: error.toString()});
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletAddress]);

    useEffect(() => {
        helpers.fiatAssets()
            .then(resp => {
                setFiatLoader(false);
                if(resp.data.success) {
                    const respData = resp.data.data;
                    const fiatArr = [];
                    for (let i = 0; i < respData.length; i++) {
                        const fiat = {};
                        const currentFiat = respData[i];
                        fiat.name = currentFiat.name;
                        fiat.logo_uri = currentFiat.logo_uri;
                        fiat.symbol = currentFiat.symbol;
                        fiat.payment_options = [];
                        const paymentOptions = currentFiat.payment_options;
                        for (let j = 0; j < paymentOptions.length; j++) {
                            const paymentOption = {};
                            const currentPaymentOption = paymentOptions[j];
                            if(currentPaymentOption.active && currentPaymentOption.payment_provider.active && currentPaymentOption.payment_provider.payout_allowed) {
                                paymentOption.id = currentPaymentOption.id;
                                paymentOption.type = currentPaymentOption.payment_type.type;
                                paymentOption.name = currentPaymentOption.payment_provider.name;
                                paymentOption.payout_min_amount = currentPaymentOption.payment_provider.payout_min_amount;
                                paymentOption.payout_max_amount = currentPaymentOption.payment_provider.payout_max_amount;
                                paymentOption.provider = currentPaymentOption.payment_provider.provider;
                                fiat.payment_options.push(paymentOption);
                            }
                        }
                        if(fiat.payment_options.length > 0) {
                            if(currency === fiat.symbol) {
                                setPaymentOptions(fiat.payment_options);
                            }
                            fiatArr.push(fiat);
                        }
                    }
                    setFiatAssets(fiatArr);
                } else {
                    snackBarMessage({message: "Something went wrong while getting fiat currency data"});
                }
            })
            .catch((error) => {
                setFiatLoader(false);
                snackBarMessage({message: error.toString()});
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetEstimations = () => {
        setNetworkFee(0);
        setEverestFee(0);
        setFee(0);
        setEstimateTokenValue('');
    };

    const getEstimations = () => {
        const data = {
            type: FIAT_WITHDRAW,
            paymentMethod,
            amount: amountCal,
            currency,
            paymentProvider: currentPaymentOption.provider
        };

        helpers.feeWithdrawEstimator(data)
            .then((response) => {
                setEstimateLoader(false);
                if (response.data.success) {
                    const respData = response.data.data;
                    setQuoteId(respData.quote_id);
                    setEstimateTokenValue(respData.fiat_out_amount);
                    setFee(respData.total_fee * 1);
                    const fee_breakdown = respData.fee_breakdown;
                    for(let i = 0; i < fee_breakdown.length; i++) {
                        const currentFee = fee_breakdown[i];
                        if(currentFee.name === "ev_fee") {
                            setEverestFee(currentFee.value * 1);
                        } else if(currentFee.name === "withdraw_fee") {
                            setNetworkFee(currentFee.value * 1);
                        }
                    }
                } else {

                }
            })
            .catch((error) => {
                setEstimateLoader(false);
                handleOrderError({error, snackBarMessage});
            });
    };

    const handleAmountChange = (event) => {
        const val = event.target.value;
        if (val) {
            const amountRegex = /^([0-9.,]+)$/;
            if (amountRegex.test(val)) {
                setAmount(val);
            }
        } else {
            setAmount('');
        }
    };

    const handleFiatCurrency = (e) => {
        const val = e.target.value;
        setCurrency(val);
        if(val === "EUR") {
            dispatch(setAppCurrency({code: "EUR", symbol: "€"}));
        } else {
            dispatch(setAppCurrency({code: "USD", symbol: "$"}));
        }
    };

    const withdraw = () => {

        if(!paymentMethod) {
            snackBarMessage({message: "Please select payment method"});
            return false;
        }

        if(!amount) {
            snackBarMessage({message: "Please enter amount"});
            return false;
        }

        if (!regexAmountCheck.test(amount)) {
            snackBarMessage({message: "Please provide a valid amount"});
            return false;
        }

        if(!estimateTokenValue) {
            snackBarMessage({message: "Please wait for the estimate to be calculated and then press next"});
            return false;
        }

        if (estimateTokenValue * 1 < currentPaymentOption.payout_min_amount * 1) {
            snackBarMessage({message: `${currentPaymentOption.payout_min_amount + currencySymbol} is the minimum amount to make the sell.`});
            return false;
        }

        if (estimateTokenValue * 1 > currentPaymentOption.payout_max_amount * 1) {
            snackBarMessage({message: `${currentPaymentOption.payout_max_amount + currencySymbol} is the maximum amount to make the sell.`});
            return false;
        }

        dispatch(setData({amount, currency, provider: currentPaymentOption.provider, totalFee: fee, quoteId, paymentMethod, networkFee, everestFee, estimatedTokenAmount: estimateTokenValue}));
        if(paymentMethod === PAYMENT_BANK) {
            navigate('/account/select');
        }
    };

    const setMaxAmount = () => {
        const balance = currency === USD_SYMBOL ? usdBalance : eurBalance;
        setAmount(balance.replaceAll(",", "."));
    };

    return (
        <>
            <Box className="login-container">
                <BuySellLink sellActive={true} />
                <Box className="full-width">
                    <Grid mt={8} container>
                        <Grid item={true} xs={3}>
                            <Typography className="input-label-feature mt16" variant="caption" component="p">You Withdraw</Typography>
                        </Grid>
                        <Grid item={true} xs={9}>
                            <Typography className="trade-balance-caption" variant="caption" component="p">You Have: {balanceLoader ? <CircularProgress size={10} color="inherit" /> : (currency === USD_SYMBOL ? usdBalance : eurBalance)} {currency+".c"} <span className='max-input-btn' onClick={setMaxAmount}>MAX</span></Typography>
                        </Grid>
                    </Grid>
                    <Grid mt={0.5} container>
                        <Grid item={true} xs={6}>
                            <FormControl variant="filled" className='pay-amount-field fiat-field'>
                                <FilledInput
                                    value={amount}
                                    onChange={handleAmountChange}
                                    type="text"
                                    placeholder="Enter Amount"
                                    error={amountErr}
                                    required
                                    fullWidth
                                />
                                <FormHelperText className="swap-fiat-values">&nbsp;</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={6} className="content-center">
                            {fiatLoader ? <CircularProgress sx={{ml: 1}} size={30} color={"custom"}/> :
                                <FormControl variant="filled" className="text-left fiat-picker" fullWidth>
                                    <Select
                                        sx={{
                                            fontWeight: "bold",
                                            '.MuiSvgIcon-root ': {
                                                fill: "#16359D !important",
                                            }
                                        }}
                                        className="buy-select-dd"
                                        value={currency}
                                        variant="filled"
                                        onChange={handleFiatCurrency}
                                        error={amountErr}
                                    >
                                        {fiatAssets.map(fiat =>
                                            <MenuItem value={fiat.symbol} key={fiat.symbol}>
                                                <ListItemIcon className={"dropdown-list-item"}>
                                                    <img src={fiat.logo_uri} alt="US Flag" width="40" height="40"/>
                                                </ListItemIcon>
                                                <span>{fiat.symbol+".c"}</span>
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            }
                        </Grid>
                    </Grid>

                    <Box className="text-left" mt={4}>
                        <Typography variant="body1"><b>Using withdraw method</b></Typography>
                        {fiatLoader ? <CircularProgress sx={{ml: 1}} size={30} color={"custom"}/> :
                            <Grid mt={0.5} container>
                                {paymentOptions.map(paymentOption =>
                                    <Grid key={paymentOption.name} item={true} xs={6}>
                                        <PaymentButton
                                            label={paymentOption.name}
                                            value={paymentOption.type}
                                            handleChange={setPaymentMethod}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        }
                    </Box>

                    <Grid mt={4} container>
                        <Grid item={true} xs={4}>
                            <Typography className="input-label-feature" variant="caption" component="p">You Receive</Typography>
                        </Grid>
                    </Grid>

                    <Grid mt={0.5} container>
                        <Grid className='text-left' item={true} xs={12}>
                            <FormControl className='pay-amount-field fiat-field' sx={{width: "100%"}}>
                                <FilledInput
                                    value={estimateTokenValue && (estimateTokenValue * 1).toFixed(5)}
                                    startAdornment={<InputAdornment position={'start'}>
                                                        <Typography className='withdraw-receive-symbol'>
                                                            {currencySymbol}
                                                        </Typography>
                                                    </InputAdornment>}
                                    type="text"
                                    readyonly={"true"}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Box mt={2.5} className='fee-area'>
                        <Grid container>
                            <Grid className='text-left' item={true} xs={12}>
                                <IconButton sx={{padding: "0"}} onClick={() => setShowFeeCalculation(prev => !prev)} disableFocusRipple={true} disableRipple={true}><Typography variant = "caption"><strong>Fee Calculation</strong></Typography><PlayArrowIcon sx={{transform: `rotate(${showFeeCalculation ? "90" : "0"}deg)`, fontSize: '15px'}}/></IconButton>
                            </Grid>
                            {showFeeCalculation &&
                                <>
                                    <Grid className="text-left" item={true} xs={6}>
                                        <Typography variant = "caption">Everest Fee</Typography>
                                    </Grid>
                                    <Grid className="text-right" item={true} xs={6}>
                                        <Typography variant = "caption">{currencySymbol}{estimateLoader ? <CircularProgress sx={{ml: 1}} size={15} color={"custom"} /> : everestFee.toFixed(2)}</Typography>
                                    </Grid>

                                    <Grid className="text-left" item={true} xs={6}>
                                        <Typography variant = "caption">Withdraw Fee</Typography>
                                    </Grid>
                                    <Grid className="text-right" item={true} xs={6}>
                                        <Typography variant = "caption">{currencySymbol}{estimateLoader ? <CircularProgress sx={{ml: 1}} size={15} color={"custom"} /> : networkFee.toFixed(2)}</Typography>
                                    </Grid>
                                </>
                            }
                            <Grid className="text-left" item={true} xs={6}>
                                <Typography variant = "caption">Total Fees</Typography>
                            </Grid>
                            <Grid className="text-right" item={true} xs={6}>
                                <Typography variant = "caption">{currencySymbol}{estimateLoader ? <CircularProgress sx={{ml: 1}} size={15} color={"custom"} /> : fee.toFixed(2)}</Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box mt={4}>
                        <Button
                            className="sdk-btn2"
                            fullWidth
                            variant="contained"
                            onClick={withdraw}
                            disabled={amountErr || !estimateTokenValue}
                        >
                            <strong>Next</strong>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Withdraw;
