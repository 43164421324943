import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sessionData: {},
    documentsData: {},
};

export const inCodeSlice = createSlice({
    name: 'inCodeData',
    initialState,
    reducers: {
        setSession: (state, action) => {
            state.sessionData = action.payload;
        },
        setDocumentsData: (state, action) => {
            state.documentsData = action.payload;
        },
    },
});

export const { setSession, setDocumentsData } = inCodeSlice.actions;

export const selectInCodeData = (state) => state.inCodeData;

export default inCodeSlice.reducer;