import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Autocomplete, Box, CircularProgress, FormControl, Grid, MenuItem, Select, TextField, Typography} from "@mui/material";
import Button from '../Shared/button';
import Step from "../Shared/step";
import {setBackFunc} from "../../store/reduxSlice";

const PoliticallyExposed = ({snackBarMessage, userInfo, setUserInfo, setShowScreen, countries, processKycLoader, processKyc}) => {

    const politicallyExposedList = [
        'Hold a high public office',
        'Have an immediate family member who does',
        'A close business associate who does',
    ];

    const sourcesWealth = [
        'Savings from salary (basic and/or bonus)',
        'Savings from salary (basic and/or bonus), self-employed',
        'Savings from salary (basic and/or bonus), self-employed company less than one year old',
        'Sales of shares or other investments / liquidation of investment portfolio',
        'Loan',
        'Sale of property - Land',
        'Sale of property - House, Condominium, Apartment',
        'Sale of property - Boat',
        'Sale of property - Autombile, Truck',
        'Inheritance - sole inheritor',
        'Inheritance - partical inheritor',
        'Maturity/surrender of life policy',
        'Maturity/surrender of retirement policy',
        'Company sale - Sole Proprietor, Sole Owner',
        'Company sale - Partnership',
        'Company sale - Corporation, LLC',
        'Company Profitsharing or Disbursement',
        'Company Dividends',
        'Gift',
    ];

    const frequencyList = [
        'Once a year',
        'Once every 3 months ',
        'Once a month ',
        'Once a week ',
        'Other',
    ];
    const reasonList = ['Investment', 'Cross Border ', 'Identity Services ', 'Buy, sell or trade Crypto ', 'Wallet Services', 'Custody Services', 'Other Services'];

    const [isPoliticalExpose, setIsPoliticalExpose] = useState('');
    const [politicalExpose, setPoliticalExpose] = useState('');
    const [frequency, setFrequency] = useState('');
    const [reason, setReason] = useState('');
    const [source, setSource] = useState('');
    const [origin, setOrigin] = useState(null);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setBackFunc(() => setShowScreen('personalDetails')));
    }, [setShowScreen, dispatch]);

    const submitKyc = () => {
        setUserInfo({...userInfo, isPoliticalExpose, politicalExpose, frequency, reason, source, origin: origin.alpha3Code});
        processKyc(isPoliticalExpose, politicalExpose, frequency, reason, source, origin.alpha3Code);
    };

    return (
        <>
            <Step totalSteps={4} currentStep={4} />
            <Box pl={2} mt={4}>
                <Typography variant="h5" sx={{fontWeight: 'bold'}}>Additional Questions</Typography>

                <Typography mt={4} variant="body1"><strong>Are you a politically exposed person?<span style={{color: "red"}}>*</span></strong></Typography>
                <Grid mt={0} container spacing={2}>
                    <Grid item={true} xs={6}>
                        <Box onClick={() => setIsPoliticalExpose(true)} className={`political-expose ${isPoliticalExpose === true && "active"}`}>Yes</Box>
                    </Grid>
                    <Grid item={true} xs={6}>
                        <Box onClick={() => {setIsPoliticalExpose(false); setPoliticalExpose('');}} className={`political-expose ${isPoliticalExpose === false && "active"}`}>No</Box>
                    </Grid>
                </Grid>
                {isPoliticalExpose &&
                <FormControl className="mt8 text-left" fullWidth>
                    <Select
                        sx={{
                            fontWeight: "bold",
                            color: "#16359D",
                            '.MuiSvgIcon-root ': {
                                fill: "#16359D !important",
                            }
                        }}
                        variant="filled"
                        value={politicalExpose}
                        displayEmpty
                        onChange={(e) => setPoliticalExpose(e.target.value)}
                    >
                        <MenuItem value="">Please select</MenuItem>
                        {politicallyExposedList.map(item => (
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                }
                <Typography mt={4} variant="body1"><strong>Expected frequency of transactions?<span style={{color: "red"}}>*</span></strong></Typography>
                <FormControl className="mt8 text-left" fullWidth>
                    <Select
                        sx={{
                            fontWeight: "bold",
                            color: "#16359D",
                            '.MuiSvgIcon-root ': {
                                fill: "#16359D !important",
                            }
                        }}
                        variant="filled"
                        value={frequency}
                        displayEmpty
                        onChange={(e) => setFrequency(e.target.value)}
                    >
                        <MenuItem value="">Please select</MenuItem>
                        {frequencyList.map(item => (
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Typography mt={4} variant="body1"><strong>Reason for signing up<span style={{color: "red"}}>*</span></strong></Typography>
                <FormControl className="mt8 text-left" fullWidth>
                    <Select
                        sx={{
                            fontWeight: "bold",
                            color: "#16359D",
                            '.MuiSvgIcon-root ': {
                                fill: "#16359D !important",
                            }
                        }}
                        variant="filled"
                        value={reason}
                        displayEmpty
                        onChange={(e) => setReason(e.target.value)}
                    >
                        <MenuItem value="">Please select</MenuItem>
                        {reasonList.map(item => (
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Typography mt={4} variant="body1"><strong>Source of wealth<span style={{color: "red"}}>*</span></strong></Typography>
                <FormControl className="mt8 text-left" fullWidth>
                    <Select
                        sx={{
                            fontWeight: "bold",
                            color: "#16359D",
                            '.MuiSvgIcon-root ': {
                                fill: "#16359D !important",
                            }
                        }}
                        variant="filled"
                        value={source}
                        displayEmpty
                        onChange={(e) => setSource(e.target.value)}
                    >
                        <MenuItem value="">Please select</MenuItem>
                        {sourcesWealth.map(item => (
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Typography mt={4} variant="body1"><strong>Origin of funds<span style={{color: "red"}}>*</span></strong></Typography>
                <Autocomplete
                    sx={{
                        mt: 2,
                        '.MuiSvgIcon-root ': {
                            fill: "#16359D !important",
                        }
                    }}
                    value={origin}
                    isOptionEqualToValue={(option, value) => option.alpha3Code === value.alpha3Code}
                    onChange={(e, v) => setOrigin(v)}
                    options={countries}
                    autoHighlight={true}
                    openOnFocus={true}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.alpha2Code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.alpha2Code.toLowerCase()}.png 2x`}
                                alt=""
                            />
                            {option.name} ({option.alpha3Code})
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            className={"country-select-field"}
                            {...params}
                            variant="filled"
                            placeholder={"Please select"}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </Box>
            <Button
                className="sdk-theme-btn normal-text"
                variant="contained"
                onClick={submitKyc}
                disabled={isPoliticalExpose === "" || !frequency || !reason || !source || !origin || (isPoliticalExpose && !politicalExpose)}
                fullWidth
            >
                <strong>{processKycLoader ? <><CircularProgress size={15} color="inherit" /> Processing...</> : "Submit KYC"}</strong>
            </Button>
        </>
    );
};

export default PoliticallyExposed;