import React from 'react';
import {Box} from "@mui/material";
import { POWERED_BY_ICON } from '../../js/images';
import { useSelector } from 'react-redux';
import { selectAppData } from '../../store/reduxSlice';
import { VIAARPE_APP_KEY } from '../../js/constants';

const PoweredBy = () => {
    const appData = useSelector(selectAppData);
    const partnerData = appData.partnerData;

    if(partnerData.appKey === VIAARPE_APP_KEY) {
        return null;
    }

    return (
        <Box className={`text-center`}>
            <a href="https://developer.everest.org/" target="_blank" rel="noreferrer"><img src={POWERED_BY_ICON} alt={"logo"} /></a>
        </Box>
    );
};

export default PoweredBy;