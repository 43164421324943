import React from "react";
import {Routes, Route} from "react-router-dom";
import DefaultLayout from "../components/DefaultLayout";
import EmptyLayout from "../components/EmptyLayout";
import Buy from '../components/Buy';
import ConfirmTransaction from '../components/Buy/confirmTransaction';
import CardPayment from "../components/Buy/cardPayment";
import Sell from '../components/Sell';
import SellConfirmTransaction from '../components/Sell/confirmTransaction';
import Login from '../components/UserLogin';
import PersonalDetails from '../components/PersonalDetails';
import Documents from '../components/PersonalDetails/documents';
import SignIn from '../components/SignIn';
import SignUp from '../components/SignUp';
import Kyc from '../components/Kyc';
import Contact from '../components/Contact';
import Currency from '../components/Currency';
import Banks from '../components/Banks';
import PassThruKyc from '../components/PassThruKyc';
import OrderDetail from '../components/Order/detail';
import IdentityWallet from '../components/IdentityWallet/Home';
import IdentityWalletDashboard from '../components/IdentityWallet/Dashboard';
import History from '../components/History';
import Balance from '../components/Wallet';
import Connect from '../components/Wallet/connect';
import TokenInfo from '../components/Wallet/tokenInfo';
import Send from '../components/Wallet/send';
import Receive from '../components/Wallet/receive';
import MyLink from '../components/Wallet/myLink';
import ReferralRewards from '../components/ReferralRewards';
import Loader from '../components/Shared/loader';
import TwitterSignIn from "../components/Shared/Twitter/signin";
import Trade from '../components/Trade';
import ManualSend from '../components/Shared/manualSend';
import Withdraw from '../components/Withdraw';
import BankSelect from '../components/Withdraw/bankSelect';
import WithdrawConfirmTransaction from '../components/Withdraw/confirmTransaction';
import { SERVICE_IDENTITY } from "../js/constants";
import ServiceUnavailable from "../components/Shared/serviceUnavailable";

const CustomRoutes = ({renderApp, features, service, appData, partnerData}) => {

    if(renderApp) {
        return (
            <Routes>
                {service === SERVICE_IDENTITY ? 
                    <Route path="/" exact element={<EmptyLayout><IdentityWallet /></EmptyLayout>} /> :
                    <>
                        {features.buy && partnerData.buy_feature_active ?
                            <>
                                <Route path="/" exact element={<DefaultLayout showBackBtn={false}><Buy /></DefaultLayout>} />
                                <Route path="/confirm/transaction" exact element={<DefaultLayout showRefreshNotice={true} protectedRoute={partnerData.enable_auth} showMenu={false} showBackBtn={false}><ConfirmTransaction /></DefaultLayout>} />
                            </> :
                            <>
                                <Route path="/" exact element={<ServiceUnavailable />} />
                                <Route path="/confirm/transaction" exact element={<ServiceUnavailable />} />
                            </>
                        }
                    </>
                }
                {features.trade && partnerData.exchange_service_active ?
                    <>
                        <Route path="/swap" exact element={<DefaultLayout showBackBtn={false}><Trade /></DefaultLayout>} />
                        <Route path="/swap/send" exact element={<DefaultLayout protectedRoute={partnerData.enable_auth} showBackBtn={false} showMenu={false}><ManualSend from="swap" /></DefaultLayout>} />
                    </> :
                    <>
                        <Route path="/swap" exact element={<ServiceUnavailable />} />
                        <Route path="/swap/send" exact element={<ServiceUnavailable />} />
                    </>
                }
                {features.sell && partnerData.sell_feature_active ?
                    <>
                        <Route path="/sell" exact element={<DefaultLayout showBackBtn={false}><Sell /></DefaultLayout>} />
                        <Route path="/sell/confirm/transaction" exact element={<DefaultLayout showRefreshNotice={true} protectedRoute={partnerData.enable_auth} showMenu={false} showBackBtn={false}><SellConfirmTransaction /></DefaultLayout>} />
                        <Route path="/sell/send" exact element={<DefaultLayout protectedRoute={partnerData.enable_auth} showBackBtn={false} showMenu={false}><ManualSend from="sell" /></DefaultLayout>} />
                    </> :
                    <>
                        <Route path="/sell" exact element={<ServiceUnavailable />} />
                        <Route path="/sell/confirm/transaction" exact element={<ServiceUnavailable />} />
                        <Route path="/sell/send" exact element={<ServiceUnavailable />} />
                    </>
                }
                {features.withdraw ? 
                    <>
                        <Route path="/withdraw" exact element={<DefaultLayout protectedRoute={true} showBackBtn={false}><Withdraw /></DefaultLayout>} />
                        <Route path="/account/select" exact element={<DefaultLayout protectedRoute={true} showBackBtn={false} showMenu={false}><BankSelect /></DefaultLayout>} />
                        <Route path="/withdraw/confirm/transaction" exact element={<DefaultLayout showRefreshNotice={true} protectedRoute={true} showMenu={false} showBackBtn={false}><WithdrawConfirmTransaction /></DefaultLayout>} />
                    </> :
                    <>
                        <Route path="/withdraw" exact element={<ServiceUnavailable />} />
                        <Route path="/account/select" exact element={<ServiceUnavailable />} />
                        <Route path="/withdraw/confirm/transaction" exact element={<ServiceUnavailable />} />
                    </>
                }
                <Route path="/login" exact element={<Login />} />
                <Route path="/connect-wallet" exact element={<EmptyLayout><Connect /></EmptyLayout>} />
                <Route path="/balance" exact element={<DefaultLayout protectedRoute={true} menuText={appData.menuText}><Balance /></DefaultLayout>} />
                <Route path="/token/info" exact element={<DefaultLayout protectedRoute={true} menuText={appData.menuText}><TokenInfo /></DefaultLayout>} />
                <Route path="/send" exact element={<DefaultLayout protectedRoute={true} menuText={"Send"}><Send /></DefaultLayout>} />
                <Route path="/receive" exact element={<DefaultLayout protectedRoute={true} menuText={"Receive"}><Receive /></DefaultLayout>} />
                <Route path="/my-link" exact element={<DefaultLayout protectedRoute={true} menuText={"My Link"}><MyLink /></DefaultLayout>} />
                <Route path="/referral-rewards" exact element={<DefaultLayout menuText={""}><ReferralRewards /></DefaultLayout>} />
                <Route path="/dashboard" exact element={<DefaultLayout showBackBtn={false} protectedRoute={true}><IdentityWalletDashboard /></DefaultLayout>} />
                <Route path="/history" exact element={<DefaultLayout menuText={"History"} protectedRoute={true} showBackBtn={true}><History /></DefaultLayout>} />
                <Route path="/signin" exact element={<DefaultLayout><SignIn /></DefaultLayout>} />
                <Route path="/signup" exact element={<DefaultLayout menuText={"Sign up"}><SignUp /></DefaultLayout>} />
                <Route path="/contact" exact element={<DefaultLayout><Contact /></DefaultLayout>} />
                <Route path="/currency" exact element={<DefaultLayout><Currency /></DefaultLayout>} />
                <Route path="/documents" exact element={<DefaultLayout protectedRoute={true}><Documents /></DefaultLayout>} />
                <Route path="/banks" exact element={<DefaultLayout protectedRoute={true}><Banks /></DefaultLayout>} />
                <Route path="/personal-details" exact element={<DefaultLayout protectedRoute={true}><PersonalDetails /></DefaultLayout>} />
                <Route path="/kyc" exact element={<DefaultLayout menuText={"KYC check"} protectedRoute={true} showMenu={false} showBackBtn={true}><Kyc /></DefaultLayout>} />
                <Route path="/card/payment" exact element={<DefaultLayout protectedRoute={true} showMenu={false} showBackBtn={false}><CardPayment /></DefaultLayout>} />
                <Route path="/kyc-pass" exact element={<DefaultLayout menuText={"Name, Birthdate, Address"} protectedRoute={true} showMenu={false} showBackBtn={true}><PassThruKyc /></DefaultLayout>} />
                <Route path="/twitter" exact element={<DefaultLayout showBackBtn={false} protectedRoute={true}><TwitterSignIn /></DefaultLayout>} />
                <Route path="/order/:orderId" exact element={<DefaultLayout menuText={"Your Order"} protectedRoute={true} showMenu={false} showBackBtn={true}><OrderDetail /></DefaultLayout>} />
            </Routes>
        );
    } else {
        return (
            <Routes>
                <Route path="*" element={<Loader />} />
            </Routes>
        );
    }

};

export default CustomRoutes;