import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import {Box, Grid, Typography} from "@mui/material";
import Button from '../../Shared/button';
import helpers from "../../../adapters/helpers";
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddWallet from './addWallet';
import { setWallet } from '../../Wallet/reduxSlice';

const WalletAccordion = ({Accordion, AccordionSummary, AccordionDetails, handleChange, expanded, setEverWallet, navigate, snackBarMessage}) => {

    const dispatch = useDispatch();

    const [wallets, setWallets] = useState([]);
    const [openWalletModal, setOpenWalletModal] = useState(false);

    const getLinkedWallets = () => {
        return helpers.getUserWallets()
            .then((response) => {
                return response;
            })
            .catch(() => {
                snackBarMessage({message: 'Unable to get Wallets. Please refresh the page and try again.'});
            });
    };

    const getAssociatedWallets = () => {
        return helpers.getAssociatedWallets()
            .then((response) => {
                return response;
            })
            .catch(() => {
                snackBarMessage({message: 'Unable to get Wallets. Please refresh the page and try again.'});
            });
    };

    const walletAction = (wallet) => {
        dispatch(setWallet(wallet));
        navigate('/balance');
    };

    const getUserWallets = () => {
        Promise.all([
            getLinkedWallets().then((resp) => resp),
            getAssociatedWallets().then((resp) => resp)
        ]).then(([walletResp, associatedWallets]) => {
            if(walletResp.data.wallets) {
                const wallets = walletResp.data.wallets;
                const walletsArr = [];
                const addressArr = [];
                for(let i =0; i < wallets.length; i++) {
                    if(wallets[i].name === "EverWallet") {
                        setEverWallet(wallets[i].address);
                    }
                    const walletObj = {name: wallets[i].name, address: wallets[i].address};
                    walletsArr.push(walletObj);
                    addressArr.push(wallets[i].address);
                }
                if(associatedWallets.data.success && associatedWallets.data.wallets) {
                    const wallets = associatedWallets.data.wallets;
                    for(let i =0; i < wallets.length; i++) {
                        if(addressArr.indexOf(wallets[i].address) < 0) {
                            const walletObj = {name: wallets[i].name, address: wallets[i].address};
                            walletsArr.push(walletObj);
                        }
                    }
                    setWallets(walletsArr);
                } else {
                    setWallets(walletsArr);
                }
            } else {
                window.alert(
                    'Something went wrong while getting wallets data.'
                );
            }
        });
    };

    useEffect(() => {
        getUserWallets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Box sx={{width: '100%', mt: 4}}>
                <Accordion expanded={expanded === 'panel1'} onChange={() => handleChange('panel1')}>
                    <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                        <Grid container>
                            <Grid item={true} xs={12}>
                                <Typography variant="caption">Wallets</Typography>
                            </Grid>
                            <Grid item={true} xs={9.2} className="content-center">
                                <p className='dashboard-email-text'>{wallets.length} Wallet{wallets.length > 1 ? "s" : ""}</p>
                            </Grid>
                            <Grid item={true} xs={2.8} className="content-center">
                                <Button className={`dashboard-add-btn normal-text`} onClick={() => setOpenWalletModal(true)} variant="contained"><AddIcon/>Add</Button>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        {wallets.map((wallet, index) =>
                            <Box onClick={() => walletAction(wallet)} key={index} className="accordion-list-item cursor-pointer">
                                <Typography variant="caption">{wallet.name}</Typography>
                                <Grid container>
                                    <Grid item={true} xs={11}>
                                        <p title={wallet.address} className='dashboard-email-text'>{wallet.address}</p>
                                    </Grid>
                                    <Grid item={true} xs={1}>
                                    <Typography sx={{textAlign: 'right', color: '#195BB6'}}>
                                        <ArrowForwardIosIcon />
                                    </Typography>
                                    </Grid>
                                </Grid>
                                
                                {wallet.name === "EverWallet" && <Typography variant="caption"><b><a href="https://wallet.everest.org" target="_blank">https://wallet.everest.org</a></b></Typography>}
                            </Box>
                        )}
                    </AccordionDetails>
                </Accordion>
            </Box>
            <AddWallet openWalletModal={openWalletModal} setOpenWalletModal={setOpenWalletModal} snackBarMessage={snackBarMessage} getUserWallets={getUserWallets} />
        </>
    );
};

export default WalletAccordion;