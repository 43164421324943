import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {Avatar, Backdrop, Box, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography} from "@mui/material";
import Button from '../../Shared/button';
import {selectUserData} from "../../../store/reduxUserSlice";
import helpers from "../../../adapters/helpers";
import AddIcon from '@mui/icons-material/Add';
import {setConfirmDialog} from "../../Shared/ConfirmDialog";
import {getDocName} from "../../../util/generalUtil";
import CloseIcon from '@mui/icons-material/Close';
import Delete from "../../../images/delete.png";
import DeleteIcon from '@mui/icons-material/Delete';
import AddDocument from "./addDocument";

const DocumentAccordion = ({Accordion, AccordionSummary, AccordionDetails, handleChange, expanded, snackBarMessage}) => {

    const userData = useSelector(selectUserData);
    const identityDocuments = userData.identityDocuments || [];

    const [documents, setDocuments] = useState([]);
    const [frontImage, setFrontImage] = useState('');
    const [backImage, setBackImage] = useState('');
    const [previewFileName, setPreviewFileName] = useState('');
    const [previewLoader, setPreviewLoader] = useState(false);

    const [openDocumentModal, setOpenDocumentModal] = useState(false);

    const getDocs = () => {
        helpers.getUserDocuments()
            .then((response) => {
                // setWalletsLoader(false);
                if(response.data.documents) {
                    const userDocs = response.data.documents;
                    setDocuments(userDocs);
                }
            })
            .catch(() => {
                // setWalletsLoader(false);
                snackBarMessage({message: 'Unable to get data. Please refresh the page and try again.'});
            });
    };

    const previewDocFile = (name) => {
        if(!previewLoader) {
            setPreviewLoader(true);
            helpers.getDocumentPreview(name)
                .then((response) => {
                    setPreviewLoader(false);
                    if (response.data.success) {
                        setPreviewFileName(name);
                        setFrontImage(response.data.file);
                    } else {
                        window.alert('Failed to get Documents Preview');
                    }
                })
                .catch((error) => {
                    setPreviewLoader(false);
                    window.alert(error);
                });
        }
    };

    const previewDoc = (docId) => {
        if(!previewLoader) {
            setPreviewLoader(true);
            helpers.getIdentityDocImage(docId)
                .then((response) => {
                    setPreviewLoader(false);
                    if (response.data.success) {
                        const {front, back} = response.data.data;
                        setFrontImage(front);
                        setBackImage(back);
                    } else {
                        window.alert('Failed to get Documents Preview');
                    }
                })
                .catch((error) => {
                    setPreviewLoader(false);
                    window.alert(error);
                });
        }
    };

    const deleteConfirm = (fileName) => {
        setConfirmDialog({
            icon: Delete,
            title: "Are you sure you want to delete?",
            message: "<span style='color: red; font-weight: bold;'>This action cannot be undone.</span>",
            height: '450px',
            onConfirm: () => {deleteDoc(fileName)}
        });
    };

    const deleteDoc = (fileName) => {
        setPreviewLoader(true);
        helpers.deleteUserDocument(fileName)
            .then((response) => {
                if (response.data.success) {
                    const newDocs = documents.filter(object => {
                        return object.filename !== fileName;
                    });
                    setDocuments(newDocs);
                    setPreviewLoader(false);
                    setFrontImage('');
                    setPreviewFileName('');
                    snackBarMessage({message: 'Document has been deleted successfully.', type: "success"});
                } else {
                    snackBarMessage({message: 'Something went wrong.'});
                }
            })
            .catch((error) => {
                setPreviewLoader(false);
                snackBarMessage({message: 'Something went wrong.'+error});
            });
    };

    const closeDialog = () => {
        setBackImage('');
        setFrontImage('');
        setPreviewFileName('');
    };

    useEffect(() => {
        getDocs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Box sx={{width: '100%', mt: 4}}>
                <Accordion expanded={expanded === 'panel2'} onChange={() => handleChange('panel2')}>
                    <AccordionSummary aria-controls="panel2bh-content" id="panel2bh-header">
                        <Grid container>
                            <Grid item={true} xs={12}>
                                <Typography variant="caption">Documents</Typography>
                            </Grid>
                            <Grid item={true} xs={9.2} className="content-center">
                                <p className='dashboard-email-text'>{documents.length + identityDocuments.length} Document{documents.length + identityDocuments.length > 1 ? "s" : ""}</p>
                            </Grid>
                            <Grid item={true} xs={2.8} className="content-center">
                                <Button className={`dashboard-add-btn normal-text`}  onClick={() => setOpenDocumentModal(true)} variant="contained"><AddIcon/>Add</Button>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        {identityDocuments.map((doc, index) =>
                            <Box key={index} className="accordion-list-item cursor-pointer" onClick={() => previewDoc(doc.documentId)}>
                                <Grid container>
                                    <Grid item={true} xs={2.5} className="content-center">
                                        <Avatar sx={{ width: 50, height: 50, marginTop: '-5px' }}  />
                                    </Grid>
                                    <Grid item={true} xs={9.5} className="content-center">
                                        <p className='dashboard-email-text'>{getDocName(doc.documentType)}</p>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                        {documents.map((doc, index) =>
                            <Box key={index} className="accordion-list-item cursor-pointer" onClick={() => previewDocFile(doc.filename)}>
                                <Grid container>
                                    <Grid item={true} xs={2.5} className="content-center">
                                        <Avatar src={'data:image/jpeg;base64,'+ doc.thumbnail} sx={{ width: 50, height: 50, marginTop: '-5px' }}  />
                                    </Grid>
                                    <Grid item={true} xs={9.5} className="content-center">
                                        <p title={doc.filename} className='dashboard-email-text'>{doc.filename}</p>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </AccordionDetails>
                </Accordion>
            </Box>
            <AddDocument openDocumentModal={openDocumentModal} setOpenDocumentModal={setOpenDocumentModal} snackBarMessage={snackBarMessage} getDocs={getDocs} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={previewLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog open={!!frontImage} fullWidth={true} onClose={closeDialog}>
                <DialogTitle sx={{ mt: 1, p: 2, color: '#16359D' }}>
                    <IconButton
                        aria-label="close"
                        onClick={closeDialog}
                        sx={{position: 'absolute', right: 8, top: 8, color: '#16359D'}}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider mt={2} />
                <DialogContent>
                    <Box>
                        <Typography variant="h5" mb={3}>Front Image</Typography>
                        <img className="video-box-inner-content" src={'data:image/jpeg;base64,' + frontImage} alt="Front"/>
                        {backImage &&
                        <>
                            <Typography variant="h5" mt={2} mb={3}>Back Image</Typography>
                            <img className="video-box-inner-content" src={'data:image/jpeg;base64,' + backImage} alt="Back"/>
                        </>
                        }
                    </Box>
                    {previewFileName && <><Box className="text-center"><Button className="confirm-buy-edit" variant="contained" onClick={() => deleteConfirm(previewFileName)}><DeleteIcon/>Delete</Button></Box></>}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DocumentAccordion;