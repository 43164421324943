import React, {useState, useEffect} from 'react';
import Web3 from 'web3';
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import Fortmatic from 'fortmatic';
import {Box, Button as MuiButton, Typography, TextField, Slide, CircularProgress, Grid, Dialog, DialogContent, IconButton} from '@mui/material';
import Button from "./button";
import CloseIcon from '@mui/icons-material/Close';
import metaMaskImg from '../../images/metamask.png';
import coin98Img from '../../images/coin98.png';
import walletConnectImg from '../../images/walletconnect.svg';
import coinBaseImg from '../../images/coinbase.png';
import fortmaticImg from '../../images/fortmatic.png';
import ConfirmDialog from "../Shared/ConfirmDialog";
import {COIN_98, COIN_BASE, FORTMATIC, META_MASK, NETWORK_EVER_CHAIN, VIAARPE_APP_KEY, WALLET_CONNECT} from "../../js/constants";
import { displayWalletAddress } from '../../util/generalUtil';
import { WALLET_CONNECT_PROJECT_ID } from '../../js/variables';
import { selectAppData, setConnectWallet, setConnectWalletProvider } from '../../store/reduxSlice';
import { useDispatch, useSelector } from 'react-redux';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import { useSDK } from '@metamask/sdk-react';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const WalletProvider = (props) => {
    return (
        <>
            <Box className="menu-item" onClick={() => props.onClickHandler()}>
                <Grid container>
                    <Grid item={true} xs={10} className="content-center">
                        <Box sx={{textAlign: 'left'}}>
                            <p className='buy-token-list-name'>{props.name}{props.loader && <CircularProgress sx={{ml: 2}} size={20} />}</p>
                        </Box>
                    </Grid>
                    <Grid item={true} xs={2}>
                        <img src={props.icon} alt="Menu Icon" height={40} width={40} />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export const ProvidersList = ({dispatch, setOpenModal, handleWalletAddress, snackBarMessage}) => {

    const [connectWalletLoader, setConnectWalletLoader] = useState(false);
    const [fortmaticLoader, setFortmaticLoader] = useState(false);
    const { connected, provider, chainId } = useSDK();

    const handleConnectWallet = (address, provider) => {
        handleWalletAddress(address);
        dispatch(setConnectWallet(address));
        dispatch(setConnectWalletProvider(provider));
    };

    useEffect(() => {
        if(connected) {
            provider.on("chainChanged", async (chainId) => {
                if(parseInt(chainId, 16) !== 1) {
                    snackBarMessage({message: "Please select the Ethereum chain from MetaMask extension"});
                    return false;
                } else {
                    const result = await provider.request({ method: 'eth_requestAccounts' })
                    if(result.length > 0) {
                        handleConnectWallet(result[0], META_MASK);
                    }
                }
            });
    
            provider.on("accountsChanged", async (result) => {
                handleConnectWallet(result[0], COIN_BASE);
            });
        }
    }, [connected, provider]);
    

    const initiateMetaMask = async () => {
        if(connected) {
            if(parseInt(chainId, 16) !== 1) {
                snackBarMessage({message: "Please select the Ethereum chain from MetaMask extension"});
                return false;
            }
        }
        try {
            const result = await provider.request({ method: 'eth_requestAccounts' })
            if(result.length > 0) {
                handleConnectWallet(result[0], META_MASK);
            }
        } catch(e) {
            snackBarMessage({message: e.message});
        }
    };

    const initiateCoin98 = async () => {
        if(window.coin98) {
            try {
                const result = await window.coin98.provider.connect();
                handleConnectWallet(result[0], COIN_98);
            } catch (error) {
                snackBarMessage({message: error.message, type: 'error'});
            }
        } else {
            snackBarMessage({message: 'Please install Coin98', type: 'error'});
        }
    };


    const initiateWalletConnect = async () => {
        setConnectWalletLoader(true);
        if(!connectWalletLoader) {
            const client = await EthereumProvider.init({
                projectId: WALLET_CONNECT_PROJECT_ID,
                showQrModal: true,
                // qrModalOptions:{
                //     themeVariables: {
                //         "--wcm-z-index": "99999",
                //         "--wcm-overlay-background-color": "lightgray"
                //     }
                // },
                chains: [1],
                // methods: ["personal_sign"], //, "eth_sendTransaction", 
                optionalMethods: ["eth_requestAccounts"],
                events: ["accountsChanged"],
                metadata: {
                  name: "Everest",
                  description: "Everest SDK/Wallet",
                  url: "https://www.everest.org",
                  icons: ["https://www.everest.org/logo.svg"],
                },
            });
    
            try {
                setOpenModal(false);
                setConnectWalletLoader(false);
                await client.connect();
                const result = await client.request({ method: "eth_requestAccounts" });
                handleConnectWallet(result[0], WALLET_CONNECT)
            } catch(e) {
                if(e.message === "User disapproved requested methods" || e.message === "User rejected") {
                    snackBarMessage({message: "User declined connection attempt. Please click try again and follow the steps to connect in your wallet."})
                }
            }
    
            client.on('accountsChanged', (error, payload) => {
                if (error) {
                    throw error;
                }
                const accounts = payload.params[0].accounts;
                handleConnectWallet(accounts[0], WALLET_CONNECT);
            });
        }
    };

    const initiateCoinBase = async () => {
        const APP_NAME = 'Everest';
        const APP_LOGO_URL = 'https://www.everest.org/logo.svg';

        const walletLink = new CoinbaseWalletSDK({
            appName: APP_NAME,
            appLogoUrl: APP_LOGO_URL,
        });

        const provider = walletLink.makeWeb3Provider();
        provider.on("chainChanged", async (chainId) => {
            if(parseInt(chainId, 16) !== 1) {
                snackBarMessage({message: "Please select the Ethereum chain from Coinbase extension"});
                return false;
            } else {
                const result = await provider.request({ method: 'eth_requestAccounts' })
                if(result.length > 0) {
                    handleConnectWallet(result[0], COIN_BASE);
                }
            }
        });

        provider.on("accountsChanged", async (result) => {
            handleConnectWallet(result[0], COIN_BASE);
        });

        const chainId = await provider.request({ method: "eth_chainId" });
        const intChainId = parseInt(chainId, 16);
        if(intChainId !== 1) {
            snackBarMessage({message: "Please select the Ethereum chain from Coinbase extension"});
            return false;
        }

        try {
            const result = await provider.request({ method: 'eth_requestAccounts' })
            if(result.length > 0) {
                handleConnectWallet(result[0], COIN_BASE);
            }
        } catch(e) {
            snackBarMessage({message: e.message});
        }        
    };

    const initiateFortmatic = () => {
        let fm = new Fortmatic('pk_live_51C7C39592A40069'); //pk_test_38950245E2A10034
        const web3 = new Web3(fm.getProvider());
        setFortmaticLoader(true);
        web3.eth
            .getAccounts((error, accounts) => {
                setFortmaticLoader(false);
                if (accounts && accounts.length > 0) {
                    handleConnectWallet(accounts[0], FORTMATIC);
                }
            })
            .catch((error) => {
                setFortmaticLoader(false);
                console.log(error);
            });
    };

    return (
        <Box sx={{width: '100%'}}>
            <Typography mt={8} variant="h6" sx={{textAlign: 'center'}}>Select Provider</Typography>
            <WalletProvider name={"MetaMask"} icon={metaMaskImg} onClickHandler={initiateMetaMask} />
            <WalletProvider name={"Coin98"} icon={coin98Img} onClickHandler={initiateCoin98} />
            <WalletProvider name={"WalletConnect"} loader={connectWalletLoader} icon={walletConnectImg} onClickHandler={initiateWalletConnect} />
            <WalletProvider name={"Coinbase Wallet"} icon={coinBaseImg} onClickHandler={initiateCoinBase} />
            <WalletProvider name={"Fortmatic"} loader={fortmaticLoader} icon={fortmaticImg} onClickHandler={initiateFortmatic} />
        </Box>
    )
};

const ConnectWallet = ({snackBarMessage, addressHandler, from, walletAddress = '', customStyle = {}, network, autoOpen = false, ...props}) => {
    const dispatch = useDispatch();
    const appData = useSelector(selectAppData);
    const appKey = appData.sdkConfiguration.appKey;
    
    const [openModal, setOpenModal] = useState(false);

    const connectWalletBtn = () => {
        if(network === NETWORK_EVER_CHAIN) { //TODO: Need to fix it
            snackBarMessage({type: "info", message: "You are not allowed to connect wallet for this network/token. Please sign in/up to use EverWallet."})
        } else {
            setOpenModal(true);
        }
    };

    useEffect(() => {
        if(autoOpen) {
            setOpenModal(true);
        }
        const ethereum = window.ethereum;
        if (ethereum) {
            let provider = ethereum;
            if (ethereum.providers) {
                provider = ethereum.providers.find(
                    (provider) => provider.isMetaMask
                );
            }
            provider.on('accountsChanged', (result) => handleWalletAddress(result[0]));
        }
        return () => {
            setOpenModal(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleWalletAddress =  (address) => {
        addressHandler(address);
        setOpenModal(false);
    };

    const handleTextWalletAddress =  (e) => {
        if(Web3.utils.isAddress(e.target.value)) {
            addressHandler(e.target.value);
            dispatch(setConnectWallet(e.target.value));
            dispatch(setConnectWalletProvider(''));
            setOpenModal(false);
        }
    };

    return (
        <>
            {from === "identity" ?
                <Button onClick={connectWalletBtn} className="sdk-theme-btn" variant="contained" sx={{mt: 2, ...customStyle}} {...props} fullWidth>
                    <strong>{walletAddress !== "" ? "Connected ("+displayWalletAddress(walletAddress)+")" : "Connect Wallet"}</strong>
                </Button> :
                <MuiButton strictClass={true} onClick={connectWalletBtn} sx={{mt: 2, ...customStyle}} {...props} variant="connectWallet" fullWidth>
                    <strong>{walletAddress !== "" ? "Connected ("+displayWalletAddress(walletAddress)+")" : "Connect Wallet"}</strong>
                </MuiButton>
            }
            <ConfirmDialog />
            <Dialog open={openModal} fullWidth={true} onClose={() => setOpenModal(false)} {...(appKey !== VIAARPE_APP_KEY ? {TransitionComponent: Transition}: {})}>
                <DialogContent sx={{p: 0, height: '600px'}} dividers>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenModal(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#16359D'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <ProvidersList dispatch={dispatch} setOpenModal={setOpenModal} handleWalletAddress={handleWalletAddress} snackBarMessage={snackBarMessage} />
                    {(from === "swap" || from === "sell") && 
                    <Box mt={4} mb={4} sx={{padding: "0px 20px", width: '100%'}}>
                        <Typography variant="h6" sx={{textAlign: 'center'}}>OR</Typography>
                        <TextField
                            className="buy-wallet-address-field"
                            sx={{mt: 2}}
                            onChange={handleTextWalletAddress}
                            variant="outlined"
                            placeholder={"Paste ETH wallet address"}
                            required
                            fullWidth
                        />
                    </Box>
                    }
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ConnectWallet;
