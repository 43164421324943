import React from 'react';
import {Box, Typography} from "@mui/material";

const Privacy = () => {

    return (
        <Box p={1} className="terms-and-conditions">
            <Typography variant="h5" mt={2} mb={2}>
                <strong>Everest<sup>TM</sup> Network Limited – PRIVACY NOTICE</strong>
            </Typography>
            <Typography variant="body1">
                Last updated on 22 February 2022
                <br />
                <br />
                Welcome to <a target="_blank" href="https://everest.org" rel="noreferrer"><strong>https://everest.org</strong></a> and subsidiary websites
                <br />
                <br />
                <strong>1. Who We Are</strong>
                <br />
                <br />
                1.1 The Data Controller of Your Personal Data is Everest Network Limited, a Maltese Company with registration number C 89123 having its registered address at Level G, (Office 1/11650), Quantum House, 75, Abate Rigord Street, Ta’ Xbiex, XBX1120 (the “Company” “We” “Us” “Our” “Everest”). The purpose of this Privacy Notice is to inform You of how the Company collects, processes, uses, stores and protects Your Personal Data as well as the rights You have with respect to the processing of Your Personal Data.
                <br />
                <br />
                1.2 By visiting Our Website/Application and using Our Services, You acknowledge reading and fully considering this Privacy Notice.
                <br />
                <br />
                1.3 The Company acknowledges that in collecting Your Personal Data We are bound by the laws of Malta and will process Your Personal Data in accordance with the GDPR. For any further request or query about how We use Your Personal Data, You may address Us/Our Data Protection Officer through the following email address:
                <br />
                <a href="mailto:DataPrivacy@everest.org"><strong>mailto:DataPrivacy@everest.org</strong></a>
                <br />
                <br />
                1.4 Any notice, demand, request or other communication which You address to the Company shall either be sent by certified mail, return receipt requested, or by e-mail. All communication done by e-mail shall be deemed received on the business day following the day of transmission.
                <br />
                <br />
                <strong>2. Definitions</strong>
                <br />
                <br />
                Capitalised terms in this Notice shall have the meaning assigned to them under the GDPR and shall be construed accordingly. Furthermore, the following definitions shall apply:
                <br />
                <br />
                2.1 Account – an account provided to You by the Company upon Your registration and acceptance of the Terms and Conditions for use of the Services.
                <br />
                <br />
                2.2 GDPR – the General Data Protection Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of Personal Data and on the free movement of such data, and repealing Directive 95/46/EC, as amended, replaced or superseded and in force from time to time.
                <br />
                <br />
                2.3 Services – Our blockchain platform operated through Our Website/Application, as defined in Our Terms and Conditions.
                <br />
                <br />
                2.4 User – any person who registers an Account or accesses and browses Our Website/Application (“You”, “Your”).
                <br />
                <br />
                <strong>3. Subject Matter</strong>
                <br />
                <br />
                3.1 This Privacy Notice sets out the terms and conditions which the Company follows in order to protect the privacy of Our Users. It describes the conditions under which We collect and process Your Personal Data and ensure security and confidentiality.
                <br />
                <br />
                3.2 The Company reserves the right to amend and update this Privacy Notice, whenever it deems appropriate, and any changes thereof shall come in force and effect from the instance they appear online on the Website/Application. Any substantial modifications to this Privacy Notice will communicated to You.
                <br />
                <br />
                <strong>4. Principles of Data Processing</strong>
                <br />
                <br />
                4.1 We fully respect Your fundamental rights and consider the protection of Your Personal Data to be a priority. Accordingly, when processing Your Personal Data, We respect the following basic principles:
                <br />
                \ 4.1.1 We submit Your Personal Data only to lawful and fair processing, and We maintain full transparency vis-à-vis the way We handle Your Personal Data.
                information.
                <br />
                \ 4.1.2 We collect and process Your Personal Data only for specified, explicit, and legitimate purposes as outlined in this Privacy Notice, and We do not process it further in any manner incompatible with these purposes.
                <br />
                \ 4.1.3 We process Your Personal Data only to the extent that it is necessary and appropriate to purposes for which it is collected.
                <br />
                \ 4.1.4 We make reasonable efforts to ensure that Your Personal Data is accurate and updated, taking all reasonable steps to immediately delete or correct it in case of inaccuracy.
                <br />
                \ 4.1.5 We process Your Personal Data in a manner that guarantees its security by using appropriate technical and organizational measures.
                <br />
                <br />
                4.2 In general, We comply with all applicable laws and statutory obligations as Data Controller of Your Personal Data.
                <br />
                <br />
                <strong>5. Types of Personal Data We Process</strong>
                <br />
                <br />
                The Company collects and processes the following types of Personal Data:
                <br />
                <br />
                5.1 At the point of Your access and use of the Website/Application – “Technical Data”, including the following:
                dealings.
                <br />
                <ul>
                    <li>IP Address;</li>
                    <li>End user device data;</li>
                    <li>Address</li>
                    <li>General communication data;</li>
                    <li>Browsing data; and</li>
                    <li>Information on user preferences regarding the Website/Application.</li>
                </ul>
                5.2 At the point of Your Account registration – “Registration Data”, including the following:
                <br />
                <ul>
                    <li>Name and Surname;</li>
                    <li>ID/Passport number;</li>
                    <li>E-mail address;</li>
                    <li>Residential address, mailing address and/or billing address; </li>
                    <li>Date and place of birth; </li>
                    <li>Information about used cryptocurrency addresses;</li>
                    <li>Biometric data, including but not limited to, fingerprints, retina and other data allowing for secure verification of your identity;</li>
                    <li>Telephone number/s;</li>
                    <li>Credit card information and other financial information;</li>
                    <li>Occupation;</li>
                    <li>Gender;</li>
                    <li>Age; and</li>
                    <li>Weight and/or height.</li>
                </ul>
                5.3 During Your business relationship with Us – “Know Your Customer (KYC) Data”, including the following:
                <br />
                <ul>
                    <li>Proof of identity (National ID card, Passport or Driving License);</li>
                    <li>Proof of address (Utility bill);</li>
                    <li>Proof of payment (bank statement); and</li>
                    <li>Source of wealth information (depending on particular case). </li>
                </ul>
                Depending on the circumstances of the relationship, we may collect additional KYC Data.
                <br />
                <br />
                5.4 Any other Personal Data directly provided by You during Your interaction with Us.
                <br />
                <br />
                <strong>6. Purposes and Legal Basis for Data Processing </strong>
                <br />
                <br />
                6.1 The Company may process Your Personal Data to fulfill its obligations under the Terms and Conditions, for the following purposes:
                <br />
                <ul>
                    <li>Administration and development of the Website/Application and the Services;</li>
                    <li>Enhancement of User experience, including the provision of personalized Services;</li>
                    <li>Fulfillment of Your requests and queries in relation to the Services;</li>
                    <li>General improvement of the Website/Application and the Services; and</li>
                    <li>Verifying compliance with the Terms and Conditions of the Website/Application. </li>
                </ul>
                6.2 The Company may process Your Personal Data to comply with its legal obligations, for the following purposes:
                <br />
                <ul>
                    <li>Verifying your identity and carrying out due diligence; and</li>
                    <li>Detection, investigation and prevention of fraudulent transactions.</li>
                </ul>
                6.3 The Company may process Your Personal Data based on Our legitimate interest to promote our Services and develop our business, for the following purposes:
                <br />
                <ul>
                    <li>Development of new products, utilities and offerings; and</li>
                    <li>Commercial communication, marketing and advertising of Our Services.</li>
                </ul>
                6.4 The Company may process Your Personal Data only with Your lawful consent for the following purposes:
                <br />
                <ul>
                    <li>Commercial communication, marketing and advertising of Our Services.</li>
                </ul>
                6.5 You have the right to withdraw Your consent at any time in writing to Our contact details mentioned in this Notice. Withdrawal of Your consent does not affect the lawfulness of the treatment of Your data prior to its revocation.
                <br />
                <br />
                <strong>7. Data Recipients</strong>
                <br />
                <br />
                7.1 For the execution of the purposes mentioned in this Privacy Notice, We may provide access or transmit Your Personal Data to the following recipients (“Data Processors”):
                <br />
                <ul>
                    <li>Our internet and data hosting providers for hosting purposes;</li>
                    <li>Third-party providers for the smooth operation of the Website/Application and Our information and communication systems; and</li>
                    <li>Third-party consultants to provide data analysis Services and Know-Your-Customer procedures.</li>
                    <li>Third party agents and contractors, for internal management and administrative purposes. </li>
                    <li>Third party content providers, for Website/Application content purposes.</li>
                </ul>
                7.2 The processing of Your Personal Data by Our Data Processors is done under a contract compelling Data Processors to the same level of data protection provided under this Privacy Notice.
                <br />
                <br />
                7.3 We make use of certain Data Processors which are located outside of the European Union. In such cases, We take all reasonable steps possible to ensure that Your Personal Data is treated as securely as it is within the European Union and in accordance with this Privacy Notice and applicable legislation. These measures include entering into the <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en" target="_blank" rel="noreferrer"><strong>Standard Contractual Clauses</strong></a> issued by the European Commission.
                <br />
                <br />
                7.4 In the event that We are required by a court or other administrative authority, pursuant to an investigation relating to unlawful activities such as money laundering and in any other case that We are legally bound to do so, the Company may transfer Your Personal Data to public authorities to the extent specified by law.
                <br />
                <br />
                7.5 Please note that if Everest or any of its subsidiaries or lines of business is merged, acquired, divested, financed, sold, disposed of or dissolved, including through merger of, consolidation, sale or divestiture of assets, the relevant customer database, including Personal Data We may possess about You, may be disclosed as part of that transaction.
                <br />
                <br />
                <strong>8. Data Security and Confidentiality</strong>
                <br />
                <br />
                8.1 In order to ensure the proper use and integrity of Your Personal Data and to prevent unauthorised or accidental access, processing, deletion, alteration or other use, the Company applies internal policies and takes all appropriate organizational, technical and procedural security measures, as well as technical standards, in accordance with applicable laws and regulations.
                <br />
                <br />
                8.2 The processing of Your Personal Data by the Company is conducted in a manner that ensures confidentiality and security, taking into account the latest developments, implementation costs and the nature, scope, context and purposes of the processing, as well as the risks for Your rights and freedoms, which are applicable in each circumstance.
                <br />
                <br />
                8.3 Your Personal Data is processed solely by authorised personnel of the Company, bound by strict obligations of confidentiality.
                <br />
                <br />
                <strong>9. Retention of Personal Data</strong>
                <br />
                <br />
                9.1 We retain Your Personal Data for as long as is necessary to fulfill the relevant purposes of processing explained in this Notice, in accordance with the Data Minimisation and Storage Limitation principles.
                <br />
                <br />
                9.2 Furthermore, the Company may retain Your Personal Data after the expiration of the relevant processing purposes for the following reasons:
                <br />
                <ul>
                    <li>In case We have a legal obligation to retain Your Personal Data under a relevant statutory provision.</li>
                    <li>Based on Our legitimate interest to defend the Company against any potential legal claims, before any competent court or public authority.</li>
                </ul>
                9.3 After the period of retention, Your Personal Data is erased from Our databases and systems.
                <br />
                <br />
                9.4 For more information about data retention terms in relation to specific Personal Data, please contact Us at  mailto:DataPrivacy@everest.org.
                <br />
                <br />
                <strong>10. Your Rights</strong>
                <br />
                <br />
                10.1 You have the right:
                <br />
                <ul>
                    <li>To request access to Your Personal Data and information related to its processing and obtain a copy thereof.</li>
                    <li>To request for the rectification of any inaccuracies or any missing Personal Data of Yours. We have developed a decentralized storage, verification and tracking platform that puts you in control of Your Personal Data. This means You are responsible for the accuracy of Your Personal Data.</li>
                    <li>To request the erasure of Your Personal Data.</li>
                    <li>To request the restriction of the processing of Your Personal Data in cases explicitly provided for by law.</li>
                    <li>To request the portability of Your Personal Data to another Data Controller in a structured, commonly used and machine-readable format.</li>
                    <li>To object to the processing of Your Personal Data in cases explicitly provided for by law.</li>
                    <li>To object to a decision taken solely on the basis of automated processing, including profiling, which has impact on You or significantly affects You. </li>
                </ul>
                10.2 . Any requests relevant to the above Section 10.1 must be addressed in writing to  mailto:DataPrivacy@everest.org .
                <br />
                <br />
                10.3 . If You feel that Your rights are infringed, You have the right to file a complaint with the Malta Office of the Information and Data Protection Commissioner at the following website <a href={"https://idpc.org.mt/en/Pages/contact/complaints.aspx"} target={"_blank"} rel="noreferrer"><strong>https://idpc.org.mt/en/Pages/contact/complaints.aspx</strong></a>.
                <br />
                <br />
                <strong>11. Your Obligations</strong>
                <br />
                <br />
                11.1 By using Our Website/Application and by providing Your Personal Data, You acknowledge that You are required to provide Your actual, accurate and complete data as requested by the Company. Furthermore, You must inform Us of any changes to Your information so as to ensure it is kept up-to-date and accurate.
                <br />
                <br />
                11.2 If You are found to be in breach of Your obligations or if We have reasonable suspicion that the information You provide is false or incomplete or in any way contrary to applicable law or this Privacy Notice, We retain the right to reject Your application for registration or to suspend or terminate Your Account immediately without notice. In such case, You have no right to any compensation due to the rejection of Your application, or the suspension or termination of Your Account.
                <br />
                <br />
                11.3 You are responsible for any information that you provide to any third parties that contact you through or because of our Service. This Notice does not govern your interaction with such third parties, which may have their own privacy notices and practices, over which we have no control. We encourage you to familiarize Yourself with the privacy notices provided by these other parties prior to providing them with any information.
                <br />
                <br />
                <strong>12. Cookies</strong>
                <br />
                <br />
                12.1 Our Website/Application uses cookies. For more information, please review Our Cookie Notice <a href={"https://everest.org/cookie-policy"} target={"_blank"} rel="noreferrer"><strong>https://everest.org/cookie-policy</strong></a>
            </Typography>
        </Box>
    );
};

export default Privacy;