import Cookies from 'universal-cookie';
import BscIcon from "../images/binance.png";
import EthIcon from "../images/eth-network-icon.png";
import PolygonIcon from "../images/polygon-network-icon.png";
import {
    DOLLAR_SIGN,
    EURO_SIGN,
    DOCUMENT_TYPE_DRIVING_LICENSE,
    DOCUMENT_TYPE_ID_CARD,
    DOCUMENT_TYPE_PASSPORT,
    DOCUMENT_TYPE_VISA,
    TS_CREATED_LABEL, TS_PENDING_LABEL, TS_COMPLETE_LABEL, TS_FAIL_LABEL, TS_WAIT_APPROVAL_LABEL, TS_NOT_APPROVAL_LABEL, EVER_CHAIN_NETWORK, ETHEREUM_NETWORK, BINANCE_NETWORK, POLYGON_NETWORK, AVALANCHE_NETWORK, SYNTHETIC_USD, SYNTHETIC_EUR
} from "../js/constants"
import { BLOCK_EXPLORER_EVERCHAIN_URL, COOKIE_DOMAIN } from '../js/variables';

export const networkList = [
    {icon: BscIcon, name: "Binance", value: "bsc", chainId: 56},
    {icon: EthIcon, name: "Ethereum", value: "erc20", chainId: 1},
    {icon: PolygonIcon, name: "Polygon", value: "matic", chainId: 137},
];

export const getDocName = (docType) => {
    switch (docType) {
        case DOCUMENT_TYPE_PASSPORT:
            return 'Passport';
        case DOCUMENT_TYPE_ID_CARD:
            return 'Nationality Identity Card';
        case DOCUMENT_TYPE_DRIVING_LICENSE:
            return "Driver's License";
        case DOCUMENT_TYPE_VISA:
            return 'Residence Permit Card';
        default:
            return docType;
    }
};

export const getCurrencySymbol = (currency) => {
    switch (currency) {
        case 'USD':
            return DOLLAR_SIGN;
        case 'EUR':
            return EURO_SIGN;
        default:
            return currency;
    }
};

export const getTransactionStatusLabel = (status) => {
    switch (status) {
        case 'created':
            return TS_CREATED_LABEL;
        case 'pending':
            return TS_PENDING_LABEL;
        case 'complete':
            return TS_COMPLETE_LABEL;
        case 'failed':
            return TS_FAIL_LABEL;
        case 'flagged_for_approval':
            return TS_WAIT_APPROVAL_LABEL;
        case 'not_approved':
            return TS_NOT_APPROVAL_LABEL;
        default:
            return status;
    }
};

export const getCentralLedgetNetwork = (network) => {
    let retNetwork;
    network = network.toLowerCase();
    switch (network) {
        case 'everchain':
            retNetwork = EVER_CHAIN_NETWORK;
            break;
        case 'ethereum':
            retNetwork = ETHEREUM_NETWORK;
            break;
        case 'binance':
            retNetwork = BINANCE_NETWORK;
            break;
        case 'polygon':
            retNetwork = POLYGON_NETWORK;
            break;
        default:
            retNetwork = AVALANCHE_NETWORK;
    }

    return retNetwork;
};

export const getNetworkBackGround = (network) => {
    let className;
    network = network.toLowerCase();
    switch (network) {
        case EVER_CHAIN_NETWORK.toLowerCase():
            className = "everchain-network";
            break;
        case ETHEREUM_NETWORK.toLocaleLowerCase():
            className = "eth-network";
            break;
        default:
            className = "eth-network";
    }

    return className;
};

export const mobileCheck = () => {
    let check = false;
    (function (a) {
        if (
            // eslint-disable-next-line
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            // eslint-disable-next-line
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

export const removeExtraSpace = s => s.trim().split(/ +/).join(' ');

export const emitMessageToSdkPackage = (action, data = null) => {
    window.parent.postMessage({action, data}, '*');
};

const isLocalStorageEnable = 0;

export const setStorage = (key, value) => {
    if (isLocalStorageEnable) {
        localStorage.setItem(key, value);
    } else {
        const cookies = new Cookies();
        cookies.set(key, value, { domain: COOKIE_DOMAIN, maxAge: 3600, secure: true, sameSite: 'none' });
    }
};

export const getStorage = (key) => {
    if (isLocalStorageEnable) {
        return localStorage.getItem(key);
    } else {
        const cookies = new Cookies();
        return cookies.get(key);
    }
};

export const removeStorage = (key) => {
    if (isLocalStorageEnable) {
        return localStorage.removeItem(key);
    } else {
        const cookies = new Cookies();
        return cookies.remove(key, { domain: COOKIE_DOMAIN, secure: true, sameSite: 'none' });
    }
};

export const removeAllStorage = () => {
    if (isLocalStorageEnable) {
        return localStorage.clear();
    } else {
        const cookies = new Cookies();
        const cookieNames = Object.keys(cookies.getAll());
        for (let i = 0; i < cookieNames.length; i++) {
            cookies.remove(cookieNames[i]);
        }
        return true;
    }
};

export const getNetworkScanBaseUrl = (network) => {
    let baseUrl;
    switch (network) {
        case BINANCE_NETWORK:
            baseUrl = 'https://bscscan.com/';
            break;
        case POLYGON_NETWORK:
            baseUrl = 'https://polygonscan.com/';
            break;
        case AVALANCHE_NETWORK:
            baseUrl = 'https://avascan.info/';
            break;
        case EVER_CHAIN_NETWORK:
            baseUrl = BLOCK_EXPLORER_EVERCHAIN_URL + '/';
            break;
        default:
            baseUrl = 'https://etherscan.io/';
    }

    return baseUrl;
};

export const tokenBalanceDisplay = (walletToken) => {
    let balance;
    if (walletToken.symbol === SYNTHETIC_USD || walletToken.symbol === SYNTHETIC_EUR) {
        balance = walletToken.balance / 100;
    } else {
        balance = (walletToken.fmt_balance.replaceAll(',', '') * 1).toFixed(5) * 1;
    }
    return balance;
};

export const displayWalletAddress = (value) => {
    if(!value) {
        return "";
    }
    const start = value.substring(0, 7);
    const end = value.substring(value.length - 6);
    return start+"..."+end;
};