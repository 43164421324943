import React, {useEffect, createRef} from 'react';
import {Box, Grid, Typography} from "@mui/material";
import Button from '../../Shared/button';

const MarketAccordion = ({Accordion, AccordionSummary, AccordionDetails, handleChange, expanded, navigate}) => {

    const ref = createRef();

    useEffect(() => {
        let refValue;
        if (ref.current) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src =
                'https://s3.tradingview.com/external-embedding/embed-widget-screener.js';
            script.async = true;
            script.innerHTML = `{
                    "width": "100%",
                    "height": 400,
                    "defaultColumn": "overview",
                    "screener_type": "crypto_mkt",
                    "displayCurrency": "USD",
                    "colorTheme": "light",
                    "locale": "en"
            }`;
            ref.current.appendChild(script);
            refValue = ref.current;
        }

        return () => {
            if (refValue) {
                while (refValue.firstChild) {
                    refValue.removeChild(refValue.firstChild);
                }
            }
        };
    }, [])

    return (
        <>
            <Box sx={{width: '100%', mt: 4}}>
                <Accordion expanded={expanded === 'panel4'} onChange={() => handleChange('panel4')}>
                    <AccordionSummary aria-controls="panel4bh-content" id="panel4bh-header">
                        <Grid container>
                            <Grid item={true} xs={12}>
                                <Typography variant="caption">Crypto Market</Typography>
                            </Grid>
                            <Grid item={true} xs={7.1} className="content-center">
                                <p className='dashboard-email-text'>Markets</p>
                            </Grid>
                            <Grid item={true} xs={4.9} className="content-center">
                                <Button className={`dashboard-add-btn normal-text`} onClick={() => navigate("/")} variant="contained">Buy | Swap</Button>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div ref={ref} className="tradingview-widget-container">
                            <div className="tradingview-widget-container__widget"></div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
};

export default MarketAccordion;