import React, {useState, useEffect} from 'react';
import web3 from "web3";
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
    Box,
    Typography,
    Button,
    InputLabel,
    Grid,
    IconButton,
    CircularProgress,
    Select,
    MenuItem,
    ListItemIcon,
    FormControl,
    FormHelperText,
    FilledInput
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import InfoIcon from '@mui/icons-material/Info';
import helpers from '../../adapters/helpers';
import {setTokenList as setReduxTokenList, selectBuyData, setData} from './reduxSlice';
import {selectAppData, selectPartnerData, setAppCurrency} from "../../store/reduxSlice";
import {
    ESTIMATE_TOOLTIP_TEXT,
    FIAT_TO_TOKEN,
    FIAT_TO_EVER_ID,
    PAYMENT_CARD,
    EVER_WALLET_NAME,
    REGION_US,
    REGION_EU,
    ID_TOKEN_SYMBOL,
    EVER_CHAIN_NETWORK,
    DEFAULT_CRYPT_TOKEN, BTC_TOKEN_SYMBOL,
    USD_SYMBOL
} from "../../js/constants";
import {getCurrencySymbol, getNetworkBackGround} from "../../util/generalUtil";
import GenericTokenImage from "../../images/generic-token.png";
import BootstrapTooltip from "../Shared/bootstrapToolTip";
import BuySellLink from "../Shared/buySellLink";
import {selectUserData} from "../../store/reduxUserSlice";
import TokenModal from '../Shared/TokenModal';
import ConnectWallet from '../Shared/connectWallet';
import { isLoggedIn } from '../../util/userFunctions';
import { SEPA_PAYMENT, SEPA_PAYMENT_ACTIVE, VISA_PAYMENT, VISA_PAYMENT_ACTIVE } from '../../js/images';
import { handleOrderError } from '../Shared/errorHandler';

const Buy = ({snackBarMessage}) => {
    const isSignIn = isLoggedIn();
    const buyData = useSelector(selectBuyData);
    const appData = useSelector(selectAppData);
    const appKey = appData.sdkConfiguration.appKey;
    const userRegion = useSelector(selectUserData).region;
    const enableAuth = appData.partnerData.enable_auth;
    const isSignAndAuth = isSignIn && enableAuth;
    const [currency, setCurrency] = useState(buyData.currency || USD_SYMBOL);
    const [estimateTokenValue, setEstimateTokenValue] = useState('');
    const [conversionRate, setConversionRate] = useState('');
    const [outputToken, setOutputToken] = useState(buyData.token);
    const [setOutputTokenId, setSetOutputTokenId] = useState(buyData.tokenId);
    const [amount, setAmount] = useState(buyData.amount || appData.partnerData.buy_initial_amount);
    const [amountErr, setAmountErr] = useState(false);
    const [minAmountErr, setMinAmountErr] = useState(false);
    const [paymentId, setPaymentId] = useState(buyData.paymentId || '');
    const [allTokens, setAllTokens] = useState([]);
    const [tokenList, setTokenList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [tokenLoader, setTokenLoader] = useState(false);
    const [estimateLoader, setEstimateLoader] = useState(false);
    const [fee, setFee] = useState(0);
    const [everestFee, setEverestFee] = useState(0);
    const [networkFee, setNetworkFee] = useState(0);
    const [quoteId, setQuoteId] = useState('');
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [fiatAssets, setFiatAssets] = useState([]);
    const [fiatLoader, setFiatLoader] = useState(true);
    const [showFeeCalculation, setShowFeeCalculation] = useState(false);
    const [outputFiatAmount, setOutputFiatAmount] = useState(0);
    const [connectWallet, setConnectWallet] = useState('');
    const [selectWallet, setSelectWallet] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [loader, setLoader] = useState(true);
    const [walletList, setWalletList] = useState([]);
    const [associateWalletList, setAssociateWalletList] = useState([]);
    const [linkedWalletList, setLinkedWalletList] = useState([]);
    const [everWallet, setEverWallet] = useState(true);
    const [idMinimumAmount, setIdMinimumAmount] = useState(50);
    const [idMaximumAmount, setIdMaximumAmount] = useState(3000);
    const [minimumAmount, setMinimumAmount] = useState(50);
    const [maximumAmount, setMaximumAmount] = useState(3000);
    const [errorText, setErrorText] = useState("");

    const [tokenDefault, setTokenDefault] = useState({});

    const amountCal = amount.replaceAll(",", ".") * 1;
    let currentToken;
    if(setOutputTokenId) {
        currentToken = tokenList.find(object => {
            return object.id === setOutputTokenId;
        });
    } else {
        currentToken = tokenList.find(object => {
            return object.symbol === outputToken;
        });
    }
    let exchangeRate = "0.00";
    if(amount && conversionRate) {
        exchangeRate = conversionRate;
    }
    const regexAmountCheck = /^([0-9]*(\,|\.)[0-9]+|[0-9]+)$/; //eslint-disable-line
    const currencySymbol = getCurrencySymbol(currency);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentPaymentOption = paymentOptions.find(object => {
        return object.id === paymentId;
    }) || {};
    const paymentMethod = currentPaymentOption.type;

    const PaymentButton = ({value, label, handleChange}) => {
        let image;
        if(label === "Debit Card") {
            image = VISA_PAYMENT;
            if(paymentId === value) {
                image = VISA_PAYMENT_ACTIVE;
            }
        } else {
            image = SEPA_PAYMENT;
            if(paymentId === value) {
                image = SEPA_PAYMENT_ACTIVE;
            }
        }
        if(label)
        return (
            <Box mt={0.5} sx={{cursor: "pointer"}} onClick={() => handleChange(value * 1)}>
                <img src={image} alt="payment" width="90%" />
            </Box>
        );
    };

    const handleWalletChange = (e) => {
        const address = e.target.value;
        setSelectWallet(address);
        setWalletAddress(address);
        setConnectWallet('');
    };

    const handleConnectWalletChange = (address) => {
        setConnectWallet(address);
        setWalletAddress(address);
        setSelectWallet('');
    };

    useEffect(() => {
        if(walletAddress) {
            resetEstimations();
            let wallet = false;
            for(let i =0; i < linkedWalletList.length; i++) {
                if(linkedWalletList[i].address.toLowerCase() === walletAddress.toLowerCase() && linkedWalletList[i].name === EVER_WALLET_NAME) {
                    wallet = true;
                }
            }
            setEverWallet(wallet);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletAddress, linkedWalletList]);

    useEffect(() => {
        if(isSignAndAuth && connectWallet && associateWalletList.length > 0) {
            const delay = setTimeout(() => {
                associateWallet();
            }, 1000);

            return () => clearTimeout(delay)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectWallet, associateWalletList]);

    const associateWallet = () => {
        const totalAssociates = associateWalletList.length;
        let found = false;
        for(let i =0; i < totalAssociates; i++) {
            if(associateWalletList[i].address.toLowerCase() === connectWallet.toLowerCase()) {
                found = true;
            }
        }
        if(!found) {
            if(web3.utils.isAddress(connectWallet)) {
                helpers.associateWallet(connectWallet)
            }
        }
    };

    const getAssociatedWallets = async () => {
        return helpers.getAssociatedWallets()
            .then((response) => {
                return response;
            })
            .catch(() => {
                setLoader(false);
                snackBarMessage({message: 'Unable to get Wallets. Please refresh the page and try again.'});
            });
    };

    const getUserWallets = async () => {
        return helpers.getUserWallets()
            .then((response) => {
                return response;
            })
            .catch(() => {
                setLoader(false);
                snackBarMessage({message: 'Unable to get Wallets. Please refresh the page and try again.'});
            });
    };

    useEffect(() => {
        if(buyData.address) {
            setWalletAddress(buyData.address)
        }
        if(buyData.addressFrom) {
            if(buyData.addressFrom === 'connect') {
                setConnectWallet(buyData.address);
            } else {
                setSelectWallet(buyData.address);
            }
        }
        if(isSignAndAuth) {
            Promise.all([
                getUserWallets().then((resp) => resp),
                getAssociatedWallets().then((resp) => resp)
            ]).then(([walletResp, associatedWallets]) => {
                if(associatedWallets.data.success && associatedWallets.data.wallets) {
                    setAssociateWalletList(associatedWallets.data.wallets);
                }
                setLoader(false);
                if(walletResp.data.wallets) {
                    const wallets = walletResp.data.wallets;
                    setLinkedWalletList(wallets);
                    const walletsArr = [];
                    const addressArr = [];
                    for(let i =0; i < wallets.length; i++) {
                        const walletObj = {name: wallets[i].name, address: wallets[i].address};
                        walletsArr.push(walletObj);
                        addressArr.push(wallets[i].address);
                        if(!buyData.address && wallets[i].name === EVER_WALLET_NAME) {
                            setSelectWallet(wallets[i].address)
                            setWalletAddress(wallets[i].address);
                        }
                    }
                    if(associatedWallets.data.success && associatedWallets.data.wallets) {
                        const wallets = associatedWallets.data.wallets;
                        for(let i =0; i < wallets.length; i++) {
                            if(addressArr.indexOf(wallets[i].address) < 0) {
                                const walletObj = {name: wallets[i].name, address: wallets[i].address};
                                walletsArr.push(walletObj);
                            }
                        }
                        setWalletList(walletsArr);
                    } else {
                        setWalletList(walletsArr);
                    }
                } else {
                    window.alert(
                        'Something went wrong while getting wallets data.'
                    );
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!currency) {
            return
        }

        for (let i = 0; i < fiatAssets.length; i++) {
            if(fiatAssets[i].symbol === currency) {
                const paymentOptions = fiatAssets[i].payment_options;
                setPaymentOptions(paymentOptions);
                let found = false;
                for (let j = 0; j < paymentOptions.length; j++) {
                    if(paymentOptions[j].id === paymentId) {
                        found = true;
                        break;
                    }
                }
                if(!found) {
                    setPaymentId(paymentOptions[0].id);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currency]);

    useEffect(() => {
        if(amount !== "") {
            if (!regexAmountCheck.test(amount)) {
                setEstimateLoader(false);
                setEstimateTokenValue('');
                setAmountErr(true);
                return false;
            } else {
                setAmountErr(false);
                setErrorText("");
            }
        }
        if(amountCal > 0 && outputToken && setOutputTokenId && currency && tokenList.length > 0) {
            setEstimateLoader(true);
            setEstimateTokenValue('');
            resetEstimations();
            const delay = setTimeout(() => {
                getEstimations(currentToken.network, currentToken.exchange);
            }, 1000);

            return () => clearTimeout(delay)
        } else {
            resetEstimations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outputToken, setOutputTokenId, amount, currency, paymentId, tokenList]);

    useEffect(() => {
        setTokenLoader(true);
        helpers
        .transactionTypes(FIAT_TO_EVER_ID)
        .then((resp) => {
            if (resp.data.success) {
                const respData = resp.data.data[0];
                setIdMinimumAmount(respData.min_deposit_amount);
                setIdMaximumAmount(respData.max_deposit_amount);
            } else {
                snackBarMessage({message: 'Something went wrong while getting fiat currency data'});
            }
        })
        .catch((error) => {
            snackBarMessage({message: error.toString()});
        });
        helpers
        .transactionTypes(FIAT_TO_TOKEN)
        .then((resp) => {
            if (resp.data.success) {
                const respData = resp.data.data[0];
                setMinimumAmount(respData.min_deposit_amount);
                setMaximumAmount(respData.max_deposit_amount);
            } else {
                snackBarMessage({message: 'Something went wrong while getting fiat currency data'});
            }
        })
        .catch((error) => {
            snackBarMessage({message: error.toString()});
        });
        helpers.fiatAssets()
            .then(resp => {
                setFiatLoader(false);
                if(!resp.data.success) {
                    snackBarMessage({message: "Something went wrong while getting fiat currency data"});
                    return
                }

                const respData = resp.data.data;
                const fiatArr = [];
                for (let i = 0; i < respData.length; i++) {
                    const fiat = {};
                    const currentFiat = respData[i];
                    fiat.id = currentFiat.id;
                    fiat.name = currentFiat.name;
                    fiat.logo_uri = currentFiat.logo_uri;
                    fiat.symbol = currentFiat.symbol;
                    fiat.payment_options = [];
                    const paymentOptions = currentFiat.payment_options;
                    for (let j = 0; j < paymentOptions.length; j++) {
                        const paymentOption = {};
                        const currentPaymentOption = paymentOptions[j];
                        if(currentPaymentOption.active && currentPaymentOption.payment_provider.active && currentPaymentOption.payment_provider.payin_allowed) {
                            paymentOption.id = currentPaymentOption.id;
                            paymentOption.type = currentPaymentOption.payment_type.type;
                            if(!paymentId && paymentOption.type === PAYMENT_CARD && fiat.symbol === currency) {
                                setPaymentId(paymentOption.id);
                            }
                            paymentOption.name = currentPaymentOption.payment_provider.name;
                            paymentOption.payin_min_amount = currentPaymentOption.payment_provider.payin_min_amount;
                            paymentOption.payin_max_amount = currentPaymentOption.payment_provider.payin_max_amount;
                            paymentOption.provider = currentPaymentOption.payment_provider.provider;
                            fiat.payment_options.push(paymentOption);
                        }
                    }
                    if(currency === fiat.symbol) {
                        setPaymentOptions(fiat.payment_options);
                    }
                    fiatArr.push(fiat);
                }
                setFiatAssets(fiatArr);
            })
            .catch((error) => {
                setFiatLoader(false);
                snackBarMessage({message: error.toString()});
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadCryptoAssets = () =>{
        return helpers.cryptoAssets()
            .then(response => {
                return response;
            })
            .catch((error) => {
                snackBarMessage({message: error.toString()});
            });
    }

    const loadTokenPairs = () => {
        return helpers
            .cryptoAssetsPairs({appKey: appKey})
            .then((response) => {
                return response;
            })
            .catch(() => {
                setLoader(false);
                snackBarMessage({
                    message:
                        'Unable to load token pairs. Please refresh the page and try again.',
                });
            });
    };

    useEffect(() => {
        Promise.all([
            loadTokenPairs().then((resp) => resp),
            loadCryptoAssets().then((resp) => resp)
        ]).then(([tokenPairs , cryptoAssets]) => {
            setTokenLoader(false);

            const tokenResp = tokenPairs.data;
            const cryptoAssetsResp = cryptoAssets.data;

            if (!tokenResp.success) {
                return;
            }
            if (!cryptoAssetsResp.success) {
                return;
            }
            setTokenDefault(tokenResp.data.default_fiat_to_crypto)

            const whitelistTokens = {};
            for (const [_, pairs] of Object.entries(tokenResp.data.fiat_to_crypto)) {
                pairs.forEach((element) => {
                    whitelistTokens[element] = true;
                })
            }

            const totalTokens = cryptoAssetsResp.data.length;
            const reduxTokenList = [];
            for (let i = 0; i < totalTokens; i++) {
                const currentToken = cryptoAssetsResp.data[i];

                if(!currentToken.active || !currentToken.buyable) {
                    continue
                }

                if(!whitelistTokens[currentToken.id]) {
                    continue;
                }

                reduxTokenList.push({
                    id: currentToken.id,
                    name: currentToken.name,
                    symbol: currentToken.symbol,
                    contractAddress: currentToken.address,
                    decimal: currentToken.decimal,
                    token: currentToken.token,
                    crypto: currentToken.crypto,
                    synthetic: currentToken.synthetic,
                    image: currentToken.logo_uri,
                    network: currentToken.network.name,
                    networkImage: currentToken.network.logo_uri,
                    networkSymbol: currentToken.network.symbol,
                    exchange: currentToken.exchange.name,
                    regionUs: currentToken.region_us,
                    regionEu: currentToken.region_eu,
                });
            }
            setAllTokens(reduxTokenList);
            dispatch(setReduxTokenList(reduxTokenList));
        }).catch((error) => {
            setTokenLoader(false);
            snackBarMessage({message: error.toString()});
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const tokenArr = [],
            symbolsArr = [],
            handleTokenPush = (token, symbols, tokens) => {
                tokens.push(token);

                let input = fiatAssets.filter(fiat => {return fiat.symbol === currency})
                if (input.length === 0) {
                    return
                }

                // Set Default Token Pair
                if(!buyData.tokenId && tokenDefault[input[0].id] === token.id) {
                    setSetOutputTokenId(token.id);
                    setOutputToken(token.symbol);
                }
            }

        allTokens.forEach((currentToken) => {
            const isRegionUS =
                userRegion === REGION_US && currentToken.regionUs;
            const isRegionEU =
                userRegion === REGION_EU && currentToken.regionEu;
            const isEverWallet =
                everWallet || (!everWallet && !currentToken.synthetic);

            switch (isSignAndAuth){
                case true:
                    if ((isRegionUS || isRegionEU) && isEverWallet) {
                        handleTokenPush(currentToken, symbolsArr, tokenArr);
                    }
                    break;
                default:
                    if (isRegionEU && isEverWallet){
                        handleTokenPush(currentToken, symbolsArr, tokenArr);
                    }
                    break
            }
        })
        tokenArr.sort((a, b) => a.symbol.localeCompare(b.symbol));
        setTokenList(tokenArr);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [everWallet, userRegion, allTokens]);

    useEffect(() => {
        const totalTokens = tokenList.length;
        if(totalTokens > 0) {
            let found = false;
            for(let i = 0; i < totalTokens; i++) {
                const currentToken = tokenList[i];
                if(currentToken.id === setOutputTokenId) {
                    found = true;
                    break;
                }
            }
            if(!found) {
                setOutputToken("");
                setSetOutputTokenId("");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenList]);

    const resetEstimations = () => {
        setNetworkFee(0);
        setEverestFee(0);
        setFee(0);
        setOutputFiatAmount(0);
        setEstimateTokenValue('');
        setConversionRate('');
    };

    const validateAmount = (minAmount, maxAmount) => {
        if(minAmount * 1 > amountCal) {
            setErrorText(`Minimum value should be greater than ${currencySymbol+minAmount}`);
            setAmountErr(true);
            return false;
        }

        if(maxAmount * 1 < amountCal) {
            setErrorText(`Maximum value should be less than ${currencySymbol+maxAmount}`);
            setAmountErr(true);
            return false;
        }

        return true;
    };

    const getEstimations = (network, exchange) => {
        // Validate Output Token
        switch (outputToken) {
            case ID_TOKEN_SYMBOL:
                if(!validateAmount(idMinimumAmount, idMaximumAmount)) {
                    return false;
                }
                break;
            default:
                if(!validateAmount(minimumAmount, maximumAmount)) {
                    return false;
                }
                break;
        }

        const data = {
            type: (outputToken === ID_TOKEN_SYMBOL && network === EVER_CHAIN_NETWORK) ? FIAT_TO_EVER_ID : FIAT_TO_TOKEN,
            paymentMethod,
            amount: amountCal,
            token: outputToken,
            currency,
            network: network,
            exchange: exchange,
            paymentProvider: currentPaymentOption.provider
        };

        helpers.feeEstimator(data)
            .then((response) => {
                setEstimateLoader(false);
                if (!response.data.success) {
                    return
                }

                const respData = response.data.data;
                setAmountErr(false);
                setErrorText("");
                setQuoteId(respData.quote_id);
                setEstimateTokenValue(respData.crypto_out_amount);
                setConversionRate(respData.conversion_price);
                setFee(respData.total_fee * 1);
                setOutputFiatAmount(respData.output_token.fiat_amount);
                const fee_breakdown = respData.fee_breakdown;
                for(let i = 0; i < fee_breakdown.length; i++) {
                    const currentFee = fee_breakdown[i];
                    switch (currentFee.name) {
                        case "ev_fee":
                            setEverestFee(currentFee.value * 1);
                            break;
                        case "network_fee":
                            setNetworkFee(currentFee.value * 1);
                            break;
                    }
                }
            })
            .catch((error) => {
                setEstimateLoader(false);
                handleOrderError({error, snackBarMessage});
            });
    };

    const handleAmountChange = (event) => {
        const val = event.target.value;
        if (!val) {
            setAmount('');
            return;
        }

        const amountRegex = /^([0-9.,]+)$/;
        if (amountRegex.test(val)) {
            setAmount(val);
        }
    };

    const outputTokenSelect = (val) => {
        setOutputToken(val.symbol);
        setSetOutputTokenId(val.id);
    };

    const handleFiatCurrency = (e) => {
        const val = e.target.value;
        setCurrency(val);
        switch (val) {
            case "EUR":
                dispatch(setAppCurrency({code: "EUR", symbol: "€"}));
                break;
            default:
                dispatch(setAppCurrency({code: "USD", symbol: "$"}));
                break;
        }
    };

    const buyToken = () => {

        if(!isSignIn && enableAuth) {
            snackBarMessage({message: "Please SignIn/SignUp to proceed further with buy"});
            return false;
        }

        if(!outputToken) {
            snackBarMessage({message: "Please select token"});
            return false;
        }

        if(!paymentMethod) {
            snackBarMessage({message: "Please select payment method"});
            return false;
        }

        if(!amount) {
            snackBarMessage({message: "Please enter amount"});
            return false;
        }

        if (!regexAmountCheck.test(amount)) {
            snackBarMessage({message: "Please provide a valid amount"});
            return false;
        }

        if(!estimateTokenValue) {
            snackBarMessage({message: "Please wait for the estimate to be calculated and then press next"});
            return false;
        }

        if(!walletAddress) {
            let msg = "Please connect a wallet before continuing further.";
            if(isSignAndAuth) {
                msg = "Please either select a wallet or connect a wallet before continuing further.";
            }
            snackBarMessage({message: msg, type: 'error'});
            return false;
        }

        if(!web3.utils.isAddress(walletAddress)) {
            snackBarMessage({message: "Please provide a valid wallet address."});
            return false;
        }

        const formattedAmount = amount.replaceAll(",", ".") * 1;
        if(formattedAmount < currentPaymentOption.payin_min_amount * 1) {
            snackBarMessage({message: `${currentPaymentOption.payin_min_amount+currencySymbol} is the minimum amount to make the purchase.`});
            return false;
        }

        if(amount > currentPaymentOption.payin_max_amount * 1) {
            snackBarMessage({message: `Maximum ${currentPaymentOption.payin_max_amount+currencySymbol} are allowed to make purchase through Debit Card.`});
            return false;
        }

        let addressFrom = "connect";
        if(selectWallet) {
            addressFrom = "select";
        }

        dispatch(setData({amount, currency, token: outputToken, paymentId, tokenId: setOutputTokenId, provider: currentPaymentOption.provider, network: currentToken.network, exchange: currentToken.exchange, totalFee: fee, quoteId, paymentMethod, networkFee, everestFee, conversionRate, estimatedTokenAmount: estimateTokenValue, address: walletAddress.toLowerCase(), addressFrom}));
        navigate('/confirm/transaction');
    };

    return (
        <>
            <Box className="login-container">
                <BuySellLink buyActive={true} />
                <Box className="full-width">
                    <Grid mt={8} container>
                        <Grid item={true} xs={2}>
                            <Typography className="input-label-feature" variant="caption" component="p">You Pay</Typography>
                        </Grid>
                    </Grid>
                    <Grid mt={0.5} container>
                        <Grid item={true} xs={6}>
                            <FormControl variant="filled" className='pay-amount-field fiat-field'>
                                <FilledInput
                                    value={amount}
                                    onChange={handleAmountChange}
                                    type="text"
                                    placeholder={`${currentPaymentOption.payin_min_amount > 0 ? currencySymbol+currentPaymentOption.payin_min_amount+" minimum value" : ""}`}
                                    error={amountErr || minAmountErr}
                                    required
                                    fullWidth
                                />
                                <FormHelperText className="swap-fiat-values">&nbsp;</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={6} className="content-center">
                            {fiatLoader ? <CircularProgress sx={{ml: 1}} size={30} color={"custom"}/> :
                                <FormControl variant="filled" className="text-left fiat-picker" fullWidth>
                                    <Select
                                        sx={{
                                            fontWeight: "bold",
                                            '.MuiSvgIcon-root ': {
                                                fill: "#16359D !important",
                                            },
                                            padding: currency !== "" ? "0px" : "4.95px"
                                        }}
                                        className="buy-select-dd"
                                        value={currency}
                                        variant="filled"
                                        onChange={handleFiatCurrency}
                                        error={amountErr || minAmountErr}
                                    >
                                        {fiatAssets.map(fiat =>
                                            <MenuItem value={fiat.symbol} key={fiat.symbol}>
                                                <ListItemIcon className={"dropdown-list-item"}>
                                                    <img src={fiat.logo_uri} alt="US Flag" width="40" height="40"/>
                                                </ListItemIcon>
                                                <span>{fiat.symbol}</span>
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            }
                        </Grid>
                        {errorText  && <Typography variant="caption" sx={{color: 'red'}}><strong>{errorText}</strong></Typography>}
                    </Grid>

                    <Box className="text-left" mt={4}>
                        <Typography className="input-label-feature" variant="caption" component="p">Select Payment Method</Typography>
                        {fiatLoader ? <CircularProgress sx={{ml: 1}} size={30} color={"custom"}/> :
                            <Grid mt={0.5} container>
                                {paymentOptions.map(paymentOption =>
                                    <Grid key={paymentOption.name} item={true} xs={4}>
                                        <PaymentButton
                                            label={paymentOption.name}
                                            value={paymentOption.id}
                                            handleChange={setPaymentId}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        }
                    </Box>

                    <Grid mt={4} container>
                        <Grid item={true} xs={4}>
                            <Typography className="input-label-feature" variant="caption" component="p">You Receive</Typography>
                        </Grid>
                    </Grid>

                    <Grid mt={0.5} container>
                        <Grid item={true} xs={6}>
                            <FormControl className='pay-amount-field crypto-field'>
                                <FilledInput
                                    value={estimateTokenValue && (estimateTokenValue * 1).toFixed(5)}
                                    type="text"
                                    readyonly={"true"}
                                    fullWidth
                                />
                                <FormHelperText className="swap-fiat-values">{outputFiatAmount > 0 ? currencySymbol+(outputFiatAmount * 1).toFixed(2) : <>&nbsp;</>}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item={true} xs={6} className="content-center">
                            {tokenLoader ? <CircularProgress sx={{ml: 1}} size={30} color={"custom"}/> :
                                <FormControl variant="filled" className="text-left crypto-picker" fullWidth>
                                    {currentToken?.network && <InputLabel className={`select-network-label ${getNetworkBackGround(currentToken?.network)}`}>{currentToken?.network}</InputLabel>}
                                    <Select
                                        sx={{
                                            fontWeight: "bold",
                                            '.MuiSvgIcon-root ': {
                                                fill: "#16359D !important",
                                            },
                                            padding: currentToken?.id > 0 ? "0px" : "4.95px"
                                        }}
                                        className={"buy-select-dd"}
                                        variant="filled"
                                        value={outputToken}
                                        displayEmpty
                                        onChange={(e) => outputTokenSelect(e.target.value)}
                                        onOpen={(e) => {setOpenModal(true); e.preventDefault();}}
                                        defaultOpen={false}
                                        open={false}
                                    >
                                        <MenuItem value="">Please select</MenuItem>
                                        {tokenList.map(token =>
                                            <MenuItem key={token.id} value={token.symbol}>
                                                <ListItemIcon className={"dropdown-list-item"}>
                                                    <img src={token.image} onError={({ currentTarget }) => {currentTarget.onerror = null;currentTarget.src = GenericTokenImage;}} alt="tokenImg" width="40" height="40" />
                                                </ListItemIcon>
                                                <span>{token.symbol.toUpperCase()}</span>
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            }
                        </Grid>
                    </Grid>

                    <Box mt={2.5} className='fee-area'>
                        <Grid container>
                            <Grid className='text-left' item={true} xs={12}>
                                <IconButton sx={{padding: "0"}} onClick={() => setShowFeeCalculation(prev => !prev)} disableFocusRipple={true} disableRipple={true}><Typography variant = "caption"><strong>Fee Calculation</strong></Typography><PlayArrowIcon sx={{transform: `rotate(${showFeeCalculation ? "90" : "0"}deg)`, fontSize: '15px'}}/></IconButton>
                            </Grid>
                            {showFeeCalculation &&
                                <>
                                    <Grid className="text-left" item={true} xs={6}>
                                        <Typography variant = "caption">Everest Fee</Typography>
                                    </Grid>
                                    <Grid className="text-right" item={true} xs={6}>
                                        <Typography variant = "caption">{currencySymbol}{estimateLoader ? <CircularProgress sx={{ml: 1}} size={15} color={"custom"} /> : everestFee.toFixed(2)}</Typography>
                                    </Grid>

                                    <Grid className="text-left" item={true} xs={6}>
                                        <Typography variant = "caption">Network Fee</Typography>
                                    </Grid>
                                    <Grid className="text-right" item={true} xs={6}>
                                        <Typography variant = "caption">{currencySymbol}{estimateLoader ? <CircularProgress sx={{ml: 1}} size={15} color={"custom"} /> : networkFee.toFixed(2)}</Typography>
                                    </Grid>
                                </>
                            }
                            <Grid className="text-left" item={true} xs={6}>
                                <Typography variant = "caption">Total Fees</Typography>
                            </Grid>
                            <Grid className="text-right" item={true} xs={6}>
                                <Typography variant = "caption">{currencySymbol}{estimateLoader ? <CircularProgress sx={{ml: 1}} size={15} color={"custom"} /> : fee.toFixed(2)}</Typography>
                            </Grid>
                            {outputToken &&
                                <>
                                    <Grid className="text-left" item={true} xs={6}>
                                        <Typography variant = "caption">1 {outputToken} <BootstrapTooltip title={ESTIMATE_TOOLTIP_TEXT} placement="right"><IconButton className="info-fee-icon"><InfoIcon sx={{fontSize: '14px'}} /></IconButton></BootstrapTooltip></Typography>
                                    </Grid>
                                    <Grid className="text-right" item={true} xs={6}>
                                        <Typography variant = "caption">{currencySymbol}{estimateLoader ? <CircularProgress sx={{ml: 1}} size={15} color={"custom"} /> : (exchangeRate * 1).toFixed(2)}</Typography>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Box>

                    <ConnectWallet snackBarMessage={snackBarMessage} walletAddress={connectWallet} from={"swap"} addressHandler={handleConnectWalletChange} />

                    {isSignAndAuth ?
                        <Box sx={{ alignItems: 'left', width: '100%' }}>
                            {loader ? <Box mt={2} sx={{textAlign: 'center'}}><CircularProgress /></Box> :
                                <>
                                    <Typography className='wallet-caption-text' mt={1} mb={1} variant="caption" component="p">Or</Typography>
                                    <FormControl className="wallet-select-form-control" variant="filled" fullWidth>
                                        {!selectWallet && <InputLabel >Select wallet address</InputLabel>}
                                        <Select
                                            className="wallet-select-dd"
                                            value={selectWallet}
                                            onChange={handleWalletChange}
                                        >
                                            {walletList.map(wallet =>
                                                <MenuItem key={wallet.address} value={wallet.address}>{wallet.name+'('+wallet.address+')'}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </>
                            }
                        </Box> :
                        <>
                            {enableAuth &&
                                <>
                                    <Button className="sdk-btn2" onClick={() => navigate('/signin', {state: {from: 'buy'}}) } sx={{mt: 2}} variant="contained" fullWidth>
                                        <strong>Sign In</strong>
                                    </Button>
                                    <Typography className={"connect-wallet-signup"} variant="caption" onClick={() => navigate('/signup', {state: {from: 'buy'}})}><strong>Sign Up With Everest</strong></Typography>
                                </>
                            }
                        </>
                    }

                    <Box mt={4}>
                        {(isSignAndAuth || connectWallet) &&
                            <Button
                                className="sdk-btn2"
                                fullWidth
                                variant="contained"
                                onClick={buyToken}
                                disabled={amountErr || !estimateTokenValue}
                            >
                                <strong>Next</strong>
                            </Button>
                        }
                    </Box>
                </Box>
            </Box>

            <TokenModal
                open={openModal}
                setModal={setOpenModal}
                loader={tokenLoader}
                tokenList={tokenList}
                tokenSelect={outputTokenSelect}
            />
        </>
    );
};

export default Buy;
