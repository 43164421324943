import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import Medium from "../../images/medium.png";
import LinkedIn from "../../images/linkedIn.png";
import Telegram from "../../images/telegram.png";
import Discord from "../../images/discord.png";
import Email from "../../images/email.png";
import { INSTAGRAM, TWITTER } from "../../js/images";

const ContactItem = ({icon, value, url}) => {
    return (
        <a href={url} target={"_blank"} className="contact-link">
            <Box mt={2} className="contact-item">
                <Grid container spacing={1} alignItems="center">
                    <Grid item={true} xs={2}>
                        <img className="display-block" src={icon} width={40} alt="icon" />
                    </Grid>
                    <Grid item={true} xs={10}>
                        <Typography variant="h6"><strong>{value}</strong></Typography>
                    </Grid>
                </Grid>
            </Box>
        </a>
    );
};

const Contact = () => {



    return (
        <>
            <Typography variant="h5" mb={4}><strong>Contact</strong></Typography>
            <ContactItem icon={TWITTER} value={"X.com (Twitter)"} url={"https://twitter.com/EverestDotOrg"}/>
            <ContactItem icon={Medium} value={"Medium"} url={"https://medium.com/everestdotorg"}/>
            <ContactItem icon={LinkedIn} value={"LinkedIn"} url={"https://www.linkedin.com/company/everestdotorg/"}/>
            <ContactItem icon={Telegram} value={"Telegram (News)"} url={"https://t.me/Everest_BaseCamp"}/>
            <ContactItem icon={Discord} value={"Discord"} url={"https://discord.com/invite/YQMP4aTYAY"}/>
            <ContactItem icon={INSTAGRAM} value={"Instagram"} url={"https://www.instagram.com/everestdotorg_/"}/>
            <ContactItem icon={Email} value={"contact@everest.org"} url={"mailto:contact@everest.org"}/>
        </>
    );
};

export default Contact;