import React from 'react';
import {Box, Typography} from "@mui/material";
import StepActive from "../../images/step-active.png";
import StepInActive from "../../images/step-inactive.png";
import { STEP_BLUE_ICON } from '../../js/images';

const Step = ({totalSteps, currentStep, showText = true, stepBlue = false}) => {
    return (
        <Box mt={3} mb={3}>
            {showText && <Typography variant="p" mr={1} sx={{color: '#C7C7C7', fontSize: '20px', fontWeight: 'bold'}}>Step {currentStep} of {totalSteps}</Typography>}
            {[...Array(currentStep)].map((x, i) =>
                <img key={i} src={stepBlue ? STEP_BLUE_ICON : StepActive} alt="active" width={43} style={{margin: '0px 1px'}} />
            )}
            {[...Array(totalSteps - currentStep)].map((x, i) =>
                <img key={i} src={StepInActive} alt="inactive" width={43} style={{margin: '0px 1px'}} />
            )}
        </Box>
    );
};

export default Step;