import { getStorage, removeStorage, setStorage } from "./generalUtil";

export const getToken = () => {
    const token = getStorage('token');
    if(token) {
        return 'Bearer ' + token;
    }
    return null;
};

export const getUID = () => {
    return getStorage('uuid');
};

export const isLoggedIn = () => {
    if (getToken()) {
        const token = getToken();
        const tokenArr = token.split('.');
        if (tokenArr.length > 2) {
            const tokenJson = atob(tokenArr[1]);
            const expTime = JSON.parse(tokenJson).exp;
            const currentTime = parseInt(new Date().getTime() / 1000);
            if(currentTime <= expTime) {
                return true;
            } else {
                removeSession();
                return false;
            }
        } else {
            removeSession();
        }
    }
    return false;
};

export const setSession = (uuid, token) => {
    setStorage('uuid', uuid);
    setStorage('token', token);
};

export const removeSession = () => {
    removeStorage('uuid');
    removeStorage('token');
};