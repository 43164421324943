import React, {useEffect, useRef} from 'react';
import {Grid, Typography} from "@mui/material";
import BackspaceIcon from '@mui/icons-material/Backspace';

const KeyboardButton = ({value, displayValue, subValue, onClickHandler, keyboardRefs}) => {
    return (
        <Grid className="keyboard-btn" ref={el => el && keyboardRefs && (keyboardRefs.current[value] = el)} item={true} pt={3} xs={4} onClick={() => onClickHandler(value)}>
            <Typography variant="h4">{displayValue ? displayValue : value}</Typography>
            {subValue && <Typography variant="caption">{subValue}</Typography>}
        </Grid>
    )
};

const Keyboard = ({onClickHandler, enterBtnRef, disable = false, removeTypeEffect = false}) => {
    const keyboardRefs = useRef([]);

    const keyUpPress = (event) => {
        let keyPress = event.key;
        if(keyPress === "Enter" && enterBtnRef) {
            enterBtnRef.current.click();
        }
        const amountRegex = /^([0-9]+)$/;
        if (amountRegex.test(keyPress) || keyPress === "Backspace") {
            keyPress = keyPress === "Backspace" ? "backSpace" : keyPress;
            keyboardRefs.current[keyPress].click();
            if(!removeTypeEffect) {
                keyboardRefs.current[keyPress].classList.remove('active');
            }
        }
    };

    const keyDownPress = (event) => {
        let keyPress = event.key;
        const amountRegex = /^([0-9]+)$/;
        if (amountRegex.test(keyPress) || keyPress === "Backspace") {
            keyPress = keyPress === "Backspace" ? "backSpace" : keyPress;
            if(!removeTypeEffect) {
                keyboardRefs.current[keyPress].classList.add('active');
            }
        }
    };

    useEffect(() => {
        if(!disable) {
            document.addEventListener('keyup', keyUpPress);
            document.addEventListener('keydown', keyDownPress);
            return () => {
                document.removeEventListener('keyup', keyUpPress);
                document.removeEventListener('keydown', keyDownPress);
            }
        }

    }, [disable]);

    return (
        <Grid container>
            <KeyboardButton value={1} keyboardRefs={keyboardRefs} onClickHandler={onClickHandler} />
            <KeyboardButton value={2} keyboardRefs={keyboardRefs} subValue={"ABC"} onClickHandler={onClickHandler} />
            <KeyboardButton value={3} keyboardRefs={keyboardRefs} subValue={"DEF"} onClickHandler={onClickHandler} />
            <KeyboardButton value={4} keyboardRefs={keyboardRefs} subValue={"GHI"} onClickHandler={onClickHandler} />
            <KeyboardButton value={5} keyboardRefs={keyboardRefs} subValue={"JKL"} onClickHandler={onClickHandler} />
            <KeyboardButton value={6} keyboardRefs={keyboardRefs} subValue={"MNO"} onClickHandler={onClickHandler} />
            <KeyboardButton value={7} keyboardRefs={keyboardRefs} subValue={"PQRS"} onClickHandler={onClickHandler} />
            <KeyboardButton value={8} keyboardRefs={keyboardRefs} subValue={"TUV"} onClickHandler={onClickHandler} />
            <KeyboardButton value={9} keyboardRefs={keyboardRefs} subValue={"WXYZ"} onClickHandler={onClickHandler} />
            <KeyboardButton  />
            <KeyboardButton value={0} keyboardRefs={keyboardRefs} onClickHandler={onClickHandler} />
            <KeyboardButton value={"backSpace"} keyboardRefs={keyboardRefs} displayValue={<BackspaceIcon />} onClickHandler={onClickHandler} />
        </Grid>
    )
};

export default Keyboard;