import { createSlice } from '@reduxjs/toolkit';
import { CAMPAIGN_TWITTER } from '../js/constants';

const initialState = {
    backFunc: null,
    currency: {code: "USD", symbol: "$"}, //{code: "EUR", symbol: "€"}
    usdToEurConversionRate: "",
    sdkConfiguration: {appKey: '', service: 'buySell', campaign: CAMPAIGN_TWITTER, buttonColor: '', buttonTextColor: ''},
    partnerData: {
        appKey: '',
        name: '',
        organization: '',
        buy_feature_active: false,
        exchange_service_active: false,
        identity_service_active:false,
        payer_service_active:false,
        sell_feature_active:false,
        buy_initial_amount: '500',
        exchange_initial_amount: '0.5',
        sell_initial_amount: '0.5',
        enable_auth: true,
    },
    connectWallet: '',
    connectWalletProvider: '',
    getProfileData: false,
    referralCode: '',
    partialRegister: false,
    menuText: "",
    features: { buy: true, sell: false, withdraw: false, trade: false, remoteData: false },
};

export const appSlice = createSlice({
    name: 'appData',
    initialState,
    reducers: {
        setBackFunc: (state, action) => {
            state.backFunc = action.payload;
        },
        setAppCurrency: (state, action) => {
            state.currency = action.payload;
        },
        setUsdToEur: (state, action) => {
            state.usdToEurConversionRate = action.payload;
        },
        setSdkConfiguration: (state, action) => {
            state.sdkConfiguration = action.payload;
        },
        setPartnerData: (state, action) => {
            state.partnerData = action.payload;
        },
        setConnectWallet: (state, action) => {
            state.connectWallet = action.payload;
        },
        setConnectWalletProvider: (state, action) => {
            state.connectWalletProvider = action.payload;
        },
        setGetProfileData: (state, action) => {
            state.getProfileData = action.payload;
        },
        setReferralCode: (state, action) => {
            state.referralCode = action.payload;
        },
        setPartialRegister: (state, action) => {
            state.partialRegister = action.payload;
        },
        setFeatures: (state, action) => {
            state.features = action.payload;
        },
        setMenuText: (state, action) => {
            state.menuText = action.payload;
        },
    },
});

export const { setBackFunc, setAppCurrency, setUsdToEur, setSdkConfiguration, setPartnerData, setConnectWallet, setConnectWalletProvider, setGetProfileData, setReferralCode, setPartialRegister, setFeatures, setMenuText } = appSlice.actions;

export const selectAppData = (state) => state.appData;

export const selectProfileData = (state) => state.getProfileData ;

export default appSlice.reducer;