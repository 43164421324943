import React from 'react';
import {Box, Typography} from "@mui/material";

const Terms = () => {

    return (
        <Box p={1} className="terms-and-conditions">
            <Typography variant="h5" mt={2} mb={2}>
                <strong>Everest<sup>TM</sup> Network Limited Terms and
                    Conditions of use</strong>
            </Typography>
            <Typography variant="body1">
                Everest™ is a blockchain application platform for
                the secure storage and verification of
                identification information and personal data as well
                as the tracking and recording of transactions.
                Everest is dedicated to liberating humanity from
                subservience to centralized, nonuser-friendly
                identity management and capital allocation
                organizations by creating a secure, decentralized
                identity management and value transfer system.
                <br />
                <br />
                Quick Summary
                <br />
                Everest is a user-centric, self-sovereign identity
                and value transfer solution based on blockchain
                technology and the cryptographic underpinnings of
                that system and reflects the principles of identity
                espoused in the sustainable development goals
                (“SDGs”) of the United Nations. Upon those
                foundations, Everest has architected the Identity
                Network with a focus on resilience, availability,
                security, privacy and control over personal data.
                <br />
                <br />
                As you use Everest, we ask you to follow these Terms
                and Conditions to get the most out of our Services.
                Just below is a summary of our Terms of Service,
                followed by the full, legally-binding set of Terms.
                This summary is for your convenience only and points
                out certain important user rights and
                responsibilities. Since you are bound by the full
                Terms of Service whenever you use Everest, you are
                required to read, understand and agree to the
                full-length version. If you do not agree to any of
                these Terms, you may not use the Everest
                Applications, any Everest-enabled applications or
                any other services provided by Everest.
                <br />
                <br />
                Our goal is to help you take back ownership and
                control of your identification and personal data, so
                that you can avail yourself of the opportunities
                associated with your identification and data. The
                services provided though Everest are open only to
                people who are 13 years of age or older unless such
                people have provided Everest with the written
                consent of a parent or guardian who is either (a)
                enrolled in the Everest Identity Network, or (b)
                provides an alternative of written and verification
                acceptable to Everest. While we will provide the
                highest level of security that we can on our end,
                you are responsible for the confidentiality of all
                of the information that you provide through your
                account and for all of the activities that take
                place as a result of your use of your account.
                <br />
                <br />
                We have a zero-tolerance policy for users who posts
                or send threatening content, content that violates
                any intellectual property rights, or the use of our
                services to harm or otherwise harass any person. We
                will delete any such content and reserve the right
                to restrict access and/or use for any reason, or no
                reason, in our discretion including, but not limited
                to, those who violates this policy.
                <br />
                <br />
                We may communicate with you from time to time,
                usually through emails, text messages, or push
                notifications. Text messaging and data rates may
                apply for which you will be responsible. You may opt
                out of receiving messages from us at any time.
                <br />
                <br />
                Both you and Everest have the right to stop your use
                of the Everest Applications or to delete your
                account at any time, but certain provisions of these
                Terms of Service will still apply even after
                termination of your account and Services. For
                example, any disputes between you and Everest even
                after your account is deleted will be governed by
                these Terms of Service. We may change the Terms of
                Service from time to time, and those changes are up
                to us - but you always have the right to decide
                whether or not you want to continue using Everest.
                If you do continue, that means you do agree to our
                new Terms. And now, on with the show!
                <br />
                <br />
                Everest Full Terms of Service
                <br />
                The following terms and conditions (the “Terms and
                Conditions” or “Agreement”) govern your use of
                applications, tools, services, products, content,
                technologies, processes and information offered by
                Everest, in its sole discretion, including, without
                limitation, the blockchain platform, web sites
                (including any subdomains thereof), mobile
                applications, mobile-web applications, biometric
                input, scanning and verification technologies,
                message boards, forums, web logs, chat rooms,
                personal chat, video chat, email, videos, articles,
                search engines and advertisements, (the
                “Applications”) owned by Everest, Inc. (“Everest”,
                “we,” “us” or “our”) and/or by our affiliates,
                including, but not limited to, our licensees,
                licensors, service providers, board members,
                employees, agents, advisors, contractors,
                subsidiaries, assignees, transferees, purchasers,
                partners, joint venturers, collaborators,
                advertisers, sponsors, marketing partners, writers
                and content providers (“Affiliates”) in relation to
                Everest. The Applications owned by Everest, are made
                available by Everest. BY USING OUR APPLICATIONS, YOU
                ACCEPT AND AGREE TO THESE TERMS AND CONDITIONS, AS
                WELL AS MODIFICATIONS THERETO, AS APPLIED TO YOUR
                USE OF THE APPLICATIONS. If you do not agree to
                these Terms and Conditions, you may not access or
                otherwise use the Applications. We may change the
                Terms and Conditions from time to time, at any time
                without notice to you, by posting such changes on
                the Applications.
                <br />
                <br />
                1. Our Limited License to You. <br />
                You may access, upload, download and share allowed
                information as well as view, verify and interact
                with the Applications solely for your nonexclusive
                personal, non-commercial and internal use, pursuant
                to the terms and conditions set forth herein, on
                your computer, mobile phone, tablet, as well as
                ATMs, biometric scanners, or other devices and,
                unless otherwise indicated in these Terms and
                Conditions or on the Applications, make single
                copies or prints of the content on the Applications
                for your personal, non-commercial and internal use
                only, provided that you keep intact all copyright
                and other proprietary notices.
                <br />
                <br />
                The Applications are the property of Everest and/or
                our Affiliates or licensors, (“Owners”), are
                protected by copyright, trademark, and other
                intellectual property laws and all rights are
                reserved in the Applications by the Owners. You may
                not use the Applications in a manner that
                constitutes an infringement of our rights or that
                has not been authorized by us in writing. More
                specifically, unless explicitly authorized in these
                Terms of Service or by the Owner(s) of the
                Applications in writing, you may not modify, copy,
                reproduce, republish, upload, post, transmit,
                translate, sell, create derivative works, exploit,
                license, encumber, distribute or make claims thereon
                in any manner or medium, whether known or unknown,
                currently in use or later put into use, any of the
                Applications.
                <br />
                <br />
                2. Publicly Submitted Materials and License to Us.{' '}
                <br />
                Any information that you deem confidential, secret,
                proprietary or otherwise private that is submitted
                through the Applications or any medium of exchange,
                whether known or unknown, existing or later
                invented, including without limitation, email, text,
                uploads and/or downloads (“Mediums”) is your
                responsibility to keep confidential, secret,
                proprietary and private, except as may set forth
                otherwise in our Privacy Policy. Any content,
                including without limitation, information, photos,
                videos, writing, creative works, demos, ideas,
                suggestions, concepts, methods, systems, designs,
                plans, techniques, name, likeness, image, links to
                pages, links to websites, links to mobile
                applications or other materials, social media,
                profiles, as well as all communications,
                transmissions, posts and contributions made via
                medium, including, but not limited to, chat rooms,
                message boards, forums, survey responses, email,
                links, shares, forwards and/or our web logs
                (“Content”), that you send, upload, download, post,
                transmit, communicate, share and/or submit using any
                Medium (“Publicly Submitted Materials”) will be
                deemed not to be confidential, private or secret,
                and may be used by us in any manner consistent with
                the Privacy Policy governing the use of such
                information.
                <br />
                <br />
                Publicly Submitted Materials shall not include
                Personal Information not publicly posted,
                distributed or disclosed by you and submitted to
                Everest in accordance with our Privacy Policy.
                <br />
                <br />
                By submitting, transmitting, or sending, in any
                medium, Publicly Submitted Materials to us, you: (i)
                represent and warrant that the Publicly Submitted
                Materials are original to you, that no other party
                has any rights thereto, and that any "moral rights"
                in Publicly Submitted Materials have been waived,
                (ii) and (ii) that you are the owner of the
                material, or are making your posting or submission
                with the express consent of the owner of the
                material; and (iii) that you are 13 years of age or
                older, You grant us and our Affiliates a
                royalty-free, unrestricted, worldwide, perpetual,
                irrevocable, non-exclusive, and fully transferable,
                assignable and sublicensable right and license to
                use, copy, reproduce, modify, adapt, publish,
                translate, create derivative works from, distribute,
                perform, display, sell, assign, license, sublicense
                and incorporate in other works any Publicly
                Submitted Materials (in whole or part) in any form,
                media, or technology now known or later developed,
                including for promotional and/or commercial
                purposes. We cannot be responsible for maintaining
                any Submitted Material that you provide to us, and
                we may delete or destroy any such Submitted Material
                at any time. Also, in connection with the exercise
                of such rights, you grant us, and anyone authorized
                by us, the right to identify you as the author of
                any of your postings or submissions by name, email
                address or screen name, public key or other
                identifier, as we deem appropriate. The rights
                granted to Everest herein by you also provide us all
                rights to remove, erase, delete any and all Publicly
                Submitted Materials in our sole discretion without
                notice, if we deem the Publicly Submitted Materials
                to be dangerous or inconsistent with the content we
                deem appropriate on our Applications. There is also
                no obligation by us to display or publish any
                Publicly Submitted Materials.
                <br />
                <br />
                In consideration for us providing you the license
                herein, you acknowledge and agree that any and all
                Publicly Submitted Materials originally created by
                you for us shall be deemed a “work made for hire”
                when the work performed is within the scope of the
                definition of a work made for hire in Section 101 of
                the United States Copyright Law, as amended. As
                such, the copyrights in those works shall belong to
                Everest from their creation. Thus, Everest shall be
                deemed the author and exclusive owner thereof and
                shall have the right to exploit any or all of the
                results and proceeds in any and all media, now known
                or hereafter devised, throughout the universe, in
                perpetuity, in all languages, as Everest, in its
                sole discretion, determines. In the event that any
                of the results and proceeds of your Publicly
                Submitted Materials are not deemed a “work made for
                hire” under Section 101 of the Copyright Act, as
                amended, you hereby, without additional
                compensation, irrevocably assign, convey and
                transfer to Everest all proprietary rights,
                including without limitation, all copyrights and
                trademarks throughout the universe, in perpetuity in
                every medium, whether now known or hereafter
                devised, to such material and any and all right,
                title and interest in and to all such proprietary
                rights in every medium, whether now known or
                hereafter devised, throughout the universe, in
                perpetuity. Any posted material which are
                reproductions of prior works by you shall be
                co-owned by us. You acknowledge that Everest has the
                right, but not the obligation to, use and display
                any postings or contributions of Publicly Submitted
                Materials of any kind and that Everest may elect to
                cease the use and display of any such materials (or
                any portion thereof), at any time in its sole
                discretion.
                <br />
                <br />
                3. Limitations on Linking and Framing. <br />
                You may establish a hypertext link to our
                Applications so long as the link does not state or
                imply any sponsorship of your site by us or by our
                Applications. However, you may not, without our
                prior written permission, frame or inline link any
                of the content of our Applications, or incorporate
                into another website or other service any of our
                material, content or intellectual property.
                <br />
                <br />
                4. Proprietary Rights. <br />
                As between you and Everest, Everest owns, solely and
                exclusively, all rights, title and interest in and
                to the Applications, including without limitation
                all the Content (including, for example, audio,
                photographs, illustrations, graphics, other visuals,
                video, copy, text, software, titles, Shockwave
                files, etc.), code, data, processes, algorithms, and
                materials thereon, the look and feel, design and
                organization of the Applications, and the
                compilation of the content, code, data and materials
                on the Applications, including but not limited to
                any copyrights, trademark rights, patent rights,
                database rights, moral rights, sui generis rights
                and other intellectual property and proprietary
                rights therein. Your use of the Applications does
                not grant to you any ownership, rights and/or
                interest in any Applications, Content, Publicly
                Submitted Materials or Intellectual Property of
                Everest.
                <br />
                <br />
                5. Online Commerce. <br />
                Certain of our Applications may allow you to elect
                to be contacted by merchants and to purchase many
                different types of products and services online that
                are provided by third parties. We are not
                responsible for the quality, accuracy, timeliness,
                reliability, delivery, warranties, your satisfaction
                or any other aspect of these products, goods and/or
                services. If you make a purchase from a merchant on,
                or through, our Applications or on any Application
                linked by our Applications, the information obtained
                during your visit to that merchant's online
                Application, and the information that you give as
                part of the transaction, such as your credit card
                number and contact information, may be collected by
                both the merchant and us. A merchant may have
                privacy and data collection practices that are
                different from ours. We have no responsibility or
                liability for these independent policies. In
                addition, when you purchase products or services on
                or through our Applications, you may be subject to
                additional terms and conditions that specifically
                apply to your purchase or use of such products or
                services. For more information regarding a merchant,
                its online store, its privacy policies, and/or any
                additional terms and conditions that may apply,
                visit that merchant's website and click on its
                information links or contact the merchant directly.
                You release us and our affiliates from any damages
                that you incur, and agree not to assert any claims
                against us or them, arising from your purchase or
                use of any products or services made available by
                third parties through our or our Affiliates’
                Applications.
                <br />
                <br />
                Your participation, correspondence or business
                dealings with any third party found on or through
                our Applications, regarding payment and delivery of
                specific goods and services, and any other terms,
                conditions, representations or warranties associated
                with such dealings, are solely between you and such
                third party. You agree that Everest shall not be
                responsible or liable for any loss, damage, or other
                matters of any sort incurred as the result of such
                dealings.
                <br />
                <br />
                You agree to be financially responsible for all
                purchases made by you or someone acting on your
                behalf through our or our Affiliates Applications.
                You agree that any purchase of services, goods or
                products made by you, or authorized by you, through
                our Applications are, and will be, for legitimate,
                non-commercial purposes only. You also agree not to
                make, or authorize to be made, any purchases for
                speculative, false or fraudulent purposes or for the
                purpose of anticipating demand for a particular
                product or service. You agree to only purchase goods
                or services for yourself or for another person for
                whom you are legally permitted to do so. When making
                a purchase for a third party that requires you to
                submit the third party's personal information to us
                or a merchant, you represent that you have obtained
                the express consent of such third party to provide
                such third party's personal information.
                <br />
                <br />
                6. Interactive Features. <br />
                Our Applications may include a variety of features
                and Content, such as bulletin boards, web logs,
                forums, chat rooms, personal chat, video chat,
                videos, articles and email services, which allow
                feedback to us and real-time interaction between
                users, and other features which allow users to
                communicate with others. Each user shall be fully
                responsible for any and all Content each user posts,
                uploads, downloads, transmits and/or communicates in
                all Mediums on our Applications. We do not control
                user generated Publicly Submitted Materials,
                communications and Content. It is a condition of
                your use of our Applications that you do not:
                <br />
                <br />
                ● Restrict or inhibit any other user from using and
                enjoying our Applications.
                <br />
                ● Use our Applications to impersonate any person or
                entity, or falsely state or otherwise misrepresent
                your affiliation with a person or entity.
                <br />
                ● Interfere with or disrupt any servers or networks
                used to provide our Applications or its features, or
                disobey any requirements, procedures, policies or
                regulations of the networks we use to provide our
                Applications.
                <br />
                ● Use our Applications to instigate or encourage
                others to commit illegal activities or cause injury
                or property damage to any person.
                <br />
                ● Gain unauthorized access to our Applications, or
                any account, computer system, or network connected
                to our Applications, by means such as hacking,
                password mining or other illicit means.
                <br />
                ● Obtain or attempt to obtain any materials or
                information through any means not intentionally made
                available through our Applications.
                <br />
                ● Use our Applications to post or transmit any
                unlawful, threatening, abusive, libelous,
                defamatory, obscene, vulgar, pornographic, profane
                or indecent information of any kind, including
                without limitation any transmissions constituting or
                encouraging conduct that would constitute a criminal
                offense, give rise to civil liability or otherwise
                violate any local, state, national or international
                law.
                <br />
                ● Use our Applications to post or transmit any
                information, software or other material that
                violates or infringes upon the rights of others,
                including material that is an invasion of privacy or
                publicity rights or that is protected by copyright,
                trademark or other proprietary right, or derivative
                works with respect thereto, without first obtaining
                permission from the owner or rights holder.
                <br />
                ● Use our Applications to post or transmit any
                information, software or other material that
                contains a virus or other harmful component.
                <br />
                ● Use our Applications to post, transmit or in any
                way exploit any information, software or other
                material for commercial purposes, or that contains
                advertising.
                <br />
                ● Use our Applications to advertise or solicit to
                anyone to buy or sell products or services, or to
                make donations of any kind, without our express
                written approval.
                <br />
                ● Gather for marketing purposes any email addresses
                or other personal information that has been posted
                by other users of our Applications.
                <br />
                <br />
                In addition to all other rights reserved and held by
                Everest, any user failing to comply with the terms
                and conditions of this Agreement may be expelled
                from, and refused continued access to, any or all of
                our Applications. Everest, or its designated agents,
                may remove or alter any user-created Content or
                Publicly Submitted Materials at any time in its sole
                discretion. Content posted within these public
                Mediums and Applications may be provided by Everest
                staff, Everest's outside contributors, or by users
                not connected with Everest, some of whom may employ
                anonymous or pseudonymous user names. Everest
                expressly disclaims all responsibility and
                endorsement and makes no representation as to the
                validity of any Content, including, without
                limitation, any opinion, advice, information or
                statement made or displayed in any Mediums by third
                parties, nor are, or shall, we be responsible for
                any errors or omissions in such Content, or for
                hyperlinks embedded in any messages. Under no
                circumstances will we or our Affiliates be liable
                for any loss or damage caused by your reliance on
                Content obtained through our or our Affiliates
                Applications. We do not endorse any Publicly
                Submitted Materials and such Content does not
                reflect the opinions of Everest or any of its
                Affiliates.
                <br />
                <br />
                Everest has no obligation whatsoever to monitor any
                Content or Publicly Submitted Materials on our
                Applications. However, you acknowledge and agree
                that we have the absolute right to monitor Content
                on our Applications, as we deem appropriate in our
                sole discretion. In addition, we reserve the right
                to alter, edit, refuse access to, or remove, any
                Content, in whole or in part, for any reason, or no
                reason, in our sole discretion on our Applications
                and to disclose such materials and the circumstances
                surrounding their transmission to any third party in
                order to satisfy any applicable law, regulation,
                legal process or governmental request and to protect
                ourselves, our clients, sponsors, users and
                visitors.
                <br />
                <br />
                7. Registration. <br />
                To access certain features of our Content and
                Applications, we may ask you to provide certain
                demographic information including, without
                limitation, your full name, gender, year of birth,
                place of birth, physical address, email, telephone
                number, proof of age, fingerprints, facial scan,
                retinal scan and other personal information to help
                you obtain a secure and verifiable digital
                identification (“Private Information”). You agree to
                provide true, accurate, current and complete
                information about yourself as prompted by our
                Applications and any applicable registration and
                information input forms. In addition to all other
                rights and reservations as stated herein, if we have
                reasonable grounds to suspect that such information
                is untrue, inaccurate, or incomplete, we have the
                right to suspend or terminate your account and
                refuse any and all current or future use of our
                Applications (or any portion thereof). Our use of
                any Private Information and personally identifiable
                information you provide to us is governed by the
                terms of our Privacy Policy.
                <br />
                <br />
                8. Dual Registration. <br />
                You agree that any and all information, Publicly
                Submitted Materials and Content provided by you or
                collected by us may be used by us in accordance with
                us and/or our Affiliates in accordance with our
                and/or our Affiliates’ respective Privacy Policies
                and may be shared with our Affiliates. You agree,
                request and authorize us, as a condition, and in
                consideration for, using the Applications, to
                automatically enroll you to receive messages from us
                and our Affiliates. Any information, Content or
                Publicly Submitted Materials provided by you may be
                used by us in accordance with our Privacy Policy,
                which can be viewed at https://www.Everest.com and
                by our Affiliates in accordance with their Privacy
                Policy(s). All Publicly Submitted Materials and
                Content provided by you to us as part of an entry
                becomes our property and will not be acknowledged or
                returned, however, all Personal Information not
                publicly posted and therefore “Private” shall remain
                your property and may only be used as directed by
                you.
                <br />
                <br />
                9. Passwords. <br />
                To use certain features of our Applications, you may
                need a username password, and/or PIN, which you may
                receive through the registration process of our
                Applications. You are responsible for maintaining
                the confidentiality of the PIN, password and
                account, and are responsible for all activities
                (whether by you or by others) that occur under your
                PIN, password or account. You agree to notify us
                immediately of any unauthorized use of your password
                or account or any other breach of security, and to
                ensure that you exit from your account at the end of
                each session. In addition to all other disclaimers
                and limitation of liability in these Terms and
                Conditions, we cannot and will not be liable for any
                loss or damage arising from your failure to protect
                your password or account information.
                <br />
                <br />
                10. Prohibited Use. <br />
                Any commercial or promotional distribution,
                publishing or exploitation of the Applications or
                any Content is strictly prohibited unless you have
                received the express prior written permission from
                authorized personnel of Everest or the otherwise
                applicable rights holder. Other than as expressly
                allowed herein, you may not download, post, display,
                publish, copy, reproduce, distribute, transmit,
                modify, perform, broadcast, transfer, create
                derivative works from, sell or otherwise exploit any
                content, code, data or materials on or available
                through the Applications. You further agree that you
                may not alter, edit, delete, remove, otherwise
                change the meaning or appearance of, or repurpose,
                any of the content, code, data, or other materials
                on or available through the Applications, including,
                without limitation, the alteration or removal of any
                trademarks, trade names, logos, service marks, or
                any other proprietary content or proprietary rights
                notices. You acknowledge that you do not acquire any
                ownership rights by downloading any copyrighted
                material from or through the Applications. If you
                make other use of the Applications, or the content,
                code, data or materials thereon or available through
                the Applications, except as otherwise provided
                above, you may violate copyright and other laws of
                the United States, other countries, as well as
                applicable state laws and may be subject to
                liability for such unauthorized use.
                <br />
                <br />
                11. Prohibited User Conduct. <br />
                You warrant and agree that, while using the
                Applications and the various services and features
                offered on or through the Applications, you shall
                not: (a) impersonate any person or entity or
                misrepresent your affiliation with any other person
                or entity; (b) insert your own or a third party’s
                advertising, branding or other promotional content
                into any of the Application’s content, materials or
                services (for example, without limitation, in an RSS
                feed or a podcast received from Everest or otherwise
                through the Applications), or use, redistribute,
                republish or exploit such content or service for any
                further commercial or promotional purposes; or (c)
                attempt to gain unauthorized access to other
                computer systems through the Applications. You shall
                not: (i) engage in spidering, “screen scraping,”
                “database scraping,” harvesting of e-mail addresses,
                wireless addresses or other contact or personal
                information, or any other automatic means of
                obtaining lists of users or other information from
                or through the Applications or the services offered
                on or through the Applications, including without
                limitation any information residing on any server or
                database connected to the Applications or the
                services offered on or through the Applications;
                (ii) obtain or attempt to obtain unauthorized access
                to computer systems, materials or information
                through any means; (iii) use the Applications or the
                services made available on or through the
                Applications in any manner with the intent to
                interrupt, damage, disable, overburden, or impair
                the Applications or such services, including,
                without limitation, sending mass unsolicited
                messages or “flooding” servers with requests; (iv)
                use the Applications or the Applications’ services
                or features in violation of Everest’s or any third
                party’s intellectual property or other proprietary
                or legal rights; or (v) use the Applications or the
                Applications’ services in violation of any
                applicable law. You further agree that you shall not
                attempt (or encourage or support anyone else’s
                attempt) to circumvent, reverse engineer, decrypt,
                or otherwise alter or interfere with the
                Applications or the Applications’ services, or any
                content thereof, or make any unauthorized use
                thereof. You agree that you shall not use the
                Applications in any manner that could damage,
                disable, overburden, or impair the Applications or
                interfere with any other party's use and enjoyment
                of the Applications or any of its services. You
                shall not obtain or attempt to obtain any materials
                or information through any means not intentionally
                made publicly available or provided for through the
                Applications.
                <br />
                <br />
                12. Public Applications.
                <br />
                Everest, in its sole discretion, from time to time,
                may make Mediums, including, but not limited to,
                social media pages, messaging services, video
                services, chat services, bulletin boards, message
                boards, blogs, other forums and other such services
                available on or through the Applications. These
                Mediums may be made publicly available and we shall
                have no obligation to, or be responsible for,
                monitoring or guaranteeing the accuracy,
                completeness, thoroughness or timeliness of any
                Content or Publicly Submitted Materials, and in no
                manner, shall be deemed to provide any medical,
                financial, personal, professional or other advice or
                recommendations of any kind. You should not rely on
                any information or Content contained in our
                Applications and you should, and hereby agree to,
                consult with your qualified health care, financial,
                personal and other professional advisors to meet
                your individual needs or advise you as to any
                treatment, recommendation or decision. In addition
                to any other rules or regulations that we may post
                in connection with a particular service, you agree
                that you shall not upload, post, transmit,
                distribute or otherwise publish in any Medium,
                through the Applications or any service or feature
                made available on or through the Applications, any
                Content, Publicly Submitted Materials or information
                which (i) restrict or inhibit any other user from
                using and enjoying the Applications or the
                Applications’ services, (ii) are fraudulent,
                unlawful, threatening, abusive, harassing, libelous,
                defamatory, obscene, vulgar, offensive,
                pornographic, profane, sexually explicit or
                indecent, (iii) constitute or encourage conduct that
                would constitute a criminal offense, give rise to
                civil liability or otherwise violate any local,
                state, national or international law, (iv) violate,
                plagiarize or infringe the rights of third parties
                including, without limitation, copyright, trademark,
                trade secret, confidentiality, contract, patent,
                rights of privacy or publicity or any other
                proprietary right, (v) contain a virus, spyware, or
                other harmful component, (vi) contain embedded
                links, advertising, chain letters or pyramid schemes
                of any kind, or (vii) constitute or contain false or
                misleading indications of origin, endorsement or
                statements of fact. You further agree not to
                impersonate any other person or entity, whether
                actual or fictitious, including anyone from Everest.
                You also may not offer to buy or sell any product or
                service on or through your comments submitted to our
                forums. You alone are responsible for the content
                and consequences of any of your activities.
                <br />
                <br />
                13. Right to Monitor, Edit and Control Content.{' '}
                <br />
                Everest reserves the right, but does not have an
                obligation, to monitor and/or review any or all
                Content and Publicly Submitted Materials posted to
                the Applications or through the Applications’
                services or features by users and you agree that
                Everest is not responsible for any such Content or
                Publicly Submitted Materials. However, Everest
                reserves the right at any and all times, in its sole
                discretion, to monitor, edit and/or disclose any
                Content, Publicly Submitted Materials and/or Private
                Information as may be reasonably required to satisfy
                any law, regulation or government request, or to
                edit, refuse to post, delete or to remove any
                information or materials, in whole or in part, that
                in Everest’s sole discretion are objectionable or in
                violation of this Terms of Use, Everest’s policies
                or applicable law. We may also impose limits on
                certain features of the forums or restrict your
                access to part or all of the forums without notice
                or penalty if we believe you are in breach of the
                guidelines set forth in this paragraph, our terms
                and conditions or applicable law, or for any other
                reason without notice of liability
                <br />
                <br />
                14. Private or Sensitive Content on Public
                Applications. <br />
                It is important to remember that Content,
                information and comments submitted to the
                Applications may be recorded and stored in multiple
                places, both on our Applications and elsewhere on
                the Internet, which are likely to be accessible for
                a long time and you have no control over who will
                read them eventually. It is therefore important that
                you are careful and selective about the personal
                information that you disclose about yourself and
                others, and in particular, you should not disclose
                sensitive, proprietary or confidential information
                in your comments to our public forums.
                <br />
                <br />
                15. Linking to the Applications. <br />
                You agree that if you include a link from any other
                web site to the Applications, such link shall open
                in a new browser window and shall link to the full
                version of an HTML formatted page of this
                Applications. You are not permitted to link directly
                to any media hosted on the Applications or our
                services, such as using an “in-line” linking method
                to cause the image hosted by us to be displayed on
                another web site. You agree not to download or use
                media hosted on the Applications on another web
                site, for any purpose, including, without
                limitation, posting such media on another site. You
                agree not to link from any other web site to this
                Applications in any manner such that the
                Applications, or any page of the Applications, is
                “framed,” surrounded or obfuscated by any
                third-party content, materials or branding. We
                reserve all of our rights under the law to insist
                that any link to the Applications be discontinued,
                and to revoke your right to link to the Applications
                from any other web site at any time without written
                notice to you.
                <br />
                <br />
                16. Orders for Products, Goods and Services. <br />
                We may make certain products, goods and services
                available to visitors and registrants of the
                Applications. If you order any products, goods or
                services, you hereby represent and warrant that you
                are 18 years old or older. You agree to pay in full
                the prices for any purchases you make either by
                credit/debit card concurrent with your online order
                or by other payment means acceptable to Everest You
                agree to pay all applicable taxes. If payment is not
                received by us from your credit or debit card issuer
                or its agents, you agree to pay all amounts due upon
                demand by us. Certain products that you purchase
                and/or download on or through the Applications may
                be subject to additional terms and conditions
                presented to you at the time of such purchase or
                download.
                <br />
                <br />
                17. Third Party Applications. <br />
                Upon creation of an account with us, you are
                expressly agreeing to receive information from us
                and from the Everest community, which may include
                our trusted partners and the third parties that help
                our Services run smoothly. You may be able to link
                from the Applications to third party web sites and
                third-party web sites may link to the Applications
                (“Linked Applications”). You acknowledge and agree
                that we have no responsibility for the information,
                content, products, services, advertising, code or
                other materials which may or may not be provided by
                or through Linked Applications, even if they are
                owned or run by affiliates of ours. Links to Linked
                Applications do not constitute an endorsement or
                sponsorship by us of such web sites or the
                information, content, products, services,
                advertising, code or other materials presented on or
                through such web sites. You may be able, or may not
                be able, to integrate or input information from
                other service providers or web sites or sync your
                Everest account with other services that you use,
                however, we are not responsible for the content of
                such information or practices of the other services
                or companies that provide them. The inclusion of any
                link to such sites on our Applications does not
                imply Everest’s endorsement, sponsorship, or
                recommendation of that site. Everest disclaims any
                liability for links (1) from another web site to
                this Applications and (2) to another web site from
                this Applications. Everest cannot guarantee the
                standards of any web site to which links are
                provided on this Applications nor shall Everest be
                held responsible for the contents of such sites, or
                any subsequent links. Everest does not represent or
                warrant that the contents of any third-party web
                site is accurate, compliant with state or federal
                law, or compliant with copyright or other
                intellectual property laws. Also, Everest is not
                responsible for or any form of transmission received
                from any linked web site. Any reliance on the
                contents of a third-party web site is done at your
                own risk and you assume all responsibilities and
                consequences resulting from such reliance.
                <br />
                <br />
                18. We may not be able to foresee or control any
                problems in the service provided by thirdparty
                service providers, and we cannot and do not assume
                any liability or responsibility for the timeliness,
                accuracy, or any failure to store or secure any user
                data or communications or personalized settings that
                you may have with those service providers. Any
                account information shown through Everest is as
                up-to-date as we are provided, and may not reflect
                your most recent transactions, even if they are
                available to you directly from those third-party
                service providers. By using the Service, you
                expressly relieve us from any and all liability
                arising from your use of or communication with any
                third party that you may encounter as a result of
                the Services. Everest may, from time to time, voice
                an opinion or make recommendations of third party
                products to its user community. Everest may make
                recommendations of sponsored products. The companies
                that own and operate any recommended products are
                the issuers of such products and are fully
                responsible for their functionality. Everest does
                not make any guarantee or warranty over those
                products. If you have any problems or issues arising
                from those products, please contact the responsible
                third-party company directly. You are subject to
                those third parties’ terms and conditions and
                privacy policies.
                <br />
                <br />
                19. Electronic Communications and Mobile Application
                License. <br />
                The communications between you and Everest use
                electronic means, whether you use our Applications,
                send us messages or emails; or whether Everest
                communicates with you via its Applications or those
                of others in any and all electronic communication
                mediums. You hereby consent to receive
                communications from Everest in an electronic form,
                and agree that all terms, conditions, agreements,
                notices, disclosures, and other communications that
                Everest provides to you electronically satisfy any
                legal requirement that such communications would
                satisfy as if it were in hard copy. The foregoing
                does not affect your non-waivable rights. You
                understand that you may be required to install
                certain software or have Internet access, a
                computer, a Smart Phone or other equipment and
                software in order for our Services to be available
                to you. You are responsible for these requirements
                and any charges required by any provider of
                services, software, licenses and equipment,
                including, but not limited to your mobile device
                service provider, your Internet service provider and
                any providers of required software and applications.
                Subject to these Terms, Everest grants you a
                personal, non-exclusive, non-transferable,
                non-sublicenseable, limited, revocable license to
                use the Applications for the devices and software
                which you legally own, control, have rightful
                possession and are licensed to use. You agree that
                the Applications will be used for personal purposes
                only. You also agree that you regarding any mobile
                applications provided, introduced or referred by
                Everest (“Apps”) and utilized by you, you will only
                use the Apps provided from a mobile applications
                store, where available, by Apple Inc., Google Play
                Store, carrier or manufacturer-specific Android
                marketplaces, or other approved mobile applications
                stores, and as permitted by the “Usage Rules” set
                forth by such stores’ terms of service. The mobile
                application stores are solely responsible for
                availability of the Apps. You acknowledge that these
                application stores and their affiliates are
                third-party beneficiaries of these Terms and shall
                have the right to enforce them. If you choose to
                receive “push” notifications from the Apps, you may
                receive communications via the Apps, on your
                smartphone. By downloading the Apps, you consent to
                receive these communications, and consent to
                Everest’s access to certain information from your
                smartphone or other connected mobile devices. If at
                any time you would like to change the way you
                receive notifications, or the information your share
                through Everest, you may do so through the Apps by
                changing your notification preferences. For
                information regarding the information we access
                through the Apps, please see our Privacy Policy.
                <br />
                <br />
                20. Trademarks. <br />
                The trademarks, logos, service marks and trade names
                (collectively the "Trademarks") displayed on the
                Applications or on Content available through the
                Applications are registered and unregistered
                Trademarks of Everest and others and may not be used
                in connection with products and/or services that are
                not related to, associated with, or sponsored by
                their rights holders that are likely to cause
                customer confusion, or in any manner that disparages
                or discredits their rights holders. All Trademarks
                not owned by Everest that appear on the Applications
                or on or through the Applications’ services, if any,
                are the property of their respective owners. Nothing
                contained on the Applications should be construed as
                granting, by implication, estoppel, or otherwise,
                any license or right to use any Trademark displayed
                on the Applications without the written permission
                of Everest or the third party that may own the
                applicable Trademark. Your misuse of the Trademarks
                displayed on the Applications or on or through any
                of the Applications’ services is strictly
                prohibited.
                <br />
                <br />
                21. Copyright Agent. <br />
                We respect the intellectual property rights of
                others, and require that the people who use the
                Applications, or the services or features made
                available on or through the Applications, do the
                same. If you believe that your work has been copied
                in a way that constitutes copyright infringement,
                please forward the following information to
                Everest’s Copyright Agent, designated as such
                pursuant to the Digital Millennium Copyright Act, 17
                U.S.C. § 512(c)(2), named below:
                <br />
                <br />
                ● Your address, telephone number, and email address;
                <br />
                ● A description of the copyrighted work that you
                claim has been infringed;
                <br />
                ● A description of where the alleged infringing
                material is located;
                <br />
                ● A statement by you that you have a good faith
                belief that the disputed use is not authorized by
                the copyright owner, its agent, or the law;
                <br />
                ● An electronic or physical signature of the person
                authorized to act on behalf of the owner of the
                copyright interest; and
                <br />
                ● A statement by you, made under penalty of perjury,
                that the above information in your notice is
                accurate and that you are the copyright owner or are
                authorized to act on the copyright owner's behalf.
                <br />
                <br />
                For Everest’s Copyright Agent please contact:
                contact@Everest.org with the phrase “Copyright
                Agent” in the subject line.
                <br />
                <br />
                22. Authorization for Charges and Recurring
                Payments. <br />
                You are hereby voluntarily subscribing and/or paying
                for goods, products and/or services of Everest and
                are hereby authorizing us to charge your credit card
                or debit card, as inputted by you, in the Amount and
                for the Term (e.g., one time, weekly, monthly or
                annually) you agreed to. At the end of each Term,
                you authorize the recurring subscription or
                continuity payments on your credit card in the
                Amount you authorized on your subscription purchase
                form. We may change the fees in effect at our
                discretion from time to time, but will give you
                advance notice of these changes via a message to the
                email address associated with your account prior to
                renewal.
                <br />
                <br />
                23. Payment Methods and Billing Cycle. <br />
                In the event that you decide to purchase a
                membership, goods or services on a recurring billing
                cycle on Everest Applications or third-party
                Applications linked to, or sold through, Everest,
                you will be billed on the agreed billing cycle in
                the agreed amount. Updating Billing Information. You
                can add, edit, or delete payment information for
                your purchases by contacting contact@Everest.org
                <br />
                <br />
                24. Free Trials & Introductory Offers. <br />
                If you subscribed to receive goods or services on a
                free trial or introductory offer basis, you
                authorize your credit card to be charged at the end
                of the free trial or introductory Term pursuant to
                the terms, conditions and price of the offer. The
                card you used to sign up for a free trial or
                introductory unless you cancel your subscription one
                day prior to the auto-renewal.
                <br />
                <br />
                25. Unauthorized or Unrecognized Charges. <br />
                If you see a credit card charge that you don’t
                recognize but it's from Everest, you may take the
                following steps:
                <br />
                <br />
                ● You can review past charges and print receipts or
                invoices from your billing history page.
                <br />
                ● After reviewing your subscription and payment
                information, if you feel that you have unauthorized
                or unrecognized charges, please contact us at [email
                address] and provide us with your name, address,
                phone number, account number, date of transaction,
                amount of transaction, credit number and transaction
                number and we will investigate your claim.
                <br />
                ● We do not offer refunds, but if you have been
                charged and believe your situation warrants an
                exception, and our review is in agreement with your
                assessment, we will credit back the charges.
                <br />
                26. Canceling Your Subscription and Customer Refund
                Policy. <br />
                <br />
                You can end your subscription at any time, however,
                we do not provide refunds on any fees or charges due
                to cancellation, including partially used periods
                and bank/merchant fees. However, under certain
                circumstances, such as trial or introductory offers,
                we will provide refunds within the time frame of the
                offer. Refunds are not issued after 30 days of
                purchase. Refunds will be only issued if required by
                law or if you contact us pursuant to, and fulfill,
                our cancellation and refund policy:
                <br />
                <br />
                If you sign up for a free trial and cancel, you
                won't be eligible to sign up for another free trial
                for at least 12 months.
                <br />
                To cancel your Premium subscription contact
                contact@Everest.org with the word “Cancel” in the
                subject line.
                <br />
                <br />
                27. Turning off auto-renewal. <br />
                If you currently have a paid subscription and you
                want to make sure you're not charged at the end of
                your term, you'll need to turn off your
                auto-renewal. To do this, please visit your billing
                information page and send an email to
                contact@Everest.org with the word “Cancel” in the
                subject line and in the body, provide description of
                the auto-renewal charge, the transaction number, the
                date and the amount.
                <br />
                <br />
                28. DISCLAIMER OF WARRANTIES. <br />
                We strive to provide great Services, Products and
                Goods, but there are certain things that we can't
                guarantee. TO THE FULLEST EXTENT PERMITTED BY LAW,
                WE, AND OUR AFFILIATES, SUPPLIERS AND DISTRIBUTORS
                MAKE NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT
                THE SERVICES, THE RESULTS OR YOUR SATISFACTION. THE
                SERVICES ARE PROVIDED "AS IS." WE ALSO DISCLAIM ANY
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE AND NON-INFRINGEMENT. THE USE OF
                THE DEFINED TERMS, INCLUDING BUT NOT LIMITED TO, THE
                TERMS “APPLICATIONS,” “CONTENT,” “PUBLICLY SUBMITTED
                MATERIALS” AND “MEDIUMS” USED THROUGHOUT ANY
                CAPITALIZED SECTIONS SHALL HAVE THE MEANINGS DEFINED
                IN THIS AGREEMENT. ALL APPLICATIONS, CONTENT AND
                MATERIALS IN ALL MEDIUMS AND, WITHOUT LIMITATION,
                ANY AND ALL SERVICES, PRODUCTS, GOODS, FEATURES,
                FUNCTIONS OR MATERIALS PROVIDED BY EVEREST "AS IS,"
                "AS AVAILABLE," WITHOUT WARRANTY OF ANY KIND, EITHER
                EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
                ANY WARRANTY FOR INFORMATION, DATA, DATA PROCESSING
                SERVICES, UPTIME OR UNINTERRUPTED ACCESS, ANY
                WARRANTIES CONCERNING THE AVAILABILITY, PLAYABILITY,
                DISPLAYABILITY, ACCURACY, PRECISION, CORRECTNESS,
                THOROUGHNESS, COMPLETENESS, USEFULNESS, OR CONTENT
                OF INFORMATION, AND ANY WARRANTIES OF TITLE,
                NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A
                PARTICULAR PURPOSE, AND WE HEREBY DISCLAIM ANY AND
                ALL SUCH WARRANTIES, EXPRESS AND IMPLIED. WE DO NOT
                WARRANT THAT THE APPLICATIONS, CONTENT, MATERIALS,
                AND WITHOUT LIMITATION, THE SERVICES, PRODUCTS,
                GOODS, FEATURES, FUNCTIONS OR MATERIALS PROVIDED
                THROUGH THE APPLICATIONS WILL BE TIMELY, SECURE,
                UNINTERRUPTED OR ERROR FREE, OR THAT DEFECTS WILL BE
                CORRECTED. WE MAKE NO WARRANTY THAT THE Everest
                Terms & Conditions v1, October 2018 Page 15
                APPLICATIONS OR THE PROVIDED SERVICES WILL MEET
                USERS’ REQUIREMENTS. NO ADVICE, RESULTS OR
                INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY
                YOU FROM US OR THROUGH THE APPLICATIONS SHALL CREATE
                ANY WARRANTY NOT EXPRESSLY MADE HEREIN. WE ALSO
                ASSUME NO RESPONSIBILITY, AND SHALL NOT BE LIABLE
                FOR, ANY DAMAGES TO, OR VIRUSES THAT MAY INFECT,
                YOUR EQUIPMENT ON ACCOUNT OF YOUR ACCESS TO, USE OF,
                OR BROWSING IN THE APPLICATIONS OR YOUR DOWNLOADING
                OF ANY CONTENT FROM THE APPLICATIONS. IF YOU ARE
                DISSATISFIED WITH THE APPLICATIONS, YOUR SOLE REMEDY
                IS TO DISCONTINUE USING THE APPLICATIONS. WE RESERVE
                ALL RIGHTS, IN OUR SOLE DISCRETION, WITHOUT WAIVER
                FOR DELAY OR NONEXERCISE OF SUCH RIGHTS, TO CANCEL,
                BLOCK, FILTER, MODIFY AND/OR TERMINATE YOUR USE OF
                ANY OR ALL OF OUR APPLICATIONS.
                <br />
                <br />
                TO THE EXTENT THAT WE OWN OR CONTROL THE CONTENT, WE
                TRY TO ENSURE, WITHOUT ANY OBLIGATION OR GUARANTEE
                ON OUR PART, THAT THE CONTENT POSTED ON THE
                APPLICATIONS IS CORRECT AND UP-TO-DATE. WE RESERVE
                THE RIGHT TO CHANGE OR MAKE CORRECTIONS TO ANY OF
                THE CONTENT PROVIDED ON THE APPLICATIONS AT ANY TIME
                AND WITHOUT ANY PRIOR WARNING. WE NEITHER ENDORSE
                NOR ARE RESPONSIBLE FOR THE ACCURACY OR RELIABILITY
                OF ANY CONTENT OR PUBLICLY SUBMITTED MATERIALS,
                OPINIONS, ADVICE OR STATEMENTS ON THE APPLICATIONS,
                NOR FOR ANY OFFENSIVE, DEFAMATORY, OBSCENE,
                INDECENT, UNLAWFUL OR INFRINGING CONTENT OR
                STATEMENTS MADE THEREON BY ANYONE OTHER THAN OUR
                AUTHORIZED EMPLOYEES AND SPOKESPERSONS WHILE ACTING
                IN THEIR OFFICIAL CAPACITIES PURSUANT TO OUR
                POLICIES, PROCEDURES AND STANDARDS. IT IS YOUR
                RESPONSIBILITY TO EVALUATE THE ACCURACY,
                COMPLETENESS OR USEFULNESS OF ANY CONTENT, PUBLICLY
                SUBMITTED MATERIALS, INFORMATION, OPINIONS OR ADVICE
                AVAILABLE THROUGH THE APPLICATIONS. YOU ARE ADVISED
                BY US, AND YOU AGREE TO, SEEK THE ADVICE OF
                PROFESSIONALS, AS APPROPRIATE, REGARDING THE
                EVALUATION OF ANY SPECIFIC CONTENT, PUBLICLY
                SUBMITTED MATERIALS, INFORMATION, OPINION OR ADVICE
                INCLUDING BUT NOT LIMITED TO THOSE RELATED TO YOUR
                HEALTH, FINANCIAL, PERSONAL AND/OR LIFESTYLE
                CONCERNS.
                <br />
                <br />
                PRIOR TO THE EXECUTION OF A PURCHASE OR SALE OF ANY
                SECURITY OR INVESTMENT, YOU ARE ADVISED TO CONSULT
                WITH YOUR BROKER OR OTHER FINANCIAL ADVISOR TO
                VERIFY PRICING AND OTHER INFORMATION. WE SHALL HAVE
                NO LIABILITY FOR INVESTMENT DECISIONS BASED UPON, OR
                THE RESULTS OBTAINED FROM, THE CONTENT PROVIDED
                HEREIN. NOTHING CONTAINED IN THE APPLICATIONS SHALL
                BE CONSTRUED AS INVESTMENT ADVICE. WE ARE NOT A
                REGISTERED BROKER-DEALER OR INVESTMENT ADVISOR AND
                DOES NOT GIVE INVESTMENT ADVICE OR RECOMMEND ONE
                PRODUCT OVER ANOTHER. Everest Terms & Conditions v1,
                October 2018 Page 16 WE CANNOT AND DO NOT GUARANTEE
                ANY CHANGE, POSITIVE OR OTHERWISE, IN YOUR FINANCIAL
                STATUS. WE DO NOT CONTROL ANY OF THE ENTITIES WE
                WORK WITH OTHER THAN THOSE THAT WE OWN AND OPERATE.
                SHOULD THE SERVICE, IN ALL OF ITS FORMS PROVIDED BY
                EVEREST, PROVE DEFECTIVE AND/OR CAUSE ANY DAMAGE TO
                YOUR COMPUTER OR INCONVENIENCE TO YOU; YOU, AND NOT
                EVEREST, SHALL ASSUME THE ENTIRE COST AND ALL
                DAMAGES WHICH MAY RESULT DIRECTLY AND INDIRECTLY
                FROM ANY AND ALL SUCH DEFECTS EVEREST USES DATA AND
                INFORMATION PROVIDED TO US BY THIRD PARTIES IN ORDER
                TO COMPILE CERTAIN PORTIONS OF YOUR PROFILE AND TO
                PROVIDE CUSTOMIZATION. AS SUCH, EVEREST RELIES ON
                THE PROVIDERS OF THIS INFORMATION FOR ITS ACCURACY.
                EVEREST MAKES NO WARRANTY THAT THE SERVICE WILL MEET
                YOUR REQUIREMENTS,
                <br />
                <br />
                WITHOUT LIMITATION OF THE ABOVE IN THIS SECTION, WE
                AND OUR AFFILIATES, SUPPLIERS AND LICENSORS MAKE NO
                WARRANTIES OR REPRESENTATIONS REGARDING ANY CONTENT,
                PRODUCTS, GOODS OR SERVICES ORDERED OR PROVIDED VIA
                THE APPLICATIONS, AND HEREBY DISCLAIM, AND YOU
                HEREBY WAIVE, ANY AND ALL WARRANTIES AND
                REPRESENTATIONS MADE IN PRODUCT OR SERVICES
                LITERATURE, FREQUENTLY ASKED QUESTIONS DOCUMENTS AND
                OTHERWISE ON THE APPLICATIONS OR IN CORRESPONDENCE
                WITH US OR ITS AGENTS. ANY PRODUCTS AND SERVICES
                ORDERED OR PROVIDED VIA THE APPLICATIONS ARE
                PROVIDED BY US “AS IS,” EXCEPT TO THE EXTENT, IF AT
                ALL, OTHERWISE SET FORTH IN A LICENSE OR SALE
                AGREEMENT SEPARATELY ENTERED INTO IN WRITING BETWEEN
                YOU AND US AND OUR AFFILIATES.
                <br />
                <br />
                29. LIMITATION OF LIABILITY. <br />
                TO THE FULLEST EXTENT ALLOWED BY LAW, IN NO EVENT
                SHALL WE AND OUR AFFILIATES BE LIABLE FOR: (1) ANY
                INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY
                OR CONSEQUENTIAL DAMAGES, OR (2) ANY LOSS OF USE,
                DATA, BUSINESS, REPUTATION, EMOTIONAL DISTRESS, OR
                PROFITS, REGARDLESS OF LEGAL THEORY INCLUDING,
                WITHOUT LIMITATION, ANY RESULTS ARISING FROM, OR
                RELATED TO, THE DIRECT OR INDIRECT USE OF, OR THE
                INABILITY TO USE, OR THE RESULTS OF USING OR
                INABILITY TO USE, THE APPLICATIONS AND/OR THE
                CONTENT, PUBLICLY SUBMITTED MATERIALS AND FUNCTIONS
                RELATED THERETO, OR THE USE OF, OR INABILITY TO USE,
                YOUR PERSONAL INFORMATION, YOUR PROVISION OF
                INFORMATION VIA THE APPLICATIONS. THE AVAILABILITY
                OR ACCURACY OF CONTENT OR MATERIALS. IT IS
                UNDERSTOOD AND AGREED THAT ALL LIMITATIONS OF
                LIABILITY AND DISCLAIMERS STATED HEREIN AND
                THROUGHOUT THIS AGREEMENT SHALL BE VALID AND BINDING
                UPON YOU, EVEN IF WE, OUR AFFILIATES AND/OR ANY
                PROTECTED PARTIES HAVE BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES. THESE EXCLUSIONS OR
                LIMITATIONS WILL APPLY REGARDLESS OF WHETHER OR NOT
                WE OR ANY OF OUR AFFILIATES OR OTHER PROTECTED
                PARTIES HAVE BEEN WARNED OF THE Everest Terms &
                Conditions v1, October 2018 Page 17 POSSIBILITY OF
                SUCH DAMAGES. WE, OUR AFFILIATES AND OTHER PROTECTED
                PARTIES HEREIN, AREN'T RESPONSIBLE FOR YOUR USE OF
                THE APPLICATIONS AND/OR THE CONDUCT, PUBLICLY
                SUBMITTED MATERIALS OR CONTENT OF YOU OR OTHERS IN
                ANY EVENT. SOME JURISDICTIONS DO NOT ALLOW THE
                LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL
                OR CONSEQUENTIAL DAMAGES SO SOME OF THE ABOVE
                LIMITATIONS MAY NOT APPLY TO CERTAIN USERS. IN NO
                EVENT SHALL THE PROTECTED ENTITIES BE LIABLE FOR, OR
                IN CONNECTION WITH, ANY USE OF THE APPLICATIONS OR
                CONTENT POSTED, TRANSMITTED, EXCHANGED OR RECEIVED
                IN ANY AND ALL MEDIUMS BY OR ON BEHALF OF ANY USER
                OR OTHER PERSON ON OR THROUGH THE APPLICATIONS. IN
                NO EVENT SHALL THE TOTAL AGGREGATE LIABILITY OF THE
                PROTECTED ENTITIES TO YOU FOR ALL DAMAGES, LOSSES,
                AND CAUSES OF ACTION (WHETHER IN CONTRACT OR TORT,
                INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE OR
                OTHERWISE) ARISING FROM THE TERMS AND CONDITIONS OR
                YOUR USE OF THE APPLICATIONS AND CONTENT EXCEED, IN
                THE AGGREGATE, THE AMOUNT, IF ANY, PAID BY YOU TO US
                FOR YOUR USE OF THE APPLICATIONS OR PURCHASE OF
                PRODUCTS VIA THE APPLICATIONS.
                <br />
                <br />
                30. Disclaimer of No Professional Services or
                Advice. <br />
                Everest is providing Applications to allow users to
                obtain, store, manage and verify the identity and
                general, non-specific, nontargeted information and
                tools for financial education and efficiency. Every
                person’s financial status is different, and a
                variety of factors can affect any decision or
                procedure for a particular financial situation. We
                do not offer and advice, educational information or
                content addressed at the specific or unique scope of
                any user’s personal, health, financial and/or other
                status. Each user is advised to seek the advice of
                their own advisors. The content of the Applications,
                (including without limitation, processes, text,
                copy, audio, video, photographs, illustrations,
                graphics and other visuals), are for informational
                purposes only and are not intended as, and do not
                constitute any professional or personal advice
                including, but not limited to, professional
                financial, securities, estate, business or legal
                advice. NEITHER EVEREST, INC., OR ANY OF ITS
                DIRECTORS, OFFICERS, EMPLOYEES, OR AGENTS IS A
                FINANCIAL PLANNER, BROKER, OR TAX ADVISOR. NONE OF
                THE SERVICES PROVIDED THROUGH EVEREST ARE INTENDED
                TO PROVIDE ANY FINANCIAL, TAX, LEGAL OR OTHER
                PROFESSIONAL ADVICE. You are advised to always seek
                the advice of your qualified professional advisors,
                with any questions or concerns you may have
                regarding your individual needs and any financial,
                medical, relationship, health and estate matters.
                Everest and its Affiliates do not recommend or
                endorse any specific systems, content, professional
                advisors, products, procedures, opinions or other
                information that may be included on the
                Applications. Reliance on any information appearing
                on the Applications, whether provided by Everest or
                its Affiliates is solely at your own risk. Although
                we will take all commercially reasonable steps to
                protect your information as set forth in our Privacy
                Policy and as required by law, and accordingly
                Everest advises you not to post or provide
                information that wish to ensure remains private.
                <br />
                <br />
                31. Disclaimer of Responsibility and Endorsement of
                Third Party Applications. <br />
                Our Applications may provide links and pointers to
                other Applications maintained by third parties. Our
                linking to such third-party Applications does not
                imply an endorsement or sponsorship of such sites,
                or the information, products or services offered on
                or through the sites. In addition, neither we nor
                our Affiliates operate or control in any respect any
                information, products or services that third parties
                may provide on or through the Applications linked to
                by us through our Applications. If applicable, any
                opinions, advice, statements, services, offers, or
                other information or content expressed or made
                available by third parties, including information
                providers, are those of the respective authors or
                distributors, and not Everest. Neither Everest nor
                any third-party provider of information guarantees
                the accuracy, completeness, or usefulness of any
                content. Furthermore, Everest neither endorses nor
                is responsible for the accuracy and reliability of
                any opinion, advice, or statement made on any of our
                Applications by anyone other than an authorized
                Everest representative while acting in his/her
                official capacity.
                <br />
                <br />
                32. Indemnification. <br />
                You agree to defend, indemnify and hold Everest and
                Everest’s and its affiliates’ directors, officers,
                employees and agents harmless from any and all
                claims, liabilities, costs and expenses, including
                reasonable attorneys' fees, arising in any way from
                your use of the Applications, your placement or
                transmission of any message, content, information,
                software or other materials through the
                Applications, or your breach or violation of the law
                or of these Terms and Conditions. Everest reserves
                the right, at its own expense, to assume the
                exclusive defense and control of any matter
                otherwise subject to indemnification by you, and in
                such case, you agree to cooperate with Everest’s
                defense of such claim, provided, however, that you
                shall remain liable for any such claim. Everest
                shall have the right to immediately terminate or
                suspend any of your passwords, accounts or
                subscriptions in the event we consider, in our sole
                discretion, any of your conduct to be unacceptable,
                or in the event you breach this agreement. the terms
                and conditions contained this agreement will survive
                termination of Service and use of the Applications.
                <br />
                <br />
                33. Resolving Disputes. <br />
                We want to address your concerns without needing a
                formal legal case. Before filing a claim against us,
                you agree to try to resolve the dispute informally
                by contacting us at contact@Everest.org with the
                word “Dispute” in the subject line we'll try to
                resolve the dispute informally by contacting you via
                email. If a dispute is not resolved within 15 days
                of submission, you or we may bring a formal
                proceeding.
                <br />
                <br />
                ● Judicial forum for disputes. You and we agree that
                any judicial proceeding to resolve claims relating
                to these Terms or the Services will be brought in
                the federal or state courts of Delaware, subject to
                the mandatory arbitration provisions below. The
                parties consent to venue and personal jurisdiction
                in such courts. If you reside in a country (for
                example, European Union member states) with laws
                that give consumers the right to bring disputes in
                their local courts, this paragraph doesn't affect
                those requirements.
                <br />
                <br />
                34. IF YOU'RE A U.S. RESIDENT, YOU ALSO AGREE TO THE
                FOLLOWING MANDATORY ARBITRATION PROVISIONS:
                <br />
                <br />
                ● We Both Agree to Arbitrate. You and we agree to
                resolve any claims relating to these Terms or the
                Services through final and binding arbitration by a
                single arbitrator, except as set forth under
                Exceptions to Agreement to Arbitrate below. This
                includes disputes arising out of or relating to
                interpretation or application of this "Mandatory
                Arbitration Provisions" section, including its
                enforceability, revocability, or validity.
                <br />
                ● Arbitration Procedures. The American Arbitration
                Association (AAA) will administer the arbitration
                under its Commercial Arbitration Rules and the
                Supplementary Procedures for Consumer Related
                Disputes. The arbitration will be held in the United
                States county where you live or work, San Diego, CA,
                or any other location we agree to.
                <br />
                ● Arbitration Fees and Incentives. The AAA rules
                will govern payment of all arbitration fees. We will
                pay all arbitration fees for individual arbitration
                for claims less than $75,000. If you receive an
                arbitration award that is more favorable than any
                offer we make to resolve the claim, we will pay you
                $1,000 in addition to the award. Neither party will
                not seek its attorneys' fees and costs in
                arbitration unless the arbitrator determines that a
                claim is frivolous.
                <br />
                ● Exceptions to Agreement to Arbitrate. The parties
                may assert claims, if they qualify, in small claims
                court in San Diego, CA. Either party may bring a
                lawsuit solely for injunctive relief to stop
                unauthorized use or abuse of the Services, or
                intellectual property infringement (for example,
                trademark, trade secret, copyright, or patent
                rights) without first engaging in arbitration or the
                informal dispute-resolution process described above.
                If the agreement to arbitrate is found not to apply
                to you or your claim, you agree to the exclusive
                jurisdiction of the state and federal courts in San
                Diego, CA to resolve your claim.
                <br />
                ● NO CLASS ACTIONS. You may only resolve disputes
                with us on an individual basis, and may not bring a
                claim as a plaintiff or a class member in a class,
                consolidated, or representative action. Class
                arbitrations, class actions, private attorney
                general actions, and consolidation with other
                arbitrations aren't allowed. If this specific
                paragraph is held unenforceable, then the entirety
                of this "Mandatory Arbitration Provisions" section
                will be deemed void.
                <br />
                <br />
                35. Modifications. <br />
                Everest may revise these Terms from time to time and
                in our sole discretion, without prior notice to you.
                All changes are effective immediately when we post
                them to the Services, and apply to all access and
                use of the Services thereafter. Your continued use
                of the Services following the posting of revised
                Terms of Service means that you accept and agree to
                the changes. You should revisit this page from time
                to time so that you are aware of any changes, as
                they are binding on you. If you do not agree to any
                changes we make to these terms, please discontinue
                use of the Services immediately. Continued use after
                notice of the changes will constitute acceptance of
                the terms in full. Finally, you may not modify these
                terms, in whole or in part. Any modification by you
                will be void and shall have no effect. We may change
                or discontinue the Services at any time without
                prior notice, and we reserve the right to terminate
                this Agreement at our election and for any reason,
                without prior notice. If you don't agree to the
                updates we make, please cancel your account before
                they become effective. Where applicable, we'll offer
                you a prorated refund (if applicable) based on the
                amounts you have prepaid for Services and your
                account cancellation date. By continuing to use or
                access the Services after the updates come into
                effect, you agree to be bound by the revised Terms
                and Conditions.
                <br />
                <br />
                36. Termination. <br />
                You're free to stop using our Services at any time.
                Everest may terminate, change, suspend or
                discontinue any aspect of the Applications or the
                Applications’ services at any time. Everest may
                restrict, suspend or terminate your access to the
                Applications and/or its services if we, in our sole
                discretion, believe you are in breach of our terms
                and conditions or applicable law, or for any other
                reason without notice or liability. Everest
                maintains a policy that provides for the termination
                in appropriate circumstances of the Applications use
                privileges of users who are repeat infringers of
                intellectual property rights. if, in our sole
                discretion, we deem any of your activities, behavior
                content or Publicly Submitted Materials
                inappropriate, harmful or dangerous, we reserve all
                rights to, among other things, terminate your
                account, obtain injunctive relief and seek damages.
                <br />
                <br />
                Depending upon the circumstances, in our discretion,
                we may provide you with reasonable advance notice
                via the messaging address associated with your
                account to remedy the activity that prompted us to
                contact you. If after such notice, you fail to take
                the steps we ask of you, we'll terminate or suspend
                your access to the Services.
                <br />
                <br />
                We may, in our discretion, decide to discontinue
                offering goods and services in response to
                unforeseen circumstances beyond our control or to
                comply with a legal requirement. If we discontinue
                offering the goods and services to which your
                subscribed before the end of any fixed or minimum
                term you have paid us for, we'll refund the portion
                of the fees you have pre-paid but for which you
                haven't yet received goods or services.
                <br />
                <br />
                In the event of termination of services, we have the
                right, but not obligation, to maintain all data and
                information to ensure that terminated users and
                infringers can be identified for maintaining the
                integrity, efficiency of the security of our
                Applications.
                <br />
                <br />
                37. Changes to Terms of Use. <br />
                Everest reserves the right, at its sole discretion,
                to change, modify, add or remove any portion of the
                Terms and Conditions, in whole or in part, at any
                time. Changes in the Terms and Conditions will be
                effective when posted. Your continued use of the
                Applications and/or the services made available on
                or through the Applications after any changes to the
                Terms and Conditions are posted will be considered
                acceptance of those changes.
                <br />
                <br />
                38. Photosensitive Seizures. <br />A very small
                percentage of people may experience a seizure when
                exposed to certain visual images, such as flashing
                lights or patterns that may appear in video games or
                other electronic or online content. Even people who
                have no history of seizures or epilepsy may have an
                undiagnosed condition that can cause these
                “photosensitive epileptic seizures” while watching
                video games or other electronic content. These
                seizures have a variety of symptoms, including
                lightheadedness, disorientation, confusion,
                momentary loss of awareness, eye or face twitching,
                altered vision or jerking or shaking of arms or
                legs. If you experience any of the foregoing
                symptoms, or if you or your family has a history of
                seizures or epilepsy, you should immediately stop
                using the Applications and consult a doctor.
                <br />
                <br />
                39. Waiver, Severability & Assignment. <br />
                Our failure to enforce a provision or right is not a
                waiver of its right to do so later. If a provision
                is found unenforceable, the remaining provisions of
                the Terms and Conditions will remain in full effect
                and an enforceable term will be substituted
                reflecting our intent as closely as possible. You
                may not assign any of your rights under these Terms
                and Conditions, and any such attempt will be void.
                We may assign its rights to any of its affiliates or
                subsidiaries, or to any successor in interest of any
                business associated with the Services. Waiver by you
                of any breach or default or failure to exercise any
                right allowed under this Agreement constitutes a
                waiver of any prior breach or default, and a waiver
                or forfeiture of any similar or future rights under
                this Agreement.
                <br />
                <br />
                40. Headings. <br />
                The headings provided herein are provided for
                convenience and are not intended to, nor shall, have
                any binding legal effect.
                <br />
                <br />
                41. Applicable Laws. <br />
                We control and operate the Applications from our
                offices in the United States of America. We do not
                represent that materials on the Applications are
                appropriate or available for use in other locations.
                Persons who choose to access the Applications from
                other locations do so on their own initiative, and
                are responsible for compliance with local laws, if
                and to the extent local laws are applicable. All
                parties to these terms and conditions waive their
                respective rights to a trial by jury.
                <br />
                <br />
                42. Controlling Law. <br />
                These Terms are entered into in the State of
                Delaware and will be governed by Delaware law and
                all disputes resolved in said venue. However, some
                countries (including those in the European Union)
                have laws that require agreements to be governed by
                the local laws of the consumer's country. This
                paragraph doesn't override those laws.
                <br />
                <br />
                43. You confirm that:
                <br />
                ● You are acting in your own capacity and not on
                behalf of a person or arrangement.
                <br />
                ● You are not listed in any financial or criminal
                sanction.
                <br />
                ● You will make available any documents or
                information as and when requested to help establish
                and confirm your identity.
                <br />
                <br />
                44. Entire Agreement. <br />
                These Terms constitute the entire agreement between
                the parties with respect to the subject matter of
                these Terms, and supersede and replace any other
                prior or contemporaneous agreements, or terms and
                conditions applicable to the subject matter of these
                Terms. These Terms create no third-party beneficiary
                rights.
            </Typography>
            <br />
            <Typography variant="h5" mt={2} mb={2}>
                Everrest<sup>TM</sup> Network Limited Privacy Policy
            </Typography>
            <Typography variant="body1">
                Thank you for visiting Everest (“Everest,” “we,”
                “us” or “our”), and using our applications and
                services including, but not limited to, our
                blockchain platform, websites, mobile device
                applications, tools, services, products, content,
                technologies, APIs, processes and information
                including, without limitation, web sites (including
                any subdomains thereof), message boards, forums, web
                logs, chat rooms, personal chat, video chat, email,
                videos, articles, search engines and advertisements,
                (“Applications”). Your privacy is important to us.
                To better protect your privacy, we provide this
                notice explaining our information practices and the
                choices you can make about the way your information
                is collected and used by Everest. Our relationship
                with you, your Personal Information and Content you
                provide, as well as our Privacy Policy is governed
                by our Terms and Conditions of Use.
                <br />
                <br />
                1. The Information We Collect
                <br />
                a. It is our intention to use the information and
                Content you provide us to help support you in
                increasing your access to digital technologies and
                financial opportunities In order get the most out of
                our Applications and to engage in activities and
                offerings we provide, you may be required to provide
                us Personal Information including your name;
                biometric identity data (e.g., fingerprints, retina
                and other data allowing for secure verification of
                your identity); birthdate; place of birth; mailing
                address; billing address; email address; telephone
                number(s); credit card information; government
                issued identification information, financial
                information, occupation; gender; age; weight;
                height; relationship status, preferences and goals;
                your interests; your image and your likeness;
                activities; calendar; tastes; opinions; contacts;
                beliefs; photos; videos; links to websites; blogs;
                posts; social media profiles; as well as your
                resources and challenges, companies with whom you do
                business and interact, and such other information
                which you may share with Everest, (“Personal
                Information”). As well, your Personal Information
                may be required for you to use our Applications to
                do such things as (i) receive products, goods and
                services using our Applications, (ii) receive
                payments and engage in exchange transactions; and/or
                (iii) enter into contests, sweepstakes or other
                activities that provide you with an opportunity to
                win prizes, receive discounts, recognition and/or
                value. Personal Information will be accessible and
                viewable by Everest and whenever such Personal
                Information is shared publicly or to
                non-confidential third-parties by you, however, only
                you have access and can view your biometric data,
                private key, password and pin (“Private Data”).
                <br />
                b. Through, and as allowed on, our Applications, and
                pursuant to our Terms and Conditions of Use, you may
                enter, transmit, upload and communicate Personal
                Information and Content with the exception of
                Private Data.
                <br />
                c. In order to help you achieve your desired goals
                in using our Applications, we may track your input
                with cookies and other technology that helps store
                and organize your Personal Information, Content and
                data, as well as utilizing algorithms and
                correlation technologies to help provide you with
                information, tools, publications, products, services
                and goods, we believe will be relevant and
                interesting to you.
                <br />
                d. Beyond what is required for registration, you can
                selectively use our Applications in an effort to
                limit what information and data you share with us
                and other end users.
                <br />
                e. When you connect with us through social media,
                web sites, mobile applications and/or other mediums,
                we may collect certain Personal Information from
                such mediums and Applications.
                <br />
                f. When you take surveys and enter challenges,
                promotions, contests, and sweepstakes, we may
                collect demographic, eligibility, and other Personal
                Information required for participation in such
                activities.
                <br />
                g. When you engage with our Applications, we may
                collect Personal Information over the course of such
                interactions.
                <br />
                h. Some Everest Applications allow you to refer and
                share information and media with friends and other
                people and to submit information about other people.
                The types of personally identifiable information
                that may be collected about other people at these
                pages generally include the recipient's name,
                address, e-mail address and telephone number,
                however, we may collect, in our discretion, any and
                all Personal Information that said persons desire to
                provide us.
                <br />
                i. We may also collect certain non-personally
                identifiable information when you visit our web
                pages or use our applications, including, but not
                limited to, the type of browser, the operating
                system you are using, your IP address, your device
                identifiers, your Internet or wireless service
                provider, and location-based information.
                <br />
                j. Financial companies choose how they share your
                personal information. Federal law gives consumers
                the right to limit some but not all sharing. Federal
                law also requires us to tell you how we collect,
                share, and protect your personal information. Please
                read this notice carefully to understand what we do.
                If you are a Vermont resident, we will automatically
                limit sharing of your information. Nevada law
                requires us to disclose that you may request to be
                placed on Everest’s internal "do not call" list at
                any time by sending your request to
                contact@Everest.org with the phrase “do not call” in
                the subject line. We are providing this notice to
                you pursuant to state law, and that you may obtain
                further information by contacting the Nevada
                Attorney General, 555 E. Washington Ave., Suite
                3900, Las Vegas, NV 89101; phone 702-486-3132; email
                BCPINFO@ag.state.nv.us.
                <br />
                k. You can delete or terminate your account at any
                time pursuant to our Terms and Conditions of Use.
                Once your account is deleted, you will not be able
                to recover your Personal Information.
                <br />
                <br />
                2. How We Use the Information
                <br />
                a. Everest does not sell Personal Information to
                third parties unless Personal has been publicly
                posted. We may provide aggregated information to our
                partners and other third parties in a way that does
                not identify you. We may also use independent
                contractors and vendors of products and services
                that will have access to our databases, software,
                code and algorithms, but who will be required to
                execute confidentiality and non-disclosure
                agreements. We may use Personal Information to
                fulfill your requests, improve the value and
                functionality of Everest Applications, verify your
                identity, provide you with support and provide
                tools, as we offer in our discretion, that help you
                achieve your goals and get the most out of our
                Applications, including providing you with relevant
                content, products, services, goods, tools,
                suggestions and reminders.
                <br />
                b. In the event that we decide to provide
                public-facing Applications, the only Personal
                Information we will display is what you choose to
                make publicly available on your profile and post
                publicly, if any. There is certain specific Personal
                Information that cannot be shared including,
                biometric data, your private key, your pin and
                password. If you choose to make information
                available publicly or to non-confidential
                thirdparties, such information will be treated as
                Publicly Submitted Materials (as defined in the
                Terms and Conditions of Use) rather than Personal
                Information pursuant to our Terms and Conditions of
                Use. In order to provide certain site functions
                through Everest, such as connectivity to your
                Personal Information, we use several thirdparty
                agents to process, route or store your information,
                or to communicate with you. As a requirement of
                using these third parties, and in full policy
                disclosure to you, we have posted links to their
                Privacy Policies on our web page entitled
                Third-Party Privacy Policies. Servers and Database
                Parse - Push Notifications Information provided
                through Everest will be treated in accordance with
                these policies, in addition to our Privacy Policy
                herein. Violations of these third-parties’ policies
                may require us to suspend or terminate your Everest
                account immediately or based upon our
                investigations. For clarity, the only third-parties
                that may have access to your Personal Information
                will be set forth on the Third-Party Privacy
                Policies page. We reserve the right to disclose any
                Personal Information to law enforcement or
                government officials or other third parties in the
                event (i) we are required to respond and disclose
                information as required by law, including, but not
                limited to, any legal process or subpoenas; (ii) we
                are required to take action where there is a
                violation of our Terms of Service, Privacy Policy,
                or as part of any investigation into fraudulent or
                illegal activities may be occurring through Everest;
                (iii) we are required to respond to any claim of
                infringement of intellectual property rights; or
                (iv) we feel such action is required to protect the
                safety of our Services, our users, or the general
                public.
                <br />
                c. We may combine the Personal Information we
                receive from you with outside records and share and
                use such information to enhance our Applications and
                our ability to market to you those products or
                services that may be of interest to you.
                <br />
                d. We may work with information providers and other
                third parties whose practices are not covered by our
                Privacy Policy (e.g., other marketers, magazine
                publishers, retailers, participatory databases, and
                non-profit organizations) that want to market
                products or services to you. We will use reasonable
                commercial efforts to ensure that direct access to
                your Personal Information is not provided to such
                third parties but rather we will utilize their
                marketing criteria to develop appropriate and
                relevant targeting of our members with Content
                products, goods and services that we believe will be
                of interest to you.
                <br />
                e. We will use Personal Information to communicate
                with you, including, but not limited to, providing
                you with tools, feedback, media and information to
                enhance your experience of our Applications, or to
                contact you about your account. We will also use
                your Personal Information to operate our business
                and provide our Applications to you, personalize
                your experiences on our Applications, better
                understand your needs and gain insights to provide
                you more relevant products, goods and services, as
                well as Content, advertising and marketing that is
                targeted to your personal needs and interests.
                <br />
                f. We will also use your Personal Information to
                address security issues, detect and avoid fraudulent
                transactions, as required by law, to enforce our
                Privacy Policies and Terms and Conditions of Use and
                to resolve disputes, communicate with you and
                respond to your requests and administer challenges,
                promotions, contests and sweepstakes.
                <br />
                g. If you choose to submit Content in any medium
                including, but limited to letters to our editors,
                blogs, opinions, articles, thoughts, posts, photos,
                video, sound or other media, we may publish your
                name, screen name and other information you have
                provided to us. You hereby consent to this.
                <br />
                h. The information we collect in connection with our
                online forums and communities is used to provide an
                interactive experience. We use this information to
                facilitate participation in these online forums and
                communities and, from time to time, to offer you
                products, programs, or services.
                <br />
                i. Sometimes we use the non-personally identifiable
                information that we collect to improve the design
                and content of our publications and websites, and to
                enable us to customize your experience. We also may
                use this information to analyze site usage, as well
                as to offer you products, programs, or services.
                <br />
                j. We will disclose information we maintain when
                required to do so by law, for example, in response
                to a court order or a subpoena. We also may disclose
                such information in response to a law enforcement
                agency's request.
                <br />
                k. Agents and contractors of Everest that have
                access to personally identifiable information are
                required to protect this information in a manner
                that is consistent with this Privacy Policy by, for
                example, not using the information for any purpose
                other than to carry out the services they are
                performing for us. Content providers, co-hosting
                facilities, content delivery networks, server and
                storage facilities and other third parties that
                provide hardware, software, services and facilities
                to facilitate working of the Applications, may not
                have privacy policies consistent with those stated
                herein. We will utilize reasonable commercial
                efforts to protect your Personal Information.
                <br />
                l. We may transfer your Personal Information to
                other Everest offices, servers, storage mediums, for
                internal management and administrative purposes on
                our behalf. Your personal data will be transferred
                to other Everest offices where necessary for the
                performance or conclusion of our contractual
                obligations to you or for your benefit. To the
                extent not prohibited under applicable law,
                transfers and disclosures of Personal Information
                may also be made where necessary for the
                establishment, exercise, or defense of legal claims,
                to protect the rights and property of Everest, our
                agents, customers, and others, including to enforce
                our agreements, policies, and terms of use; or to
                protect the personal safety of any person.
                <br />
                m. Please note that if Everest or any of its
                subsidiaries or lines of business is merged,
                acquired, divested, financed, sold, disposed of or
                dissolved, including through merger of,
                consolidation, sale or divestiture of assets, the
                relevant customer database, including Personal
                Information we may possess about you, may, in whole
                or in part, be sold, disposed of, transferred,
                divested, or otherwise disclosed as part of that
                transaction or proceeding. Notwithstanding the
                foregoing, your Private Data shall not be accessible
                to anyone but you at anytime, subject to Force
                Majeure, Acts of God and technological advancements
                that may break the security technologies that
                protect your Private Data.
                <br />
                n. Although we take appropriate measures to
                safeguard against unauthorized disclosures of
                information, we cannot assure you that the Personal
                Information that we collect will never be disclosed
                in a manner that is inconsistent with this Privacy
                Policy. Inadvertent disclosures may result, for
                example, when third parties misrepresent their
                identities in asking the site for access to Personal
                Information about themselves for purposes of
                correcting possible factual errors in the data.
                <br />
                <br />
                3. Privacy Options and Compliance
                <br />
                a. Our Service mainly works on an opt-in model of
                communication. Once you sign up for an account with
                us, you are agreeing to receive electronic
                communications from us, through either your email
                address, mobile phone text message, or push
                notifications in the Everest App. We may use your
                messaging address to contact you in the event that
                we have updates or other information to provide you.
                Such information may include, without limitation,
                new services offered by us, our partners, or
                information relating to your account. Additionally,
                we may reach out to you from time to time in order
                to notify you that our Terms of Service or Privacy
                Policy have changed. We comply with the federal
                CAN-SPAM Act and as such all emails from us will
                have means for you to opt out of the receipt of
                further email correspondence, except for any emails
                disclosing material changes to our Terms of Service
                or Privacy Policy. Alert us immediately if, for any
                reason, you cannot access or have difficulty
                accessing our “unsubscribe” processes. Please note
                that we will not ask for any Personally Identifying
                Information by email. You should immediately report
                any emails you may receive that ask for any Personal
                Information, whether or not it is Personally
                Identifying Information, and appear as though they
                are coming from or promoting Everest, to
                contact@Everest.org with the word “abuse” in the
                subject line.
                <br />
                b. You can choose how much information you provide
                to Everest, and you can customize the communications
                that Everest provides to you. Please do note, there
                may be a minimum amount of information required to
                obtain an account with Everest, and by limiting how
                much information you provide may limit the
                functionality of Everest for your purposes. Once the
                Everest mobile App and login portal launches, you
                can manage your notification preferences by logging
                into your account and changing those preferences.
                You can also unsubscribe from any mailings by
                clicking the “unsubscribe” link at the bottom of
                emails you receive. You may also manage the sharing
                of certain Personal Information with us when you
                connect Everest to a social media platform or
                application. Please refer to the privacy settings of
                the social media website or application to determine
                how you may adjust our permissions and manage the
                interactivity between Everest and your social media
                account or your mobile device.
                <br />
                c. Everest sites will not use or transfer Personal
                Information provided to us in ways unrelated to the
                ones described above without also providing you with
                an opportunity to opt-out of these unrelated uses.
                <br />
                d. Everest wants to give you the best possible user
                experience, and current information about you helps
                us do just that. You can keep your information
                up-to-date by logging in and editing your profile
                (once the Everest mobile App and login portal are
                launched) adding to your account, or interacting
                with our modules. If you want to close your account
                or have other questions or requests, please contact
                us at contact@Everest.org. While we can assist you
                in many ways, we may not always be able to delete
                records of past interactions and transactions as
                required by applicable law. We will retain your
                information for as long as your account is active or
                as needed to provide you services and to maintain a
                record of your transactions to support our
                Applications. Once your account has been deleted, we
                will no longer retain any of your personal
                information unless required by applicable law.
                <br />
                e. California's "Shine the Light" law, Civil Code
                section 1798.83, requires certain businesses to
                respond to requests from California customers asking
                about the businesses' practices related to
                disclosing personal information to third parties for
                the third parties' direct marketing purposes.
                Alternately, such businesses may have in place a
                policy not to disclose personal information of
                customers to third parties for the third parties'
                direct marketing purposes if the customer has
                exercised an option to opt-out of such
                information-sharing. (Note: Everest sites do not
                recognize the “do not track signals” that some
                browsers may employ.) To request information, please
                contact contact@Everest.org with the phrase “direct
                marketing” in the subject line.
                <br />
                <br />
                4. Collection of Information by Third-Party Sites
                and Sponsors
                <br />
                a. Everest Applications allow for you to connect or
                sync your account with third-party sites, services,
                or social media platforms, which are not owned or
                operated by Everest. You are responsible for any
                information that you provide to those companies or
                any other third party that contacts you through or
                because of our Service. This privacy policy does not
                govern your interaction with those third party
                sites. Those sites may have their own privacy
                policies and practices for use of Personal
                Information, over which we have no control. The
                companies and service providers that we may work
                with may also have their own privacy practices, over
                which we have no control. We encourage you to
                familiarize yourself with the privacy statements
                provided by these other parties prior to providing
                them with information or taking advantage of any
                offer.
                <br />
                b. Everest Applications may offer contests,
                sweepstakes, promotions, editorial features, or
                other activities or offerings that are sponsored,
                co-sponsored by, or presented with, third parties.
                By virtue of your participation in such activities
                or offerings, your IP address and Personal
                Information that you voluntarily submit may be
                provided to both the Everest site and the third
                parties. Everest has no control over the third
                parties' use of this information.
                <br />
                c. Everest Applications may use third parties to
                present or serve advertisements, including online
                behavioral advertisements, that you may see on the
                Everest Applications and to conduct research about
                the advertisements. This privacy notice does not
                cover any use of information that such third parties
                may have collected from you (e.g., type of browser,
                operating system, domain name, day and time of
                visit, page(s) visited) or the methods used by the
                third-parties to collect that information (e.g.,
                cookies, web beacons and clear gifs).
                <br />
                <br />
                5. Mobile Application
                <br />
                a. You will have the ability to download and use the
                Everest mobile App, which may ask for access to your
                location or other Personal Information, or for your
                permission to receive notifications through the
                application. We may also ask for access to your
                calendar, photos or other applications on your phone
                in order to provide services to you. You will have
                the ability to deny our access at your discretion,
                though denial of access may mean that you will not
                be able to use all of the services we provide to our
                users. We will not share your Personally Identifying
                Information with nonaffiliated third-parties. All
                information may become part of our non-identifying,
                aggregate data.
                <br />
                <br />
                6. Cookies
                <br />
                a. To provide a smooth and easy experience when you
                use Everest, we may use technologies such as
                cookies, pixel tags, server logs, and other similar
                technologies to automatically collect and store
                information. As you use our Services, your devices
                communicate with servers operated by us and by our
                service providers to coordinate the interactivity
                and fulfill your requests for services and
                information. For example, when you use our
                Applications, Everest may place cookies on your
                computer, mobile and other devices, allowing Everest
                to recognize when you return so that we can
                customize and create a more pleasant user experience
                for you. You may always choose not to allow cookies
                through your device settings, but please note that
                some of our Applications or portions of our App may
                then no longer work for you. Everest may develop
                relationships with third party companies to
                advertise for our services on other websites, and
                they may perform tracking and reporting functions
                for our Applications and our advertisements. This
                Privacy Policy does not cover the collection methods
                or use of the information by these third parties,
                and Everest is not responsible for cookies or other
                collection methods of those parties. If you arrive
                on the Everest site or access the Applications
                through the advertising or suggestion of a
                third-party, we encourage you to review the privacy
                policies or statements of these third party
                companies to learn more about their use of cookies
                and other technologies.
                <br />
                b. Cookies are small text files that we place in
                your computer or device to store your preferences.
                Cookies, by themselves, do not tell us your e-mail
                address or other personal information unless you
                choose to provide this information to us by, for
                example, registering with one of our Applications.
                Once you choose to provide us with Personal
                Information, this information may be linked to the
                data stored in the cookie. A cookie assigns a unique
                numerical identifier to your Web browser or device,
                and may enable us to recognize you as the same user
                who has used our Applications, and relate your use
                of the Applications to other information about you,
                such as your usage information, Personal Information
                and Content.
                <br />
                c. We use cookies (and Local Shared Objects, HTML5,
                cache cookies, web beacons, or clear gifs, described
                further below) to understand usage of the
                Applications and to improve our content and
                offerings and to deliver advertisements that you
                might be interested in. For example, we may use
                cookies to personalize your experience on our
                Applications (e.g., to recognize you by name when
                you return to our site, to save your password in
                password-protected areas, to enable shopping carts,
                or to tailor content or product and service
                offerings).
                <br />
                i. Most browsers automatically accept cookies. You
                can set your browser option so that you will not
                receive cookies and you can also delete existing
                cookies from your browser. However, you may find
                that some parts of the site will not function
                properly if you have refused cookies or similar
                tracking technologies and you should be aware that
                disabling cookies or similar tracking technologies
                might prevent you from accessing some of our
                content. However, your viewing of editorial content
                may be hampered. If you wish to block cookies, go to
                http://www.allaboutcookies.org/ to find out how, or
                check your browser's instructions.
                <br />
                ii. Local Shared Objects: Local shared objects, such
                as .Flash cookies, also may be stored on your
                computer or device. Local shared objects operate a
                lot like cookies, but cannot be managed in the same
                way. Depending on how local shared objects are
                enabled on your computer or device, you may be able
                to manage them using software settings. For
                information on managing Flash cookies, for example,
                see
                http://helpx.adobe.com/flash-player/kb/disable-localshared-objects-flash.html.
                <br />
                iii. HTML 5: HTML5, the language some Web sites are
                coded in, may be used to store information on your
                computer or device about your Application usage
                activities. This information may be retrieved by us
                to help us manage our Applications, such as by
                giving us information about how our Applications are
                being used by our visitors, how they can be
                improved, and to customize them for our users.
                <br />
                iv. Cache Cookies: Cache cookies, such as eTags, may
                be used to identify your computer or device as the
                same computer or device that visited a Application
                or Applications in the past.
                <br />
                v. Web Beacons: Our websites may also use small
                pieces of code called "web beacons" or "clear gifs"
                to collect anonymous and aggregate advertising
                metrics, such as counting page views, promotion
                views, or advertising responses. A web beacon is an
                electronic image called a single-pixel or clear GIF.
                Web beacons can recognize certain types of
                information, such as a user's cookie number, time
                and date of a page view, and description of the page
                where the web beacon is placed. These web beacons
                may be used to deliver cookies that conform to our
                cookie policy above.
                <br />
                vi. These tracking technologies may be deployed by
                us and/or by our service providers or partners on
                our behalf. These technologies enable us to assign a
                unique number to you, and relate your Application
                usage information to other information about you,
                including your personal information. We may match
                information collected from you through different
                means or at different times, including both personal
                information and Application usage information, and
                use such information along with offline and online
                information obtained from other sources (including
                third parties) such as demographic information and
                updated contact information (where that information
                has been lawfully disclosed to us) for the purposes
                of learning more about you so we can provide you
                with relevant content.
                <br />
                <br />
                7. Our Commitment to Security
                <br />
                a. Everest protects your information by taking
                reasonable steps to secure it against unauthorized
                access. We do this through encryption, physical
                administration by our staff members, and electronic
                measures. However, no security system or method is
                guaranteed to be safe from hackers or human error.
                We will contact you the soonest possible if we find
                any breaches in our security methods.
                <br />
                b. We will take reasonable steps to accurately
                Record the Personal Information that you provide to
                us and any subsequent updates.
                <br />
                c. We encourage you to review, update, and correct
                the Personal Information that we maintain about you,
                and you may request that we delete Personal
                Information about you that is inaccurate,
                incomplete, or irrelevant for legitimate purposes,
                or are being processed in a way which infringes any
                applicable legal requirements, by contacting us as
                set out in the "How to Contact Us" section below. At
                our election, instead of deleting such information,
                we may retain it with your comment or correction
                noted in our records. By contacting us, you may also
                object to our further use of your Personal
                Information if you have compelling legitimate
                grounds (this may involve closing your account). We
                may ask you to verify your identity and to provide
                other details before we are able to provide you with
                any information, correct any inaccuracies, or delete
                any information. Your right to review, update,
                correct, and delete your Personal Information may be
                limited, subject to the law or your jurisdiction:
                <br /> (i) if your requests are abusive or
                unreasonably excessive, (ii)
                <br /> where the rights or safety of another person
                or persons would be encroached upon, or (iii) <br />
                if the information or material you request relates
                to existing or anticipated legal proceedings between
                you and us, or providing access to you would
                prejudice negotiations between us or an
                investigation of possible unlawful activity. Your
                right to review, update, correct and delete your
                information is subject to our records retention
                policies and applicable law, including any statutory
                retention requirements.
                <br />
                d. We will retain your Personal Information while
                you have an account with us and thereafter for as
                long as we need it for purposes not prohibited by
                applicable laws. Thereafter, we will either delete
                your Personal Information or de-identify it so that
                it is anonymous and not attributed to your identity.
                Your rights to request that we delete your Personal
                Information are set forth in the "Accessing,
                Correcting, and Deleting Your Personal Information"
                section above.
                <br />
                e. Everest hosts its platforms within modern data
                centers, using industry-standard web technologies
                including Secure Socket Layer (SSL) for confidential
                communications between our users and each platform.
                <br />
                f. Because we desire your Personal Information to be
                securely stored in a manner to prevent unauthorized
                access or use, we have developed a decentralized
                storage, verification and tracking platform that
                puts you in control of your Personal Information.
                This means you are responsible for the accuracy of
                your Personal Information and how your Personal
                Information is used. We do not interfere with your
                control and choices of how your Personal Information
                is used, however, we may, in our discretion, at
                anytime and without notice, terminate accounts and
                delete information, we deem in violation of our
                Terms and Conditions of Use, without liability to
                you.
                <br />
                <br />
                8. Special Note for Parents
                <br />
                a. The site you are visiting is one of the Everest
                general audience sites and is not intended for use
                by children. For more information about our online
                privacy practices for children's sites, consult the
                privacy notice at the children's site in which you
                are interested.
                <br />
                b. Everest complies with the Children’s Online
                Privacy Protection Act, and does not intentionally
                direct any services at children under thirteen (13).
                Since we do not at this time require any user to
                give us his or her specific age, it may be possible
                that children under 13 may access and use the
                Service without our knowledge. However, if we
                determine that any user is under the age of 13, we
                will immediately suspend that user’s account, and it
                shall be that user’s responsibility to provide proof
                of age to continue use of the Service. If you
                believe that we have inadvertently collected
                information of a child under the age of 13, please
                contact us immediately at contact@Everest.org with
                the phrase “underage information” so that we can
                remove the information.
                <br />
                <br />
                9. Changes to this Privacy Policy
                <br />
                a. This Privacy Policy may be changed by Everest.
                The revised Privacy Policy will be posted to this
                page so that you are aware of the information we
                collect, how we use it, and under what circumstances
                we may disclose it.
                <br />
                <br />
                10. How to Contact Us
                <br />
                a. If you have any questions or concerns about the
                Everest Privacy Notice or its implementation please
                contact us via email by contact@Everest.org.
            </Typography>
        </Box>
    );
};

export default Terms;