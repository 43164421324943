import React from "react";
import { useSelector } from "react-redux";
import {Box, Divider, Grid} from "@mui/material";
import GenericTokenImage from "../../images/generic-token.png";
import TokenRow from "./tokenRow";
import { selectWalletData } from "./reduxSlice";

const SendInfo = () => {
    const walletData = useSelector(selectWalletData);
    const token = walletData.token;
    const network = walletData.network;

    return (
        <Box className="send-info">
            <Box className="buy-modal-item">
                <TokenRow token={token} enableSend={false} />
            </Box>
            <Divider sx={{background: "#16359D", height: "2px"}} />
            <Box className="buy-modal-item">
                <Grid container spacing={2}>
                    <Grid item={true} xs={4}>
                        <Box sx={{marginTop: '15px', textAlign: 'center'}}>
                            <b>Network</b>
                        </Box>
                    </Grid>
                    <Grid className="balance-token-cell justify-center" item={true} xs={7}>
                        <img
                            src={network.logo_uri}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = GenericTokenImage;
                            }}
                            alt="token Icon"
                            width={50}
                        />
                        <span className='balance-token-symbol' title={token.symbol}>{network.name}</span>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default SendInfo;