import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {Box, CircularProgress, Divider, FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import { EVER_CHAIN_NETWORK, ID_TOKEN_SYMBOL } from "../../js/constants";
import BuySellLink from "../Shared/buySellLink";
import TokenRow from './tokenRow';
import FooterReward from "./reward";
import helpers from "../../adapters/helpers";
import { setBackFunc, setMenuText } from "../../store/reduxSlice";
import { selectWalletData, setToken, setNetwork as setWalletNetwork } from "./reduxSlice";

const Balance = ({snackBarMessage}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const walletData = useSelector(selectWalletData);
    
    const walletAddress = walletData.wallet.address;
    const [network, setNetwork] = useState(walletData.network.name);
    const [loader, setLoader] = useState(true);
    const [networkList, setNetworkList] = useState([]);
    const [tokens, setTokens] = useState([]);
    const [filteredTokens, setFilteredTokens] = useState([]);

    const validTokens = [ID_TOKEN_SYMBOL];
    useEffect(() => {
        dispatch(setBackFunc(() => navigate('/dashboard')));
        if(!walletAddress) {
            navigate(-1);
        } else {
            dispatch(setMenuText(walletAddress));
        helpers.getNetworks()
            .then((resp) => {
                if (resp.data.success) {
                    setNetworkList(resp.data.data);
                } else {
                    snackBarMessage({message: 'something went wrong while getting networks. Please refresh and try again.'});
                }
            })
            .catch((error) => {
                snackBarMessage({message: error.toString()});
            });
        helpers.getUserTokens(walletAddress)
            .then(resp => {
                setLoader(false);
                if(resp.data.success) {
                    const tokenList = resp.data.data;
                    tokenList.sort((a, b) => {
                        if (a.symbol < b.symbol) return -1;
                        if (a.symbol > b.symbol) return 1;
                        return 0;
                    });
                    const tokenArr = [], fTokenArr = [];
                    for (let i = 0; i < tokenList.length; i++) {
                        const currentToken = tokenList[i];
                        const token = {
                            id: currentToken.id,
                            name: currentToken.name,
                            symbol: currentToken.symbol,
                            address: currentToken.address,
                            balance: currentToken.balance,
                            fmt_balance: currentToken.fmt_balance,
                            decimals: currentToken.decimals,
                            image: currentToken.icon_uri,
                            network: currentToken.network,
                            send: true,
                        };
                        const currentNetworkLow = currentToken.network.toLowerCase()
                        if(currentNetworkLow === network.toLowerCase()) {
                            if (currentNetworkLow === EVER_CHAIN_NETWORK.toLowerCase() && !validTokens.includes(currentToken.symbol)) {
                                token.send = false;
                            }
                            fTokenArr.push(token);
                        }
                        tokenArr.push(token);
                    }
                    setTokens(tokenArr);
                    setFilteredTokens(fTokenArr);
                } else {
                    snackBarMessage({message: "Something went wrong while getting fiat currency data"});
                }
            })
            .catch((error) => {
                setLoader(false);
                snackBarMessage({message: error.toString()});
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fTokenArr = [];
        const selectedNetwork = network.toLowerCase();
        for (let i = 0; i < tokens.length; i++) {
            const currentToken = tokens[i];
            const currentNetworkLow = currentToken.network.toLowerCase()
            currentToken.send = true;
            if(currentNetworkLow === selectedNetwork) {
                if (currentNetworkLow === EVER_CHAIN_NETWORK.toLowerCase() && !validTokens.includes(currentToken.symbol)) {
                    currentToken.send = false;
                }
                fTokenArr.push(currentToken);
            }
        }
        setFilteredTokens(fTokenArr);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [network]);

    const tokenSelect = (token) => {
        if(token.send) {
            const selectedNetwork = networkList.find(net => net.name === network);
            dispatch(setToken(token));
            dispatch(setWalletNetwork({name: selectedNetwork.name, chain_id: selectedNetwork.chain_id, symbol: selectedNetwork.symbol, logo_uri: selectedNetwork.logo_uri}));
            navigate('/token/info');
        }
    };

    
    return (
        <>
            <Box className="login-container" mt={8}>
                <BuySellLink />
                <Box mt={4} className="full-width">
                    <FormControl fullWidth>
                        <InputLabel>Network</InputLabel>
                        <Select
                            value={network}
                            label="Network"
                            className="text-left"
                            onChange={(e) => setNetwork(e.target.value)}
                        >
                            {networkList.map(net =>
                                <MenuItem key={net.name} title={net.name} value={net.name}>{net.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <Grid className="balance-header" container>
                        <Grid item={true} xs={6}><b>Token</b></Grid>
                        <Grid item={true} xs={6}><b>Balance</b></Grid>
                    </Grid>
                    {
                        loader ? <Box mt={4} className="text-center buy-modal-text-color"><CircularProgress size={50} color={"inherit"} /></Box> :
                        filteredTokens.length > 0 ?
                        filteredTokens.map(token =>
                            <Box key={token.id}>
                                <Box className="buy-modal-item" onClick={() => tokenSelect(token)}>
                                    <TokenRow token={token} />
                                </Box>
                                <Divider />
                            </Box>
                        ) :
                        <Box mt={10} className="login-container buy-modal-text-color font30">No Tokens</Box>
                    }
                </Box>
                
            </Box>
            <FooterReward />
        </>
    );
};

export default Balance;