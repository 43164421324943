import React, {useState, useEffect} from "react";
import {useNavigate} from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux";
import {Box, Dialog, DialogContent, IconButton, Typography, Slide, Grid, Container, Button, DialogTitle} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {getUID, isLoggedIn, removeSession} from "../../util/userFunctions";
import {selectAppData, setBackFunc} from "../../store/reduxSlice";
import {selectBuyData, setToken, setTokenId, reset as buyReset, setData} from "../Buy/reduxSlice";
import {selectUserData, setUserID, setProfile, reset} from "../../store/reduxUserSlice";
import helpers from "../../adapters/helpers";
import Terms from "../SignUp/terms";
import Privacy from "../SignUp/privacy";
import {setConfirmDialog} from "../Shared/ConfirmDialog";
import usePrevious from "../Shared/usePrevious";
import {CAMPAIGN_EXCHANGE, DOLLAR_SIGN, EURO_SIGN, FIAT_TO_EVER_ID, FIAT_TO_TOKEN} from "../../js/constants";
import { ACCOUNTS_MENU_HOVER_ICON, ACCOUNTS_MENU_ICON, CONTACT_MENU_HOVER_ICON, CONTACT_MENU_ICON, HEADER_LOGO, HISTORY_MENU_HOVER_ICON, HISTORY_MENU_ICON, HOME_MENU_HOVER_ICON, HOME_MENU_ICON, MY_LINK_MENU_HOVER_ICON, MY_LINK_MENU_ICON, PROFILE_MENU_HOVER_ICON, PROFILE_MENU_ICON, REF_REWARDS_MENU_HOVER_ICON, REF_REWARDS_MENU_ICON, SIGN_OUT, TERMS_MENU_HOVER_ICON, TERMS_MENU_ICON } from "../../js/images";

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="left" ref={ref} {...props} />;
});

const TransitionTermsPolicy = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MenuItem = (props) => {

    const [hover, setHover] = useState(false);
    
    return (
        <>
            <Box className="menu-item" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={props.onClickHandler}>
                <Grid container>
                    <Grid className="content-center text-center" item={true} xs={3} md={1}>
                        {props.icon && <img src={hover ? props.hoverIcon : props.icon} alt="Menu Icon" width={40} height={40} />}
                    </Grid>
                    <Grid  item={true} xs={0} md={0.5} />
                    <Grid item={true} xs={7} md={8.5} className="content-center">
                        <Box sx={{textAlign: 'left'}}>
                            <p className='menu-item-name'><strong>{props.name}</strong></p>
                        </Box>
                    </Grid>
                    <Grid className="flex-right items-center" item={true} xs={2} md={2}>
                        <ArrowRightIcon sx={{fontSize: '30px'}} />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

const NavigationContent = (props) => {

    const {navigate, setOpenModal} = props;
    const [termsModal, setTermsModal] = useState(false);
    const [showTermsPrivacy, setShowTermsPrivacy] = useState('terms');
    // const walletRef = useRef("");
    const navigateTo = (link) => {
        navigate(link);
        setOpenModal(false);
    };

    if(isLoggedIn()) {
        return (
            <Box mt={8}>
                {/* <a ref={walletRef} href="https://wallet.everest.org" target="_blank" rel="noreferrer" style={{display: "none"}}>Wallet</a>
                <MenuItem name={"Wallet"} icon={WalletIcon} onClickHandler={() => walletRef.current.click()} /> */}
                <MenuItem name={"Home"} icon={HOME_MENU_ICON} hoverIcon={HOME_MENU_HOVER_ICON} onClickHandler={() => navigateTo(props.isExchangeCampaign ? '/dashboard' : '/')} />
                {props.service === "buySell" && 
                    <>
                        <MenuItem name={"Profile"} icon={PROFILE_MENU_ICON} hoverIcon={PROFILE_MENU_HOVER_ICON} onClickHandler={() => navigateTo('/personal-details')} />
                        <MenuItem name={"History"} icon={HISTORY_MENU_ICON} hoverIcon={HISTORY_MENU_HOVER_ICON} onClickHandler={() => navigateTo('/history')} />
                        <MenuItem name={"Accounts"} icon={ACCOUNTS_MENU_ICON} hoverIcon={ACCOUNTS_MENU_HOVER_ICON} onClickHandler={() => navigateTo('/banks')} />
                    </>
                }
                <MenuItem name={"My Link"} icon={MY_LINK_MENU_ICON} hoverIcon={MY_LINK_MENU_HOVER_ICON} onClickHandler={() => navigateTo('/my-link')} />
                <MenuItem name={"Contact"} icon={CONTACT_MENU_ICON} hoverIcon={CONTACT_MENU_HOVER_ICON} onClickHandler={() => navigateTo('/contact')} />
                <MenuItem name={"Referral Rewards"} icon={REF_REWARDS_MENU_ICON} hoverIcon={REF_REWARDS_MENU_HOVER_ICON} onClickHandler={() => navigateTo('/referral-rewards')} />
                <MenuItem name={"Terms & Privacy"} icon={TERMS_MENU_ICON} hoverIcon={TERMS_MENU_HOVER_ICON} onClickHandler={() => setTermsModal(true)} />
                <Box mt={8} className="text-center">
                    <IconButton sx={{padding: "0"}} disableFocusRipple={true} disableRipple={true}>
                        <img src={SIGN_OUT} alt="Menu Icon" width={20} />&nbsp;
                        <Typography className="menu-logout-text" onClick={props.logout} variant="subtitle1">Sign Out</Typography>
                    </IconButton>
                </Box>

                <Dialog open={termsModal} fullWidth={true} onClose={() => setTermsModal(false)} TransitionComponent={TransitionTermsPolicy}>
                    <DialogTitle className="buy-modal-text-color">
                        <Typography variant="h5" component="p">
                            <strong>Terms of Use & Privacy</strong>
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={() => setTermsModal(false)}
                            className="menu-buttons"
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent className="bank-modal-content" dividers>
                        <Box className="terms-modal-btn">
                            <Grid container spacing={5}>
                                <Grid item={true} xs={6}>
                                    <Typography className={`font-bold text-right ${showTermsPrivacy === 'terms' ? 'active' : ''}`} variant="h5" onClick={() => setShowTermsPrivacy('terms')}>Terms of Use</Typography>
                                </Grid>
                                <Grid item={true} xs={6}>
                                    <Typography className={`font-bold ${showTermsPrivacy === 'privacy' ? 'active' : ''}`} variant="h5" onClick={() => setShowTermsPrivacy('privacy')}>Privacy</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        {showTermsPrivacy === 'terms' ? <Terms /> : <Privacy />}
                    </DialogContent>
                </Dialog>
            </Box>
        );
    } else {
        return (
            <Container component="main" maxWidth="xs">
                <Box className="header-container">
                    <Typography variant="h5">
                        <strong>Welcome to EverWallet</strong>
                    </Typography>
                    <Button sx={{width: "65%", mt: 7}} onClick={() => navigateTo('/signin')} className="sdk-btn3" variant="contained">
                        <strong>Sign In</strong>
                    </Button>
                    <Button sx={{width: "65%", mt: 3}} onClick={() => navigateTo('/signup')} className="sdk-btn-connect-wallet" variant="contained">
                        <strong>Create Account</strong>
                    </Button>
                </Box>
            </Container>
        );
    }
};

const Navigation = ({snackBarMessage, showRefreshNotice, showMenu, showBackBtn, menuText}) => {
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();
    const buyData = useSelector(selectBuyData);
    const appData = useSelector(selectAppData);
    const service = appData.sdkConfiguration.service;
    const enableAuth = appData.partnerData.enable_auth;
    const isExchangeCampaign = appData.sdkConfiguration.campaign === CAMPAIGN_EXCHANGE;
    const userData = useSelector(selectUserData);
    const region = userData.region;
    const prevRegion = usePrevious(region);
    const dispatch = useDispatch();

    useEffect(() => {
        if(userData.session === "destroy") {
            setConfirmDialog({
                title: "Session Expired.",
                message: "Your session has expired. Please Login Again.",
                onConfirm: () => { removeSession(); navigate('/signin'); dispatch(reset());},
                confirmButtonText: "Login",
                cancelButtonShow: false,
                height: '400px',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData.session]);

    useEffect(() => {
        if(isLoggedIn() && service === "buySell" && buyData.amount && prevRegion && prevRegion !== region) {
            const currentToken = buyData.token;
            const tokenList = buyData.tokenList;
            const totalTokens = tokenList.length;
            let newToken = {id: 0};
            for(let i = 0; i < totalTokens; i++) {
                const token = tokenList[i];
                if(currentToken === token.symbol){
                    if(region === "US" && token.region_us) {
                        newToken = token;
                    } else if(region === "EU" && token.region_eu) {
                        newToken = token;
                    }
                }
            }
            if(newToken.id === 0) {
                setConfirmDialog({
                    title: "Region Changed.",
                    message: "It seems that your region has been changed and selected token is not available in that region. Please select the token again.",
                    onConfirm: () => { dispatch(setToken('')); dispatch(setTokenId('')); navigate('/');},
                    confirmButtonText: "Continue",
                    cancelButtonShow: false,
                    height: '400px',
                });
            } else {
                const data = {
                    type: buyData.token === "ID" ? FIAT_TO_EVER_ID : FIAT_TO_TOKEN,
                    paymentMethod: buyData.paymentMethod,
                    amount: buyData.amount.replaceAll(",", ".") * 1,
                    token: buyData.token,
                    currency: buyData.currency,
                    network: buyData.network,
                    exchange: newToken.exchange,
                    paymentProvider: buyData.provider
                };
                dispatch(setData({exchange: newToken.exchange}));
                helpers.feeEstimator(data)
                .then((response) => {
                    if (response.data.success) {
                        const respData = response.data.data;
                        const fee_breakdown = respData.fee_breakdown;
                        let everestFee = 0, networkFee = 0;
                        for(let i = 0; i < fee_breakdown.length; i++) {
                            const currentFee = fee_breakdown[i];
                            if(currentFee.name === "ev_fee") {
                                everestFee = currentFee.value * 1;
                            } else if(currentFee.name === "network_fee") {
                                networkFee = currentFee.value * 1;
                            }
                        }
                        dispatch(setData({
                            tokenId: newToken.id,
                            exchange: newToken.exchange,
                            quoteId: respData.quote_id,
                            estimatedTokenAmount: respData.crypto_out_amount,
                            conversionRate: respData.conversion_price,
                            totalFee: respData.total_fee * 1,
                            everestFee,
                            networkFee,
                        }));
                    } else {
                        snackBarMessage({message: "Something went wrong while refreshing estimates."});
                        navigate(-1);
                    }
                })
                .catch((error) => {
                    const errorObject = JSON.parse(error.request.response);
                    if(errorObject.code.message === "PROVIDER_CANT_EXCHANGE_RATE") {
                        snackBarMessage({message: "Currently we can't provide the exchange rate of this token"});
                    } else {
                        snackBarMessage({message: errorObject.code.message});
                    }
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [region]);

    useEffect(() => {
        if(isLoggedIn()) {
            dispatch(setUserID(getUID()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(userData.uuid) {
            helpers.getProfile()
                .then((response) => {
                    const userData = response.data.users;
                    const walletData = response.data.wallets;
                    const photoThumbnail = response.data.photoThumbnail;
                    const region = userData.region;

                    let paymentCurrency = EURO_SIGN;
                    if(region === "US") {
                        paymentCurrency = DOLLAR_SIGN
                    }

                    const userStoreData = {
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        email: userData.email,
                        countryCode: userData.countryCode,
                        phoneNumber: userData.phoneNumber,
                        birthDate: userData.birthDate,
                        orgs: userData.orgs,
                        residentialAddress: {...userData.residentialAddress},
                        identityDocuments: userData.identityDocuments,
                        walletAddress: walletData.address,
                        region,
                        paymentCurrency,
                        photoThumbnail,
                    };
                    dispatch(setProfile(userStoreData));
                    // if(userData.defaultCurrency === "EUR") {
                    //     dispatch(setAppCurrency({code: "EUR", symbol: "€"}));
                    // } else {
                    //     dispatch(setAppCurrency({code: "USD", symbol: "$"}));
                    // }
                })
                .catch(() => {
                    console.log('Unable to get data. Please refresh the page and try again.');
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData.uuid, appData.getProfileData]);

    const logout = () => {
        setOpenModal(false);
        removeSession();
        dispatch(reset());
        dispatch(buyReset());
        navigate('/');
    };

    const backBtn = () => {
        if(appData.backFunc) {
            appData.backFunc();
            dispatch(setBackFunc(null));
        } else {
            navigate(-1);
        }
    };

    return (
        <Box className="nav-bar">
            {showRefreshNotice &&
                <Box className="header-notice-bar">
                    <strong>Please do not refresh or leave this screen</strong>
                </Box>
            }
            {showBackBtn &&
                <IconButton
                    className="back-button"
                    sx={{top: showRefreshNotice ? "48px" : "8px"}}
                    onClick={backBtn}
                >
                    <ArrowBackIcon/>
                </IconButton>
            }
            {menuText &&
                <Typography className="navigation-back-text" sx={{top: showRefreshNotice ? "38px" : "-2px"}} variant="h5" mt={2}>
                    <strong>{menuText}</strong>
                </Typography>
            }
            {showMenu && enableAuth &&
                <IconButton
                    className="menu-buttons-refresh"
                    sx={{top: showRefreshNotice ? "48px" : "14px", zIndex: 10}}
                    onClick={() => setOpenModal(true)}
                >
                    <MenuIcon />
                </IconButton>
            }
            <Dialog className="navigation-modal" PaperProps={{elevation: 10}} open={openModal} fullWidth={true} onClose={() => setOpenModal(false)} TransitionComponent={Transition}>
                <Box className="asset-header">
                    <img src={HEADER_LOGO} alt = "logo" />
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenModal(false)}
                        className="asset-close-btn"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent sx={{p: 0, height: '600px'}}>
                    <NavigationContent navigate={navigate} setOpenModal={setOpenModal} service={service} isExchangeCampaign={isExchangeCampaign} logout={logout} />
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default Navigation;