import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';
import {selectAppData} from '../../store/reduxSlice';
import {setConfirmDialog} from './ConfirmDialog';

const ServiceUnavailable = () => {

    const navigate = useNavigate();
    const appData = useSelector(selectAppData);
    const features = appData.features;
    const partnerData = appData.partnerData;

    const [initNavigate, setInitNavigate] = useState(false);

    useEffect(() => {
        if (!initNavigate) {
            return
        }
        switch (true) {
            case features.buy:
                navigate("/")
                break;
            case features.trade:
                navigate("/swap")
                break;
            case   features.sell:
                navigate("/sell")
                break;
        }
    }, [initNavigate])

    useEffect(() => {
        if (!features.remoteData) {
            return
        }

        if (features.buy || features.trade || features.sell) {
            switch (true) {
                case (!features.buy || !partnerData.buy_feature_active):
                    if (features.trade && partnerData.exchange_service_active) {
                        navigate("/swap")
                    }
                    break;
            }
            return;
        }

        setConfirmDialog({
            title: "Not Available",
            message: "This feature is not available in your region. You can use Swap to transition your value to a stablecoin.",
            confirmButtonText: "Ok",
            onConfirm: () => setInitNavigate(true),
            cancelButtonShow: false,
            height: "490px"
        });
    }, [features]);

    return (
        <></>
    );
};

export default ServiceUnavailable;