import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    address: '',
    addressFrom: '',
    amount: '',
    token: '',
    recieveToken: '',
    estimatedAmount: '',
    conversionRate: '',
};

export const TradeSlice = createSlice({
    name: 'tradeData',
    initialState,
    reducers: {
        setNetwork: (state, action) => {
            state.network = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setAmount: (state, action) => {
            state.amount = action.payload;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
        },
        setBank: (state, action) => {
            state.bank = action.payload;
        },
        setData: (state, action) => {
            return {...state, ...action.payload};
        },
        reset: () => {
            return initialState;
        }
    },
});

export const { setNetwork, setToken, setAmount, setAddress, setBank, setData, reset } = TradeSlice.actions;

export const selectTradeData = (state) => state.tradeData;

export default TradeSlice.reducer;