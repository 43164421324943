import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from "react-redux";
import {Avatar, Backdrop, Box, CircularProgress, Grid, Typography} from "@mui/material";
import MuiAccordion  from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {selectUserData} from "../../../store/reduxUserSlice";
import helpers from "../../../adapters/helpers";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ConfirmDialog, {setConfirmDialog} from "../../Shared/ConfirmDialog";
import WalletAccordion from './walletAccordion';
import DocumentAccordion from './documentAccordion';
import TwitterTile from './twitterTile';
import AddBtn from "../../../images/add-btn.png";
import InfoIcon from "../../../images/info.png";
import moment from "moment";
import {selectAppData} from "../../../store/reduxSlice";
import { CAMPAIGN_EXCHANGE, CAMPAIGN_NON_MLM, CAMPAIGN_TWITTER } from '../../../js/constants';
import MarketAccordion from './marketAccordion';
import { WALLET_URL } from '../../../js/variables';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    // backgroundColor:
    //     theme.palette.mode === 'dark'
    //         ? 'rgba(255, 255, 255, .05)'
    //         : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Dashboard = ({snackBarMessage}) => {

    const navigate = useNavigate();
    const userData = useSelector(selectUserData);
    const appData = useSelector(selectAppData);
    const twitterFlow = appData.sdkConfiguration.campaign === CAMPAIGN_TWITTER;
    const isNonMlmCampaign = appData.sdkConfiguration.campaign === CAMPAIGN_NON_MLM;
    const isExchangeCampaign = appData.sdkConfiguration.campaign === CAMPAIGN_EXCHANGE;
    const [loader, setLoader] = useState(false);
    const [claims, setClaims] = useState(4);
    const [expanded, setExpanded] = useState(false);
    const [everWallet, setEverWallet] = useState('');
    const [showAdditionalData, setShowAdditionalData] = useState(false);
    const [kycData, setKycDate] = useState('');
    const [twitterHandler, setTwitterHandler] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [refCount, setRefCount] = useState(0);
    const [refCountLoader, setRefCountLoader] = useState(true);

    let orgDisplay = userData.orgs;
    if(orgDisplay && orgDisplay.length > 1) {
        orgDisplay = orgDisplay[1];
    }

    const handleChange = (panel) =>  {
        setExpanded(panel === expanded ? false : panel);
    };

    const getClaims = () => {
        if(showAdditionalData) {
            setClaims((claims) => claims + 5);
        }
    };

    const twitterPost = () => {
        navigate('/twitter', {state: {share: true}});
    };

    useEffect(() => {
        if(isExchangeCampaign) {
            setExpanded('panel4');
        } else {
            setExpanded('panel3');
        }
    }, [isExchangeCampaign]);

    useEffect(() => {
        getClaims();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAdditionalData]);

    useEffect(() => {
        helpers.twitterInfo()
            .then((response) => {
                if (response.data.success) {
                    const respData = response.data.data;
                    const screenName = respData.screen_name;
                    setClaims((claims) => claims + 1);
                    setTwitterHandler(screenName);
                    if(twitterFlow && !respData.share_opt_in) {
                        setConfirmDialog({
                            title: "Twitter Share",
                            message: "To post a tweet, update your profile and bio on Twitter, sharing your verified EverWallet, click Continue",
                            confirmButtonText: "Continue",
                            onConfirm: () => twitterPost(),
                            cancelButtonShow: false,
                            height: '490px'
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });

        helpers.getKycStatus()
            .then((response) => {
                if (response.data.success) {
                    const respData = response.data.data;
                    if(respData.isKYCUser && respData.status === "FULLY_VERIFIED") {
                        setShowAdditionalData(true);
                    }
                } else {
                    snackBarMessage({message: 'Something went wrong.'});
                }
            })
            .catch((error) => {
                snackBarMessage({message: 'Something went wrong.'+error});
            });

        helpers.getReferralCode()
            .then((response) => {
                if (response.data.success) {
                    const respData = response.data.data;
                    setReferralCode(respData.code);
                } else {
                    snackBarMessage({message: 'Something went wrong.'});
                }
            })
            .catch((error) => {
                snackBarMessage({message: 'Something went wrong.'+error});
            });
        helpers.getTotalNumberOfReferrals()
            .then((response) => {
                setRefCountLoader(false);
                if (response.data.success) {
                    setRefCount(response.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(showAdditionalData && everWallet) {
            helpers.getKycStatusByAddress(everWallet)
                .then((response) => {
                    if (response.data.success) {
                        const respData = response.data.data;
                        setKycDate(respData.kycDate);
                    } else {
                        snackBarMessage({message: 'Something went wrong.'});
                    }
                })
                .catch((error) => {
                    snackBarMessage({message: 'Something went wrong.'+error});
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [everWallet, showAdditionalData]);

    const copyAffiliateLink = () => {
        const routePath = "/r1/";
        navigator.clipboard.writeText(WALLET_URL+routePath+referralCode)
        snackBarMessage({
            message: 'Verify Me Link has been copied successfully.',
            type: 'success'
        });
    };

    const copyReferralCode = () => {
        navigator.clipboard.writeText(referralCode)
        snackBarMessage({
            message: 'Referral Code has been copied successfully.',
            type: 'success'
        });
    };

    const referralResult = () => {
        setConfirmDialog({
            icon: InfoIcon,
            title: "Referral Result",
            message: `Your Referral Code has been used <strong>${refCount}</strong> number of times.`,
            confirmButtonShow: true,
            confirmButtonText: "Close",
            cancelButtonShow: false,
            height: '350px'
        });
    };

    return (
        <Box mt={8}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box className="dashboard-email-item">
                <Grid container>
                    <Grid item={true} xs={2}>
                        <Avatar src={'data:image/jpeg;base64,'+ userData.photoThumbnail} sx={{ width: 50, height: 50, marginTop: '-5px' }}  />
                    </Grid>
                    <Grid item={true} xs={0.5} />
                    <Grid item={true} xs={9.5} className="content-center">
                        <p className='dashboard-email-text font-bold'>{userData.email ? userData.email : everWallet}</p>
                    </Grid>
                </Grid>
            </Box>

            {isExchangeCampaign && <MarketAccordion Accordion={Accordion} AccordionSummary={AccordionSummary} AccordionDetails={AccordionDetails} handleChange={handleChange} expanded={expanded} navigate={navigate} />}
            <WalletAccordion snackBarMessage={snackBarMessage} Accordion={Accordion} AccordionSummary={AccordionSummary} AccordionDetails={AccordionDetails} handleChange={handleChange} expanded={expanded} setEverWallet={setEverWallet} navigate={navigate} />
            <Box sx={{width: '100%', mt: 4}}>
                <Accordion expanded={expanded === 'panel3'} onChange={() => handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3bh-content" id="panel3bh-header">
                        <Grid container>
                            <Grid item={true} xs={12}>
                                <Typography variant="caption">Profile Information</Typography>
                            </Grid>
                            <Grid item={true} xs={12} className="content-center">
                                <p className='dashboard-email-text'>{claims} Claims</p>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails sx={{maxHeight: '500px', overflowY: 'auto'}}>
                        <Box className="accordion-list-item">
                            <Grid container>
                                <Grid item={true} xs={2.5} className="content-center">
                                    <Avatar src={'data:image/jpeg;base64,'+  userData.photoThumbnail} sx={{ width: 50, height: 50, marginTop: '-5px' }}  />
                                </Grid>
                                <Grid item={true} xs={5} className="content-center">
                                    <Typography variant="caption">Human & Unique</Typography>
                                </Grid>
                                {twitterHandler &&
                                    <Grid item={true} xs={4.5} className="content-center">
                                        <button className="post-twitter-btn aff-btn" onClick={(e) => {copyAffiliateLink(); e.preventDefault();}}>Verify Me Link</button>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                        <Box className="accordion-list-item">
                            <Typography variant="caption">Phone (mobile)</Typography>
                            <p className='dashboard-email-text'>+{userData.countryCode} {userData.phoneNumber}</p>
                        </Box>
                        <Box className="accordion-list-item">
                            <Typography variant="caption">EverWallet</Typography>
                            <p title={everWallet} className='dashboard-email-text'>{everWallet}</p>
                            <Typography variant="caption"><b><a href="https://wallet.everest.org" rel="noreferrer" target="_blank">https://wallet.everest.org</a></b></Typography>
                        </Box>
                        <Box className="accordion-list-item">
                            <Typography variant="caption">Referral Code - share Everest with others</Typography>
                            <p title={everWallet} className='dashboard-email-text'>{referralCode}</p>
                            <div className="twitter-btn-div">
                                <button className="post-twitter-btn aff-btn" onClick={(e) => {copyReferralCode(); e.preventDefault();}}>Copy Referral Code</button>
                                <button className="post-twitter-btn" style={{marginLeft: "5px"}} onClick={(e) => {referralResult(); e.preventDefault();}} disabled={refCountLoader}>Referral Results</button>
                            </div>
                        </Box>
                        <TwitterTile twitterHandler={twitterHandler} isNonMlmCampaign={isNonMlmCampaign} referralCode={referralCode} setLoader={setLoader} snackBarMessage={snackBarMessage} />
                        {showAdditionalData ?
                            <>
                                <Box className="accordion-list-item">
                                    <Typography variant="caption">Verified Name</Typography>
                                    <p title={everWallet} className='dashboard-email-text'>{userData.firstName} {userData.lastName}</p>
                                </Box>
                                <Box className="accordion-list-item">
                                    <Typography variant="caption">Verified Email</Typography>
                                    <p title={everWallet} className='dashboard-email-text'>{userData.email}</p>
                                </Box>
                                <Box className="accordion-list-item">
                                    <Typography variant="caption">Verified Date of Birth</Typography>
                                    <p title={everWallet} className='dashboard-email-text'>{userData.birthDate}</p>
                                </Box>
                                <Box className="accordion-list-item">
                                    <Typography variant="caption">Verified State of Residence</Typography>
                                    <p title={everWallet} className='dashboard-email-text'>{userData.residentialAddress.state}</p>
                                </Box>
                                <Box className="accordion-list-item">
                                    <Typography variant="caption">Verified Country of Residence</Typography>
                                    <p title={everWallet} className='dashboard-email-text'>{userData.residentialAddress.country}</p>
                                </Box>
                                {kycData &&
                                <Box className="accordion-list-item">
                                    <Typography variant="caption">Everest KYCed</Typography>
                                    <p title={everWallet} className='dashboard-email-text'>{moment(kycData).format("DD MMMM yyyy")}</p>
                                </Box>
                                }
                            </>
                            :
                            <Box className="accordion-list-item cursor-pointer" onClick={() => navigate('/kyc')}>
                                <Typography variant="caption">KYCed Status</Typography>
                                <Grid container spacing={0}>
                                    <Grid item={true} xs={10} className="content-center">
                                        <p title={everWallet} className='dashboard-email-text'>Get KYCed Now</p>
                                    </Grid>
                                    <Grid item={true} xs={2} className="text-right">
                                        <img src={AddBtn} width={20} alt="Add" />
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        <Box className="accordion-list-item">
                            <Typography variant="caption">Member of</Typography>
                            <p className='dashboard-email-text'>{orgDisplay}</p>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <DocumentAccordion snackBarMessage={snackBarMessage} Accordion={Accordion} AccordionSummary={AccordionSummary} AccordionDetails={AccordionDetails} handleChange={handleChange} expanded={expanded} />
            <ConfirmDialog />
        </Box>
    );
};

export default Dashboard;