import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from 'react-router-dom';
import {Box, Container, Divider, Typography} from "@mui/material";
import {isLoggedIn} from "../../../util/userFunctions";
import ConnectWallet from '../../Shared/connectWallet';
import Button from '../../Shared/button';
import {selectAppData, setConnectWallet} from "../../../store/reduxSlice";
import { CAMPAIGN_NON_MLM, CAMPAIGN_TWITTER } from '../../../js/constants';
import Logo from "../../../images/logo.png";
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) ~ :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));

const Home = ({snackBarMessage}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoggedIn()) {
            navigate('/dashboard')
        }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [navigate]);

    const signIn = () => {
        navigate('/signin')
    };

    const signUp = () => {
        navigate('/signup')
    };

    const walletConnect = (address) => {
        dispatch(setConnectWallet(address));
        navigate('/signup')
    };

    return (
        <Box className="identity-home-wrapper">
            <Container component="main" maxWidth="xs">
                <Box className="login-container">
                    <img className="sign-in-logo-identity" src={Logo} width={130} alt='logo' />
                    <Typography fontWeight={500} fontSize={16} mt={4} sx={{lineHeight: 2}}>
                        Access EverWallet to buy, swap, sell,<br />send and receive crypto!
                    </Typography>

                    <Box sx={{padding: "0px 20px", width: "100%"}}>
                        <Button onClick={signUp} sx={{mt: 12, textTransform: 'inherit', height: '50px'}} fullWidth>
                            <strong>Create Account</strong>
                        </Button>

                        <Root sx={{mt: 4, color: "#C4C4C4"}}>
                            <Divider role="presentation">Or</Divider>
                        </Root>

                        <ConnectWallet snackBarMessage={snackBarMessage} customStyle={{"&.MuiButtonBase-root:hover": {bgcolor: "black"}, background: "black", textTransform: 'inherit', height: '50px', mt: 4}} from={"identity"} addressHandler={walletConnect} />
                        
                        <Button highlightButton={true} onClick={signIn} sx={{mt: 5, textTransform: 'inherit', height: '50px', color: 'black', border: '1px solid black'}} fullWidth>
                            <strong>Sign in</strong>
                        </Button>
                    </Box>                   
                </Box>
            </Container>
        </Box>
    );
};

export default Home;