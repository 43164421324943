import { createTheme } from "@mui/material";

// const queryParams = new URLSearchParams(window.location.search);
// const buttonColor = queryParams.get("buttonColor") || "";
// const buttonTextColor = queryParams.get("buttonTextColor") || "";
// const isValidButtonColor = /^#[0-9A-F]{6}$/i.test("#"+buttonColor);

const defaultTheme = createTheme({
    typography: {
        fontFamily: [
            "Spartan",
            "sans-serif"
        ].join(",")
    },
    palette: {
        primary: {
            main: "#000000",
        },
        secondary: {
            main: "#16359D",
        },
        custom: {
            light: '#000000',
            main: '#000000',
            dark: '#000000',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        action: {
            disabled: '#000000',
        }
    },
    fee: {
        color: "#A6A6A6"
    },
    customGray: {
        color: "#A6A6A6"
    },
    tradeInput: {
        borderRadius: "0px",
    },
    components: {
        MuiButton: {
            styleOverrides: {
                connectWallet: {
                    padding: "10px 12px",
                    borderRadius: "100px",
                    background: "white",
                    border: "3px solid #000000",
                    color: "#000000",
                    fontSize: "14px",
                    textTransform: "none",
                    boxShadow: "none",
                    '&:hover': {
                        // border: "3px solid #000000",
                        background: "#000000",
                        color: "white",
                    },
                    '&:disabled': {
                        border: "3px solid #A6A6A6",
                        background: "#FFF",
                        color: "#A6A6A6",
                    },
                },
                themeContained: {
                    padding: "10px 12px",
                    borderRadius: "100px",
                    background: "white",
                    border: "3px solid #000000",
                    color: "#000000",
                    fontSize: "14px",
                    textTransform: "none",
                    boxShadow: "none",
                    '&:hover': {
                        padding: "13px 15px",
                        border: "none",
                        color: "white",
                        background: "linear-gradient(89.98deg, #35E0F4 0.02%, #33D7F0 6.97%, #2EBDE4 18.55%, #2594D0 33.31%, #195BB6 50.5%, #1132A3 61.64%, #1132A3 99.98%)"
                    },
                    '&:disabled': {
                        border: "3px solid #A6A6A6",
                        background: "#FFF",
                        color: "#A6A6A6",
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: "#db3131",
                    "&$error": {
                        color: "#db3131",
                    },
                },
            },
        },
    },
});

export default defaultTheme;