import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from 'react-router-dom';
import helpers from '../../adapters/helpers';
import {setConfirmDialog} from "../Shared/ConfirmDialog";
import {Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import Button from '../Shared/button';
import Step from "../Shared/step";
import {setBackFunc} from "../../store/reduxSlice";
import {selectInCodeData} from "../Shared/InCode/reduxSlice";

const UserInfo = ({snackBarMessage, userInfo, setUserInfo, setShowScreen, InputField, countries}) => {

    const navigate = useNavigate();
    const inCodeData = useSelector(selectInCodeData).documentsData;
    const [gender, setGender] = useState(userInfo.gender || '');
    const [externalGender, setExternalGender] = useState(false);
    const [email, setEmail] = useState(userInfo.email || '');
    const [disableEmail, setDisableEmail] = useState(false);
    const [dob, setDob] = useState('');
    const [address, setAddress] = useState(userInfo.address || '');
    const [city, setCity] = useState(userInfo.city || '');
    const [state, setState] = useState(userInfo.state || '');
    const [zipCode, setZipCode] = useState(userInfo.zipCode || '');
    const [externalAddress, setExternalAddress] = useState(false);
    let documentDataCalls = 1;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setBackFunc(() => setShowScreen('selectDocument')));
    }, [setShowScreen, dispatch]);

    useEffect(() => {
        if(inCodeData.issuingCountry) {
            const respData = inCodeData;
            if(respData.gender) {
                setGender(respData.gender);
                setExternalGender(true);
            }
            const country = countries.find((c) => c.alpha3Code === inCodeData.issuingCountry);
            setUserInfo({...userInfo, country, nationality: country});
            const birthDateWithTimeZone = new Date(respData.birthDate);
            const userTimeZoneOffset = birthDateWithTimeZone.getTimezoneOffset() * 60000;
            const birthDate = new Date(respData.birthDate + userTimeZoneOffset);
            setDob(birthDate.toLocaleDateString('en-US'));
            if (respData.checkedAddressBean?.street) {
                const addressData = respData.checkedAddressBean;
                setExternalAddress(true);
                setAddress(addressData.street);
                setCity(addressData.city);
                setState(addressData.state);
                setZipCode(addressData.postalCode);
            }
        } else {
            getKycDocumentsData();
        }
        getUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserData = () => {
        helpers.getProfile()
            .then((response) => {
                const userData = response.data.users;
                if(userData.email) {
                    setEmail(userData.email);
                    setDisableEmail(true);
                }
            });
    };

    const getKycDocumentsData = () => {
        helpers.getKycDocumentsData()
            .then(docProcessSuccess)
            .catch(handleDocGetError);
    };

    const handleDocGetError = (error) => {
        if (error.response) {
            const myObject = JSON.parse(error.request.response);
            if (myObject.code.message === 'DOCUMENT_PROCESS_IN_PROGRESS' || myObject.code.message === 'DOCUMENT_DOES_NOT_EXIST') {
                if (documentDataCalls < 18) {
                    setConfirmDialog({
                        title: "Document Information",
                        message: "Please wait few seconds to get the data from documents.",
                        cancelButtonShow: false,
                        confirmButtonText: "Continue",
                        height: '450px'
                    });
                    setTimeout(() => {
                        documentDataCalls++;
                        getKycDocumentsData();
                    }, 10000);
                } else {
                    setConfirmDialog({
                        title: "Error",
                        message: "We are unable to verify the documents. Please upload documents once again.",
                        confirmButtonText: "Upload documents",
                        onConfirm: () => {setShowScreen('selectDocument')},
                        cancelButtonText: "Cancel",
                        onCancel: () => {navigate('/')},
                        height: '480px'
                    });
                }
            } else if (myObject.code.message === 'DOCUMENT_NOT_APPROVED_BY_JUMIO') {
                setConfirmDialog({
                    title: "Error",
                    message: "Provided document is not approved. Please provide the valid document again.",
                    confirmButtonText: "Upload documents",
                    onConfirm: () => {setShowScreen('selectDocument')},
                    cancelButtonText: "Cancel",
                    onCancel: () => {navigate('/')},
                    height: '480px'
                });
            } else if (myObject.code.message === 'DOCUMENT_UNSUPPORTED_COUNTRY_OR_TYPE') {
                setConfirmDialog({
                    title: "Error",
                    message: "Provided document is not supported. Please provide the different document.",
                    confirmButtonText: "Select Document",
                    onConfirm: () => {setShowScreen('selectDocument')},
                    cancelButtonShow: false,
                    height: '450px'
                });
            } else {
                setConfirmDialog({
                    title: "Error",
                    message: myObject.code.message,
                    onConfirm: () => {setShowScreen('selectDocument')},
                    cancelButtonShow: false,
                    confirmButtonText: "Continue",
                    height: '450px'
                });
            }
        } else if (error.request) {
            window.alert(error.request);
        } else {
            window.alert(error.message);
        }
    };

    const docProcessSuccess = (response) => {
        if (response.data.success) {
            setConfirmDialog({title: ""});
            const respDataArr = response.data.data;
            for (let i = 0; i < respDataArr.length; i++) {
                if (!response.data.data[i].isFaceMatch) {
                    setConfirmDialog({
                        title: "Error",
                        message: "Please provide your own valid documents.",
                        onConfirm: () => {navigate('/kyc')}
                    });
                    return false;
                }
                const respData = response.data.data[i];
                if(respData.gender) {
                    setGender(respData.gender);
                    setExternalGender(true);
                }
                setDob(respData.dateOfBirth);
                if (respData.address.street) {
                    const addressData = respData.address;
                    setExternalAddress(true);
                    setAddress(addressData.street);
                    setCity(addressData.city);
                    setState(addressData.state);
                    setZipCode(addressData.postcode);
                }
            }
        } else {
            snackBarMessage({
                message: 'Something went wrong while getting documents data. Please try again',
                type: 'error',
            });
        }
    };

    const submitData = () => {
        if(!dob) {
            snackBarMessage({message: 'Please provide date of birth'});
            return false;
        }
        if(!gender) {
            snackBarMessage({message: 'Please provide gender'});
            return false;
        }
        if(!address) {
            snackBarMessage({message: 'Please provide address'});
            return false;
        }
        if(!city) {
            snackBarMessage({message: 'Please provide city'});
            return false;
        }
        if(!state) {
            snackBarMessage({message: 'Please provide state'});
            return false;
        }
        if(!zipCode) {
            snackBarMessage({message: 'Please provide zip code'});
            return false;
        }
        setUserInfo({...userInfo, gender, email, address, city, state, zipCode});
        setShowScreen("personalDetails")
    };

    return (
        <>
            <Step totalSteps={4} currentStep={2} />
            <Box pl={2} mt={4}>
                <Typography variant="h5" sx={{fontWeight: 'bold'}}>Personal details</Typography>
                <Typography variant="caption">Good news! Some of the information  will be populated from scanning  your document earlier.</Typography>
                <InputField label={"Date of Birth"} value={dob} disabled={true} />
                <FormControl sx={{mt: 3}} fullWidth>
                    <InputLabel>Gender</InputLabel>
                    <Select
                        value={gender}
                        label="Gender"
                        onChange={(e) => setGender(e.target.value)}
                        disabled={!!(externalGender && gender)}
                    >
                        <MenuItem key="M" value="M">Male</MenuItem>
                        <MenuItem key="F" value="F">Female</MenuItem>
                        <MenuItem key="X" value="X">Another</MenuItem>
                    </Select>
                </FormControl>
                <InputField label={"Email"} value={email} onChangeHandler={(val) => setEmail(val)} disabled={disableEmail} />
                <InputField label={"Home Address"} value={address} onChangeHandler={(val) => setAddress(val)} disabled={!!(externalAddress && address)} />
                <Grid container>
                    <Grid item={true} xs={6}>
                        <InputField label={"City"} width={'95%'} value={city} onChangeHandler={(val) => setCity(val)} disabled={!!(externalAddress && city)} fullWidth={false} />
                    </Grid>
                    <Grid item={true} xs={6}>
                        <InputField label={"State"} value={state} onChangeHandler={(val) => setState(val)} />
                    </Grid>
                </Grid>
                <InputField label={"Zipcode"} value={zipCode} onChangeHandler={(val) => setZipCode(val)} disabled={!!(externalAddress && zipCode)} />
            </Box>

            <Button
                className="sdk-theme-btn normal-text"
                variant="contained"
                onClick={submitData}
                disabled={!email || !address || !gender}
                fullWidth
            >
                <strong>Next</strong>
            </Button>
        </>
    );
};

export default UserInfo;