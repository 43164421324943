import Web3 from 'web3';
import { fromHuman, toHuman } from '../js/conversions';
import { META_MASK } from '../js/constants';

export const web3 = new Web3();

export const convertEthToWei = (eth) => {
    return web3.utils.toWei(eth, 'ether');
};

export const getGweiFromWei = (amount) => {
    //Conversion from WEI to GWEI..
    return web3.utils.fromWei(amount, 'gwei');
};

export const getFormattedValueFromWei = (weiAmount) => {
    //Conversion from WEI to Ether/ID..
    let eth = web3.utils.fromWei(weiAmount, 'ether');
    return numberFormat(eth);
};

export const getUnFormattedValueFromWei = (weiAmount) => {
    //Conversion from WEI to Ether/ID..
    return web3.utils.fromWei(weiAmount, 'ether');
};

export const numberFormat = (amount) => {
    let newValue = amount.split('.');
    //if value is zero return value..
    if (parseFloat(amount) === 0) {
        return '0';
    }

    //Split using "." and parse number..
    if (newValue.length === 2) {
        //Get before and after decimal part..
        let beforeDecimal = newValue[0];
        let afterDecimal = newValue[1];
        if (parseInt(afterDecimal) === 0) {
            return parseFloat(amount).toFixed(1).toString();
        } else if (afterDecimal.substring(0, 6) === '000000')
            if (beforeDecimal === '0') {
                return '< 0.000001';
            } else {
                return amount.slice(0, amount.indexOf('.') + 2);
            }
        else if (afterDecimal.substring(0, 3) === '000') {
            if (
                beforeDecimal === '0' &&
                afterDecimal.length >= 6 &&
                afterDecimal.substring(0, 6) !== '000'
            ) {
                return amount.slice(0, amount.indexOf('.') + 7);
            } else {
                return amount.slice(0, amount.indexOf('.') + 6);
            }
        } else {
            return amount.slice(0, amount.indexOf('.') + 4);
        }
    } else {
        return parseFloat(amount).toFixed(1).toString();
    }
};

export const numberFormatWithoutDisplay = (amount) => {
    let newValue = amount.split('.');
    //if value is zero return value..
    if (parseFloat(amount) === 0) {
        return '0';
    }

    //Split using "." and parse number..
    if (newValue.length === 2) {
        //Get before and after decimal part..
        let beforeDecimal = newValue[0];
        let afterDecimal = newValue[1];
        if (parseInt(afterDecimal) === 0) {
            return parseFloat(amount).toFixed(1).toString();
        } else if (afterDecimal.substring(0, 6) === '000000')
            if (beforeDecimal === '0') {
                return '0';
            } else {
                return amount.slice(0, amount.indexOf('.') + 2);
            }
        else if (afterDecimal.substring(0, 3) === '000') {
            if (
                beforeDecimal === '0' &&
                afterDecimal.length >= 6 &&
                afterDecimal.substring(0, 6) !== '000'
            ) {
                return amount.slice(0, amount.indexOf('.') + 7);
            } else {
                return amount.slice(0, amount.indexOf('.') + 2);
            }
        } else {
            return amount.slice(0, amount.indexOf('.') + 4);
        }
    } else {
        return parseFloat(amount).toFixed(1).toString();
    }
};

const erc20ABI = [
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "decimals",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "recipient",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "sender",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "recipient",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    }
];

const getProvider = (provider) => {
    if(!provider) {
        const rpcUrl = "https://eth-mainnet.public.blastapi.io"; //https://rpc.ankr.com/eth/67768a20d3594251d88c4386d1b887ac8e2015a278e014aa824385f69954eca7
        provider = new Web3.providers.HttpProvider(rpcUrl);
    }
    const web3 = new Web3(provider);
    return web3;
};

export const getTokenBalance = async (walletAddress, token, provider) => {
    const web3 = getProvider(provider);
    const contract = new web3.eth.Contract(erc20ABI, token.contractAddress);
    const ercBalance = await contract.methods.balanceOf(walletAddress).call();
    const balance = toHuman(ercBalance, token.decimal, token.decimal) * 1;
    return balance;
};

export const getEthBalance = async (walletAddress, provider) => {
    const web3 = getProvider(provider);
    const nativeBalance = await web3.eth.getBalance(walletAddress);
    return web3.utils.fromWei(nativeBalance, 'ether');
}

export const validateTxHash = (hash) => {
    return /^0x([A-Fa-f0-9]{64})$/.test(hash);
}

export const sendProviderTransaction = (data) => {
    const {fromAddress, toAddress, token, amount, provider, metaMaskProvider, handleError, handleSuccess} = data;
    let providerApp;
    if(provider === META_MASK) {
        providerApp = metaMaskProvider;
    } else {
        providerApp = window.coin98.provider;
    }
    const Web3Provider = new Web3(providerApp);
    const amountInBaseUnits = fromHuman(amount, token.decimal);
    if(token.symbol !== "ETH") {
        const tokenContract = new Web3Provider.eth.Contract(erc20ABI, token.contractAddress);
        tokenContract.methods.transfer(toAddress, amountInBaseUnits).send({ from: fromAddress })
        .then(data => {
            console.log('Transaction Data:', data);
            handleSuccess(data.transactionHash);
        })
        .catch(handleError);
        // .on('transactionHash', function(hash){
        //     console.log('Transaction Hash:', hash);
        //     handleSuccess(hash);            
        // })
        // .on('receipt', function(receipt){
        //     console.log('Transaction Receipt:', receipt);
        // })
        // .on('confirmation', function(confirmationNumber, receipt){
        //     console.log('Confirmation Number:', confirmationNumber);
        // })
        // .on('error', handleError)
    } else {
        Web3Provider.eth.sendTransaction({
            from: fromAddress,
            to: toAddress,
            value: amountInBaseUnits.toString(16)
        })
        .then(data => {
            console.log('Transaction Data:', data);
            handleSuccess(data.transactionHash);
        })
        .catch(handleError);
        // .on('transactionHash', function(hash){
        //     console.log('Transaction Hash:', hash);
        //     handleSuccess(hash);
        // })
        // .on('receipt', function(receipt){
        //     console.log('Transaction Receipt:', receipt);
        // })
        // .on('confirmation', function(confirmationNumber, receipt){
        //     console.log('Confirmation Number:', confirmationNumber);
        // })
        // .on('error', handleError)
    }
};