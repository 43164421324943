import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import GenericTokenImage from "../../images/generic-token.png";
import { tokenBalanceDisplay } from "../../util/generalUtil";
import BootstrapTooltip from "../Shared/bootstrapToolTip";

const TokenRow = ({token}) => {
    const balance = tokenBalanceDisplay(token);
    const balanceLength = (balance+"").length;
    return (
        <Grid container spacing={2}>
            <Grid className="balance-token-cell" item={true} xs={7}>
                <img
                    src={token.image}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = GenericTokenImage;
                    }}
                    alt="token Icon"
                    width={50}
                />
                <span className='balance-token-symbol' title={token.symbol}>{token.symbol}</span>
            </Grid>
            <Grid item={true} xs={4}>
                <Box className="text-overflow-cut" sx={{marginTop: '15px', textAlign: 'left'}}>
                    {balanceLength > 9 ? <BootstrapTooltip title={balance} placement="top"><span>{balance}</span></BootstrapTooltip> : <span>{balance}</span>}
                </Box>
            </Grid>
            {token.send &&
                <Grid item={true} xs={1}>
                    <Typography sx={{marginTop: '15px', textAlign: 'right', color: '#195BB6'}}>
                        <ArrowForwardIosIcon />
                    </Typography>
                </Grid>
            }
        </Grid>
    );
};

export default TokenRow;