import React, {useState, useEffect} from 'react';
import moment from "moment";
import {useSelector} from "react-redux";
import {Box, Button, Typography} from "@mui/material";
import helpers from '../../adapters/helpers';
import Step from "../Shared/step";
import {removeExtraSpace} from "../../util/generalUtil";
import {selectUserData} from "../../store/reduxUserSlice";

const PersonalDetails = ({snackBarMessage, userInfo, setUserInfo, setShowScreen, InputField, handleChange}) => {

    const userData = useSelector(selectUserData);
    const [firstName, setFirstName] = useState(userInfo.firstName || '');
    const [lastName, setLastName] = useState(userInfo.lastName || '');
    const [dob, setDob] = useState(userInfo.dob || '');
    const [disableFirstName, setDisableFirstName] = useState(false);
    const [disableLastName, setDisableLastName] = useState(false);
    const [firstNameErr, setFirstNameErr] = useState(false);
    const [lastNameErr, setLastNameErr] = useState(false);
    const [dobErr, setDobErr] = useState(false);
    const nameRegex = /^[a-z ,.'-]+$/i;

    useEffect(() => {
        if(userData.firstName === "" && userInfo.firstName === "") {
            getUserData();
        } else if(userInfo.firstName === "") {
            setFirstName(userData.firstName);
            setLastName(userData.lastName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserData = () => {
        helpers.getProfile()
            .then((response) => {
                const userData = response.data.users;
                if(userData.firstName) {
                    setFirstName(userData.firstName);
                    setDisableFirstName(false); //true
                }
                if(userData.lastName) {
                    setLastName(userData.lastName);
                    setDisableLastName(false); //true
                }
            });
    };

    const submitData = () => {
        setFirstNameErr(false);
        setLastNameErr(false);
        setDobErr(false);
        if(!firstName) {
            setFirstNameErr(true);
        }
        if(!lastName) {
            setLastNameErr(true);
        }
        if(!dob) {
            setDobErr(true);
        }

        if(!moment(dob).isValid()) {
            setDobErr(true);
            return false;
        }

        const ageInYears = moment().diff(dob, 'years', false);
        if(ageInYears < 18) {
            snackBarMessage({message: "You must be at least 18 years of age."});
            setDobErr(true);
            return false;
        }

        if(!firstName || !lastName || !dob) {
            return false;
        }

        setUserInfo({...userInfo, firstName, lastName, dob});
        setShowScreen("addressDetails")
    };

    return (
        <>
            <Step totalSteps={2} currentStep={1} />
            <Box pl={2} mt={4}>
                <Typography variant="h5" sx={{fontWeight: 'bold'}}>What is your name?</Typography>
                <InputField label={"First Name"} value={firstName} onChangeHandler={val => handleChange(val, setFirstName, setFirstNameErr, nameRegex)} disabled={disableFirstName} error={firstNameErr} />
                <InputField label={"Last Name"} value={lastName} onChangeHandler={val => handleChange(val, setLastName, setLastNameErr, nameRegex)} disabled={disableLastName} error={lastNameErr} />
                <InputField label={"Date of Birth"} value={dob} onChangeHandler={val => handleChange(val, setDob, setDobErr)} type="date" error={dobErr} shrink={true} />
            </Box>
            <Button
                className="login-buttons navigation-login-background normal-text mt32"
                variant="contained"
                onClick={submitData}
                disabled={!removeExtraSpace(firstName) || !removeExtraSpace(lastName) || !dob}
                fullWidth
            >
                <strong>Next</strong>
            </Button>
        </>
    );
};

export default PersonalDetails;