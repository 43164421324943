import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import {Box, Divider, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, Button} from "@mui/material";
import {getDocName} from "../../util/generalUtil";
import helpers from "../../adapters/helpers";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import {setConfirmDialog} from "../Shared/ConfirmDialog";
import Delete from "../../images/delete.png";

const Documents = ({snackBarMessage}) => {
    const [identityDocs, setIdentityDocs] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [frontImage, setFrontImage] = useState('');
    const [backImage, setBackImage] = useState('');
    const [loader, setLoader] = useState(true);
    const [previewLoader, setPreviewLoader] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const {state} = useLocation();
    if(state && state.identityDocs && !identityDocs) {
        setIdentityDocs(state.identityDocs);
    }

    useEffect(() => {
        if(!state) {
            helpers.getProfile()
                .then((response) => {
                    setLoader(false);
                    const userData = response.data.users;
                    setIdentityDocs(userData.identityDocuments);
                })
                .catch(() => {
                    setLoader(false);
                    snackBarMessage({message: 'Unable to get data. Please refresh the page and try again.'});
                });
        }
        helpers.getUserDocuments()
            .then((response) => {
                setLoader(false);
                if(response.data.documents) {
                    const userDocs = response.data.documents;
                    setDocuments(userDocs);
                }
            })
            .catch(() => {
                setLoader(false);
                snackBarMessage({message: 'Unable to get data. Please refresh the page and try again.'});
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const previewDoc = (docId) => {
        if(!previewLoader) {
            setPreviewLoader(true);
            helpers.getIdentityDocImage(docId)
                .then((response) => {
                    setPreviewLoader(false);
                    if (response.data.success) {
                        const {front, back} = response.data.data;
                        setFrontImage(front);
                        setBackImage(back);
                    } else {
                        window.alert('Failed to get Documents Preview');
                    }
                })
                .catch((error) => {
                    setPreviewLoader(false);
                    window.alert(error);
                });
        }
    };

    const previewDocFile = (name) => {
        if(!previewLoader) {
            setPreviewLoader(true);
            helpers.getDocumentPreview(name)
                .then((response) => {
                    setPreviewLoader(false);
                    if (response.data.success) {
                        setFrontImage(response.data.file);
                    } else {
                        window.alert('Failed to get Documents Preview');
                    }
                })
                .catch((error) => {
                    setPreviewLoader(false);
                    window.alert(error);
                });
        }
    };

    const deleteConfirm = (fileName) => {
        setConfirmDialog({
            icon: Delete,
            title: "Are you sure you want to delete?",
            message: "This action cannot be undone.",
            onConfirm: () => {deleteDoc(fileName)}
        });
    };

    const deleteDoc = (fileName) => {
        setLoader(true);
        helpers.deleteUserDocument(fileName)
            .then((response) => {
                if (response.data.success) {
                    const newDocs = documents.filter(object => {
                        return object.filename !== fileName;
                    });
                    setDocuments(newDocs);
                    setLoader(false);
                    setIsDelete(false);
                    snackBarMessage({message: 'Document has been deleted successfully.', type : 'success'});
                } else {
                    snackBarMessage({message: 'Something went wrong.'});
                }
            })
            .catch((error) => {
                setLoader(false);
                snackBarMessage({message: 'Something went wrong.'+error});
            });
    };

    const DocumentItem = ({data, type}) => {
        if(type === "identity") {
            return (
                <Box className="menu-item" onClick={() => previewDoc(data.documentId)}>
                    <Grid container>
                        <Grid item={true} xs={10} className="content-center">
                            <Box sx={{textAlign: 'left'}}>
                                <p className='buy-token-list-name'>{getDocName(data.documentType)}</p>
                            </Box>
                        </Grid>
                        <Grid item={true} xs={2}>
                            <Typography sx={{textAlign: 'right'}}>
                                <ArrowRightIcon sx={{fontSize: '30px', color: '#195BB6'}} />
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            );
        } else {
            return (
                <Box className="menu-item" sx={{position: 'relative'}}>
                    {isDelete &&
                    <IconButton className="bank-remove-icon" onClick={() => deleteConfirm(data.filename)}>
                        <CancelIcon/>
                    </IconButton>
                    }
                    <Grid onClick={() => previewDocFile(data.filename)} container>
                        <Grid item={true} xs={10} className="content-center">
                            <Box sx={{textAlign: 'left'}}>
                                <p className='buy-token-list-name'>{data.filename}</p>
                            </Box>
                        </Grid>
                        <Grid item={true} xs={2}>
                            <Typography sx={{textAlign: 'right'}}>
                                <ArrowRightIcon sx={{fontSize: '30px', color: '#195BB6'}} />
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            );
        }
    };

    return (
        <>
            <Typography variant="h5" mt={8}>
                <strong>Documents</strong>
            </Typography>
            {documents.length > 0 &&
                <Button
                    className={`${isDelete ? "confirm-buy-delete-disable" : "confirm-buy-edit"} normal-text`}
                    sx={{mt: 8, mb: 6}}
                    variant="contained"
                    onClick={() => setIsDelete(prev => !prev)}>
                    <DeleteIcon/>Delete
                </Button>
            }
            {loader ?
                <Box className="text-center" mt={8}><CircularProgress/></Box> :
                <>
                    {identityDocs && identityDocs.map((doc, index) =>
                        <DocumentItem key={index} data={doc} type={"identity"}/>
                    )}

                    {documents && documents.map((doc, index) =>
                        <DocumentItem key={index} data={doc} type={"normal"}/>
                    )}
                    {!identityDocs && documents.length === 0 && <Typography mt={4} className="text-center" variant="h4">No Documents</Typography>}
                </>

            }
            {previewLoader && <Box className="text-center" mt={8}><CircularProgress/></Box>}
            <Dialog open={!!frontImage} fullWidth={true} onClose={() => setFrontImage('')}>
                <DialogTitle sx={{ mt: 1, p: 2, color: '#16359D' }}>
                    <IconButton
                        aria-label="close"
                        onClick={() => setFrontImage('')}
                        sx={{position: 'absolute', right: 8, top: 8, color: '#16359D'}}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider mt={2} />
                <DialogContent>
                    <Box>
                        <Typography variant="h5" mb={3}>Front Image</Typography>
                        <img className="video-box-inner-content" src={'data:image/jpeg;base64,' + frontImage} alt="Front"/>
                        {backImage &&
                            <>
                                <Typography variant="h5" mt={2} mb={3}>Back Image</Typography>
                                <img className="video-box-inner-content" src={'data:image/jpeg;base64,' + backImage} alt="Back"/>
                            </>
                        }
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Documents;