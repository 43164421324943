import React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { selectAppData } from '../../store/reduxSlice';

const isOneActive = (...features) => {
    return features.filter(Boolean).length === 1;
};

const BuySellLink = ({buyActive, sellActive, tradeActive}) => {
    const navigate = useNavigate();
    const appData = useSelector(selectAppData);
    const features = appData.features;
    const partnerData = appData.partnerData;
    if(isOneActive(features.buy, features.sell, features.trade) || isOneActive(partnerData.buy_feature_active, partnerData.sell_feature_active, partnerData.exchange_service_active)) {
        return (null);
    }
    let col = 4;
    if(!features.trade || !features.sell) {
        col = 6;
    }

    return (
        <Box className="buy-sell-btn">
            <Box sx={{marginTop: "6px"}}>
                <Grid container spacing={5} sx={{width: "370px"}}>
                    {features.buy && partnerData.buy_feature_active &&
                        <Grid className="buy-sell-item" item={true} xs={col}>
                            <Typography className={`buy-sell-text font-bold ${buyActive ? 'active' : ''}`} variant="h5" onClick={() => navigate('/')}>Buy</Typography>
                        </Grid>
                    }
                    {features.sell && partnerData.sell_feature_active &&
                        <Grid className="buy-sell-item" item={true} xs={col}>
                            <Typography className={`buy-sell-text font-bold ${sellActive ? 'active' : ''}`} variant="h5" onClick={() => navigate('/sell')}>Sell</Typography>
                        </Grid>
                    }
                    {features.trade && partnerData.exchange_service_active &&
                        <Grid className="buy-sell-item" item={true} xs={col}>
                            <Typography className={`buy-sell-text font-bold ${tradeActive ? 'active' : ''}`} variant="h5" onClick={() => navigate('/swap')}>Swap</Typography>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Box>
    );
};

export default BuySellLink;