import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {Box} from "@mui/material";
import Button from '../Shared/button';
import SendInfo from "./sendInfo";
import FooterReward from "./reward";
import { setBackFunc } from "../../store/reduxSlice";
import { useDispatch } from "react-redux";

const TokenInfo = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setBackFunc(() => navigate('/balance')));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const send = () => {
        navigate('/send');
    };

    const receive = () => {
        navigate('/receive');
    };
    
    return (
        <>
            <Box className="login-container" mt={8}>
                <Box mt={4} className="full-width">
                    <SendInfo />
                    <Button onClick={send} className="login-buttons navigation-login-background normal-text sign-up" variant="contained" mt={4} mb={4} fullWidth>
                        <strong>Send</strong>
                    </Button>
                    <Button onClick={receive} className="login-buttons navigation-login-border normal-text sign-in" mt={8} variant="contained" fullWidth>
                        <strong className="sign-up-label navigation-login-buttons">Receive</strong>
                    </Button>
                </Box>
            </Box>
            <FooterReward />
        </>   
    );
};

export default TokenInfo;