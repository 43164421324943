import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    quoteId: '',
    currency: '',
    provider: '',
    exchange: 'bitstamp',
    network: 'Ethereum',
    amount: '',
    token: '',
    tokenId: '',
    paymentMethod: '',
    paymentId: '',
    totalFee: 0,
    networkFee: 0,
    // providerFee: 0,
    everestFee: 0,
    estimatedTokenAmount: '',
    conversionRate: '',
    address: '',
    addressFrom: '',
    bank: {},
    tokenList: [],
};

export const buySlice = createSlice({
    name: 'buyData',
    initialState,
    reducers: {
        setNetwork: (state, action) => {
            state.network = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setTokenId: (state, action) => {
            state.tokenId = action.payload;
        },
        setPaymentMethod: (state, action) => {
            state.paymentMethod = action.payload;
        },
        setAmount: (state, action) => {
            state.amount = action.payload;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
        },
        setAddressFrom: (state, action) => {
            state.addressFrom = action.payload;
        },
        setBank: (state, action) => {
            state.bank = action.payload;
        },
        setTokenList: (state, action) => {
            state.tokenList = action.payload;
        },
        setData: (state, action) => {
            return {...state, ...action.payload};
        },
        reset: () => {
            return initialState;
        }
    },
});

export const { setNetwork, setToken, setTokenId, setPaymentMethod, setAmount, setAddress, setAddressFrom, setBank, setTokenList, setData, reset } = buySlice.actions;

export const selectBuyData = (state) => state.buyData;

export default buySlice.reducer;