import { createSlice } from '@reduxjs/toolkit';
import { NETWORK_ETHEREUM } from '../../js/constants';

const initialState = {
    wallet: {name: "", address: ""},
    token: {},
    network: {name: NETWORK_ETHEREUM, logo_uri: ""},
};

export const walletSlice = createSlice({
    name: 'walletData',
    initialState,
    reducers: {
        setWallet: (state, action) => {
            state.wallet = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setNetwork: (state, action) => {
            state.network = action.payload;
        },
    },
});

export const { setWallet, setToken, setNetwork } = walletSlice.actions;

export const selectWalletData = (state) => state.walletData;

export default walletSlice.reducer;