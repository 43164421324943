import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import { useNavigate } from 'react-router-dom';
import {Box, Container, Typography} from "@mui/material";
import {isLoggedIn} from "../../util/userFunctions";
import ConnectWallet from '../Shared/connectWallet';
import {setConnectWallet} from "../../store/reduxSlice";
import Logo from "../../images/logo.png";

const Home = ({snackBarMessage}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoggedIn()) {
            navigate('/dashboard')
        }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [navigate]);

    const walletConnect = (address) => {
        dispatch(setConnectWallet(address));
        navigate('/signup')
    };

    return (
        <Box className="identity-home-wrapper">
            <Container component="main" maxWidth="xs">
                <Box className="login-container">
                    <img className="sign-in-logo" src={Logo} width={150} alt='logo' />
                    <Typography variant="h6" mt={3}><strong>An Everest Verified Wallet</strong></Typography>
                    <Box mt={20} sx={{width: "100%"}}>
                        <ConnectWallet autoOpen={true} snackBarMessage={snackBarMessage} from={"identity"} addressHandler={walletConnect} />   
                    </Box>                 
                </Box>
            </Container>
        </Box>
    );
};

export default Home;