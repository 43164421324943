import React from "react";
import {useSelector} from "react-redux";
import {selectConfirmDialogData, setData, setTitle} from './reduxSlice';
import {Box, Dialog, DialogContent, Typography} from "@mui/material";
import Button from "../button";
import store from "../../../store"
import DialogError from "../../../images/dialog-error.png";

export const setConfirmDialog = ({icon, title, message, height = "auto", width = "100%", onConfirm, onCancel, confirmButtonShow = true, confirmButtonText = "Yes", cancelButtonText = "No", cancelButtonShow = true}) => {
    // if(!title) {
    //     throw new Error("Please provide title param for confirm dialog component");
    // }
    icon = icon || DialogError;
    store.dispatch(setData({icon, title, message, height, width, onConfirm, onCancel, confirmButtonShow, confirmButtonText, cancelButtonShow, cancelButtonText}));
};

const ConfirmDialog = () => {
    const confirmDialogData = useSelector(selectConfirmDialogData);
    const close = () => store.dispatch(setTitle(""));

    const {icon, title, message, height, width, onConfirm, onCancel, confirmButtonShow, confirmButtonText, cancelButtonText, cancelButtonShow} = confirmDialogData;
    return (
        <Dialog PaperProps={{className: "confirm-dialog", sx: {height, width}}} open={!!title} fullWidth={true}>
            <DialogContent sx={{p: 0, height: '600px'}}>
                <Box mt={4} className="login-container" sx={{padding: '0px 40px'}}>
                    <img src={icon} alt={"icon"} />
                    <Typography className="confirm-dialog-title">{title}</Typography>
                    <Typography className="confirm-dialog-text" dangerouslySetInnerHTML={{__html: message}} />
                    {confirmButtonShow &&
                        <Button onClick={() => {if(onConfirm) {onConfirm();} close();}} className="sdk-theme-btn mt32 normal-text" variant="contained" fullWidth>
                            <strong>{confirmButtonText}</strong>
                        </Button>
                    }
                    {cancelButtonShow &&
                        <Button onClick={() => {if(onCancel) {onCancel();} close();}} highlightButton={true} className="login-buttons normal-text" variant="contained" fullWidth>
                            <strong className="sign-up-label navigation-login-buttons">{cancelButtonText}</strong>
                        </Button>
                    }
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ConfirmDialog;