import axios from 'axios';
import {  API_URL, ORGNAME, SECRET } from '../js/variables';

const apiUrl = API_URL;
const orgname = ORGNAME;
const secret = SECRET;
const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
const lookup = new Uint8Array(256);

for (let i = 0; i < chars.length; i++) {
    lookup[chars.charCodeAt(i)] = i;
}

const array2B64encode = (arraybuffer, byteOffset, length) => {
    if (length === null || length === undefined) {
        length = arraybuffer.byteLength;
    }
    const bytes = new Uint8Array(arraybuffer, byteOffset || 0, length);
    const len = bytes.length;

    let base64 = '';
    for (let i = 0; i < len; i += 3) {
        base64 += chars[bytes[i] >> 2];
        base64 += chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
        base64 += chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
        base64 += chars[bytes[i + 2] & 63];
    }

    if (len % 3 === 2) {
        base64 = base64.substring(0, base64.length - 1) + '=';
    } else if (len % 3 === 1) {
        base64 = base64.substring(0, base64.length - 2) + '==';
    }

    return base64;
};

const _createKongAuthHeader = (orgname, verb, url, date) => {
    const enc = new TextEncoder('utf-8');

    return window.crypto.subtle
        .importKey(
            'raw',
            enc.encode(secret),
            {
                name: 'HMAC',
                hash: 'SHA-256',
            },
            false,
            ['sign', 'verify']
        )
        .then((key) => {
            const signing_string = `${verb} ${url.pathname}${url.search} HTTP/1.1\nhost: ${url.hostname}\nx-date: ${date}`;

            return window.crypto.subtle.sign(
                'HMAC',
                key,
                enc.encode(signing_string)
            );
        })
        .then(array2B64encode)
        .then((signature) => {
            return `hmac username="${orgname}", algorithm="hmac-sha256", headers="request-line host x-date", signature="${signature}"`;
        });
};

const _fetch = (path, method = 'GET', data = null, options) => {
    const url = new URL(`${path}`, apiUrl);
    const config = {
        url: url,
        method: method,
        headers: {
            'cache-control': 'no-cache',
        },
        data: data,
        json: true,
    };

    Object.assign(config, options);

    const date = new Date().toUTCString();
    return _createKongAuthHeader(orgname, method, url, date)
        .then((authHeader) => {
            config.headers.Authorization = authHeader;
            config.headers['x-date'] = date;
            return config;
        })
        .then(axios);
};

export default _fetch;
