import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UserInfo from './userInfo';
import Biometric from './biometric';
import Pin from './pin';
import Twitter from '../Shared/Twitter/signin';
import {useSelector} from "react-redux";
import {selectBuyData} from "../Buy/reduxSlice";
import {selectAppData} from "../../store/reduxSlice";
import Step from "../Shared/step";
import {isLoggedIn} from "../../util/userFunctions";
import { CAMPAIGN_EXCHANGE, CAMPAIGN_NON_MLM, CAMPAIGN_TWITTER } from '../../js/constants';

const SignUp = ({snackBarMessage}) => {

    const navigate = useNavigate();
    const buyData = useSelector(selectBuyData);
    const appData = useSelector(selectAppData);
    const service = appData.sdkConfiguration.service;
    const twitterFlow = appData.sdkConfiguration.campaign === CAMPAIGN_TWITTER;
    const isNonMlmCampaign = appData.sdkConfiguration.campaign === CAMPAIGN_NON_MLM;
    const isExchangeCampaign = appData.sdkConfiguration.campaign === CAMPAIGN_EXCHANGE;
    const organization = appData.partnerData.organization;
    const connectWalletAddress = appData.connectWallet;
    const referralCode = appData.referralCode;
    const [showScreen, setShowScreen] = useState(appData.partialRegister ? 'pin' : 'biometric');
    const [userInfo, setUserInfo] = useState('');

    const {state} = useLocation();
    const navigateTo = state?.navigateTo;

    const defaultOrgs = ["INF"];
    if(organization) {
        defaultOrgs.push(organization);
    }

    const [uuid, setUuid] = useState('');

    useEffect(() => {
        if (isLoggedIn()) {
            navigate('/')
        }
    }, [navigate]);

    useEffect(() => {
        if (appData.partialRegister) {
            setShowScreen('pin');
            setUuid(state?.uuid);
        }
    }, [appData.partialRegister]);

    const profileData = {
        orgs: defaultOrgs,
        lang: 'english',
        ...userInfo
    };
    if(buyData.address && buyData.addressFrom === "connect") {
        profileData.metadata = {associated_wallets: JSON.stringify([buyData.address])};
    }
    if(connectWalletAddress) {
        profileData.metadata = {associated_wallets: JSON.stringify([connectWalletAddress])};
    }

    const setInitialState = () => {
        setShowScreen('biometric');
        setUserInfo('');
        setUuid('');
    };

    const currentStep = () => {
        if(service === "identity") {
            switch (showScreen) {
                case "biometric":
                    return 1;
                case "pin":
                    return 2;
                default:
                    return 3;
            }
        } else {
            switch (showScreen) {
                case "biometric":
                    return 1;
                case "userInfo":
                    return 2;
                case "pin":
                    return 3;
                default:
                    return 4;
            }
        }
    };

    const totalStep = () => {
        if(service === "identity") {
            return 2;
        }  else {
            return 3;
        }
    };

    return (
        <>
            <Step totalSteps={totalStep()} currentStep={currentStep()} />
            {
                {
                    'biometric': <Biometric snackBarMessage={snackBarMessage} setShowSignUpScreen={setShowScreen} setUuid={setUuid} service={service} />,
                    'userInfo': <UserInfo snackBarMessage={snackBarMessage} setShowScreen={setShowScreen} userInfo={userInfo} setUserInfo={setUserInfo} partialRegister={appData.partialRegister} />,
                    'pin': <Pin snackBarMessage={snackBarMessage} setShowScreen={setShowScreen} profileData={profileData} uuid={uuid} setInitialState={setInitialState} service={service} partialRegister={appData.partialRegister} buyData={buyData} navigateTo={navigateTo} referralCode={referralCode} connectWalletAddress={connectWalletAddress} twitterFlow={twitterFlow} isNonMlmCampaign={isNonMlmCampaign} isExchangeCampaign={isExchangeCampaign} />,
                    'twitter': <Twitter navigateTo={navigateTo} />
                }[showScreen]
            }
        </>
    );
};

export default SignUp;