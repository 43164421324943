import React, {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";
import {Box, Checkbox, CircularProgress, FormControlLabel, Typography} from "@mui/material";
import TwitterVerify from "../../../images/twitter-verify.svg";
import TwitterIcon from '@mui/icons-material/Twitter';
import helpers from "../../../adapters/helpers";
import Button from "../button";
import { mobileCheck } from '../../../util/generalUtil';
import { selectAppData } from '../../../store/reduxSlice';
import { CAMPAIGN_NON_MLM } from '../../../js/constants';

const TwitterSignIn = ({snackBarMessage, navigateTo}) => {

    const navigate = useNavigate();

    const appData = useSelector(selectAppData);
    const isNonMlmCampaign = appData.sdkConfiguration.campaign === CAMPAIGN_NON_MLM;

    const {state} = useLocation();
    const share = state?.share || false;
    const [loading, setLoading] = useState(false);
    const [screen, setScreen] = useState(share ? "share" : "default");
    const [postOpt, setPostOpt] = useState(false);
    const [continueBtn, setContinueBtn] = useState(false);
    const [tweetText, setTweetText] = useState('');

    const isMobile = mobileCheck();

    const twitterLogin = () => {
        setLoading(true);
        let win;
        if(isMobile) {
            win = window.open();
        }
        helpers.twitterRequestToken()
            .then((response) => {
                if(response.data.success) {
                    if(isMobile) {
                        win.location.href = "https://api.twitter.com/oauth/authorize?oauth_token="+response.data.data;
                    } else {
                        win = window.open("https://api.twitter.com/oauth/authorize?oauth_token="+response.data.data, "_blank", "width=700,height=700");
                    }
                    const timer = setInterval(function() {
                        if(win.closed) {
                            clearInterval(timer);
                            handleTwitterLogin();
                        }
                    }, 1000);
                    setTimeout(() => {
                        setContinueBtn(true);
                    }, 10000);
                } else {
                    snackBarMessage({message: 'Failed to get request token.'});
                }
            })
            .catch((error) => {
                setLoading(false);
                snackBarMessage({message: 'Failed to get request token: '+ error});
            });
    };

    const handleTwitterLogin = () => {
        helpers.twitterInfo()
            .then((resp) => {
                setLoading(false);
                if (resp.data.success) {
                    if(isNonMlmCampaign) {
                        navigate('/dashboard');
                    } else {
                        setScreen('share')
                    }
                } else {
                    setScreen('noInfo')
                }
            })
            .catch(() => {
                setLoading(false);
                setScreen('noInfo')
            });
    };

    const next = () => {
        setLoading(true);
        helpers.twitterUpdate({share_opt: postOpt, tweet_text: tweetText})
            .then((resp) => {
                setLoading(false);
                if (resp.data.success) {
                    if(navigateTo && navigateTo === "kyc") {
                        navigate('/kyc');
                    } else {
                        navigate('/dashboard');
                    }
                } else {
                    snackBarMessage({message: 'Something went wrong while updating data'});
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                snackBarMessage({message: 'Something went wrong while updating data'});
            });
    };

    return (
        <Box className={"login-container"} mt={2}>
            <img src={TwitterVerify} alt="twitter verify" width={150} />
            {
                {
                    'default':
                        <>
                            <Typography mt={2} mb={3} variant="h6"><strong>Add Your Twitter Account Now</strong></Typography>
                            <Typography className="text-left" sx={{padding: "0px 40px"}} variant="body1"><strong>This will connect your EverWallet to your Twitter account so you can receive direct tips, and let your followers know you have a verified wallet, proving you're not a bot!</strong></Typography>
                            {loading ?
                                <>
                                    <CircularProgress sx={{mt: 4}}  size={40} color={"custom"} />
                                    <Typography variant="h6" sx={{fontWeight: 'bold'}}>Processing...<br />Please close the popup window after authorization.</Typography>
                                    {continueBtn && <Typography variant="caption" sx={{fontWeight: 'bold'}}>If closing the popup did not update this screen, please <a href="javascript:;" onClick={handleTwitterLogin}>click here</a> to continue.</Typography>}
                                </>
                                :
                                <a href="javascript:;" onClick={twitterLogin} className="twitter-button"><TwitterIcon sx={{color: "#1DA1F2", marginBottom: '-5px'}} /> <span>Sign in with Twitter</span></a>
                            }
                        </>,
                    'share':
                        <>
                            <Typography mt={2} mb={3} variant="h6"><strong>Share my Verified EverWallet to my Twitter Account</strong></Typography>
                            <Typography className="text-left" sx={{padding: "0px 40px", marginBottom: "16px"}} variant="body1"><strong>We will tweet the below message, and add your proof of humanity/referral link to your bio.</strong></Typography>
                            <div className="tweet-text-parent">    
                                <div className="tweet-disable-text">
                                    I have a Verified #EverWallet with @EverestDotOrg! Get yours now to earn $ID rewards, send/receive payments, and prove you’re not a bot: &lt;your affiliate link&gt;
                                </div>
                                <textarea value={tweetText} maxLength={120} onChange={(e) => setTweetText(e.target.value)} className='tweet-text-area' placeholder="Personal message to include in tweet (optional)" autoFocus={true} />
                            </div>
                            <FormControlLabel
                                sx={{mt: 4}}
                                control={
                                    <Checkbox
                                        checked={postOpt}
                                        onChange={() => setPostOpt(prev => !prev)}
                                        inputProps={{ 'aria-label': 'controlled' }} />}
                                label={"I agree to this post and profile update"} />
                            {loading ? <CircularProgress sx={{mt: 4}}  size={40} color={"custom"} /> : <Button onClick={next} disabled={!postOpt} className="sdk-theme-btn normal-text" variant="contained" fullWidth><strong>Continue</strong></Button>}

                        </>,
                    'noInfo':
                        <>
                            <Typography mt={2} mb={3} variant="h6"><strong>Twitter Account not Connected</strong></Typography>
                            <Typography className="text-left" sx={{padding: "0px 40px"}} variant="body1">
                                <strong>Your twitter account is not linked properly. You can retry this process from the home screen after login</strong>
                            </Typography>
                            <Button onClick={() => navigate('/dashboard')} className="sdk-theme-btn normal-text" variant="contained" fullWidth><strong>Skip</strong></Button>

                        </>
                }[screen]
            }
            </Box>
    )
};

export default TwitterSignIn;