import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import helpers from '../../adapters/helpers';
import {Box, Typography, Button, Grid, CircularProgress} from '@mui/material';
import {reset, selectSellData, setData} from './reduxSlice';
import {getCentralLedgetNetwork, getCurrencySymbol} from "../../util/generalUtil";
import {TOKEN_TO_FIAT} from "../../js/constants";
import {setConfirmDialog} from "../Shared/ConfirmDialog";
import {selectAppData, setBackFunc} from "../../store/reduxSlice";
import DialogSuccess from "../../images/dialog-success.png";
import BackBtn from '../Shared/backBtn';
import { handleOrderError } from '../Shared/errorHandler';

let i = 0;
const ConfirmTransaction = ({snackBarMessage}) => {
    const timeoutRef = useRef();
    const sellData = useSelector(selectSellData);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const appData = useSelector(selectAppData);
    const appKey = appData.sdkConfiguration.appKey;
    const [btnLoading, setBtnLoading] = useState(false);

    const totalAmount = (sellData.amount.replaceAll(",", ".") * 1);
    const currencySymbol = getCurrencySymbol(sellData.currency);

    useEffect(() => {
        dispatch(setBackFunc(() => navigate('/sell')));
        getEstimations();
        return () => {
            window.clearTimeout(timeoutRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getEstimations = () => {
        const amountCal = sellData.amount.replaceAll(",", ".") * 1;
        const data = {
            type: TOKEN_TO_FIAT,
            amount: amountCal,
            token: sellData.token.symbol,
            currency: sellData.currency,
            network: getCentralLedgetNetwork(sellData.network),
            exchange: sellData.exchange
        };
        helpers.feeSellEstimator(data)
            .then((response) => {
                setBtnLoading(false);
                if (response.data.success) {
                    let everestFee = 0, networkFee = 0;
                    const respData = response.data.data;
                    const quoteId = respData.quote_id;
                    const estimatedTokenAmount = respData.fiat_out_amount;
                    const conversionRate = respData.conversion_price;
                    const fee = respData.total_fee * 1;
                    const fee_breakdown = respData.fee_breakdown;
                    for(let i = 0; i < fee_breakdown.length; i++) {
                        const currentFee = fee_breakdown[i];
                        if(currentFee.name === "ev_fee") {
                            everestFee = currentFee.value * 1;
                        } else if(currentFee.name === "network_fee") {
                            networkFee = currentFee.value * 1;
                        }
                    }
                    dispatch(setData({totalFee: fee, quoteId, networkFee, everestFee, conversionRate, estimatedTokenAmount}));
                }
            })
            .catch((error) => {
                setBtnLoading(false);
                handleOrderError({error, snackBarMessage});
            });
    };

    const getDepositAddress = (oId) => {
        i++;
        helpers.getDepositAddress(oId).then(resp => {
            if(resp.data.success) {
                const address = resp.data.data.address;
                if(address) {
                    setBtnLoading(false);
                    navigate('/sell/send', {
                        state: {
                            address,
                            orderId: oId
                        }
                    });
                } else {
                    if (i < 10) {
                        timeoutRef.current = setTimeout(() => {
                            getDepositAddress(oId)
                        }, 10000);
                    } else {
                        snackBarMessage({message: "We are unable to get the deposit address. Please try again later"});
                        navigate(-1);
                    }

                }
            } else {
                snackBarMessage({message: "Something went wrong while getting address."});
            }
        }).catch((error) => {
            snackBarMessage({message: "Can't get Deposit Address:"+error.toString()});
        });
    };

    const processTransaction = () => {
        if(!btnLoading) {
            setBtnLoading(true);
            helpers.createOrder({quote_id: sellData.quoteId, wallet_address: sellData.address}, appKey)
                .then(resp => {
                    // setBtnLoading(false);
                    if(resp.data.success) {
                        const respData = resp.data.data;
                        const orderId = respData.order_id;
                        if(sellData.manualSend) {
                            getDepositAddress(orderId);
                        } else {
                            setBtnLoading(false);
                            setConfirmDialog({
                                title: "Sell Success",
                                message: "Your transaction was successful for more details on the transaction please track your order.",
                                cancelButtonText: "Sell again",
                                onCancel: () => {dispatch(reset()); navigate('/sell');},
                                confirmButtonText: "Track Order",
                                onConfirm: () => {dispatch(reset()); navigate('/order/'+orderId);},
                                height: '490px',
                                icon: DialogSuccess
                            });
                        }
                    } else {
                        snackBarMessage({message: "Something went wrong while creating order."});
                    }
                }).catch(error => {
                setBtnLoading(false);
                handleOrderError({error, snackBarMessage, navigate, quoteConfirmFunc: () => {setBtnLoading(true); getEstimations();}, confirmFunc: () => {dispatch(reset()); navigate('/sell')}});
            });
        }
    };

    return (
        <>
            <BackBtn onClickHandler={() => navigate('/sell')} backBtnText={"Confirm Your Order"} />
            <Typography mt={1} variant="caption"><b>Token Send From</b></Typography>
            <Box mt={2} className="confirm-address">
                <Typography variant="caption" component="p"><strong>{sellData.address}</strong></Typography>
            </Box>
            <Typography mt={3} variant="caption" component="p"><b>Order Estimate Details</b></Typography>
            <Box sx={{color: '#A6A6A6'}}>
                <Grid sx={{padding: '10px'}} container>
                    <Grid className='text-left' item={true} xs={6}>
                        <Typography variant="caption">{totalAmount} {sellData.token.symbol} @ {(sellData.conversionRate * 1).toFixed(2)} {sellData.currency}</Typography>
                    </Grid>
                    <Grid className='flex-right' item={true} xs={6}>
                        <span className="confirm-transaction-network" style={{backgroundColor: sellData.network === "Ethereum" ? '#A1A9DC' : '#5D8CCC'}}>{sellData.network}</span>
                    </Grid>

                    <Grid className='text-left' item={true} xs={6}>
                        <Typography variant="caption">{(totalAmount * 1).toFixed(5)} {sellData.token.symbol}</Typography>
                    </Grid>
                    <Grid className='text-right' item={true} xs={6}>
                        <Typography variant="caption">{(sellData.estimatedTokenAmount * 1).toFixed(2)} {sellData.currency}</Typography>
                    </Grid>

                    <Grid className='text-left' item={true} xs={6}>
                        <Typography variant="caption">Total Fees</Typography>
                    </Grid>
                    <Grid className='text-right' item={true} xs={6}>
                        <Typography variant="caption">{(sellData.totalFee).toFixed(2)} {sellData.currency}</Typography>
                    </Grid>

                    <Grid className='text-left' item={true} xs={6}>
                        <Typography variant="caption"><strong>Total Receive</strong></Typography>
                    </Grid>
                    <Grid className='text-right' item={true} xs={6}>
                        <Typography variant="caption"><strong>{currencySymbol}{(sellData.estimatedTokenAmount * 1).toFixed(2)} {sellData.currency}</strong></Typography>
                    </Grid>
                </Grid>
            </Box>

            <Button
                className="sdk-btn2 mt32"
                variant="contained"
                onClick={processTransaction}
                fullWidth
            >
                <strong>{btnLoading ? <><CircularProgress size={15} color="inherit" /> Processing...</> : "Sell"}</strong>
            </Button>
        </>
    );
};

export default ConfirmTransaction;
