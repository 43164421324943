import React, {useEffect, useState} from 'react';
import {FormHelperText, TextField, Typography} from "@mui/material";
import Button from "../Shared/button";
import {useDispatch} from "react-redux";
import {setBackFunc} from "../../store/reduxSlice";

const UserInfo = ({snackBarMessage, userInfo, setUserInfo, setShowScreen, partialRegister}) => {

    const [email, setEmail] = useState(userInfo.email || '');
    const [emailErr, setEmailErr] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setBackFunc(() => setShowScreen('biometric')));
    }, [setShowScreen, dispatch]);

    const next = () => {
        setEmailErr(false);
        if(!email) {
            setEmailErr(true);
            snackBarMessage({message: "Please enter email"});
            return false;
        }
        setUserInfo({email});
        setShowScreen('pin')
    };

    return (
        <>
            <Typography variant="h5"><strong>Enter your email</strong></Typography>

            <TextField
                sx={{mt: 3}}
                inputProps={{style: {WebkitTextFillColor: "#000000"}}}
                variant="outlined"
                label={"Email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={emailErr}
                fullWidth
                required
            />
            {emailErr && <FormHelperText className="text-field-helper-error">*Mandatory field</FormHelperText>}

            <Button onClick={next} className="sdk-theme-btn normal-text" variant="contained" fullWidth>
                <strong>Next</strong>
            </Button>
        </>
    );
};

export default UserInfo;