import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Box, Typography, Grid, CircularProgress} from '@mui/material';
import helpers from "../../adapters/helpers";
import {selectWithdrawData, setBank} from "./reduxSlice";
import Logo from '../../logo.svg';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ConnectPlaidBank from '../Shared/connectPlaidBank';
import {setBackFunc} from "../../store/reduxSlice";
import BackBtn from '../Shared/backBtn';
import AddBankBtn from '../Shared/addBankBtn';

const BankSelect = ({snackBarMessage}) => {
    const {state} = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const sellData = useSelector(selectWithdrawData);

    const [btnLoading, setBtnLoading] = useState(false);
    const [banks, setBanks] = useState(state?.banks || []);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(!state?.banks) {
            getBanks();
        }
        dispatch(setBackFunc(() => navigate('/withdraw')));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const getBanks = () => {
        setLoading(true);
        helpers.getBanks()
            .then((response) => {
                setLoading(false);
                if (response.data.success && response.data.data) {
                    if (response.data.data) {
                        setBanks(response.data.data);
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                snackBarMessage({message: 'Failed to get banks detail: '+ error});
            });
    };

    const selectBank = (bank) => {
        if(!btnLoading) {
            setBtnLoading(true);
            dispatch(setBank(bank));
            navigate('/withdraw/confirm/transaction')
        }
    };

    return (
        <>
            <BackBtn onClickHandler={() => navigate('/withdraw')} backBtnText={"Select Bank Account"} />
            {loading ? <Box className="text-center" mt={8}><CircularProgress/></Box> :
                <>
                    {banks.map(bank =>
                        <Box key={bank.id} className="bank-item" onClick={() => selectBank(bank)}>
                            <Grid container>
                                <Grid item={true} xs={2}>
                                    <img src={Logo} alt="Menu Icon" height={40} width={40} />
                                </Grid>
                                <Grid item={true} xs={8} className="content-center">
                                    <Box sx={{textAlign: 'left'}}>
                                        <p className='buy-token-list-name'><strong>{bank.bankName}</strong></p>
                                        <p className='buy-token-list-symbol buy-token-list-symbol-color'>{bank.accountName} | ***{bank.accountNumber.substr(bank.accountNumber.length - 4)}</p>
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={2}>
                                    <Typography sx={{textAlign: 'right'}}>
                                        {sellData.bank.id === bank.id ? <CheckCircleIcon sx={{fontSize: '30px', color: '#195BB6'}} /> : <ArrowRightIcon sx={{fontSize: '30px', color: '#195BB6'}} />}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    {btnLoading && <Box mt={4} sx={{textAlign: 'center'}}><CircularProgress/></Box>}
                </>
            }

            <ConnectPlaidBank successHandler={getBanks} snackBarMessage={snackBarMessage} />
            <Typography className="text-center" variant="h6" mt={2} mb={2}><strong>OR</strong></Typography>
            <AddBankBtn snackBarMessage={snackBarMessage} successHandler={getBanks} />
        </>
    );
};

export default BankSelect;
