import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    icon: '',
    title: '',
    message: '',
    height: '',
    width: '',
    onConfirm: '',
    onCancel: '',
    confirmButtonShow: '',
    confirmButtonText: '',
    cancelButtonShow: '',
    cancelButtonText: '',
};

export const confirmDialogSlice = createSlice({
    name: 'confirmDialogData',
    initialState,
    reducers: {
        setTitle: (state, action) => {
            state.title = action.payload;
        },
        setData: (state, action) => {
            return action.payload;
        },
    },
});

export const { setTitle, setData } = confirmDialogSlice.actions;

export const selectConfirmDialogData = (state) => state.confirmDialogData;

export default confirmDialogSlice.reducer;