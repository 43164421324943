import React, {useEffect} from "react";
import {Box, Grid, Typography} from "@mui/material";
import Button from "../Shared/button";
import { REWARD_STEP_5 } from "../../js/images";

const Step5 = ({setStep, dispatch, setBackFunc, navigate}) => {

    useEffect(() => {
        dispatch(setBackFunc(() => setStep(4)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <>
            <Typography className="text-center" variant="caption" component={"p"} mt={2} mb={1}>
                <strong>A detailed example of commissions...</strong>
            </Typography>
            <img src={REWARD_STEP_5} alt="referralReward" width={"100%"} />
            <Grid sx={{padding: "0px 40px"}} mt={0.5} container spacing={1}>
                <Grid className="gift-cell text-left" item={true} xs={8}>
                    <Typography component="span" variant="caption">
                        Sign up 1,000 users...
                    </Typography>
                </Grid>
                <Grid className="gift-cell" item={true} xs={4}>
                    
                </Grid>
                <Grid className="text-left" item={true} xs={8}>
                    <Typography className="ref-reward-commission" component="span" variant="caption">
                        Avg. spend per user
                    </Typography>
                </Grid>
                <Grid className="balance-token-cell text-left" item={true} xs={4}>
                    <Typography className="gift-popup-text" variant="caption">$100</Typography>
                </Grid>
                <Grid className="text-left" item={true} xs={8}>
                    <Typography className="ref-reward-commission" component="span" variant="caption">
                        <b>Total Commissions (10%):</b>
                    </Typography>
                </Grid>
                <Grid className="balance-token-cell text-left" item={true} xs={4}>
                    <Typography className="gift-popup-text" variant="caption"><b>$10,000</b></Typography>
                </Grid>
            </Grid>
            <Typography variant="h6" mt={6}>
                <strong>Your referral rewards sure add up!</strong>
            </Typography>
            <Button onClick={() => navigate("/dashboard")} className="sdk-theme-btn normal-text gift-btn-width" variant="contained">
                <strong>Done</strong>
            </Button>
            <Box mt={14}>
                <Typography variant="caption">
                    For Terms & Conditions, please visit: <a href="https://foundationnetwork.org" target="_blank">https://foundationnetwork.org</a> 
                </Typography>
            </Box>
        </>
    );
};

export default Step5;