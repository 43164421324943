import React from 'react';
import { Navigate } from 'react-router-dom';
import {Container, Box} from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Navigation from './navigation';
import {withSnackBar} from '../../util/snackbar';
import {isLoggedIn} from "../../util/userFunctions";
import PoweredBy from '../Shared/poweredBy';

export default withSnackBar(function DefaultLayout({protectedRoute, showRefreshNotice = false, showMenu = true, showBackBtn = true, menuText, children, snackBarMessage}) {
    if(protectedRoute && !isLoggedIn()) {
        return <Navigate to="/" replace />
    } else {
        return (
            <Container component="main" maxWidth="xs">
                <>
                    <CssBaseline />
                    <Navigation snackBarMessage={snackBarMessage} showRefreshNotice={showRefreshNotice} showMenu={showMenu} showBackBtn={showBackBtn} menuText={menuText} />
                    <Box mt={8} sx={{minHeight: "calc(100vh - 120px)"}}>
                        {React.cloneElement(children, { snackBarMessage: snackBarMessage })}
                    </Box>
                    <PoweredBy />
                </>
            </Container>
        );
    }
})
