import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {Box, Button, Container, IconButton, Typography} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import Logo from "../../images/logo-white.png";
import {isLoggedIn} from "../../util/userFunctions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const SignIn = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (isLoggedIn()) {
            navigate(-1)
        }
    }, [navigate]);

    const signIn = () => {
        navigate('/signin')
    };

    const signUp = () => {
        navigate('/signup')
    };

    return (
        <Box className="login-wrapper">
            <IconButton
                className="back-button"
                onClick={() => navigate(-1)}
            >
                <ArrowBackIcon/>
            </IconButton>
            <Container component="main" maxWidth="xs">
                <Box className="login-container color-white">
                    <img className="sign-in-logo" src={Logo} width={450} alt='logo' />
                    <Typography variant="h4" mt={4}>
                        <strong>Your Global Everything Account</strong>
                    </Typography>
                    <Typography fontSize={21} mt={3}>
                    Everest is a platform and ecosystem that empowers users & businesses to build the future.
                    </Typography>
                    <Button onClick={signUp} className="login-buttons white-border sign-up" mt={8} variant="contained" fullWidth>
                        <PersonIcon sx={{mr: 1}} />
                        <strong className="sign-up-label">Sign up</strong>
                    </Button>
                    <Button onClick={signIn} className="login-buttons white-border sign-in" variant="contained" mt={4} mb={4} fullWidth>
                        <strong>Sign in</strong>
                    </Button>
                </Box>
            </Container>
        </Box>
    );
};

export default SignIn;