import { createSlice } from '@reduxjs/toolkit';
import {DOLLAR_SIGN, REGION_EU} from "../js/constants";

const initialState = {
    uuid: "",
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
    birthDate: "",
    photoThumbnail: "",
    residentialAddress: {
        street: "",
        city: "",
        state: "",
        postcode: "",
        country: ""
    },
    identityDocuments: null,
    region: REGION_EU,
    paymentCurrency: DOLLAR_SIGN,
    session: ""
};

export const userSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        setUserID: (state, action) => {
            state.uuid = action.payload;
        },
        setRegion: (state, action) => {
            state.region = action.payload;
        },
        setProfile: (state, action) => {
            return {...state, ...action.payload};
        },
        setSession: (state, action) => {
            state.session = action.payload;
        },
        reset: () => {
            return initialState;
        }
    },
});

export const { setUserID, setRegion, setProfile, setSession, reset } = userSlice.actions;

export const selectUserData = (state) => state.userData;

export default userSlice.reducer;