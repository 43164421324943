import React, { useEffect } from "react";
import QRCode from "react-qr-code";
import {useDispatch, useSelector} from "react-redux";
import {Box, InputAdornment, IconButton, TextField, Typography} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { selectWalletData} from "./reduxSlice";
import { useNavigate } from "react-router-dom";
import { setBackFunc } from "../../store/reduxSlice";

const Receive = ({snackBarMessage}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const walletData = useSelector(selectWalletData);
    const walletAddress = walletData.wallet.address;

    useEffect(() => {
        dispatch(setBackFunc(() => navigate('/token/info')));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const copyBtn = () => {
        navigator.clipboard.writeText(walletAddress);
        snackBarMessage({
            message: 'Wallet Address has been copied successfully.',
            type: 'success'
        });
    };
    
    return (
        <Box className="login-container" mt={8}>
            <Box mt={4} className="full-width">
                <Typography variant="h4" mb={2}>
                    <strong>Receive Info</strong>
                </Typography>
                <TextField
                    className="pay-amount-field"
                    variant="outlined"
                    value={walletAddress}
                    type="text"
                    margin="normal"
                    label={"Wallet Address"}
                    disabled={true}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><IconButton onClick={copyBtn}><ContentCopyIcon /></IconButton></InputAdornment>,
                    }}
                    fullWidth
                />
                <Box mt={4}>
                    <QRCode value={walletAddress} />
                </Box>
            </Box>
        </Box>
    );
};

export default Receive;