import React, {useState} from 'react';
import {TextField} from "@mui/material";
import PersonalDetails from './personalDetails';
import AddressDetails from './addressDetails';
import {removeExtraSpace} from "../../util/generalUtil";

const InputField = ({label, value, disabled, onChangeHandler, width, required = true, fullWidth = true, type = "text", error = false, shrink = false}) => {
    const style = {mt: 3};
    if(width) {
        style.width = width;
    }
    if(value) {
        style["& .MuiInputBase-root.Mui-disabled"] = {
            "& > fieldset": {
                borderColor: "#00B171"
            }
        };
        style["& .MuiInputBase-root"] = {
            "& > fieldset": {
                borderColor: "#00B171"
            }
        };
    }
    return <TextField
        inputProps={{style: {WebkitTextFillColor: "#000000"}}}
        id={label}
        type={type}
        variant="outlined"
        label={label}
        value={value}
        disabled={disabled}
        fullWidth={fullWidth}
        required={required}
        sx={style}
        error={error}
        onChange={(e) => onChangeHandler(e.target.value)}
        helperText={error && "*Mandatory field"}
        FormHelperTextProps={{sx: {fontWeight: "bold", textAlign: "right"}}}
        InputLabelProps={shrink ? {shrink} : {}}
    />
};

const PassThruKyc = ({snackBarMessage}) => {

    const [showScreen, setShowScreen] = useState('personalDetails');
    const userInfoInitial = {firstName: '', lastName: '', dob: '', address1: '', address2: '', city: '', state: '', zipCode: '', country: ''};
    const [userInfo, setUserInfo] = useState(userInfoInitial);

    // const setInitialState = () => {
    //     setShowScreen('personalDetails');
    //     setUserInfo(userInfoInitial);
    // };

    const handleChange = (val, setFunc, setErrFunc, regexCheck = '') => {
        if(regexCheck !== "" && val !== "" && !regexCheck.test(val)) {
            return false;
        }
        setFunc(val);
        const valCheck = typeof val === "string" ? removeExtraSpace(val) : val;
        if(!valCheck) {
            setErrFunc(true);
        } else {
            setErrFunc(false);
        }
    };

    if(showScreen === 'personalDetails') {
        return <PersonalDetails
            snackBarMessage={snackBarMessage}
            setShowScreen={setShowScreen}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            InputField={InputField}
            handleChange={handleChange}
        />
    } else if(showScreen === 'addressDetails') {
        return <AddressDetails
            snackBarMessage={snackBarMessage}
            setShowScreen={setShowScreen}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            InputField={InputField}
            handleChange={handleChange}
        />
    }
};

export default PassThruKyc;