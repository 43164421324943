import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Autocomplete, Box, Button, CircularProgress, TextField, Typography} from "@mui/material";
import {selectAppData, setAppCurrency} from "../../store/reduxSlice";
import helpers from "../../adapters/helpers";

const Currency = ({snackBarMessage}) => {
    const appData = useSelector(selectAppData);
    const dispatch = useDispatch();
    const currencies = [
        { countryCode: 'US', label: 'USD', symbol: '$' },
        { countryCode: 'EU', label: 'EUR', symbol: '€' },
    ];

    const getDefaultCurrency = () => {
        if(appData.currency) {
            const currency = currencies.find((cur) => cur.label === appData.currency.code);
            if(currency) {
                return currency;
            }
        }
        return {countryCode: 'US', label: 'USD', symbol: '$'};
    };

    const [currency, setCurrency] = useState(getDefaultCurrency());
    const [loading, setLoading] = useState(false);


    const setDefaultCurrency = () => {
        if(!loading) {
            setLoading(true);
            helpers.updateDefaultCurrency({currency: currency.label})
                .then((response) => {
                    if (response.data.success) {
                        // dispatch(setAppCurrency({code: currency.label, symbol: currency.symbol}));
                        setLoading(false);
                        snackBarMessage({message: "Default currency has been changed successfully.", type: 'success'})
                    } else {
                        snackBarMessage({message: "Something went wrong."});
                    }
                })
                .catch((error) => {
                    snackBarMessage({message: error.toString()});
                });
        }
    };

    return (
        <>
            <Typography variant="h5" mb={4}><strong>Default Currency</strong></Typography>
            <Autocomplete
                sx={{ mt: 2 }}
                value={currency}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                onChange={(event, value) => setCurrency(value)}
                options={currencies}
                autoHighlight={true}
                openOnFocus={true}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
                            alt=""
                        />
                        {option.label}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select currency"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />

            <Button onClick={setDefaultCurrency} className="login-buttons navigation-login-background sign-up" variant="contained" fullWidth>
                <strong>{loading ? <><CircularProgress size={15} color="inherit" /> Saving...</> : "Save"}</strong>
            </Button>
        </>
    );
};

export default Currency;