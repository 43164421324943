import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "@mui/material";
import { doc, getDoc } from 'firebase/firestore';
import ReactGA from 'react-ga4';
import Routes from './routes';
import theme from "./themes";
import darkTheme from "./themes/dark";
import { emitMessageToSdkPackage } from "./util/generalUtil";
import ConfirmDialog, { setConfirmDialog } from "./components/Shared/ConfirmDialog";
import db from './util/firebase';
import { SERVICE_BUY_SELL, SERVICE_IDENTITY, CAMPAIGN_TWITTER, VIAARPE_APP_KEY } from "./js/constants";
import { GA_TRACKING_ID } from "./js/variables";
import helpers from "./adapters/helpers";
import { selectAppData, setSdkConfiguration, setPartnerData, setReferralCode, setFeatures } from "./store/reduxSlice";
import { selectUserData } from "./store/reduxUserSlice";
import './index.css';

window.addEventListener('message', (event) => {
    if (typeof event.data === 'object' && event.data.action === 'CLOSE_WIDGET') {
        setConfirmDialog({
            title: "Exit",
            message: "Are you sure you want to exit Everest?",
            confirmButtonText: "Yes",
            onConfirm: () => emitMessageToSdkPackage("CLOSE_WIDGET"),
            cancelButtonText: "No",
            height: "490px"
        });
    }
});

const App = () => {
    const browseHistory = createBrowserHistory();
    ReactGA.initialize(GA_TRACKING_ID);
    browseHistory.listen((location) => {
        ReactGA.send({
            hitType: "pageView",
            page: location.pathname + location.search,
        });
    });

    const queryParams = new URLSearchParams(window.location.search);
    const appKey = queryParams.get("appKey");
    
    const [renderApp, setRenderApp] = useState(false);

    const appData = useSelector(selectAppData);
    const service = appData.sdkConfiguration.service;
    const features = appData.features;
    const partnerData = appData.partnerData;
    const themeToUse = partnerData.appKey === VIAARPE_APP_KEY ? darkTheme : theme;
    const userRegion = useSelector(selectUserData).region;
    const dispatch = useDispatch();
    useEffect(() => {
        const service = queryParams.get("service") || SERVICE_BUY_SELL;
        const campaign = queryParams.get("campaign") || CAMPAIGN_TWITTER;
        const buttonColor = queryParams.get("buttonColor") && /^#[0-9A-F]{6}$/i.test("#" + queryParams.get("buttonColor")) ? '#' + queryParams.get("buttonColor") : 'linear-gradient(89.98deg, #35E0F4 0.02%, #33D7F0 6.97%, #2EBDE4 18.55%, #2594D0 33.31%, #195BB6 50.5%, #1132A3 61.64%, #1132A3 99.98%)';// || "#1976d2";
        const buttonTextColor = queryParams.get("buttonTextColor") && /^#[0-9A-F]{6}$/i.test("#" + queryParams.get("buttonTextColor")) ? '#' + queryParams.get("buttonTextColor") : '#ffffff';// || "#ffffff";
        let supportPayload = `<p class="sup-pa"><span class="sup-head">Email: </span>support@everest.org</p>
                            <p class="sup-pa"><span class="sup-head">Phone: </span>+(356) 2546-6021</p>`;

        dispatch(setSdkConfiguration({ appKey, service, campaign, buttonColor, buttonTextColor }));
        if (!appKey) {
            setConfirmDialog({
                title: "Invalid Configuration",
                message: "Please provide a valid APP key or contact everest support." + supportPayload,
                confirmButtonShow: false,
                cancelButtonShow: false,
                height: '370px',
                width: '470px',
            });
        } else {
            helpers.partnerInfo(appKey)
                .then((resp) => {
                    const respData = resp.data.data;
                    if (service === SERVICE_BUY_SELL && !respData.exchange_service_active) {
                        setConfirmDialog({
                            title: "Exchange Service unavailable",
                            message: "Exchange service is not available against provided appKey. Please contact support." + supportPayload,
                            confirmButtonShow: false,
                            cancelButtonShow: false,
                            height: '370px',
                            width: '470px',
                        });
                    } else if (service === SERVICE_IDENTITY && !respData.identity_service_active) {
                        setConfirmDialog({
                            title: "Identity Service unavailable",
                            message: "Identity service is not available against provided appKey. Please contact support." + supportPayload,
                            confirmButtonShow: false,
                            cancelButtonShow: false,
                            height: '370px',
                            width: '470px',
                        });
                    } else {
                        dispatch(setPartnerData({ ...partnerData, ...respData, appKey }));
                        setRenderApp(true);
                    }
                })
                .catch((error) => {
                    let errorObj = JSON.parse(error.request.response);
                    let msg = "Provided APP key is invalid. Please contact support." + supportPayload;
                    if (errorObj.error === "inactive partner") {
                        msg = "Provided APP key has been deactivated. Please contact support." + supportPayload;
                    }
                    setConfirmDialog({
                        title: "Invalid APP Key",
                        message: msg,
                        confirmButtonShow: false,
                        cancelButtonShow: false,
                        height: '370px',
                        width: '470px',
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (renderApp) {
            const queryParams = new URLSearchParams(window.location.search);
            const referralCode = queryParams.get("referralCode");
            if (referralCode) {
                helpers.isValidReferralCode(referralCode)
                    .then((response) => {
                        if (response.data.success && response.data.data) {
                            dispatch(setReferralCode(referralCode));
                        }
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderApp]);

    useEffect(() => {
        if (renderApp && service === SERVICE_BUY_SELL) {
            getFeatures();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderApp, userRegion]);

    const getFeatures = async () => {
        const featuresRef = doc(db, 'features', userRegion);
        const featureSnap = await getDoc(featuresRef);

        if (featureSnap.exists()) {
            dispatch(setFeatures({ ...featureSnap.data(), remoteData: true }));
        }
    };

    return (
        <ThemeProvider theme={themeToUse}>
            <Router>
                <Routes
                    renderApp={renderApp}
                    features={features}
                    service={service}
                    appData={appData}
                    partnerData={partnerData}
                />
            </Router>
            <ConfirmDialog />
        </ThemeProvider>
    )
};

export default App;