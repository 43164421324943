import React, {useRef, useState} from 'react';
import {Box, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Slide, Typography} from "@mui/material";
import Button from '../../Shared/button';
import CloseIcon from '@mui/icons-material/Close';
import InputField from '../../Shared/inputField';
import helpers from "../../../adapters/helpers";
import {setConfirmDialog} from "../../Shared/ConfirmDialog";
import Step from "../../Shared/step";
import WebCam from "react-webcam";
import DialogSuccess from "../../../images/dialog-success.png";

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DocumentNameScreen = ({documentName, setDocumentName, setDocumentScreen, snackBarMessage}) => {

    const nextStep = () => {
        if(!documentName) {
            snackBarMessage({message: "Please enter the document name"});
            return false;
        }
        setDocumentScreen('upload');
    };

    return (
        <>
            <Box className="text-center">
                <Step totalSteps={2} currentStep={1} />
            </Box>
            <Typography mt={2} variant="h4"><strong>Name your document</strong></Typography>

            <InputField label={"Document Name"} value={documentName} onChangeHandler={(val) => setDocumentName(val)} />

            <Button onClick={nextStep} sx={{mt: 2}} fullWidth>
                <strong>Continue</strong>
            </Button>
        </>
    )
};

const DocumentUploadScreen = ({documentName, getDocs, close, snackBarMessage}) => {

    const webCamRef = useRef(null);
    const fileRef = useRef(null);
    const videoConstraints = {
        audio: false,
        height: {
            min: 720,
        },
        width: {
            ideal: 1280,
        },
        aspectRatio: 1.333, // 4:3
        facingMode: 'user',
        frameRate: {
            ideal: 30,
            max: 30,
        },
    };

    const [image, setImage] = useState('');
    const [loading, setLoading] = useState(false);

    const captureDocument = () => {
        const imageSrc = webCamRef.current.getScreenshot();
        setImage(imageSrc)
    };

    const attachDocument = (event) => {
        if (!event.target.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
            alert('Please upload the image in jpg, jpeg or png format');
        } else {
            if (event.target.files && event.target.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    let imageSrc = e.target.result;
                    setImage(imageSrc);
                };
                reader.readAsDataURL(event.target.files[0]);
            }
        }
    };

    const next = () => {
        if(!image) {
            snackBarMessage({message: "Please either select the document or take the photo of the document"});
            return false;
        }

        if(!loading) {
            setLoading(true);
            const arr = image.split(/[,]/);
            helpers.uploadDocument({filename: documentName, content: arr[1]})
            .then((response) => {
                setLoading(false);
                if (response.data.success) {
                    setConfirmDialog({
                        icon: DialogSuccess,
                        title: "Success",
                        message: "Your document has been successfully added to your wallet.",
                        confirmButtonText: "Continue",
                        onConfirm: () => {getDocs(); close();},
                        height: '400px',
                        cancelButtonShow: false
                    });
                } else {
                    snackBarMessage({message: 'Something went wrong while uploading document.'});
                }
            })
            .catch((error) => {
                setLoading(false);
                const err = JSON.parse(error.request.response);
                snackBarMessage({message: 'Error: '+err.error});
            });
        }
    };

    return (
        <>
            <Box className="text-center">
                <Step totalSteps={2} currentStep={2} />
            </Box>
            <Box mt={2}>
                {image ?
                    <>
                        <Box className="doc-video-box image-corner-color">
                            <span />
                            <img className="video-box-inner-content" src={image} alt={"doc"} />
                        </Box>

                        {loading ?
                            <Box mt={4} sx={{color: '#00B171', textAlign: 'center'}}>
                                <CircularProgress size={50} color="inherit" />
                                <Typography variant="h5" sx={{fontWeight: 'bold'}}>Processing...<br />one moment please</Typography>
                            </Box>
                            :
                            <>
                                <Button onClick={next} sx={{mt: 2}} variant="contained" disabled={!document} fullWidth>
                                    <strong>Submit</strong>
                                </Button>

                            <Button className="sdk-theme-btn normal-text" onClick={() => setImage("")} sx={{mt: 2}} highlightButton={true} fullWidth>
                                <strong>Retake Photo</strong>
                            </Button>
                            </>
                        }
                    </>
                    :
                    <>
                        <Box className="doc-video-box">
                            <span />
                            <WebCam
                                className="video-box-inner-content"
                                audio={false}
                                ref={webCamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                                minScreenshotHeight={
                                    videoConstraints.height.min
                                }
                                minScreenshotWidth={videoConstraints.width.min}
                                screenshotQuality={1}
                            />
                        </Box>

                        <Button className="sdk-theme-btn normal-text" onClick={captureDocument} sx={{mt: 4}} variant="contained" fullWidth>
                            <strong>Take Photo</strong>
                        </Button>

                        <Button onClick={() => fileRef.current.click()} sx={{mt: 2}} highlightButton={true} className="sdk-theme-btn normal-text" fullWidth>
                            <strong>Choose File to Upload</strong>
                            <input type="file" ref={fileRef} style={{display: 'none'}} onChange={attachDocument} />
                        </Button>
                    </>
                }
            </Box>
        </>
    )
};

const AddDocument = ({openDocumentModal, setOpenDocumentModal, getDocs, snackBarMessage}) => {
    const [documentScreen, setDocumentScreen] = useState('name');
    const [documentName, setDocumentName] = useState('');

    const close = () => {
        setOpenDocumentModal(false);
        setTimeout(() => {
            setDocumentScreen('name');
            setDocumentName('');
        }, 500);
    };

    return (
        <Dialog fullWidth={true} open={openDocumentModal} TransitionComponent={Transition}>
            <DialogTitle className="buy-modal-text-color">
                <Typography variant="subtitle1" component="p">
                    <strong>Add Document</strong>
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={close}
                    className="menu-buttons"
                >
                    <CloseIcon />
                </IconButton>

            </DialogTitle>
            <DialogContent dividers>
                {
                    {
                        'name': <DocumentNameScreen snackBarMessage={snackBarMessage} documentName={documentName} setDocumentName={setDocumentName} setDocumentScreen={setDocumentScreen} />,
                        'upload': <DocumentUploadScreen snackBarMessage={snackBarMessage} documentName={documentName} getDocs={getDocs} close={close} />
                    }[documentScreen]
                }
            </DialogContent>
        </Dialog>
    );
};

export default AddDocument;