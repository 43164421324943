import React from 'react';
import {Grid, Typography} from "@mui/material";
import { BACK_BTN_ICON } from '../../js/images';

const BackBtn = ({onClickHandler, backBtnText}) => {
    return (
        <Grid container>
            <Grid item={true} xs={1.5}>
                <img className="cursor-pointer" src={BACK_BTN_ICON} onClick={onClickHandler} alt="back" />
            </Grid>
            <Grid item={true} xs={10.5}>
                <Typography className="back-btn-text" variant="h5">
                    <strong>{backBtnText}</strong>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default BackBtn;