import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import GenericTokenImage from "../../../images/generic-token.png";

const TokenGrid = ({token, from, showBalance}) => {
    const columnLength = from === 'select' ? 4 : 5;
    return (
        <Grid container spacing={2}>
            <Grid className="token-image-grid" item={true} xs={1.5}>
                <img
                    className="modal-token-image"
                    src={token.image}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = GenericTokenImage;
                    }}
                    alt="token Icon"
                />
            </Grid>
            <Grid item={true} xs={5.5}>
                <Box sx={{textAlign: 'left'}}>
                    <p className='buy-token-list-name' title={token.name}>{token.name}</p>
                    <p className='buy-token-list-symbol buy-token-list-symbol-color' title={showBalance ? token.fmt_balance+" "+token.symbol : ""}>{showBalance ? (token.fmt_balance * 1).toFixed(6) * 1 : ""} {token.symbol}</p>
                </Box>
            </Grid>
            <Grid item={true} xs={columnLength}>
                <Box className="modal-network-wrapper">
                    <span className="modal-token-network" style={{backgroundColor: token.networkSymbol === "ETH" ? '#A1A9DC' : '#5D8CCC'}}>{token.network}</span>
                </Box>
            </Grid>
            {from === 'select' &&
            <Grid item={true} xs={1}>
                <Typography sx={{textAlign: 'right', color: '#195BB6'}}>
                    <ArrowDropDownIcon />
                </Typography>
            </Grid>
            }
        </Grid>
    );
};

export default TokenGrid;