import * as React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { MetaMaskProvider } from "@metamask/sdk-react";
import store from "./store";
import App from "./app";

// window.store = store; //TODO: Remove

ReactDOM.render(
  <React.StrictMode>
    <MetaMaskProvider
    debug={false}
    sdkOptions={{
      logging:{
        developerMode: false,
      },
      extensionOnly: true,
      preferDesktop: true,
      dappMetadata: {
        name: "Everest",
        url: window.location.href,
        iconUrl: "https://www.everest.org/logo.svg"
      }
    }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </MetaMaskProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
