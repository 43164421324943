import React from "react";
import {TextField} from "@mui/material";

const InputField = ({label, value, disabled, onChangeHandler, width, required = true, fullWidth = true}) => {
    const style = {mt: 3};
    if(width) {
        style.width = width;
    }
    if(value) {
        style["& .MuiInputBase-root.Mui-disabled"] = {
            "& > fieldset": {
                borderColor: "#00B171"
            }
        };
        style["& .MuiInputBase-root"] = {
            "& > fieldset": {
                borderColor: "#00B171"
            }
        };
    }
    return <TextField
        inputProps={{style: {WebkitTextFillColor: "#000000"}}}
        id={label}
        variant="outlined"
        label={label}
        value={value}
        disabled={disabled}
        fullWidth={fullWidth}
        required={required}
        sx={style}
        onChange={(e) => onChangeHandler(e.target.value)}
    />
};

export default InputField;