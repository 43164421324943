import React, {useState, useEffect} from "react";
import {useNavigate} from 'react-router-dom';
import {Box, CircularProgress, Grid, Typography} from "@mui/material";
import helpers from "../../adapters/helpers";
import moment from "moment";
import SellIcon from "../../images/history-sell.png";
import BuyIcon from "../../images/history-buy.png";
import {getCurrencySymbol, getTransactionStatusLabel} from "../../util/generalUtil";
import { FIAT_TO_EVER_ID, FIAT_TO_TOKEN, FIAT_WITHDRAW, TOKEN_TO_FIAT, TOKEN_TO_TOKEN } from "../../js/constants";

const HistoryItem = ({data, navigate}) => {
    const validTransactionTypes = [FIAT_TO_TOKEN, FIAT_TO_EVER_ID, TOKEN_TO_FIAT, TOKEN_TO_TOKEN, FIAT_WITHDRAW];
    if (!validTransactionTypes.includes(data.transaction_type)) {
        return null;
    }

    const status = getTransactionStatusLabel(data.transaction_status);
    const date = moment(data.created_at).format("DD MMM yyyy, H:mm");

    const detail = () => {
        navigate('/order/'+data.trx_uuid,  {
            state: {
                orderData: data,
                from: 'history'
            }
        })
    };

    let currencySymbol = getCurrencySymbol(data.currency_code_in);
    let icon = BuyIcon;
    let token = data.token_out;
    let gridCurrencyLabel = "Bought";
    let amountLabel = "Amount Paid";
    let paid = currencySymbol+(data.currency_in_amount * 1).toFixed(2);
    let receive = (data.token_out_amount * 1).toFixed(5) +' '+ data.token_out;

    if(data.transaction_type === TOKEN_TO_FIAT) {
        currencySymbol = getCurrencySymbol(data.currency_code_out);
        icon = SellIcon;
        token = data.token_in;
        gridCurrencyLabel = "Sold";
        amountLabel = "Amount Sent";
        paid = ((data.token_in_amount * 1).toFixed(5) * 1) +' '+ data.token_in;
        receive = (data.currency_out_amount * 1).toFixed(2) + " " + data.currency_code_out+".c";
    } else if(data.transaction_type === FIAT_WITHDRAW) {
        currencySymbol = getCurrencySymbol(data.currency_code_out);
        icon = SellIcon;
        token = "";
        gridCurrencyLabel = "Withdraw";
        amountLabel = "Amount";
        paid = ((data.token_in_amount * 1).toFixed(5) * 1) +' '+ data.currency_code_out+".c";
        receive = currencySymbol + (data.currency_out_amount * 1).toFixed(2);
    } else if(data.transaction_type === TOKEN_TO_TOKEN) {
        paid = `${(data.amount * 1).toFixed(5) * 1} ${data.token_in}`;
    }

    return (
        <>
            <Box className="history-item" onClick={detail}>
                <Grid container>
                    <Grid item={true} xs={1}>
                        <img src={icon} />
                    </Grid>
                    <Grid item={true} xs={5}>
                        <strong>{gridCurrencyLabel} {token}</strong>
                    </Grid>
                    <Grid item={true} xs={6}>
                        {date}
                    </Grid>
                </Grid>
                <Grid style={{color: '#A1A1A1'}} container>
                    <Grid item={true} xs={1} />
                    <Grid item={true} xs={5}>
                        <b>{amountLabel}</b>
                    </Grid>
                    <Grid item={true} xs={6}>
                        <b>Amount Received</b>
                    </Grid>
                </Grid>
                <Grid style={{fontWeight: '500'}} container>
                    <Grid item={true} xs={1} />
                    <Grid className="text-overflow-cut" item={true} xs={5}>
                        {paid}
                    </Grid>
                    <Grid className="text-overflow-cut" item={true} xs={6}>
                        {receive}
                    </Grid>
                </Grid>
                <Grid mt={0.5} container>
                    <Grid className="text-right" item={true} xs={12}>
                        Status: <span className={`history-status-${data.transaction_status}`}><b>{status}</b></span>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};


const History = ({snackBarMessage}) => {
    const [loader, setLoader] = useState(true);
    const [pageLoader, setPageLoader] = useState(false);
    const [history, setHistory] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        getHistory();
    }, [pageNumber]);
      
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [pageLoader, totalPages]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || pageLoader || (pageNumber >= totalPages)) {
            return;
        }
        setPageNumber(pageNumber + 1);
    };

    const getHistory = () => {
        setPageLoader(true);
        helpers.getOrderHistory(pageNumber)
        .then(resp => {
            setLoader(false);
            setPageLoader(false);
            const respData = resp.data;
            if(respData.success && respData.data.results && respData.data.results.length > 0) {
                setHistory([...history, ...respData.data.results]);
                setTotalPages(respData.data.total_pages);
            }
        })
        .catch((error) => {
            setLoader(false);
            snackBarMessage({message: error.toString()});
        })
    };

    return (
        <>
            <Box className="order-detail-wrapper" mt={8}>
                {loader ?
                    <Box className="text-center" mt={8}><CircularProgress size={100}/></Box> :
                    <>
                        {history.length > 0 ?
                        <>
                            {history.map((item, index) =>
                                <HistoryItem key={index} data={item} navigate={navigate} />
                            )}
                        </> :
                            <Typography className="text-center" variant="h4">No Records</Typography>
                        }
                        {pageLoader && <Box className="text-center" mt={2}><CircularProgress size={50} /></Box>}
                    </>
                }
            </Box>
        </>
    );
};

export default History;